import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { ReactComponent as PasswordCrossIcon } from "./../../assets/passwordCross.svg";
import { ReactComponent as PasswordTickIcon } from "./../../assets/passwordTick.svg";
import Logo from "./../../assets/Logo.svg";
import header_Logo from "./../../assets/Logo/header_logo_new.png";
import "./css/styles.css";

const ForgotPassword = (props) => {
  const [charLength, setCharLength] = useState(false);
  const [numberLength, setNumberLength] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const onChangeCurrentPassword = (e) => {
    const { name, value } = e.target;
    console.log("value", value);
    if (name == "newPassword") {
      setNewPassword(value);
      setCurrentPassword(e.target.value);
      setPasswordError("");
      let charLength1 = value.length >= 10 ? true : false;
      const regex = /\d/;
      const regex1 = /[!@#$%^&*_+]/g;
      setSpecialChar(regex1.test(value));
      setNumberLength(regex.test(value));
      setCharLength(charLength1);
      validateNewpassword(value, confirmNewPassword);
    } else {
      setConfirmNewPassword(value);
      validateNewpassword(newPassword, value);
    }
  };

  const validateNewpassword = (newp, confirmNewPass) => {
    if (newp != "" && confirmNewPass != "") {
      if (newp === confirmNewPass) {
        setPasswordError("");
        return true;
      } else {
        setPasswordError("New Password and Confirm New Password do not match.");
        return false;
      }
    }
  };

  const submitConform = async () => {
    if (validateNewpassword(newPassword, confirmNewPassword)) {
    }
  };
  return (
    <>
      <div className="sellConatctDiv"></div>
      <div className="logoDiv">
        <img src={header_Logo} className="appLogo" alt="Logo" sm="4" />
      </div>
      <div className="resetPasswordMainDiv">
        <div>
          <p className="newPasswordHeading"> Create a New Password</p>
          <div className="headingParagraphDiv">
            <p className="headingParagraph">
              Your password should have at least 10 characters with at least one
              number and one special character.
            </p>
          </div>
        </div>
        <div>
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="newPassword"
                    value={currentPassword}
                    onChange={onChangeCurrentPassword}
                    placeholder="Enter New Password"
                  />
                  <Form.Text className="">
                    <span className="close-icon mr-1">
                      {charLength ? (
                        <PasswordTickIcon />
                      ) : (
                        <PasswordCrossIcon />
                      )}
                    </span>
                    At least 10 characters
                  </Form.Text>
                  <Form.Text className="">
                    <span className="close-icon mr-1">
                      {numberLength ? (
                        <PasswordTickIcon />
                      ) : (
                        <PasswordCrossIcon />
                      )}
                    </span>
                    At least one number
                  </Form.Text>
                  <Form.Text className="">
                    <span className="close-icon mr-1">
                      {specialChar ? (
                        <PasswordTickIcon />
                      ) : (
                        <PasswordCrossIcon />
                      )}
                    </span>
                    One special character(!@#$%^&*_+)
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmNewPassword"
                    placeholder="Confirm new Password"
                    value={confirmNewPassword}
                    onChange={onChangeCurrentPassword}
                  />
                </Form.Group>
              </Col>
              {passwordError != "" && (
                <div className="passwordErrorError">{passwordError}</div>
              )}
            </Row>
          </Form>
        </div>
        <center>
          {" "}
          <Button
            variant="outline-secondary"
            className="submitBtn"
            onclick={submitConform}
          >
            {" "}
            Submit{" "}
          </Button>
        </center>
      </div>
    </>
  );
};

export default ForgotPassword;
