import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tab, Nav } from "react-bootstrap";
import { withRouter } from "react-router";
import { ReactComponent as PlusIcon } from "./../../assets/plusIcon.svg";
import CollectionsCard from "./../../components/CollectionsCard";
import SearchComponent from "./../../components/SearchComponent";
import { getTimeZones } from "./../../library/Requests";
import "./styles.css";

import {
  getDraftedCollections,
  getPublishedCollections,
} from "../../library/Requests";

const Collections = (props) => {
  const [draftedCollections, setDraftedCollections] = useState([]);
  const [publishedCollections, setPublishedCollections] = useState([]);
  const [eventKeyTypes, setEventKeyTypes] = useState("");

  const [draftedCurrentPage, setDraftedCurrentPage] = useState(1);
  const [draftedPazeSize, setDraftedPazeSize] = useState(50);
  const [draftedTotalCount, setDraftedTotalCount] = useState(0);
  const [isDraftedLoading, setIsDraftedLoading] = useState(false);

  const [publishedCurrentPage, setPublishedCurrentPage] = useState(1);
  const [publishedPageSize, setPublishedPageSize] = useState(50);
  const [publishedTotalCount, setPublishedTotalCount] = useState(0);
  const [isPublishedLoading, setIsPublishedLoading] = useState(false);

  const [findText, setFindText] = useState("");
  const [timeZ, setTimeZ] = useState([]);

  useEffect(async () => {
    const timezResp = await getTimeZones();
    setTimeZ(timezResp.data);
    getDataOnload();
    setEventKeyTypes(
      props?.location?.search == "?type=published" ? "published" : "drafted"
    );
  }, [props]);

  const getDataOnload = async () => {
    getDraftedOnload(draftedCurrentPage, "", "");
    getPublishedOnload(publishedCurrentPage, "", "");
  };

  const getDraftedOnload = async (draftedCurrentPage, pagetype, findText) => {
    setIsDraftedLoading(true);
    const draftedResp = await getDraftedCollections(
      draftedCurrentPage,
      draftedPazeSize,
      findText
    );
    if (pagetype == "LoadMore") {
      setDraftedCollections([...draftedCollections, ...draftedResp.data]);
    } else {
      setDraftedCollections(draftedResp.data);
    }
    setDraftedTotalCount(draftedResp.totalCount);
    setIsDraftedLoading(false);
  };

  const loadMoreDrafted = () => {
    var a = draftedCurrentPage + 1;
    setDraftedCurrentPage(a);
    getDraftedOnload(a, "LoadMore", "");
  };

  const getPublishedOnload = async (
    publishedCurrentPage,
    pagetype,
    findText
  ) => {
    setIsPublishedLoading(true);
    const publishededResp = await getPublishedCollections(
      publishedCurrentPage,
      publishedPageSize,
      findText
    );
    if (pagetype == "LoadMore") {
      setPublishedCollections([
        ...publishedCollections,
        ...publishededResp.data,
      ]);
    } else {
      setPublishedCollections(publishededResp.data);
    }
    setPublishedTotalCount(publishededResp.totalCount);
    setIsPublishedLoading(false);
  };

  const loadMorePublished = () => {
    var a = publishedCurrentPage + 1;
    setPublishedCurrentPage(a);
    getPublishedOnload(a, "LoadMore", "");
  };

  const searchClick = async (search) => {
    setFindText(search);
    eventKeyTypes == "drafted"
      ? getDraftedOnload(draftedCurrentPage, "", search)
      : getPublishedOnload(publishedCurrentPage, "", search);
  };

  return (
    <div className="lotsContainer">
      <Row className="lotsManagementRow">
        <Col sm="4" className="noPadding">
          <span className="headingText">Lot Management</span>
        </Col>
        <Col sm="6" className="searchiconBox">
          <SearchComponent searchClickCall={searchClick} />
        </Col>
        <Col sm="2" className="createBtnColumn">
          <Button
            className="createCollectionBtn"
            onClick={(event) => {
              props.history.push(`/dashboard/createCollection`);
            }}
          >
            <PlusIcon className="plusIcon" />
            Create Collection
          </Button>
        </Col>
      </Row>
      <Tab.Container
        id="article-tabs"
        activeKey={eventKeyTypes}
        onSelect={(selectedKey) => setEventKeyTypes(selectedKey)}
      >
        <Row sm="12">
          <Col sm="12" className="noPadding">
            <Nav variant="tabs" className="collectionsTabs">
              <Nav.Item>
                <Nav.Link eventKey="drafted">Drafted Collections</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="published">Published Collections</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm="12" className="noPadding">
            <Tab.Content>
              <Tab.Pane eventKey="drafted">
                <Row sm="12" className="cardGroup">
                  {(draftedCollections || []).map((collection, index) => {
                    let timeZone = timeZ.find(
                      (i) => collection?.timeZoneId === i?.id
                    )?.abbrivation;
                    if (!timeZone) {
                      timeZone = timeZ.find(
                        (i) => collection?.timeZoneId === i?.id
                      )?.name;
                    }

                    return (
                      <>
                        {collection.status === 2 && (
                          <>
                            <CollectionsCard
                              key={index.toString()}
                              collectionData={collection}
                              timeZone={timeZone}
                            />
                          </>
                        )}
                      </>
                    );
                  })}
                </Row>
                {isDraftedLoading && (
                  <>
                    {" "}
                    <div className="spinner-borderWord">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </>
                )}
                {!isDraftedLoading &&
                  draftedTotalCount > draftedCollections.length && (
                    <Row className="loadMoreRow">
                      <Button
                        onClick={() => loadMoreDrafted()}
                        variant="custom"
                        className="loadMoreBtn bottomPadding"
                      >
                        Load More
                      </Button>
                    </Row>
                  )}
              </Tab.Pane>
              <Tab.Pane eventKey="published">
                <Row sm="12" className="cardGroup">
                  {(publishedCollections || []).map((collection, index) => {
                    let timeZone = timeZ.find(
                      (i) => collection?.timeZoneId === i?.id
                    )?.abbrivation;
                    if (!timeZone) {
                      timeZone = timeZ.find(
                        (i) => collection?.timeZoneId === i?.id
                      )?.name;
                    }
                    return (
                      <>
                        {collection.status === 1 && (
                          <>
                            <CollectionsCard
                              key={index.toString()}
                              collectionData={collection}
                              timeZone={timeZone}
                            />
                          </>
                        )}
                      </>
                    );
                  })}
                </Row>
                {isPublishedLoading && (
                  <>
                    {" "}
                    <div className="spinner-borderWord">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </>
                )}
                {!isPublishedLoading &&
                  publishedTotalCount > publishedCollections.length && (
                    <Row className="loadMoreRow">
                      <Button
                        onClick={() => loadMorePublished()}
                        variant="custom"
                        className="loadMoreBtn bottomPadding"
                      >
                        Load More
                      </Button>
                    </Row>
                  )}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <Row className="viewMoreBtnRow">
        {/* <Button
                    className="viewMoreBtn"
                >
                    View More
                </Button> */}
      </Row>
    </div>
  );
};

export default withRouter(Collections);
