import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { ReactComponent as BannerChevronRight } from "./../../assets/bannerChevronRight.svg";
import { ReactComponent as BannerChevronLeft } from "./../../assets/bannerChevronLeft.svg";
import { ReactComponent as BannerEditIcon } from "./../../assets/bannerEditIcon.svg";
import { ReactComponent as BannerDeleteIcon } from "./../../assets/bannerDeleteIcon.svg";
import "./styles.css";
import { withRouter } from "react-router-dom";
import CustomModal from "./../../components/CustomModal";

class WSR extends Component {
  static propTypes = {};

  state = {
    isUserLogin: false,
    deleteWarning: false,
    deleteData: {},
  };
  //   /api/Customer/getallusers
  componentDidMount = () => {
    // this.getAllSiteManagers();
  };
  deleteItem = (item) => {
    this.setState({ deleteWarning: true, deleteData: item });
  };
  deleteWarningConform = () => {
    this.props.isDeleteSiteManager(this.state.deleteData);
    this.setState({ deleteWarning: false });
  };

  render() {
    const { allData } = this.props;
    const { deleteWarning } = this.state;
    return (
      <Row className="wsrContainer">
        {(allData || []).map((item, index) => {
          //  console.log(item);
          return (
            <>
              <div className="managewordManagerView">
                <Col sm="8" className="imageColumn imageColumnWord noPadding">
                  <img className="wordSectionImage" src={item?.image} />
                </Col>
                <Col sm="4" className="wsRightContainer noPadding">
                  <Row className="bannerIconsRow birSpacing">
                    <BannerEditIcon
                      onClick={() => {
                        this.props.isEditSiteManager(item);
                      }}
                      className="BannerEditIcon"
                    />
                    <BannerDeleteIcon
                      onClick={() => {
                        this.deleteItem(item);
                      }}
                    />
                  </Row>
                  <p className="wsBannerTitle">{item?.title}</p>
                  <Row>
                    {index == 0 && (
                      <ul className="wordIndicatorsList">
                        <li className="wordBannerIndicators filledIndicators"></li>
                        <li className="wordBannerIndicators"></li>
                        <li className="wordBannerIndicators"></li>
                        <li className="wordBannerIndicators"></li>
                      </ul>
                    )}
                    {index == 1 && (
                      <ul className="wordIndicatorsList">
                        <li className="wordBannerIndicators "></li>
                        <li className="wordBannerIndicators filledIndicators"></li>
                        <li className="wordBannerIndicators"></li>
                        <li className="wordBannerIndicators"></li>
                      </ul>
                    )}
                    {index == 2 && (
                      <ul className="wordIndicatorsList">
                        <li className="wordBannerIndicators "></li>
                        <li className="wordBannerIndicators"></li>
                        <li className="wordBannerIndicators filledIndicators"></li>
                        <li className="wordBannerIndicators"></li>
                      </ul>
                    )}
                    {index == 3 && (
                      <ul className="wordIndicatorsList">
                        <li className="wordBannerIndicators "></li>
                        <li className="wordBannerIndicators"></li>
                        <li className="wordBannerIndicators"></li>
                        <li className="wordBannerIndicators filledIndicators"></li>
                      </ul>
                    )}

                    <span className="bannerStoriesText">
                      Category # {item?.categoryId}
                    </span>
                  </Row>
                  <Row className="bannerRightContent">{item?.subTitle}</Row>
                  <a href={item?.textLink} target="_blank">
                    <Button className="bannerDiscoverBtn">Discover</Button>
                  </a>

                  <Row className="bannerArrowsRow">
                    <span className="leftArrBox boxSpacing">
                      {" "}
                      <BannerChevronLeft />
                    </span>
                    <span className="rightArrBox">
                      {" "}
                      <BannerChevronRight />{" "}
                    </span>
                  </Row>
                </Col>
              </div>
            </>
          );
        })}
        <CustomModal
          show={deleteWarning}
          titleText={`Do you want to delete this`}
          deleteText="Delete"
          cancelText="Cancel"
          onCancel={() => this.setState({ deleteWarning: false })}
          onDelete={() => this.deleteWarningConform()}
        />
      </Row>
    );
  }
}

export default withRouter(WSR);
