import moment from "moment";

import { ENCRYPTED_KEY } from "../library/constants.js";

import { AES, enc } from "crypto-js";
import vect2 from "../assets/image/vect2.png";

export const triggerEventForProfileUpdate = (eventName) => {
  var event = new Event(eventName);
  window.document.dispatchEvent(event);
};

export const convert24to12HoursFormat = (timeString1) => {
  var now = moment(timeString1, "HHmmss").format("h:mm a");
  return now;
};
const GetLOGINID = () => {
  try {
    let decrypted = AES.decrypt(
      localStorage.getItem("customerInfoDetails"),
      ENCRYPTED_KEY
    );
    let decryptedObject = decrypted.toString(enc.Utf8);
    const LOGINID11 =
      localStorage.getItem("customerInfoDetails") != null
        ? JSON.parse(decryptedObject).id
        : undefined;
    return LOGINID11;
  } catch (e) {
    console.log(e);
  }
};
export const LOGINID = GetLOGINID();
export const showUserMessage = (msg) => {
  document.getElementById("showUserMessageDivID").innerHTML = msg;
  document.getElementById("showUserMessageDivID1").style.display = "flex";
  setTimeout(() => {
    document.getElementById("showUserMessageDivID1").style.display = "none";
  }, 5000);
};

export const validateImageFormat = (type) => {
  if (type == "image/jpeg" || type == "image/png") {
    return true;
  } else {
    showUserMessage("Please upload Jpeg/Png images only.");
    return false;
  }
};

export const appendToMesaages = (value, typeMessage, text) => {
  console.log(value);
  var type = value;
  var lights = document.getElementsByClassName("activeBidInsertRowItem");
  while (lights.length)
    lights[0].className = lights[0].className.replace(
      /\bactiveBidInsertRowItem\b/g,
      ""
    );

  if (typeMessage == "warningMessage" && text != "") {
    var currencyName1 = document.getElementById("currencyName").value;
    document.getElementById("messagesListParent")?.insertAdjacentHTML(
      "beforeend",
      ` <div>
            <div class='auction_live_sec d-flex align-items-center'>
              <p class='warningMessgeTextsent'>${text}</p>
            </div>
          </div>`
    );
  } else {
    var currencyName1 = document.getElementById("currencyName").value;
    document.getElementById("messagesListParent")?.insertAdjacentHTML(
      "beforeend",
      ` <div id="BidInsertRowItem" class="activeBidInsertRowItem">
            <div class='auction_live_sec d-flex align-items-center'>
              <div class='live_sec_icon'><span class='live_sec_icon_img'>
                  <img src=${vect2} />
                  </span>
              </div>
              <div class='live_sec_cont'>
                <p class='live_sec_cont_type'>${value.split("_")[1]}</p>
                <p class='live_sec_cont_amount'>${value.split("_")[0]} ${
        value.split("_")[2]
      } ${currencyName1} </p>
              </div>
            </div>
          </div>`
    );
  }
  setTimeout(function () {
    var lights = document.getElementsByClassName("activeBidInsertRowItem");
    while (lights?.length)
      lights[0].className = lights[0].className.replace(
        /\bactiveBidInsertRowItem\b/g,
        ""
      );
  }, 2000);
  document.getElementById("auction_box_inner_list").scrollTop = 999999;
};
