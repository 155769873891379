import fetch from "cross-fetch";
import moment from "moment";
import _ from "lodash";
import Axios from "axios";

function _getSession() {
  // const state = Store.getState();
  return null;
}
function _clearCache(url) {
  return;
}

export const get = async function (url) {
  const response = await Axios.get(url, {
    headers: {
      Authorization: "bearer " + localStorage.getItem("cat"),
    },
  });
  return response.data;
};
export const put = async function (url, body = {}) {
  const response = await Axios.put(url, body, {
    headers: {
      Authorization: "bearer " + localStorage.getItem("cat"),
    },
  });
  return response.data;
};
export const post = async function (url, body = {}) {
  const response = await Axios.post(url, body, {
    headers: {
      Authorization: "bearer " + localStorage.getItem("cat"),
    },
  });
  return response.data;
};
export const del = async function (url, body = {}) {
  const response = await Axios.delete(url, {
    headers: {
      Authorization: "bearer " + localStorage.getItem("cat"),
    },
  });
  return response.data;
};

// export const get = async function (url) {
//     let Token_key = null;

//     return request(url, {
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         session: _getSession(),
//         'Authorization': Token_key,
//       }
//     });
//   };

//   export const put = async function (url, body = {}) {
//     let Token_key = null;
//     const promise = await request(url, {
//       method: 'put',
//       headers: {
//         'Content-Type': 'application/json',
//         session: _getSession(),
//         'Authorization': Token_key,
//       },
//       body: JSON.stringify(body)
//     });
//     _clearCache(url);
//     return promise;
//   };

//   export const convertObjectToQuerystring = (obj) => {
//     return Object.keys(obj)
//       .map((key) => `${key}=${obj[key]}`)
//       .join("&");
//   };

//   export const post = async function (url, body = {}) {
//     let Token_key = null;
//     const promise = await request(url, {
//       method: 'post',
//       headers: {
//         'Content-Type': 'application/json',
//         session: _getSession(),
//         'Authorization': Token_key,
//       },
//       body: JSON.stringify(body)
//     });
//     _clearCache(url);
//     return promise;
//   };

//   export const del = async function (url, body = {}) {
//     let Token_key = null;
//     const promise = await request(url, {
//       method: 'delete',
//       headers: {
//         'Content-Type': 'application/json',
//         session: _getSession(),
//         'Authorization': Token_key,
//       },
//       body: JSON.stringify(body)
//     });
//     _clearCache(url);
//     return promise;
//   };

const request = async function (url, options = {}) {
  // Add version into all of the requests

  try {
    const resp = await fetch(url, options);
    const status = resp.status;
    const contentType = resp.headers
      .get("content-type")
      .replace(/;.*$/, "")
      .trim();
    // session should be one of the headers

    const body = await resp.text();
    let content;
    switch (contentType.toLowerCase()) {
      case "application/json":
      case "application/x-javascript":
      case "text/javascript":
      case "text/x-javascript":
      case "text/x-json":
      default:
        try {
          content = JSON.parse(body);
        } catch (err) {
          content = { err: body };
        }
        break;
      // eslint-disable-next-line no-fallthrough
      case "text/html":
      case "text/plain":
        content = body;
        break;
    }
    if (status == 401) {
      // Store.dispatch(authenticationLogout());
      // Store.dispatch(userRequest('login'));
    }
    if (!(status >= 200 && status <= 299)) {
      // const errResponse = JSON.parse(body);
      // const errors = errResponse.errors ? errResponse.errors.join(' ') : JSON.stringify(errResponse);
      // throw new Error(`${errors} status ${status}`);
    }
    content.status = status;
    if (_.isEmpty(content)) {
      throw new Error("Error, no content returned");
    }
    if (content.err) {
      throw new Error(content.err);
    }
    return content;
  } catch (err) {
    const [error, status] = err.message.split("status");
    const parsedStatus = status && parseInt(status.trim(), 10);
    const params = url.split("/").pop();
    //   if (!(params === 'validate' && parsedStatus === 409)) {
    //     Store.dispatch(displayError(error));
    //   }
    return {
      success: false,
      data: [],
      results: [],
      result: [],
      user: {},
      error: error && error.trim(),
      status: parsedStatus,
    };
  }
};
