import "./App.css";
import { Switch, Route } from "react-router-dom";
import routes from "./routes";
import Header from "./components/Header";

import HomePage from "./pages/HomePage";

function App() {
  return (
    <div className="adminControlApp">
      <HomePage />
    </div>
  );
}

export default App;
