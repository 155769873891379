import React from "react";
import { Card, Button, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import moment from "moment";
import "./styles.css";

const AuctionsCard = (props) => {
  const { auctionsData, type, timeZone } = props;
  const actionType = auctionsData?.auctionType == "1" ? "online" : "live";
  // console.log("auctionsData",auctionsData)
  const auctionTimeZone =
    auctionsData?.timeZoneAbbr !== null
      ? auctionsData?.timeZoneAbbr
      : auctionsData?.timeZone;
  return (
    <Col sm="4">
      <Card className="auctionsCard">
        <Card.Img
          variant="top"
          className="mainImageNew"
          src={auctionsData?.coverImage}
          onClick={() => {
            props.history.push(
              `/dashboard/auctionManagement/AuctionLots/${auctionsData?.id}?type=${type}`
            );
          }}
          style={{ cursor: "pointer" }}
        />
        <Card.Body>
          <Card.Text>
            {/* <span className="date">{moment(auctionsData?.onlineStartDate).format("MMMM Do YYYY")}</span>
                        <Line className="lineStyles" />
                        <span className="time">
                            {(auctionsData?.auctionType == "1") ? <>
                                {moment(auctionsData?.onlineStartTime, 'HHmmss').format("h:mm a")} {timeZone}  </> :
                                <>{moment(auctionsData?.liveStartTime, 'HHmmss').format("h:mm a")} {timeZone}  </>
                            }
                        </span> */}

            {auctionsData?.auctionType == "1" ? (
              <>
                <span className="date">
                  {moment(auctionsData?.auctionStartDate).format(
                    "MMMM Do YYYY"
                  )}
                </span>
                <Line className="lineStyles" />
                <span className="time">
                  {" "}
                  {moment(auctionsData?.auctionStartTime, "HHmmss").format(
                    "h:mm a"
                  )}{" "}
                  {auctionTimeZone}{" "}
                </span>
              </>
            ) : (
              <>
                <span className="date">
                  {moment(auctionsData?.auctionStartDate).format(
                    "MMMM Do YYYY"
                  )}
                </span>
                <Line className="lineStyles" />
                <span className="time">
                  {" "}
                  {moment(auctionsData?.auctionStartTime, "HHmmss").format(
                    "h:mm a"
                  )}{" "}
                  {auctionTimeZone}{" "}
                </span>
              </>
            )}

            <Line className="lineStyles" />
            <span> {auctionsData?.auctionType} </span>
          </Card.Text>
          <Card.Title className="lineClamp">{auctionsData?.title}</Card.Title>
          <Col className="browseBtnColumn">
            <span className="reservePriceText">
              {auctionsData?.lotsMetReservePrice} Lots met Reserve Price
            </span>
            <Button
              className="actionBrowseBtn"
              onClick={() => {
                props.history.push(
                  `/dashboard/auctionManagement/AuctionLots/${auctionsData?.id}?type=${type}`
                );
              }}
            >
              Browse Lot
            </Button>
          </Col>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default withRouter(AuctionsCard);
