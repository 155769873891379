import { get, post, put, del } from "./helpers";
import { API_BASE, SERVER_IP } from "../constants";

export const getAllSiteManagersCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/SiteManagement/getsitemanager?Type=${id}`
  );
};
export const deletesitemanagerCall = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/SiteManagement/deletesitemanager`,
    body
  );
};
export const updatesitemanagerCall = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/SiteManagement/updatesitemanager`,
    body
  );
};
