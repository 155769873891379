import React, { Component } from "react";
import { Row, Col, Button, Carousel } from "react-bootstrap";
import { ReactComponent as RightTilt } from "./../../assets/rightTilt.svg";
import { ReactComponent as ArrowRightBanner } from "./../../assets/arrowRightBanner.svg";
import { ReactComponent as ArrowLeftBanner } from "./../../assets/arrowLeftBanner.svg";
import { ReactComponent as BannerEditIcon } from "./../../assets/bannerEditWhite.svg";
import { ReactComponent as BannerDeleteIcon } from "./../../assets/bannerDeleteWhite.svg";
import "./styles.css";
import {
  createNewHSM,
  getAllSiteManagersCall,
} from "./../../library/Requests/SiteManager";
import CustomModal from "./../../components/CustomModal";

import { Switch, Route, withRouter } from "react-router-dom";

// import Carousel from "react-bootstrap/Carousel";

const Banner =
  "https://consumerenergyalliance.org/cms/wp-content/uploads/2016/09/massachusetts-boston-landscape.jpg";
// const BannerTwo = "https://finearthiphop.blob.core.windows.net/images/collection/c4e961e2-d3d2-4468-a611-b71ce9b42520/637521744576559359-1616577654279__Madonna_benois_01.jpg";

// const items = [
//     {
//       id: 1,
//       heading: "Basquiat & HipHop",
//       smallHeading: "Stories",
//       peregraph: "How Basquiat became a hero of the Hip Hop Community",
//       sliderImg: Banner,
//     }
//     // {
//     //   id: 2,
//     //   heading: "Basquiat & HipHop",
//     //   smallHeading: "Stories",
//     //   peregraph: "How Basquiat became a hero of the Hip Hop Community",
//     //   sliderImg: BannerTwo,
//     // },
//     // {
//     //   id: 3,
//     //   heading: "Basquiat & HipHop",
//     //   smallHeading: "Stories",
//     //   peregraph: "How Basquiat became a hero of the Hip Hop Community",
//     //   sliderImg: Banner,
//     // },
//     // {
//     //   id: 4,
//     //   heading: "Basquiat & HipHop",
//     //   smallHeading: "Stories",
//     //   peregraph: "How Basquiat became a hero of the Hip Hop Community",
//     //   sliderImg: BannerTwo,
//     // }
//     ];

class HSR extends Component {
  static propTypes = {};

  state = {
    isUserLogin: false,
    deleteWarning: false,
    deleteData: {},
  };
  //   /api/Customer/getallusers
  componentDidMount = () => {
    // this.getAllSiteManagers();
  };
  deleteItem = (item) => {
    this.setState({ deleteWarning: true, deleteData: item });
  };
  deleteWarningConform = () => {
    this.props.isDeleteSiteManager(this.state.deleteData);
    this.setState({ deleteWarning: false });
  };
  getAllSiteManagers = async () => {
    // const resp = await getAllSiteManagersCall("1");
    // this.setState({allData:resp?.data });
  };
  render() {
    // categoryId: "",
    // image: "",
    // title: "",
    // subTitle: "",
    // textLink: "",
    // type: "1",

    // const { allData } = this.state;
    const { allData } = this.props;
    const { deleteWarning } = this.state;
    return (
      <>
        <div className="apper">
          <div className="hsrContainer">
            {(allData || []).map((item, index) => {
              //  console.log(item);
              return (
                <>
                  <div className="manageSiteManagerView">
                    <Row className="imageSectionView">
                      <img src={item?.image || Banner} />
                    </Row>
                    <div className="BottomSectionView">
                      <Row className="bannerIconsRow">
                        <BannerEditIcon
                          onClick={() => {
                            this.props.isEditSiteManager(item);
                          }}
                          className="BannerEditIcon hsrIcon"
                        />
                        <BannerDeleteIcon
                          className="hsrIcon"
                          onClick={() => {
                            this.deleteItem(item);
                          }}
                        />
                      </Row>
                      <Row className="bannerContentRow">
                        <Col className="bannerTextContent">
                          <p className="bannerCategoryText">
                            {" "}
                            <RightTilt />{" "}
                            <span className="finalTextcolorName">
                              Category #{item.categoryId}
                            </span>{" "}
                          </p>
                          <p className="bannerTitleText">
                            <span className="finalTextcolorName">
                              {item.title.substr(0, 40)}{" "}
                            </span>{" "}
                          </p>
                          <p className="bannerSubText">
                            <span className="finalTextcolorName">
                              {" "}
                              {item.subTitle.substr(0, 120)}
                            </span>{" "}
                          </p>
                          <Row style={{ paddingLeft: "5px" }}>
                            <span className="leftArrowBox boxSpacing">
                              {" "}
                              <ArrowLeftBanner />
                            </span>
                            <span className="rightArrowBox">
                              {" "}
                              <ArrowRightBanner />{" "}
                            </span>
                          </Row>
                        </Col>

                        {index == 0 && (
                          <Col className="bannerCountContainer">
                            <ul className="bannerNumberLine">
                              <li className="bannerNumberCount numberActiveIndicator">
                                01
                              </li>
                              <li className="bannerNumberCount"></li>
                              <li className="bannerNumberCount"></li>
                              <li className="bannerNumberCount"></li>
                            </ul>
                          </Col>
                        )}
                        {index == 1 && (
                          <Col className="bannerCountContainer">
                            <ul className="bannerNumberLine">
                              <li className="bannerNumberCount"></li>
                              <li className="bannerNumberCount numberActiveIndicator">
                                02
                              </li>
                              <li className="bannerNumberCount"></li>
                              <li className="bannerNumberCount"></li>
                            </ul>
                          </Col>
                        )}
                        {index == 2 && (
                          <Col className="bannerCountContainer">
                            <ul className="bannerNumberLine">
                              <li className="bannerNumberCount"></li>
                              <li className="bannerNumberCount"></li>
                              <li className="bannerNumberCount numberActiveIndicator">
                                03
                              </li>
                              <li className="bannerNumberCount"></li>
                            </ul>
                          </Col>
                        )}
                        {index == 3 && (
                          <Col className="bannerCountContainer">
                            <ul className="bannerNumberLine">
                              <li className="bannerNumberCount"></li>
                              <li className="bannerNumberCount"></li>
                              <li className="bannerNumberCount"></li>
                              <li className="bannerNumberCount numberActiveIndicator">
                                04
                              </li>
                            </ul>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </>
              );
            })}

            {/*             
            <div className="custom_top_banner_slider">
        <Carousel fade>
          {items.map((i) => {
            return (
              <Carousel.Item key={i.id}>
                <img
                  className="d-block w-100"
                  src={i.sliderImg}
                  alt="benner-slider-img"
                />
                <Carousel.Caption className="text-left">
                  <span className="position-relative font-weight-bold pl-2">
                    {i.smallHeading}
                  </span>
                  <h3 className="mb-2">{i.heading}</h3>
                  <p>{i.peregraph}</p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div> */}
          </div>
          <CustomModal
            show={deleteWarning}
            titleText={`Do you want to delete this`}
            deleteText="Delete"
            cancelText="Cancel"
            onCancel={() => this.setState({ deleteWarning: false })}
            onDelete={() => this.deleteWarningConform()}
          />
        </div>
      </>
    );
  }
}

export default withRouter(HSR);
