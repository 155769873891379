import React, { useState, useEffect, Component } from "react";
import { Row, Col, Accordion, Card, Form, Button } from "react-bootstrap";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import { ReactComponent as ImagePlaceholderIcon } from "./../../assets/ImagePlaceholder.svg";
import { ReactComponent as DeleteBin } from "./../../assets/deleteBin.svg";
import TagsInput from "./../../components/TagsInput";
import "./styles.css";
import { Switch, Route, withRouter } from "react-router-dom";

import {
  getTimeZones,
  getcurrenciesCall,
  deleteImageByIdCall,
} from "./../../library/Requests";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import moment from "moment";
import { data } from "jquery";

import uplaodImageNew from "../../services";
import {
  convert24to12HoursFormat,
  validateImageFormat,
} from "./../../Scenes/common.js";

const format = "h:mm a";
const now = moment().hour(0).minute(0);

class CollectionDetailsForm extends Component {
  static propTypes = {};

  state = {
    timezones: [],
    allCurrenciesData: [],
    image: { coverImage: "" },
  };
  //   /api/Customer/getallusers
  componentDidMount = () => {
    this.getTimeZones();
    this.getAllCurrencies();
  };
  getTimeZones = async () => {
    const resp = await getTimeZones();
    const responseData = resp.data;
    this.setState({
      timezones: responseData,
      coverImage: this.props?.state?.coverImage,
    });
  };

  getAllCurrencies = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ allCurrenciesData: resp?.data });
  };
  selectedTags = (tags) => {};
  DefaultValuenow = (time) => {
    if (time != undefined && time != null && time != "" && time) {
      return moment(time, "HHmmss");
    } else {
      // return moment("11:04:00",'HHmmss');
      return moment().hour(0).minute(0);
    }
  };

  handleChange = async (e) => {
    if (e.target.files.length) {
      this.props?.updateImageError();
      if (validateImageFormat(e.target.files[0].type)) {
        const filesName = e.target.files[0];
        this.setState({
          image: {
            coverImage: URL.createObjectURL(e.target.files[0]),
          },
        });
        const respApp = await uplaodImageNew(filesName, "collection");
        this.props.handleInputChangeforImageCall(
          "coverImage",
          respApp?.imageUrl
        );
      }
    }
  };
  dateFormatType = (date) => {
    var dtToday = new Date();
    if (date != undefined && date != "") {
      dtToday = new Date(date);
    }
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var DateNow = year + "-" + month + "-" + day;
    return DateNow;
  };

  render() {
    const todayDate = new Date();
    const {
      handleChangeValue,
      handleAuctionChange,
      handleInputChangeforImageCall,
      errorsFields,
      errorsData,
      state,
      timeOnChange,
      handleBiddingType,
      handleDeleteImg,
    } = this.props;
    console.log("errorsFields", errorsFields);
    const startTimeFormat = moment(state.onlineStartTime).format("h:mm A");
    console.log(state);
    console.log(errorsFields);

    const AcType = state.auctionType == "1" ? true : false;
    console.log(state.timeZoneId);
    console.log(this.props);
    console.log(now);
    const { timezones, image, allCurrenciesData } = this.state;
    return (
      <>
        <div className="apper">
          <Card>
            <Accordion.Toggle
              id="Create_Collection_Section_1"
              as={Card.Header}
              eventKey="0"
            >
              <span className="formHeading">1. Collection Details</span>
              <AngleUpIcon className="angleUpIcon" />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="collectionsDetailsBody">
                <Form.Group>
                  <Form.Label className="requiredField">Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    onChange={handleChangeValue}
                    value={state.title}
                    isInvalid={errorsFields?.title}
                    maxLength="300"
                    placeholder="Enter Title"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorsFields?.title ? <>{errorsData?.title}</> : null}
                  </Form.Control.Feedback>

                  <Form.Label>Artist Name</Form.Label>
                  <TagsInput
                    selectedTags={this.selectedTags}
                    selectTagsList={this.props.selectTagsList}
                    className="form-control"
                    name="artistName"
                    value={state.artistName}
                    placeholder="Enter Artist Name"
                  />

                  <Form.Label className="requiredField">
                    Select Currency
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => handleChangeValue(e)}
                    name="currency"
                    value={state.currency}
                    isInvalid={errorsFields?.currency}
                  >
                    <option value="" key="">
                      Select Currency
                    </option>
                    {(allCurrenciesData || [])?.map((currencies) => {
                      return (
                        <>
                          {(currencies.currencyCode === "CAD" ||
                            currencies.currencyCode === "USD") && (
                            <option value={currencies.id} key={currencies.id}>
                              {currencies.currencyCode}
                            </option>
                          )}
                        </>
                      );
                    })}
                    <optgroup label="Other Currency">
                      {/* {(timezones || []).map((timezone) => (
                                    <option value={timezone.id} key={timezone.id}>
                                        {timezone.name} ({timezone.currentUtcOffset})
                                    </option>
                                ))} */}
                      {(allCurrenciesData || [])?.map((currencies) => (
                        <option value={currencies.id} key={currencies.id}>
                          {currencies.currencyCode}
                        </option>
                      ))}
                    </optgroup>
                    {/* {(allCurrenciesData || []).map((item, index)=>(
                                <option
                                 selected={(LotData?.currency== item?.currencyCode) ? true : false}
                                  name="currency" value={item?.currencyCode} 
                                  >{item?.currencyCode}</option>
                                  ))} */}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errorsFields?.currency ? (
                      <>{errorsData?.currency}</>
                    ) : null}
                  </Form.Control.Feedback>
                  <Form.Label>Auction Type</Form.Label>
                  <Row className="">
                    <Form.Check
                      inline
                      type="radio"
                      className="collectionsRadioBtn"
                      id="online"
                      label="Online"
                      name="auctionType"
                      value={1}
                      checked={AcType}
                      onChange={(e) => {
                        // console.log("Auction_Value:", state.auctionType);
                        handleAuctionChange(e);
                      }}
                    />
                    <Form.Check
                      // nameqw={state.auctionType}
                      inline
                      type="radio"
                      className="collectionsRadioBtn rightRadioBtn"
                      id="live"
                      label="Live"
                      name="auctionType"
                      value={2}
                      checked={state.auctionType == "2" ? true : false}
                      onChange={(e) => {
                        // console.log("Auction_Value:", state.auctionType);
                        handleAuctionChange(e);
                      }}
                    />
                  </Row>
                  <Form.Control.Feedback type="invalid">
                    {errorsFields?.auctionType ? (
                      <>{errorsData?.auctionType}</>
                    ) : null}
                  </Form.Control.Feedback>

                  <Row>
                    <Form.Label className="requiredField">
                      Select Timezone
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => handleChangeValue(e)}
                      name="timeZoneId"
                      value={state.timeZoneId}
                      isInvalid={errorsFields?.timeZoneId}
                    >
                      <option value="" key="">
                        Select Timezone
                      </option>
                      {(timezones || []).map((timezone) => {
                        return (
                          <>
                            {timezone?.name === "Eastern Standard Time" && (
                              <option value={timezone.id} key={timezone.id}>
                                {timezone.name} ({timezone?.abbrivation} (
                                {timezone.currentUtcOffset}))
                              </option>
                            )}
                          </>
                        );
                      })}

                      <optgroup label="Other Timezones">
                        {(timezones || []).map((timezone) => (
                          <option value={timezone.id} key={timezone.id}>
                            {timezone.name} ({timezone?.abbrivation} (
                            {timezone.currentUtcOffset}))
                          </option>
                        ))}
                      </optgroup>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errorsFields?.timeZoneId ? (
                        <>{errorsData?.timeZoneId}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Row>
                  {state.auctionType == "1" ? (
                    <>
                      <Row className="COllectionStartEndTIme">
                        <Row className="COllectionStartTIme">
                          <Form.Group style={{ marginBottom: "0" }}>
                            <Form.Label>Start Date & time</Form.Label>
                            <Row className="selectDateTimeRow">
                              <Row className="selectDateRow">
                                {/* <DatePicker 
                                                selected={todayDate} 
                                                onChange={date => handleChangeValue(date)} 
                                            /> */}
                                <Form.Control
                                  // min={this.dateFormatType()}
                                  type="date"
                                  name="onlineStartDate"
                                  value={moment(state.onlineStartDate).format(
                                    "YYYY-MM-DD"
                                  )}
                                  // value={state.onlineStartDate}
                                  onChange={(e) => handleChangeValue(e)}

                                  // placeholder="Date of Birth"
                                  // minDate={moment().toDate()}
                                />
                              </Row>
                              <Row className="selectTimeRow">
                                <TimePicker
                                  showSecond={false}
                                  value={this.DefaultValuenow(
                                    state?.onlineStartTime
                                  )}
                                  className="xxx"
                                  name="startTime"
                                  onChange={(e) =>
                                    timeOnChange(e, "onlineStartTime")
                                  }
                                  format={format}
                                  use12Hours
                                  inputReadOnly
                                />
                              </Row>
                            </Row>
                          </Form.Group>
                        </Row>
                        <Row className="COllectionEndTIme">
                          <Form.Group style={{ marginBottom: "0" }}>
                            <Form.Label>End Date & time</Form.Label>
                            <Row className="selectDateTimeRow">
                              <Row className="selectDateRow">
                                <Form.Control
                                  className="start_date_select"
                                  type="date"
                                  min={this.dateFormatType(
                                    state.onlineStartDate
                                  )}
                                  name="onlineEndDate"
                                  value={moment(state.onlineEndDate).format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChange={(e) => handleChangeValue(e)}
                                  // placeholder="Date of Birth"
                                />
                              </Row>
                              <Row className="selectTimeRow">
                                <TimePicker
                                  showSecond={false}
                                  value={this.DefaultValuenow(
                                    state?.onlineEndTime
                                  )}
                                  className="xxx"
                                  name="endTime"
                                  onChange={(e) =>
                                    timeOnChange(e, "onlineEndTime")
                                  }
                                  format={format}
                                  use12Hours
                                  inputReadOnly
                                />
                              </Row>
                            </Row>
                          </Form.Group>
                        </Row>
                      </Row>
                      {errorsData?.ActionDateErrors != "" && (
                        <Row className="ActionDateErrorsData">
                          {errorsData?.ActionDateErrors}
                        </Row>
                      )}
                    </>
                  ) : (
                    <>
                      <Row>
                        <Row className="COllectionStartTIme">
                          <Form.Group style={{ marginBottom: "0" }}>
                            <Form.Label>Start Date & time</Form.Label>
                            <Row className="selectDateTimeRow">
                              <Row className="selectDateRow">
                                <Form.Control
                                  // min={this.dateFormatType()}
                                  className="start_date_select"
                                  type="date"
                                  name="liveStartDate"
                                  value={moment(state.liveStartDate).format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChange={(e) => handleChangeValue(e)}
                                />
                              </Row>
                              <Row className="selectTimeRow">
                                <TimePicker
                                  showSecond={false}
                                  value={this.DefaultValuenow(
                                    state?.liveStartTime
                                  )}
                                  className="xxx"
                                  name="liveStartTime"
                                  onChange={(e) =>
                                    timeOnChange(e, "liveStartTime")
                                  }
                                  format={format}
                                  use12Hours
                                  inputReadOnly
                                />
                              </Row>
                            </Row>
                          </Form.Group>
                        </Row>
                      </Row>

                      <Form.Label>Bidding Type</Form.Label>
                      <Row className="">
                        <Form.Check
                          variant="custom-variant"
                          inline
                          type="radio"
                          className="collectionsRadioBtn"
                          label="Advanced Bidding"
                          name="biddingType"
                          // value={true}
                          checked={state?.isAdvanceBidding ? true : false}
                          autocomplete="off"
                          onChange={(e) => {
                            handleBiddingType(true, "advance");
                          }}
                        />
                        <Form.Check
                          variant="custom-variant"
                          inline
                          type="radio"
                          className="collectionsRadioBtn"
                          label="Absentee Bidding"
                          name="biddingType"
                          checked={state?.isAbsenteeBidding ? true : false}
                          // value={false}
                          autocomplete="off"
                          onChange={(e) => {
                            handleBiddingType(true, "absentee");
                          }}
                        />
                      </Row>

                      <Row>
                        <Row className="COllectionStartTIme">
                          <Form.Group style={{ marginBottom: "0" }}>
                            <Form.Label>
                              {state.isAdvanceBidding
                                ? "Advanced Bid Start Date & Time"
                                : "Absentee Bid Start Date & Time"}
                            </Form.Label>
                            <Row className="selectDateTimeRow">
                              <Row className="selectDateRow">
                                <Form.Control
                                  min={this.dateFormatType()}
                                  max={this.dateFormatType(state.liveStartDate)}
                                  className="start_date_select"
                                  type="date"
                                  name="biddingStartDate"
                                  value={moment(state.biddingStartDate).format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChange={(e) => handleChangeValue(e)}
                                  disabled={
                                    !state?.isAdvanceBidding &&
                                    !state?.isAbsenteeBidding
                                      ? true
                                      : false
                                  }
                                />
                              </Row>
                              <Row className="selectTimeRow">
                                <TimePicker
                                  showSecond={false}
                                  defaultValue={this.DefaultValuenow(
                                    state?.biddingStartTime
                                  )}
                                  className="xxx"
                                  name="biddingStartTime"
                                  onChange={(e) =>
                                    timeOnChange(e, "biddingStartTime")
                                  }
                                  format={format}
                                  use12Hours
                                  inputReadOnly
                                  disabled={
                                    !state?.isAdvanceBidding &&
                                    !state?.isAbsenteeBidding
                                      ? true
                                      : false
                                  }
                                />
                              </Row>
                            </Row>
                          </Form.Group>
                        </Row>
                      </Row>

                      {errorsData?.LiveTimeErrors != "" && (
                        <Row className="ActionDateErrorsData">
                          {errorsData?.LiveTimeErrors}
                        </Row>
                      )}
                    </>
                  )}
                  <Row className="imageContainer">
                    <Row className="imageRow">
                      {state?.coverImage ? (
                        <>
                          <DeleteBin
                            className="deleteImgIcon"
                            onClick={(image) => {
                              handleDeleteImg(image);
                            }}
                          />
                          <img
                            src={state?.coverImage}
                            alt="Preview"
                            className="previewImageCollection"
                          />
                        </>
                      ) : (
                        <label htmlFor="uploadButton">
                          <span className="labelStyles">
                            <ImagePlaceholderIcon />
                            <p className="uploadImageBtn">Upload image</p>
                          </span>
                        </label>
                      )}

                      {!state.coverImage && (
                        <input
                          id="uploadButton"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          // className="uploadImageBtn"
                          onChange={this.handleChange}
                        />
                      )}
                    </Row>
                  </Row>
                  {this.props?.coverImageError && (
                    <p className="image-error">{this.props?.coverImageError}</p>
                  )}
                </Form.Group>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </>
    );
  }
}

//   const mapDispatchToProps = (dispatch) =>
//     bindActionCreators(
//       {
//           HomePage,
//       },
//       dispatch
//     );

export default withRouter(CollectionDetailsForm);
