export const API_BASE = "/api";

var azure_accountID_id = "fineartblob";
export const azure_contentGuId = "temp";
export const ENCRYPTED_KEY = "FINEARTHIPHOPENCRYPTEDKEY";

export const VONTAGE_API_KEY = "47382381"; //"47354851"
export const VONTAGE_API_SECRET = "729e80e57105483f662ae1736f0fd07e81e1fc8c"; //"31c783736d0e36b726b1600cb9e5f8f20d65093b"
// export const SERVER_IP = 'https://api-development5.azurewebsites.net';
// 'https://api-development5.azurewebsites.net/dev';
// 'https://api-development5.azurewebsites.net/staging';
// let SERVER_IP_URL;
let SERVER_IP_URL = "https://api.fineart.hiphop";
if (typeof window !== "undefined") {
  var hostName = window.location.hostname;
  if (hostName === "localhost") {
    SERVER_IP_URL = "https://qaapi.fineart.hiphop";
    azure_accountID_id = "qafahhblob";
  } else if (hostName === "qabackoffice.fineart.hiphop") {
    SERVER_IP_URL = "https://qaapi.fineart.hiphop";
    azure_accountID_id = "qafahhblob";
  }
}

export const SERVER_IP = SERVER_IP_URL;
export const azure_accountID = azure_accountID_id;
