import { Row, Col, Dropdown, Button, ButtonGroup, Form } from "react-bootstrap";
import { ReactComponent as UpArrowIcon } from "./../../assets/upArrow.svg";
// import { ReactComponent as DoArrowIcon } from "./../../assets/downArrow.svg"
import "./styles.css";
import { UPDATE_FILTER_DATA } from "../../library/Requests/eventConstants";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import {
  Slider as SliderR,
  Handles,
  Tracks,
  Rail,
  Ticks,
} from "react-compound-slider";
import { Handle, Track, Tick, SliderRail } from "./components";
import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import {
  ReactiveBase,
  RangeSlider,
  SelectedFilters,
  // ResultList,
  // ReactiveList,
} from "@appbaseio/reactivesearch";

class FilterDataComponent extends Component {
  static propTypes = {};

  state = {
    artists: [],
    categories: {},
    selectedArtists: [],
    selectedCategories: [],
    selectedPaymentStatus: [],
    selectedReservePrice: false,
    showArtists: true,
    showCategories: true,
    showReservedPrice: true,
    showPaymentStatus: true,
    isAuctionManagement: false,
    showcurrentBid: true,
    showEstimate: true,
    // currentBidFrom: 0,
    // currentBidTo: 90000,
    // estimateBidFrom: 0,
    // estimateBidTo: 90000,
    limitTo: 5,
    limitCategory: 5,
    bidMin: 1,
    bidMax: 900000,
    domain: [10, 20],
    updateValue: [10, 20],
    extimateMin: 1,
    extimateMax: 900000,
    domainEstimate: [10, 20],
    updateValueEstimate: [10, 20],
    firstTimeUpdate: false,
    firstTimeUpdateBid: false,
    avoidByDefauldFirstCall: false,
  };

  componentDidMount = () => {
    const self = this;
    window.document.addEventListener(
      UPDATE_FILTER_DATA,
      function (event) {
        self.updateStateData();
      },
      false
    );
    // this.updatepriceFilter();
    // this.updatepriceFilter1();
    const pagetype = this.props.location?.pathname;
    if (pagetype.indexOf("dashboard/auctionManagement") > 0) {
      this.setState({ ...this.state, isAuctionManagement: true });
    }
  };

  updateStateData = () => {
    const datan = this.props?.collectionDetailFull;
    if (datan?.artists) {
      this.setState({ artists: datan?.artists });
      let artistsArray = datan?.artists;
      // console.log("showArtists__", artistsArray.length)
    }
    if (datan?.categories) {
      const databew = { categories: { data: datan?.categories } };
      this.setState({ categoriesData: databew });
    }
    this.setState({ avoidByDefauldFirstCall: true });
    this.updateStateValues();
  };
  updateStateValues = (type) => {
    const { bidMin, bidMax, extimateMin, extimateMax } = this.state;
    const maxEstimat = this.props?.collectionDetailFull?.maxEstimatePrice;
    const maxCurrentBidPrice =
      this.props?.collectionDetailFull?.maxCurrentBidPrice;
    const bidinfo = [bidMin, maxCurrentBidPrice];
    const estimateinfo = [extimateMin, maxEstimat];
    // console.log(this.props);
    // console.log(this.state);
    // const artistsList = this.props?.collectionDetailFull?.artists;
    // const categoriesList = this.props?.collectionDetailFull?.categories;
    // console.log("4444444vinod11")
    this.setState(
      {
        domain: bidinfo,
        updateValue: bidinfo,
        domainEstimate: estimateinfo,
        updateValueEstimate: estimateinfo,
      },
      () => {
        // console.log("updated",this.state);
      }
    );

    // if(type="clearAll"){

    // }
  };

  onUpdate = (value) => {
    const { updateValue } = this.state;
    if (updateValue[0] != value[0] || updateValue[1] != value[1]) {
      this.setState({ updateValue: value });
      // this.getRefreshData();
    }
  };
  onUpdateDataCall = (value) => {
    const { updateValue, firstTimeUpdateBid, avoidByDefauldFirstCall } =
      this.state;
    if (avoidByDefauldFirstCall) {
      this.setState({ firstTimeUpdateBid: true });
      if (
        updateValue[0] != value[0] ||
        updateValue[1] != value[1] ||
        firstTimeUpdateBid
      ) {
        this.setState({ updateValue: value });
        this.getRefreshData();
      }
    }
  };
  onUpdateEstimate = (value) => {
    const { updateValueEstimate } = this.state;
    if (
      updateValueEstimate[0] != value[0] ||
      updateValueEstimate[1] != value[1]
    ) {
      this.setState({ updateValueEstimate: value });
      // this.getRefreshData();
    }
  };
  onUpdateEstimateDataCall = (value) => {
    const { updateValueEstimate, firstTimeUpdate, avoidByDefauldFirstCall } =
      this.state;
    if (avoidByDefauldFirstCall) {
      this.setState({ firstTimeUpdate: true });
      //  console.log("updateValueEstimate",updateValueEstimate)
      //  console.log("value",value)
      if (
        updateValueEstimate[0] != value[0] ||
        updateValueEstimate[1] != value[1] ||
        firstTimeUpdate
      ) {
        this.setState({ updateValueEstimate: value });
        this.getRefreshData();
      }
    }
  };

  // updatepriceFilter = () => {
  //   var typingTimerNew;
  //   var doneTypingIntervalNew = 2000;
  //   const self = this;
  //   (function () {

  //     var parent = document.querySelector("#rangeSliderEstimate");
  //     if (!parent) return;

  //     var
  //       rangeS = parent.querySelectorAll(".range-input-Estimate"),
  //       numberS = parent.querySelectorAll(".number-input-Estimate");

  //     rangeS.forEach(function (el) {
  //       el.oninput = function () {
  //         var slide1 = parseFloat(rangeS[0].value),
  //           slide2 = parseFloat(rangeS[1].value);

  //         if (slide1 > slide2) {
  //           [slide1, slide2] = [slide2, slide1];
  //         }
  //         self.setState({ estimateBidFrom: slide1, estimateBidTo: slide2 })
  //         clearTimeout(typingTimerNew);
  //         typingTimerNew = setTimeout(self.getRefreshData, doneTypingIntervalNew);

  //       }
  //     });

  //   })();
  // }
  // updatepriceFilter1 = () => {
  //   var typingTimer;
  //   var doneTypingInterval = 2000;
  //   const self = this;
  //   (function () {
  //     var parent = document.querySelector("#rangeSliderCurrentBid");
  //     if (!parent) return;
  //     var
  //       rangeS = parent.querySelectorAll(".range-input-CurrentBid"),
  //       numberS = parent.querySelectorAll(".number-input-CurrentBid");

  //     rangeS.forEach(function (el) {
  //       el.oninput = function () {
  //         var slide1 = parseFloat(rangeS[0].value),
  //           slide2 = parseFloat(rangeS[1].value);
  //         if (slide1 > slide2) {
  //           [slide1, slide2] = [slide2, slide1];
  //         }
  //         self.setState({ currentBidFrom: slide1, currentBidTo: slide2 })
  //         clearTimeout(typingTimer);
  //         typingTimer = setTimeout(self.getRefreshData, doneTypingInterval);
  //       }
  //     });

  //   })();
  // }

  params = new URLSearchParams(this.props.location.search);

  pageType = this.params.get("type");

  clearAllFilters = (e, type) => {
    const self = this;
    if (type == "ClearAll") {
      if (e.target.click) {
        // console.log("this.state",this.state);
        this.updateStateValues("clearAll");

        document
          .querySelectorAll("input[type=checkbox]")
          .forEach((el) => (el.checked = false));
        this.setState({ selectedArtists: [], selectedCategories: [] });
      } else {
        this.setState({ ...this.state });
      }
    }
    setTimeout(() => {
      self.getRefreshData();
    }, 10);
  };

  FilterValueSelect = (e, artist, type) => {
    const self = this;
    if (type == "Artists") {
      if (e.target.checked) {
        this.setState({
          selectedArtists: this.state.selectedArtists.concat(artist),
        });
      } else {
        const existData = this.state.selectedArtists;
        const index = existData.findIndex((el, ind) => el == artist);
        existData.splice(index, 1);
        this.setState({ selectedArtists: existData });
      }
    } else if (type == "Category") {
      if (e.target.checked) {
        this.setState({
          selectedCategories: this.state.selectedCategories.concat(artist),
        });
      } else {
        const existData = this.state.selectedCategories;
        const index = existData.findIndex((el, ind) => el.id == artist);
        existData.splice(index, 1);
        this.setState({ selectedCategories: existData });
      }
    } else if (type == "PaymentStatus") {
      if (e.target.checked) {
        this.setState({
          selectedPaymentStatus:
            this.state.selectedPaymentStatus.concat(artist),
        });
      } else {
        const existData = this.state.selectedPaymentStatus;
        const index = existData.findIndex((el, ind) => el == artist);
        existData.splice(index, 1);
        this.setState({ selectedPaymentStatus: existData });
      }
    } else if (type == "MetReservePrice") {
      this.setState({ selectedReservePrice: e.target.checked });
    }

    setTimeout(() => {
      self.getRefreshData();
    }, 10);
  };

  getRefreshData = () => {
    const {
      selectedArtists,
      selectedCategories,
      updateValue,
      updateValueEstimate,
      selectedPaymentStatus,
      selectedReservePrice,
    } = this.state;
    const currentBidFrom = updateValue[0]?.toFixed();
    const currentBidTo = updateValue[1]?.toFixed();
    const estimateBidFrom = updateValueEstimate[0]?.toFixed();
    const estimateBidTo = updateValueEstimate[1]?.toFixed();

    const body = {
      page: 1,
      pageSize: 50,
      getOnlyTotalCount: false,
      collectionId: this.props.collectionid,
    };
    if (selectedArtists.length > 0) {
      body.artist = selectedArtists;
    }
    if (selectedCategories.length > 0) {
      body.categoryId = selectedCategories;
    }

    if (
      this.props?.collectionDetailFull?.maxCurrentBidPrice != currentBidTo ||
      currentBidFrom != 1
    ) {
      if (currentBidFrom != undefined && currentBidFrom != "NaN")
        body.currentBidFrom = currentBidFrom;

      if (currentBidTo != undefined && currentBidTo != "NaN")
        body.currentBidTo = currentBidTo;
    }

    if (
      this.props?.collectionDetailFull?.maxEstimatePrice != estimateBidTo ||
      estimateBidFrom != 1
    ) {
      if (estimateBidFrom != undefined && estimateBidFrom != "NaN")
        body.estimateBidFrom = estimateBidFrom;

      if (estimateBidTo != undefined && estimateBidTo != "NaN")
        body.estimateBidTo = estimateBidTo;
    }

    if (selectedPaymentStatus.length > 0) {
      body.orderStatus = selectedPaymentStatus;
    }

    if (selectedReservePrice) body.metReservePrice = selectedReservePrice;

    this.props.getUpdatedDataCall(body);
  };
  showHideFIlters = (type) => {
    if (type == "Artists") {
      this.setState({ showArtists: !this.state.showArtists });
    } else if (type == "Category") {
      this.setState({ showCategories: !this.state.showCategories });
    } else if (type == "currentBid") {
      this.setState({ showcurrentBid: !this.state.showcurrentBid });
    } else if (type == "Estimate") {
      this.setState({ showEstimate: !this.state.showEstimate });
    } else if (type == "ReservedPrice") {
      this.setState({ showReservedPrice: !this.state.showReservedPrice });
    } else if (type == "PaymentStatus") {
      this.setState({ showPaymentStatus: !this.state.showPaymentStatus });
    }
  };

  onShowMore = () => {
    this.setState({
      limitTo: this.state.limitTo + 5,
    });
  };
  onShowMoreCategory = () => {
    this.setState({
      limitCategory: this.state.limitCategory + 5,
    });
  };

  render() {
    const {
      artists,
      categories,
      showArtists,
      showCategories,
      showEstimate,
      showcurrentBid,
      limitTo,
      showReservedPrice,
      showPaymentStatus,
      isAuctionManagement,
      estimateBidFrom,
      limitCategory,
      updateValue,
      bidMin,
      bidMax,
      domain,
      extimateMin,
      extimateMax,
      domainEstimate,
      updateValueEstimate,
    } = this.state;

    const collData = this.props.collectionData;
    const self = this;
    let currencyName = this.props?.allCurrenciesData?.find(
      (i) => collData?.currency == i?.id
    )?.currencyCode;
    // console.log("renderredState", this.state);
    return (
      <>
        <div className="filterContainer">
          <Row className="filterByRow">
            <span className="filterByText">Filter by</span>
            <span
              className="filterByText filterByTextClearAll"
              onClick={(e) => {
                this.clearAllFilters(e, "ClearAll");
              }}
            >
              Clear All
            </span>
          </Row>
          <Col className="filterColumn">
            {collData?.status == 1 && (
              <div className="parentFilter">
                <div
                  className="filterHeader"
                  onClick={() => {
                    this.showHideFIlters("currentBid");
                  }}
                >
                  <div className="headertext">Current Bid</div>
                  <div className="arrowIcon">
                    {showcurrentBid ? (
                      <UpArrowIcon className="upArrowFilter" />
                    ) : (
                      <ChevronLeft className="upArrowFilter" />
                    )}
                  </div>
                </div>
                <div className="filterBody">
                  {showcurrentBid && (
                    <>
                      <SliderR
                        className="position-relative two_side_slider"
                        // rootStyle={sliderStyle}
                        domain={domain} // [min, max]
                        values={[bidMin, bidMax]}
                        onUpdate={this.onUpdate}
                        onChange={this.onUpdateDataCall}
                      >
                        <Rail>
                          {({ getRailProps }) => (
                            <SliderRail getRailProps={getRailProps} /> // render your clickable rail!
                          )}
                        </Rail>
                        <Handles>
                          {({ handles, getHandleProps }) => (
                            <div className="slider-handles two_side_slider_handle">
                              {handles.map((handle) => (
                                <Handle
                                  key={handle.id}
                                  handle={handle}
                                  domain={domain}
                                  getHandleProps={getHandleProps}
                                />
                              ))}
                            </div>
                          )}
                        </Handles>
                        <Tracks left={false} right={false}>
                          {({ tracks, getTrackProps }) => (
                            // render your (optional) tracks!
                            <div className="slider-tracks two_side_slider_tracks">
                              {tracks.map(({ id, source, target }) => (
                                <Track
                                  key={id}
                                  source={source}
                                  target={target}
                                  getTrackProps={getTrackProps}
                                />
                              ))}
                            </div>
                          )}
                        </Tracks>
                      </SliderR>

                      <div className="value d-flex align-items-center">
                        <span className="d-inline-block">
                          {currencyName} {updateValue[0]?.toFixed()}
                        </span>
                        <span className="d-inline-block ml-auto">
                          {currencyName} {updateValue[1]?.toFixed()}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="parentFilter">
              <div
                className="filterHeader"
                onClick={() => {
                  this.showHideFIlters("Estimate");
                }}
              >
                <div className="headertext">Estimate</div>
                <div className="arrowIcon">
                  {showEstimate ? (
                    <UpArrowIcon className="upArrowFilter" />
                  ) : (
                    <ChevronLeft className="upArrowFilter" />
                  )}
                </div>
              </div>
              <div className="filterBody">
                {showEstimate && (
                  <>
                    <SliderR
                      className="position-relative two_side_slider"
                      // rootStyle={sliderStyle}
                      domain={domainEstimate} // [min, max]
                      values={[extimateMin, extimateMax]}
                      onUpdate={this.onUpdateEstimate}
                      onChange={this.onUpdateEstimateDataCall}
                    >
                      <Rail>
                        {({ getRailProps }) => (
                          <SliderRail getRailProps={getRailProps} /> // render your clickable rail!
                        )}
                      </Rail>
                      <Handles>
                        {({ handles, getHandleProps }) => (
                          <div className="slider-handles two_side_slider_handle">
                            {handles.map((handle) => (
                              <Handle
                                key={handle.id}
                                handle={handle}
                                domain={domainEstimate}
                                getHandleProps={getHandleProps}
                              />
                            ))}
                          </div>
                        )}
                      </Handles>
                      <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                          // render your (optional) tracks!
                          <div className="slider-tracks two_side_slider_tracks">
                            {tracks.map(({ id, source, target }) => (
                              <Track
                                key={id}
                                source={source}
                                target={target}
                                getTrackProps={getTrackProps}
                              />
                            ))}
                          </div>
                        )}
                      </Tracks>
                    </SliderR>
                    <div className="value d-flex align-items-center">
                      <span className="d-inline-block">
                        {currencyName} {updateValueEstimate[0]?.toFixed()}
                      </span>
                      <span className="d-inline-block ml-auto">
                        {currencyName} {updateValueEstimate[1]?.toFixed()}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="parentFilter">
              <div
                className="filterHeader"
                onClick={() => {
                  this.showHideFIlters("Artists");
                }}
              >
                <div className="headertext">Artist/Maker</div>
                <div className="arrowIcon">
                  {showArtists ? (
                    <UpArrowIcon className="upArrowFilter" />
                  ) : (
                    <ChevronLeft className="upArrowFilter" />
                  )}
                </div>
              </div>
              <div className="filterBody">
                {showArtists && (
                  <ul className="allListData">
                    {/* <li className="listFilterItems"> 
                              <input type="checkbox" className="checkboxSelect" onClick={(e)=>{this.artistSelect(e,"I have a bike1")}} id="vehicle1"/>
                              <label for="vehicle1"> I have a bike1</label>
                            </li>
                            <li className="listFilterItems"> 
                              <input type="checkbox" className="checkboxSelect" onClick={(e)=>{this.artistSelect(e,"I have a bike2")}} id="vehicle2"/>
                              <label for="vehicle2"> I have a bike2 </label>
                            </li> */}

                    {(artists || []).slice(0, limitTo).map((item, index) => {
                      const numnerU = "artists" + index;
                      return (
                        <>
                          <li className="listFilterItems">
                            <input
                              type="checkbox"
                              className="checkboxSelect"
                              onClick={(e) => {
                                this.FilterValueSelect(e, item, "Artists");
                              }}
                              id={numnerU}
                            />
                            <label for={numnerU}> {item}</label>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                )}
                {artists && artists.length > limitTo && (
                  <span
                    className="filterShowMore"
                    onClick={() => this.onShowMore()}
                  >
                    Show More
                  </span>
                )}
              </div>
            </div>

            <div className="parentFilter">
              <div
                className="filterHeader"
                onClick={() => {
                  this.showHideFIlters("Category");
                }}
              >
                <div className="headertext">Category</div>
                <div className="arrowIcon">
                  {showCategories ? (
                    <UpArrowIcon className="upArrowFilter" />
                  ) : (
                    <ChevronLeft className="upArrowFilter" />
                  )}
                </div>
              </div>
              <div className="filterBody">
                {showCategories && (
                  <ul className="allListData">
                    {(this.props.collectionDetailFull?.categories || [])
                      .slice(0, limitCategory)
                      .map((item, index) => {
                        const numnerU = "Category" + index;
                        return (
                          <>
                            <li className="listFilterItems">
                              <input
                                type="checkbox"
                                className="checkboxSelect"
                                onClick={(e) => {
                                  this.FilterValueSelect(
                                    e,
                                    item?.id,
                                    "Category"
                                  );
                                }}
                                id={numnerU}
                              />
                              <label for={numnerU}> {item?.category}</label>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                )}
                {this.props.collectionDetailFull?.categories &&
                  this.props.collectionDetailFull?.categories.length >
                    limitCategory && (
                    <span
                      className="filterShowMore"
                      onClick={() => this.onShowMoreCategory()}
                    >
                      Show More
                    </span>
                  )}
              </div>
            </div>
            {/* <>
                          <li className="listFilterItems">
                            <input type="checkbox" className="checkboxSelect" onClick={(e) => { this.FilterValueSelect(e,null, "ClearAll") }} />
                            <label> Clear All</label>
                          </li>
                        </> */}
            {isAuctionManagement == true && (
              <>
                <div className="parentFilter">
                  <div
                    className="filterHeader"
                    onClick={() => {
                      this.showHideFIlters("ReservedPrice");
                    }}
                  >
                    <div className="headertext">Reserve Price</div>
                    <div className="arrowIcon">
                      {showReservedPrice ? (
                        <UpArrowIcon className="upArrowFilter" />
                      ) : (
                        <ChevronLeft className="upArrowFilter" />
                      )}
                    </div>
                  </div>
                  <div className="filterBody">
                    {showReservedPrice && (
                      <ul className="allListData">
                        <li className="listFilterItems">
                          <input
                            type="checkbox"
                            className="checkboxSelect"
                            onClick={(e) => {
                              this.FilterValueSelect(
                                e,
                                "MetReservePrice",
                                "MetReservePrice"
                              );
                            }}
                            id="MetReservePriceValue"
                          />
                          <label for="MetReservePriceValue">
                            {" "}
                            Met Reserve Price
                          </label>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </>
            )}

            {this.pageType == "past" && (
              <>
                <div className="parentFilter">
                  <div
                    className="filterHeader"
                    onClick={() => {
                      this.showHideFIlters("PaymentStatus");
                    }}
                  >
                    <div className="headertext">Payment Status</div>
                    <div className="arrowIcon">
                      {showPaymentStatus ? (
                        <UpArrowIcon className="upArrowFilter" />
                      ) : (
                        <ChevronLeft className="upArrowFilter" />
                      )}
                    </div>
                  </div>
                  <div className="filterBody">
                    {showPaymentStatus && (
                      <ul className="allListData">
                        <li className="listFilterItems">
                          <input
                            type="checkbox"
                            className="checkboxSelect"
                            onClick={(e) => {
                              this.FilterValueSelect(
                                e,
                                "Paid",
                                "PaymentStatus"
                              );
                            }}
                            id="PaymentStatusPaid"
                          />
                          <label for="PaymentStatusPaid"> Paid </label>
                        </li>
                        <li className="listFilterItems">
                          <input
                            type="checkbox"
                            className="checkboxSelect"
                            onClick={(e) => {
                              this.FilterValueSelect(
                                e,
                                "UnPaid",
                                "PaymentStatus"
                              );
                            }}
                            id="PaymentStatusUnPaid"
                          />
                          <label for="PaymentStatusUnPaid"> UnPaid</label>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </>
            )}
          </Col>
        </div>
      </>
    );
  }
}

export default withRouter(FilterDataComponent);
