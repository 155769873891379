import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Card,
  CardColumns,
  Nav,
  Form,
  Accordion,
  Button,
} from "react-bootstrap";
import BannerComponent from "./../../components/BannerComponent";
import LotsCard from "./../../components/LotsCard";
import LotsViewCard from "./../../components/LotsViewCard";
import FilterDataComponent from "./../../components/FilterComponent";
import { ReactComponent as GridActiveIcon } from "./../../assets/gridViewActive.svg";
import { ReactComponent as GridGreyIcon } from "./../../assets/gridGrayIcon.svg";
import { ReactComponent as ListActiveIcon } from "./../../assets/listViewActive.svg";
import { ReactComponent as ListGreyIcon } from "./../../assets/listGreyIcon.svg";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import {
  UPDATE_FILTER_DATA,
  UPDATE_LOT_DATA,
} from "../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "../../Scenes/common";
import SearchComponent from "./../../components/SearchComponent";

import CustomModal from "./../../components/CustomModal";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  getCollectionItemsCall,
  getCollectionItemsByStatus,
  getCollectionByIdCall,
  getItemidsbyCollectionIdCall,
  updateCollectionItemStatus,
  deleteLotByIdCall,
  getcurrenciesCall,
} from "./../../library/Requests";
import "./styles.css";

const Lots = (props) => {
  let { id } = useParams();

  const [currenciesData, setCurrenciesData] = useState([]);
  const [allLotIdsList, setAllLotIdsList] = useState([]);
  const [collectionItems, setCollectionItems] = useState([]);
  const [collectionDetailFull, setCollectionDetailFull] = useState({});
  const [lot, setLot] = useState({});
  const [deleteLotInfo, setDeleteLotInfo] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [withdrawSuccessModal, setWithdrawSuccessModal] = useState(false);
  const [jsonBody, setJsonBody] = useState({});
  const [sortColumn, setSortColumn] = useState("EstimatedPrice");
  const [sortDirection, setSortDirection] = useState("asc");
  const [collectionData, setCollectionData] = useState({});
  const [collectionDataFull, setCollectionDataFull] = useState({});
  const [bannerTimeZ, setBannerTimeZ] = useState([]);
  const [withDrawLotID, setWithDrawLotID] = useState();
  const [searchText, setSearchText] = useState("");
  const [rePublishLot, setRePublishLot] = useState(false);
  const [rePublishedSuccessModal, setRePublishedSuccessModal] = useState(false);
  const [publishLot, setPublishLot] = useState(false);
  const [publishedSuccessModal, setPublishedSuccessModal] = useState(false);
  const [publishPage, setPublishPage] = useState(1);
  const [draftPage, setDraftPage] = useState(1);
  const [withdrawPage, setWithdrawPage] = useState(1);
  const [pLots, setPLots] = useState([]);
  const [dLots, setDLots] = useState([]);
  const [wLots, setWLots] = useState([]);
  const [publishedItemCount, setpublishedItemCount] = useState(0);
  const [draftedItemCount, setdraftedItemCount] = useState(0);
  const [withdrawnItemCount, setwithdrawnItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCnt, setTotalCnt] = useState(0);
  const [deletedTypeLot, setDeletedTypeLot] = useState("");

  useEffect(async () => {
    getcurrenciesDataCall();
    getDataonLoad("onLoad");
  }, []);

  const getDataonLoad = async (page) => {
    const body = {
      page: 1,
      pageSize: 15,
      getOnlyTotalCount: false,
      collectionId: id,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    };
    setJsonBody(body);
    getCollectionData(id, body, page);
    getAllLotIdsList(id);
    getMoreCollectionItems(body);
  };
  const getAllLotIdsList = async (id) => {
    let resp1 = await getItemidsbyCollectionIdCall(id);
    setAllLotIdsList(resp1?.data);
  };

  const publishViewMore = (clickedType) => {
    setIsLoading(true);
    const body = jsonBody;
    if (clickedType == "Plots") {
      setPublishPage(publishPage + 1);
      body.page = publishPage + 1;
      getMoreCollectionItems(body, "published");
    } else if (clickedType == "Dlots") {
      setDraftPage(draftPage + 1);
      body.page = draftPage + 1;
      getMoreCollectionItems(body, "drafted");
    } else if (clickedType == "Wlots") {
      setWithdrawPage(withdrawPage + 1);
      body.page = withdrawPage + 1;
      getMoreCollectionItems(body, "withdrawn");
    }
  };

  const getMoreCollectionItems = async (body, type) => {
    if (type != "published" || type != "drafted" || type != "withdrawn") {
      setCollectionItems([]);
    }
    if (type == "published") {
      if (collectionData?.status == 1) {
        body.status = [1, 4, 5, 6, 7];
      } else {
        body.status = [2];
      }
      let resp = await getCollectionItemsCall(body);
      setIsLoading(false);
      setPLots([...pLots, ...resp?.data]);
    } else if (type == "drafted") {
      body.status = [2];
      let resp = await getCollectionItemsCall(body);
      setIsLoading(false);
      setDLots([...dLots, ...resp?.data]);
    } else if (type == "withdrawn") {
      body.status = [3];
      let resp = await getCollectionItemsCall(body);
      setIsLoading(false);
      setWLots([...wLots, ...resp?.data]);
    }
  };

  const withdrawnItems = collectionData?.withDrawnItems;

  let bannerTimeZone = bannerTimeZ.find(
    (i) => collectionData?.timeZoneId === i?.id
  )?.abbrivation;
  if (!bannerTimeZone) {
    bannerTimeZone = bannerTimeZ.find(
      (i) => collectionData?.timeZoneId === i?.id
    )?.name;
  }
  const getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    setCurrenciesData(resp?.data);
  };
  const getCollectionItemsNew = (body) => {
    body.sortColumn = sortColumn;
    body.sortDirection = sortDirection;
    body.searchText = searchText;
    setJsonBody(body);
    getCollectionItems(body);
  };

  const getCollectionData = async (id, body, page) => {
    const resp = await getCollectionByIdCall(id);
    setCollectionData(resp?.data);
    setCollectionDataFull(resp?.data);
    getCollectionItems(body, resp?.data, page);
  };
  const getCollectionItems = async (id, collectionD, page) => {
    let resp = await getCollectionItemsCall(id);
    setCollectionItems(resp.data);
    setTotalCnt(resp?.totalCount);
    setCollectionDetailFull(resp);
    if (page == "onLoad") {
      triggerEventForProfileUpdate(UPDATE_FILTER_DATA);
    }
    const newcoll = collectionD ? collectionD : collectionData;
    if (newcoll?.status == 1) {
      id.status = [1, 4, 5, 6, 7];
      let publishResp = await getCollectionItemsCall(id);
      setPLots(publishResp?.data);
      setpublishedItemCount(publishResp?.totalCount);
      id.status = [2];
      let draftResp = await getCollectionItemsCall(id);
      setDLots(draftResp?.data);
      setdraftedItemCount(draftResp?.totalCount);
      id.status = [3];
      let withdrawResp = await getCollectionItemsCall(id);
      setWLots(withdrawResp?.data);
      setwithdrawnItemCount(withdrawResp?.totalCount);
    } else {
      setPLots(resp.data);
    }
  };

  const withdrawLot = async (id) => {
    setWithDrawLotID(id);
    setWithdrawModal(true);
  };

  const rePublisgtem = async (id) => {
    setWithDrawLotID(id);
    setRePublishLot(true);
  };
  const publishItem = async (id) => {
    setWithDrawLotID(id);
    // setPublishLot(true);
    props.history.push(`/dashboard/previewSingleLot/${id}`);
  };
  const RePublishConfirm = async () => {
    setRePublishLot(false);
    let body = {
      id: withDrawLotID,
      status: 1,
    };
    let resp = await updateCollectionItemStatus(body);
    setRePublishedSuccessModal(true);
    getDataonLoad();
  };
  const publishConfirm = async () => {
    setPublishLot(false);
    let body = {
      id: withDrawLotID,
      status: 1,
    };
    let resp = await updateCollectionItemStatus(body);
    setPublishedSuccessModal(true);
    getDataonLoad();
  };
  const withdrawLotConfirm = async () => {
    setWithdrawModal(false);
    let body = {
      id: withDrawLotID,
      status: 3,
    };
    let resp = await updateCollectionItemStatus(body);
    setWithdrawSuccessModal(true);
    getDataonLoad();
  };
  const sortColumnChange = (e) => {
    const value = e.target.value;
    const sortCol = value.split("_")[0];
    const sortDir = value.split("_")[1];
    setSortColumn(sortCol);
    setSortDirection(sortDir);
    jsonBody.sortColumn = sortCol;
    jsonBody.sortDirection = sortDir;
    getCollectionItems(jsonBody);
  };
  // const getUpdatedDataCall = async (body)=>{
  //     let resp = await getCollectionItemsCall(id);
  //     setCollectionItems(resp.data);
  //     setCollectionDetailFull(resp)
  //     triggerEventForProfileUpdate(UPDATE_LOT_DATA)
  // }
  const deleteLot = async (lot, typeLot) => {
    setDeleteModal(true);
    setDeleteLotInfo(lot);
    setDeletedTypeLot(typeLot);
  };

  const deleteLotConfirm = async (key) => {
    setDeleteModal(false);
    const Did = deleteLotInfo?.id;
    const resp = await deleteLotByIdCall(Did);
    const updatedLots = collectionItems;
    const index = updatedLots.findIndex((item) => item?.id === Did);
    if (index > -1) {
      updatedLots.splice(index, 1);
      setCollectionItems(updatedLots);

      setTotalCnt(parseInt(totalCnt) - 1);
      if (deletedTypeLot == "pLots") {
        setpublishedItemCount(parseInt(publishedItemCount) - 1);
      }
      if (deletedTypeLot == "wLots") {
        setwithdrawnItemCount(parseInt(withdrawnItemCount) - 1);
      }
      if (deletedTypeLot == "dLots") {
        setdraftedItemCount(parseInt(draftedItemCount) - 1);
      }
    }
    setDeleteSuccessModal(true);
  };
  const checkwithDraw = (details) => {
    let returnV = false;
    for (var key in details) {
      if (key == "withDrawnItems" && details?.withDrawnItems?.length > 0) {
        returnV = true;
      }
    }
    return returnV;
  };
  const searchClick = (search) => {
    const body = jsonBody;
    body.searchText = search;
    setSearchText(search);
    setJsonBody(body);
    getCollectionItems(body);
  };
  const previewAndPublish = () => {
    if (allLotIdsList && allLotIdsList.length > 0) {
      let lotID = allLotIdsList.filter((item) => item.rno == "1");
      if (lotID.length > 0) {
        props.history.push(`/dashboard/previewLot/${lotID[0].itemId}`);
      }
    }
  };

  const itemCount = collectionItems?.totalCount ?? 0;
  const createMarkup = (data) => {
    return { __html: data };
  };

  return (
    <div className="lotsMatrixContainer">
      <BannerComponent
        collectionData={collectionData}
        itemCount={totalCnt}
        previewAndPublish={previewAndPublish}
        allLotIdsList={allLotIdsList}
      />
      <Row className="filterLotsContainer">
        <Tab.Container id="collections-tabs" defaultActiveKey="browseLots">
          <Col sm="12" className="tabsColumn">
            <Nav variant="tabs" className="collectionsTabs">
              <Nav.Item>
                <Nav.Link eventKey="browseLots">
                  Browse Lots (
                  {collectionData?.status == 1 ? publishedItemCount : totalCnt})
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="overview">Overview</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="auctionDetails">Auction Details</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="conditionsOfSale">
                  Conditions Of Sale
                </Nav.Link>
              </Nav.Item>
              {collectionData?.status == 1 && (
                <>
                  <Nav.Item>
                    <Nav.Link eventKey="draftedLots">
                      Drafted Lots ({draftedItemCount}){" "}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="withdrawnLots">
                      Withdrawn Lots ({withdrawnItemCount}){" "}
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </Col>

          <Row className="filterLotsRow">
            {/* <Col sm="3" className="filterByColumn">
                        </Col> */}
            <Col sm="12" className="cardMatrix">
              <Tab.Content>
                <Tab.Pane eventKey="browseLots">
                  <Row>
                    <Col
                      className="searchBoxLotsPage"
                      sm={{ span: 9, offset: 3 }}
                    >
                      <div className="sortByBlock">
                        <span className="sortByText">Sort by:</span>
                        <Form.Control
                          as="select"
                          className=" sortBySelect"
                          onChange={sortColumnChange}
                        >
                          <option value="Sno_asc">
                            Lot Number ( Low to High)
                          </option>
                          <option value="Sno_desc">
                            Lot Number ( High to Low)
                          </option>
                          <option value="EstimatedPrice_asc">
                            Estimate ( Low to High)
                          </option>
                          <option value="EstimatedPrice_desc">
                            Estimate ( High to Low)
                          </option>
                        </Form.Control>
                      </div>
                      <SearchComponent searchClickCall={searchClick} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3" className="filterByColumn">
                      <FilterDataComponent
                        collectionData={collectionDataFull}
                        collectionDetailFull={collectionDetailFull}
                        allCurrenciesData={currenciesData}
                        collectionid={id}
                        getUpdatedDataCall={getCollectionItemsNew}
                      />
                    </Col>
                    <Col sm="9" className="noPadding">
                      <Row className="cardGroup ">
                        {(pLots || []).map((collectionItem, index) => {
                          // setLot(collectionItem)
                          return (
                            <>
                              <LotsCard
                                key={index.toString()}
                                index={index}
                                collectionItemData={collectionItem}
                                lotItems={collectionItems}
                                deleteCollectionItem={deleteLot}
                                collectionData={collectionData}
                                withdrawCollectionItem={withdrawLot}
                                allCurrenciesData={currenciesData}
                                rePublishCollectionItem={rePublisgtem}
                                typeofLot="pLots"
                              />
                            </>
                          );
                        })}
                      </Row>

                      {isLoading && (
                        <>
                          <div className="spinner-borderWord">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </>
                      )}

                      {!isLoading &&
                        (collectionData?.status == 1
                          ? publishedItemCount
                          : totalCnt) > pLots.length && (
                          <Row className="loadMoreRow">
                            <Button
                              onClick={() => publishViewMore("Plots")}
                              variant="custom"
                              className="loadMoreBtn"
                            >
                              Load More
                            </Button>
                          </Row>
                        )}
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="draftedLots">
                  <Row>
                    <Col
                      className="searchBoxLotsPage"
                      sm={{ span: 9, offset: 3 }}
                    >
                      <div className="sortByBlock">
                        <span className="sortByText">Sort by:</span>
                        <Form.Control
                          as="select"
                          className=" sortBySelect"
                          onChange={sortColumnChange}
                        >
                          <option value="Sno_asc">
                            Lot Number ( Low to High)
                          </option>
                          <option value="Sno_desc">
                            Lot Number ( High to Low)
                          </option>
                          <option value="EstimatedPrice_asc">
                            Estimate ( Low to High)
                          </option>
                          <option value="EstimatedPrice_desc">
                            Estimate ( High to Low)
                          </option>
                        </Form.Control>
                      </div>
                      <SearchComponent searchClickCall={searchClick} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3" className="filterByColumn">
                      <FilterDataComponent
                        collectionData={collectionDataFull}
                        collectionDetailFull={collectionDetailFull}
                        allCurrenciesData={currenciesData}
                        collectionid={id}
                        getUpdatedDataCall={getCollectionItemsNew}
                      />
                    </Col>
                    <Col sm="9" className="noPadding">
                      <Row className="cardGroup ">
                        {(dLots || []).map((collectionItem, index) => {
                          // setLot(collectionItem)
                          return (
                            <>
                              <LotsCard
                                key={index.toString()}
                                index={index}
                                collectionItemData={collectionItem}
                                lotItems={collectionItems}
                                deleteCollectionItem={deleteLot}
                                collectionData={collectionData}
                                withdrawCollectionItem={withdrawLot}
                                allCurrenciesData={currenciesData}
                                rePublishCollectionItem={rePublisgtem}
                                publishCollectionItem={publishItem}
                                typeofLot="dLots"
                              />
                            </>
                          );
                        })}
                      </Row>
                      {!isLoading && draftedItemCount > dLots.length && (
                        <Row className="loadMoreRow">
                          <Button
                            onClick={() => publishViewMore("Dlots")}
                            variant="custom"
                            className="loadMoreBtn"
                          >
                            Load More
                          </Button>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="withdrawnLots">
                  <Row>
                    <Col
                      className="searchBoxLotsPage"
                      sm={{ span: 9, offset: 3 }}
                    >
                      <div className="sortByBlock">
                        <span className="sortByText">Sort by:</span>
                        <Form.Control
                          as="select"
                          className=" sortBySelect"
                          onChange={sortColumnChange}
                        >
                          <option value="Sno_asc">
                            Lot Number ( Low to High)
                          </option>
                          <option value="Sno_desc">
                            Lot Number ( High to Low)
                          </option>
                          <option value="EstimatedPrice_asc">
                            Estimate ( Low to High)
                          </option>
                          <option value="EstimatedPrice_desc">
                            Estimate ( High to Low)
                          </option>
                        </Form.Control>
                      </div>
                      <SearchComponent searchClickCall={searchClick} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3" className="filterByColumn">
                      <FilterDataComponent
                        collectionData={collectionDataFull}
                        collectionDetailFull={collectionDetailFull}
                        allCurrenciesData={currenciesData}
                        collectionid={id}
                        getUpdatedDataCall={getCollectionItemsNew}
                      />
                    </Col>
                    <Col sm="9" className="noPadding">
                      <Row className="cardGroup ">
                        {(wLots || []).map((collectionItem, index) => {
                          // setLot(collectionItem)
                          return (
                            <>
                              <LotsCard
                                key={index.toString()}
                                index={index}
                                collectionItemData={collectionItem}
                                lotItems={collectionItems}
                                deleteCollectionItem={deleteLot}
                                collectionData={collectionData}
                                withdrawCollectionItem={withdrawLot}
                                allCurrenciesData={currenciesData}
                                rePublishCollectionItem={rePublisgtem}
                                typeofLot="wLots"
                              />
                            </>
                          );
                        })}
                      </Row>

                      {!isLoading && withdrawnItemCount > wLots.length && (
                        <Row className="loadMoreRow">
                          <Button
                            onClick={() => publishViewMore("Wlots")}
                            variant="custom"
                            className="loadMoreBtn"
                          >
                            Load More
                          </Button>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="overview">
                  <Row sm="12" className="scrollContent">
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        collectionData?.collectionOverview
                      )}
                    />
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="auctionDetails">
                  <Row className="auctionDetailSpacing">
                    <Row className="auctionNameValues">
                      <p>
                        <span className="auctionDetailsLeft">
                          Auction name:
                        </span>{" "}
                        <span className="adTitle">
                          {collectionData?.title}{" "}
                        </span>
                      </p>
                      <p>
                        <span className="auctionDetailsLeft">
                          Auction Date:{" "}
                        </span>
                        <span className="">
                          {collectionData?.auctionType == "1" ? (
                            <>
                              <span className="adDate">
                                {moment(collectionData?.onlineStartDate).format(
                                  "MMMM Do YYYY"
                                )}
                              </span>
                              <Line className="lineStyles" />
                              <span className="adTime">
                                {" "}
                                {moment(
                                  collectionData?.onlineStartTime,
                                  "HHmmss"
                                ).format("h:mm a")}{" "}
                                {bannerTimeZone}{" "}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="adDate">
                                {moment(collectionData?.liveStartDate).format(
                                  "MMMM Do YYYY"
                                )}
                              </span>
                              <Line className="lineStyles" />
                              <span className="adTime">
                                {" "}
                                {moment(
                                  collectionData?.liveStartTime,
                                  "HHmmss"
                                ).format("h:mm a")}{" "}
                                {bannerTimeZone}{" "}
                              </span>
                            </>
                          )}

                          <Line className="lineStyles" />
                          <span className="adOnlineAuction">
                            {" "}
                            {collectionData?.auctionType === 1
                              ? "Online"
                              : "Live"}{" "}
                            Auction
                          </span>
                        </span>
                      </p>
                      <p>
                        <span className="auctionDetailsLeft">
                          Number of Lots:{" "}
                        </span>{" "}
                        <span className="adTitle lotsNumberSpacing">
                          {" "}
                          {totalCnt}
                        </span>{" "}
                      </p>
                    </Row>
                    <p className="furtherInfoText">
                      Please get in touch with one of the specialists listed
                      below for further information.
                    </p>
                    <p className="forAssistText">
                      For assistance with bidding and registration, please
                      contact:
                    </p>
                    <p className="forAssistContent">+ 12345 67890</p>
                    <p className="forAssistContent">loremipsum@gmail.com</p>
                  </Row>
                  {checkwithDraw(collectionData) ? (
                    <>
                      <Accordion className="adAccordion" defaultActiveKey="0">
                        <Card>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            className="formHeading"
                          >
                            <span className="formHeading">
                              {" "}
                              Lot information1
                            </span>
                            <AngleUpIcon className="AngleUpIcon" />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              {(withdrawnItems || [])?.map((item, i) => {
                                return (
                                  <Row className="adCardBlock">
                                    <p className="adCardHeading">
                                      Lot {item?.sno}{" "}
                                    </p>
                                    <p className="adCardContent">
                                      This Lot has been Withdrawn from the Sale.
                                    </p>
                                  </Row>
                                );
                              })}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </>
                  ) : null}
                </Tab.Pane>
                <Tab.Pane eventKey="conditionsOfSale">
                  <Row sm="12" className="scrollContent">
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        collectionData?.conditionOfSale
                      )}
                    />
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Row>
      <CustomModal
        show={deleteModal}
        titleText="Do you want to delete this Lot?"
        deleteText="Yes"
        cancelText="No"
        onCancel={() => setDeleteModal(false)}
        onDelete={() => deleteLotConfirm()}
      />
      <CustomModal
        show={deleteSuccessModal}
        titleText="Lot is Deleted Successfully"
        deleteText="ok"
        cancelText=""
        // onCancel={() => this.hideModel(false)}
        onDelete={() => setDeleteSuccessModal(false)}
      />
      <CustomModal
        show={withdrawModal}
        titleText="Do you want to Withdraw this Lot?"
        deleteText="Yes"
        cancelText="No"
        onCancel={() => setWithdrawModal(false)}
        onDelete={() => withdrawLotConfirm()}
      />
      <CustomModal
        show={rePublishLot}
        titleText="Do you want to Re-Publish this Lot?"
        deleteText="Yes"
        cancelText="No"
        onCancel={() => setRePublishLot(false)}
        onDelete={() => RePublishConfirm()}
      />
      <CustomModal
        show={rePublishedSuccessModal}
        titleText="Lot is Re-Published Successfully."
        deleteText="ok"
        cancelText=""
        onDelete={() => setRePublishedSuccessModal(false)}
      />
      <CustomModal
        show={publishLot}
        titleText="Do you want to Publish this Lot?"
        deleteText="Yes"
        cancelText="No"
        onCancel={() => setPublishLot(false)}
        onDelete={() => publishConfirm()}
      />
      <CustomModal
        show={publishedSuccessModal}
        titleText="Lot is Published Successfully."
        deleteText="ok"
        cancelText=""
        onDelete={() => setPublishedSuccessModal(false)}
      />
      <CustomModal
        show={withdrawSuccessModal}
        titleText="Lot is Withdrawn Successfully."
        deleteText="ok"
        cancelText=""
        onDelete={() => setWithdrawSuccessModal(false)}
      />
    </div>
  );
};

export default Lots;
