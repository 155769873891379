import React from "react";
import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { withRouter } from "react-router";
import kane from "./../../assets/kane.jpg";
import "./styles.css";

const RoleInformation = (props) => {
  return (
    <div>
      <Row className="roleInfoHeadingRow">
        <ChevronLeft />
        <span className="roleMgmtHeading">Role Management</span>
      </Row>
      <Row className="roleInformationContainer">
        <Row className="roleInformation">
          <span className="roleInfoHeading">Role Information</span>
          <Button
            className="editBtn"
            onClick={(event) => {
              props.history.push(`/dashboard/createNewRole`);
            }}
          >
            Edit
          </Button>
        </Row>
        <Row className="roleInformationCard">
          <Col className="noPadding">
            <img src={kane} roundedCircle className="imageAvatar" />
            <p className="roleInfoName">Kadin Schleifer</p>
            <Row>
              <Col sm="6" className="noPadding">
                <Form.Label>Email Address</Form.Label>
                <p className="fieldValues">kadinschleifer@gmail.com</p>
              </Col>
              <Col sm="6" className="noPadding mobileNumber">
                <Form.Label>Mobile Number</Form.Label>
                <p className="fieldValues">+1 91919 19191</p>
              </Col>
            </Row>
            <Row>
              <Col sm="3" className="noPadding">
                <Form.Label>Gender</Form.Label>
                <p className="fieldValues">Male</p>
              </Col>
              <Col sm="3" className="noPadding roleColumn">
                <Form.Label>Role</Form.Label>
                <p className="fieldValues">Admin</p>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <Button className="resetPasswordBtn">Reset Password</Button>
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default withRouter(RoleInformation);
