import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Card, Form, Button } from "react-bootstrap";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import "./styles.css";

import uplaodImageNew from "../../services";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { edititemconditionalreportCall } from "./../../library/Requests";

const ConditionReport = (props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [saveContinue, setSaveContinue] = useState(false);
  const [fieldisEmpty, setFieldisEmpty] = useState(false);

  useEffect(() => {
    if (props.isEditLot && props.lotItemDetails?.conditionalReport) {
      document.getElementsByClassName(
        "DraftEditor-editorContainer"
      )[1].innerHTML = props.lotItemDetails?.conditionalReport;
    }
  });
  const onEditorStateChange = (editorState) => {
    console.log(editorState);
    setEditorState(editorState);
    setFieldisEmpty(false);
  };
  const uploadImageCallBack = async (file) => {
    return new Promise(async (resolve, reject) => {
      const respnew = await uplaodImageNew(file, "lot");
      console.log(respnew);
      console.log(file);
      const obj1 = {
        data: {
          link: respnew?.imageUrl,
          type: file.type,
        },
      };
      resolve(obj1);
    });
  };
  const saveAndContinue = async () => {
    var s = convertToRaw(editorState.getCurrentContent());
    const flagVale = s.blocks[0].text.trim().length > 0;
    // console.log(flagVale);
    // const flagVale = true;
    // return false;
    if (flagVale || props.isEditLot) {
      const body = {
        id: document.getElementById("collectionLotID").value,
        conditionalReport: document.getElementsByClassName(
          "DraftEditor-editorContainer"
        )[1].innerHTML,
      };
      const respNew = await edititemconditionalreportCall(body);
      document.getElementById("Accordion_Section_5").click();
      props.getItemDetailsCall();
      setSaveContinue(true);
    } else {
      setFieldisEmpty(true);
    }
  };

  return (
    <div class="wrapper">
      <Card>
        <Accordion.Toggle
          id="Accordion_Section_4"
          as={Card.Header}
          eventKey="4"
        >
          <span className="formHeading">5. Condition Report</span>
          {/* <AngleUpIcon className="AngleUpIcon" /> */}
          {saveContinue ? (
            <Button className="AccordionEditBtn"> Edit </Button>
          ) : (
            <AngleUpIcon className="angleUpIcon" />
          )}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body className="conditionReportBody">
            <p className="editorHeading requiredField">Condition Report</p>
            <div className="editor">
              <Editor
                editorState={editorState}
                placeholder="Enter Condition Report"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  // textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                  image: {
                    uploadCallback: uploadImageCallBack,
                    alt: { present: true, mandatory: false },
                  },
                }}
              />
            </div>
            {fieldisEmpty && (
              <Row className="errorEditorlot">
                Please enter Condition Report
              </Row>
            )}
            <Row className="btnRow btnAlign">
              <Button className="saveContinue" onClick={saveAndContinue}>
                Save & Continue
              </Button>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
};

export default ConditionReport;
