import React from "react";
import CarouselComponent from "./../../components/CarouselComponent";

const NewFeatures = () => {
  return (
    <div>
      <CarouselComponent />
    </div>
  );
};

export default NewFeatures;
