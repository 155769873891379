import React, { useState } from "react";
import { Carousel, Row, Col, Card } from "react-bootstrap";
import banner from "./../../assets/banner.jpg";
import fifty from "./../../assets/fifty.jpg";
import twentyFive from "./../../assets/twentyFive.jpg";
import fifty2 from "./../../assets/fifty2.jpg";
import twentyFive2 from "./../../assets/twentyFive2.jpg";
import bottomFifty from "./../../assets/bottomFifty.jpg";
import { ReactComponent as ArrowLeft } from "./../../assets/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "./../../assets/arrowRight.svg";
import "./styles.css";

const CarouselComponent = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Row>
        <Col>
          <Carousel
            nextIcon={<ArrowRight />}
            prevIcon={<ArrowLeft />}
            data-interval="false"
            data-pause="hover"
            activeIndex={index}
            onSelect={handleSelect}
            className="carouselStyles"
            id="imageCarousel"
          >
            <Carousel.Item>
              <img className="carouselImages" src={banner} alt="First slide" />
              <Carousel.Caption>
                <h3>First slide label</h3>
                <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p>
              </Carousel.Caption>
            </Carousel.Item>

            {/* <Carousel.Item>
                            <img
                                className="carouselImages"
                                src={banner}
                                alt="Second slide"
                            />

                            <Carousel.Caption>
                                <h3>Second slide label</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img
                                className="carouselImages"
                                src={banner}
                                alt="Third slide"
                            />

                            <Carousel.Caption>
                                <h3>Third slide label</h3>
                                <p>
                                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                            </p>
                            </Carousel.Caption>
                        </Carousel.Item> */}
          </Carousel>
        </Col>
        <Col sm="1" className="carouselIndicatorColumn">
          <div className="carouselNumber">
            <p className="indicatorNumber">1</p>
            <p className="indicatorNumber">2</p>
            <p className="indicatorNumber">3</p>
          </div>
          {/* <ol class="carousel-indicators">
                    <li data-target="#carousel-example-generic" data-slide-to="0" class="active">1</li>
                    <li data-target="#carousel-example-generic" data-slide-to="1">2</li>
                    <li data-target="#carousel-example-generic" data-slide-to="2">3</li>
                </ol> */}
        </Col>
      </Row>

      <Row className="cardsCarouselRow">
        <Carousel
          nextIcon={<ArrowRight />}
          prevIcon={<ArrowLeft />}
          data-interval="false"
          data-pause="hover"
          activeIndex={index}
          onSelect={handleSelect}
          className="cardsCarouselStyles"
          id="cardsCarousel"
        >
          <Carousel.Item>
            <Row>
              <Col sm="6">
                <Card>
                  <img variant="top" src={fifty} />
                  <Card.Body>
                    <Card.Title>Card Title</Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="3">
                <Card>
                  <img variant="top" src={twentyFive} />
                  <Card.Body>
                    <Card.Title>Card Title</Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="3">
                <Row>
                  <Card className="">
                    <img variant="top" src={bottomFifty} />
                    <Card.Body>
                      <Card.Title>Card Title</Card.Title>
                      <Card.Text>
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Row>
                {/* <Row>
                                    <Card>
                                        <img variant="top" src={bottomFifty} />
                                        <Card.Body>
                                            <Card.Title>Card Title</Card.Title>
                                            <Card.Text>
                                                Some quick example text to build on the card title and make up the bulk of
                                                the card's content.
                                    </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Row> */}
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col sm="6">
                <Card>
                  <img variant="top" src={fifty2} />
                  <Card.Body>
                    <Card.Title>Card Title</Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="3">
                <Card>
                  <img variant="top" src={twentyFive2} />
                  <Card.Body>
                    <Card.Title>Card Title</Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="3">
                <Row>
                  <Card className="">
                    <img variant="top" src={bottomFifty} />
                    <Card.Body>
                      <Card.Title>Card Title</Card.Title>
                      <Card.Text>
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Row>
                {/* <Row>
                                    <Card>
                                        <img variant="top" src={bottomFifty} />
                                        <Card.Body>
                                            <Card.Title>Card Title</Card.Title>
                                            <Card.Text>
                                                Some quick example text to build on the card title and make up the bulk of
                                                the card's content.
                                    </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Row> */}
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Row>
    </div>
  );
};

export default CarouselComponent;
