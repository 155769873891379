import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Card, Form, Button } from "react-bootstrap";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import { ReactComponent as PlusIcon } from "./../../assets/plusIcon.svg";
import "./styles.css";

import {
  createitemprovenanceCall,
  edititemprovenanceCall,
} from "./../../library/Requests";

const AddProvenanceForm = (props) => {
  const [inputList, setInputList] = useState([{ provenanceOwner: "" }]);
  const [saveContinue, setSaveContinue] = useState(false);
  const [emptyDataFirst, setEmptyDataFirst] = useState(false);

  const ProvDeta = props.lotItemDetails?.itemProvenances;

  useEffect(() => {
    if (props.isEditLot) {
      // ProvDeta
      if (ProvDeta != undefined && ProvDeta != null && ProvDeta.length > 0) {
        setInputList(ProvDeta);
      }
      // details[key] = LotD1[key]
    }
  }, [props]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setEmptyDataFirst(false);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { provenanceOwner: "" }]);
  };
  const saveandContinuePage = async () => {
    console.log(inputList);
    // for
    const firstVal = inputList[0].provenanceOwner;
    console.log(firstVal);
    if (firstVal != "") {
      // return false;
      const newPro = [];
      for (var key in inputList) {
        console.log(key);
        if (
          inputList[key].provenanceOwner != null &&
          inputList[key].provenanceOwner != ""
        ) {
          const bodytemp = {
            id: inputList[key].id,
            itemId: document.getElementById("collectionLotID").value,
            provenanceOwner: inputList[key].provenanceOwner,
            createdBy: 0,
            updatedBy: 0,
          };
          newPro.push(bodytemp);
        }
      }
      const body = { itemProvenance: newPro };
      console.log(body);
      if (props.isEditLot || saveContinue) {
        const resp = await edititemprovenanceCall(body);
        if (resp?.success) {
          setSaveContinue(true);
          document.getElementById("Accordion_Section_4").click();
          props.getItemDetailsCall();
        }
      } else {
        const resp = await createitemprovenanceCall(body);
        if (resp?.success) {
          setSaveContinue(true);
          setInputList(resp?.result);
          document.getElementById("Accordion_Section_4").click();
          props.getItemDetailsCall();
        }
      }
    } else {
      setEmptyDataFirst(true);
    }
    //

    // var span = document.getElementsByClassName("angleUpIcon");
    // let iconCheck =  document.getElementById("Accordion_Section_0").contains(span)
  };

  return (
    <div class="wrapper">
      <Card>
        <Accordion.Toggle
          id="Accordion_Section_3"
          as={Card.Header}
          eventKey="3"
        >
          <span className="formHeading">4. Provenance</span>
          {/* <AngleUpIcon className="AngleUpIcon" /> */}
          {saveContinue ? (
            <Button className="AccordionEditBtn"> Edit </Button>
          ) : (
            <AngleUpIcon className="angleUpIcon" />
          )}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body className="provenanceBody">
            <Form.Label className="requiredField" style={{ marginBottom: "0" }}>
              Mention the Owners name
            </Form.Label>
            {inputList.map((x, i) => {
              return (
                <>
                  <Row>
                    <Form.Control
                      type="text"
                      className="ownerNameField"
                      name="provenanceOwner"
                      value={x.provenanceOwner}
                      placeholder="John Doe"
                      onChange={(e) => handleInputChange(e, i)}
                    />
                    {inputList.length - 1 !== i && (
                      <span
                        className="ownerCloseButton"
                        onClick={() => handleRemoveClick(i)}
                      >
                        X
                      </span>
                    )}
                    {inputList.length - 1 === i && (
                      <Button
                        className="addMoreOwnersBtn"
                        onClick={handleAddClick}
                      >
                        <PlusIcon className="plusIcon" />
                        Add More owners
                      </Button>
                    )}
                  </Row>
                </>
              );
            })}
            {emptyDataFirst && (
              <Row className="errorEditorlot">Please enter the value</Row>
            )}
            <Row className="btnRow btnAlign">
              <Button className="saveContinue" onClick={saveandContinuePage}>
                Save & Continue
              </Button>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
};

export default AddProvenanceForm;
