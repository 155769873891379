import HSL from "./../../components/HSL";
import HSR from "./../../components/HSR";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
import {
  createNewHSM,
  getAllSiteManagersCall,
  deletesitemanagerCall,
} from "./../../library/Requests/SiteManager";

import { triggerEventForProfileUpdate } from "../../Scenes/common";
import { EDIT_SITE_DATA } from "../../library/Requests/eventConstants";

import { withRouter } from "react-router-dom";
import "./styles.css";

class HeroSectionManager extends Component {
  static propTypes = {};

  state = {
    isUserLogin: false,
    isEdit: false,
    editData: {},
    allData: [],
  };
  //   /api/Customer/getallusers
  componentDidMount = () => {
    this.getAllSiteManagers();
  };

  isEditSiteManager = (item) => {
    console.log(item);
    localStorage.setItem("editSiteDataItem", JSON.stringify(item));
    this.setState({ isEdit: true, editData: item });
    triggerEventForProfileUpdate(EDIT_SITE_DATA);
  };
  isDeleteSiteManager = async (item) => {
    if (item?.id) {
      const body = {
        id: item?.id,
      };
      const resp = await deletesitemanagerCall(body);
      if (resp?.success) {
        const allData = this.state.allData;
        const Findindex = allData.findIndex((itemL) => itemL.id == item?.id);
        if (Findindex > -1) {
          allData.splice(Findindex, 1);
        }
        this.setState({ allData: allData });
      }
    }
  };

  getAllSiteManagers = async () => {
    const resp = await getAllSiteManagersCall("1");
    this.setState({ allData: resp?.data });
  };
  getStateUpdate = () => {
    this.getAllSiteManagers();
  };
  render() {
    const { allData, isEdit, editData } = this.state;
    return (
      <>
        <div className="wrapper">
          <Row className="hsmContainer">
            <Col sm="3" className="noPadding">
              <HSL
                allData={allData}
                isEdit={isEdit}
                editData={editData}
                getStateUpdate={this.getStateUpdate}
              />
            </Col>
            <Col sm="9" className="noPadding">
              <HSR
                allData={allData}
                isEditSiteManager={this.isEditSiteManager}
                isDeleteSiteManager={this.isDeleteSiteManager}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(HeroSectionManager);
