import React from "react";
import { Card, Button, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { ReactComponent as GreyLine } from "./../../assets/greyLine.svg";
import { ReactComponent as Love } from "./../../assets/love.svg";
import { ReactComponent as Share } from "./../../assets/share.svg";
import { ReactComponent as ViewedIcon } from "./../../assets/viewedIcon.svg";
import moment from "moment";
import "./styles.css";

const ArticleCard = (props) => {
  const gotoviewArticle = () => {
    props.history.push(`/dashboard/viewArticle/${ArticleData?.id}`);
  };
  const gotoEditPage = () => {
    props.history.push(`/dashboard/editArticle/${ArticleData?.id}`);
  };
  const ArticleData = props?.ArticleData;
  return (
    <Col sm="4" className="cardsColumn">
      <Card className="collectionsCard">
        <Card.Img
          className="mainImageNew"
          onClick={gotoviewArticle}
          variant="top"
          src={ArticleData?.featuredImage}
          style={{ cursor: "pointer" }}
        />
        <Card.Body>
          <Card.Title className="cardTitleHeight" onClick={gotoviewArticle}>
            {ArticleData?.title.substring(0, 70)}{" "}
          </Card.Title>
          {ArticleData?.status == 2 ? (
            <>
              {" "}
              <Button className="articleEditBtn" onClick={gotoEditPage}>
                Edit
              </Button>
            </>
          ) : (
            <>
              <Card.Text>
                <span className="articleDate">
                  {moment(ArticleData?.createdOnUtc).format("MMMM Do YYYY")}{" "}
                </span>
                <GreyLine className="lineStyles" />
                <span className="favourite">
                  {" "}
                  <ViewedIcon /> 0{" "}
                </span>
              </Card.Text>
              <Button className="showOnHomeBtn" onClick={gotoviewArticle}>
                Push to Homepage
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default withRouter(ArticleCard);
