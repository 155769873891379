import React, { useState } from "react";

import bigScreenImg from "../../assets/image/big_screen.png";
import LiveActionVideoList from "../../components/LiveActionVideoList";

import bidWhiteImg from "../../assets/image/bid-white.png";
import vect3 from "../../assets/image/vect3.png";

import videoIcon from "./../../assets/image/play-button.svg";
import closeIcon from "./../../assets/image/close.svg";

import "./css/LiveActionVideoContent.css";
import LiveVideo from "./../../pages/LiveVideo";
class LiveActionVideoContent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      fullScreenMode: false,
      openLeftSideBar: false,
    };
  }
  render() {
    const { fullScreenMode, openLeftSideBar } = this.state;
    const collectionData = this.props?.collectionData;
    const lotData = this.props?.lotData;
    const idCollection = this.props?.idCollection;
    const highestBidDetails = this.props?.highestBidDetails;
    const nextBidAmount = this.props?.highestBidDetailsFull?.nextBidAmount ?? 0;
    let currencyName = this.props?.currenciesList.find(
      (i: any) => collectionData?.currency == i?.id
    )?.currencyCode;

    return (
      <>
        <div
          className="d-block d-lg-none live_action_right_sidebar_icon"
          onClick={() => {
            this.setState({ openLeftSideBar: true });
          }}
        >
          <img src={videoIcon} alt="menu-icon" />
        </div>
        <div
          className={`auction_box_3 auction_box_right ${
            openLeftSideBar ? "active" : ""
          }`}
        >
          <div className="auction_box_vedio_sec">
            {/* <div className="live_action_video">
            <div> */}

            <LiveVideo
              BiddingLodding={this.props?.BiddingLodding}
              triggerBidCall={this.props?.triggerBidCall}
              lotData={lotData}
              highestBidDetails={highestBidDetails}
              currencyName={currencyName}
              idCollection={idCollection}
              nextBidAmount={nextBidAmount}
              lotItemStatus={this.props?.lotItemStatus}
              playStartedCall={this.props?.playStartedCall}
              streamCode={this.props.streamCode}
            />

            {/* </div>
            
          </div> */}
          </div>
          {/* <div className="auction_box_inner_list">
          <ul className="reset">
            <LiveActionVideoList />
          </ul>
        </div> */}
          {/* <div className="btn_lots">
          <a
            href="inner3.html"
            className="lots_btn_same position-absolute text-white w-100 d-flex align-items-center justify-content-center text-decoration-none"
          >
            <img src={bidWhiteImg} className="bid_icon mr-1" />
            BID - USD 1600 12
          </a>
        </div> */}
        </div>
        <img
          src={closeIcon}
          alt="close-icon"
          className="right_video_close_bar position-absolute d-block d-lg-none"
          onClick={() => {
            this.setState({ openLeftSideBar: false });
          }}
        />
      </>
    );
  }
}
export default LiveActionVideoContent;

// import React, { useState } from "react";

// import bigScreenImg from "../../assets/image/big_screen.png";
// import LiveActionVideoList from "../../components/LiveActionVideoList";

// import bidWhiteImg from "../../assets/image/bid-white.png";
// import vect3 from "../../assets/image/vect3.png";

// import videoIcon from "./../../assets/image/play-button.svg";
// import closeIcon from "./../../assets/image/close.svg";

// import "./css/LiveActionVideoContent.css";
// import { Button } from "react-bootstrap";

// class LiveActionVideoContent extends React.Component<any, any> {
//   constructor(props: any) {
//     super(props);
//     this.state = {
//       fullScreenMode: false,
//       openLeftSideBar: false,
//     }
//   }
//   render(){
//     const {fullScreenMode, openLeftSideBar} =  this.state;
//   return (
//     <>
//       <div
//         className="d-block d-lg-none live_action_right_sidebar_icon"
//         onClick={() => {this.setState({openLeftSideBar: true})}}
//       >
//         <img src={videoIcon} alt="menu-icon" />
//       </div>
//       <div
//         className={`auction_box_3 auction_box_right ${
//           openLeftSideBar ? "active" : ""
//         }`}
//       >
//         <div className="auction_box_vedio_sec">
//           <div className="live_action_video">
//           <iframe width="560" height="315" src="https://livestream.eastus.cloudapp.azure.com:5443/WebRTCAppEE/play.html?name=stream1" frameBorder="0" allowFullScreen></iframe>
//             <aside className="vedio_tab position-relative justify-content-between d-flex">
//               <div className="vedio_tab_left d-flex">
//                 <div className="vedio_tab_1">Lot 01</div>
//                 <div className="vedio_tab_2">
//                   <h4>Big Daddy Kane Graphical Illustration </h4>
//                   <div className="estimate_sec">
//                     <span>Estimate:</span>
//                     <span className="strong">USD 8,000 - USD 12,000</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="vedio_tab_3 text-right ml-auto">
//                 <h4>Current Bid: USD 2000</h4>
//                 <h6>reserve met</h6>
//               </div>
//               <div className="vedio_overlay">
//                 <div
//                   className="scrn_big"
//                   onClick={() => {this.setState({fullScreenMode : true})}}
//                 >
//                   <img src={bigScreenImg} />
//                 </div>
//               </div>
//             </aside>
//           </div>
//         </div>
//         <div className="auction_box_inner_list customListStyles">
//           <ul className="reset customUlStyles" >
//             <LiveActionVideoList />
//           </ul>
//         </div>
//         <div className="onceTwiceWarning">
//             <span className="CountingWarnStyles">Going once... Going twice.</span>
//             <span className="CountingWarnStyles lotCloseWarning">Fair Warning {`----->`} This lot about to close</span>
//           </div>
//         <div className="biddingBottomBlock">
//           <span className="enterBiddingTitle">Enter Bidding Id</span>
//           <div className="biddingInputDiv">
//           <input type="text" className="enterBidding" />
//           <Button variant="custom" className="liveBidButton">BID - USD 1600</Button>
//           </div>
//         </div>
//       </div>
//       <img
//         src={closeIcon}
//         alt="close-icon"
//         className="right_video_close_bar position-absolute d-block d-lg-none"
//         onClick={() => {this.setState({openLeftSideBar: false})}}
//       />
//       <div
//         className={`live_action_fullscreen_mode position-fixed w-100 vh-100 bg-white ${
//           fullScreenMode ? "active" : ""
//         }`}
//       >
//         <div className="auction_box_vedio_sec w-75">
//           <div className="live_action_video">
//           <iframe width="560" height="315" src="https://livestream.eastus.cloudapp.azure.com:5443/WebRTCAppEE/play.html?name=stream1" frameBorder="0" allowFullScreen></iframe>
//             <aside className="vedio_tab position-relative justify-content-between d-flex">
//               <div className="vedio_tab_left d-flex">
//                 <div className="vedio_tab_1">Lot 01</div>
//                 <div className="vedio_tab_2">
//                   <h4>Big Daddy Kane Graphical Illustration </h4>
//                   <div className="estimate_sec">
//                     <span>Estimate:</span>
//                     <span className="strong">USD 8,000 - USD 12,000</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="vedio_tab_3 text-right ml-auto">
//                 <h4>Current Bid: USD 2000</h4>
//                 <h6>reserve met</h6>
//               </div>
//               <div className="vedio_overlay">
//                 <div
//                   className="scrn_big"
//                   onClick={() => {this.setState({fullScreenMode : false})}}
//                 >
//                   <img src={vect3} />
//                 </div>
//               </div>
//             </aside>
//           </div>
//         </div>
//         <div className="auction_box_inner_list position-relative">
//           <ul className="reset">
//             <LiveActionVideoList />
//           </ul>
//           <div className="btn_lots">
//             {/* <a
//               href="inner3.html"
//               className="lots_btn_same position-absolute text-white w-100 d-flex align-items-center justify-content-center text-decoration-none"
//             >
//               <img src={bidWhiteImg} className="bid_icon mr-1" />
//               BID - USD 1600
//             </a> */}
//             {/* <Button variant="custom" className="liveBidButton">BID - USD 1600</Button> */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
//       }
// };
// export default LiveActionVideoContent;
