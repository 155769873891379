import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import uploadFileToAzure from "../../services";

class uploadImage extends Component {
  static propTypes = {};

  state = {
    image: "",
    imageFiles: {},
    imageSRC: "",
  };
  onFileChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ imageSRC: reader.result });
      });
      reader.readAsDataURL(event.target.files[0]);
      this.setState({ imageFiles: event.target.files[0] });

      console.log(event.target.files[0]);

      let ingFIle = event.target.files[0];
      console.log(ingFIle.lastModified);
      const mille_Sec = Math.round(new Date());
      // ingFIle.name = `${ingFIle.lastModified}__${ingFIle.name}`;

      console.log(ingFIle);

      const uploadedFileURL = await uploadFileToAzure(ingFIle, mille_Sec);
    }
  };
  render() {
    return (
      <>
        <div className="rapper-imageUpload">
          <input
            type="file"
            onChange={(e) => this.onFileChange(e)}
            accept="image/*"
          />
          this is file
          <img src={this.state.imageSRC}></img>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadImage,
    },
    dispatch
  );

export default uploadImage;
