import React, { useState, Component } from "react";
import { Row, Col, Accordion, Card, Button, Form } from "react-bootstrap";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as ImagePlaceholderIcon } from "./../../assets/ImagePlaceholder.svg";
import "./styles.css";

import { ReactComponent as Love } from "./../../assets/love.svg";
import { ReactComponent as Share } from "./../../assets/share.svg";

import {
  createarticleDataCall,
  updatearticlestatusCall,
  getarticlebyidCall,
} from "./../../library/Requests/ContentManagement";

import CustomModal from "./../../components/CustomModal";

import moment from "moment";

import { withRouter } from "react-router-dom";

class PreviewArticle extends Component {
  static propTypes = {};

  state = {
    articleID: 0,
    ArticleData: {},
    viewArticle: false,
    articlePublishPopup: false,
  };
  closePreview = () => {
    const type = this.state.ArticleData?.status == 1 ? "?type=published" : "";
    this.props.history.push(`/dashboard/contentManagement${type}`);
  };
  componentDidMount = () => {
    const currentPath = this.props.location.pathname;
    if (currentPath.indexOf("dashboard/previewArticle") > 0) {
      let collID = currentPath.replace("/dashboard/previewArticle/", "");
      this.setState({ articleID: collID });
      this.getArticleById(collID);
    } else if (currentPath.indexOf("dashboard/viewArticle") > 0) {
      let collID = currentPath.replace("/dashboard/viewArticle/", "");
      this.setState({ articleID: collID, viewArticle: true });
      this.getArticleById(collID);
    }
  };
  getArticleById = async (id) => {
    let respInfo = await getarticlebyidCall(id);
    let someCollection = respInfo.data;
    this.setState({ ArticleData: someCollection });
    console.log(someCollection);
  };
  gotoEditPage = () => {
    this.props.history.push(
      `/dashboard/editArticle/${this.state?.ArticleData?.id}`
    );
  };
  hideModel = () => {};
  publishArticle = async () => {
    const body = {
      id: this.state.ArticleData?.id,
      status: 1,
    };
    let respInfo = await updatearticlestatusCall(body);
    console.log(respInfo);
    if (respInfo.success) {
      this.setState({ articlePublishPopup: true });
      // this.closePreview();
    }
  };
  createMarkup = (data) => {
    return { __html: data };
  };
  render() {
    const { ArticleData, viewArticle, articlePublishPopup } = this.state;
    return (
      <>
        <div className="wrapper">
          <div className="PreviewArticleContainer">
            <div className="createArticleHeaderContainer">
              <Row className="articlePreviewHeadingRow btnsRowPreview">
                <span
                  onClick={this.closePreview}
                  className="contentMgmtHeading"
                >
                  <ChevronLeft />
                  {ArticleData.status == 2
                    ? "Drafted Articles"
                    : "Published Articles"}
                </span>
                {viewArticle ? (
                  <>
                    <Button
                      className=" editArticle_Preview"
                      onClick={this.gotoEditPage}
                    >
                      Edit
                    </Button>
                    {ArticleData.status == 2 && (
                      <Button
                        className="Close_Preview"
                        onClick={this.publishArticle}
                      >
                        Publish
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      className="closeArticle_Preview"
                      onClick={this.closePreview}
                    >
                      Close Preview
                    </Button>
                  </>
                )}
              </Row>
            </div>
            <Card className="previewArticle">
              <Card.Body className="previewArticleBody">
                <Row className=" btnsRowPreview">
                  {/* <span className="textHeaderPreview">Fineart Hiphop &nbsp; | &nbsp; {moment(ArticleData?.createdOnUtc).format("MMM Do, YYYY")} </span>
                    <span className="textHeaderPreview"><Love/> 0 &nbsp;&nbsp; &nbsp;&nbsp; <Share/> 0</span> */}
                </Row>
                <Row className="headerPreviewContent">
                  <p className="PreviewTitle">{ArticleData?.title} </p>
                </Row>
                <Row className="headerPreviewContent">
                  <p className="PreviewAuthor"> By {ArticleData?.author} </p>
                </Row>
                <Row className="headerPreviewContent imageContainer">
                  <Row className="imageRow">
                    {ArticleData?.featuredImage ? (
                      <img
                        src={ArticleData?.featuredImage}
                        alt="Preview"
                        className="previewImage"
                      />
                    ) : (
                      <ImagePlaceholderIcon />
                    )}
                  </Row>
                </Row>
                <Row className="headerPreviewContent headerContentViewArticle">
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      ArticleData?.articalContent
                    )}
                  />
                  {/* <p className="PreviewAuthor"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sollicitudin netus tempus faucibus euismod risus semper venenatis. </p>
                    <p className="PreviewAdescCon"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas eu sagittis, est dictum vivamus nisl, imperdiet elit. Gravida sapien nulla mus amet sit orci egestas. Felis sit diam dignissim dolor tincidunt tempor, ultrices ultrices orci. Vel sociis ullamcorper sed enim nullam gravida aliquam. 
                    </p>
                    <p className="PreviewAdescCon">  Dignissim mauris vulputate mi, quis sit. Tortor nunc tortor volutpat lacus, eu. Amet et placerat fringilla cursus quisque. Pharetra, leo iaculis a, dui nunc. Elementum eros, convallis ac feugiat egestas id nunc rhoncus sapien. Mauris amet convallis rhoncus sit metus. Egestas ultrices enim urna, habitasse duis sit ut. Get varius semper sit sed nunc in lacus, cum. Nisi, sed et faucibus egestas nunc. Augue sit felis ante viverra morbi tempor mi platea. Molestie rhoncus sit enim praesent feugiat.
                    </p> */}
                </Row>

                {/* <Row className="headerPreviewContent" > */}
                {/* <p className="PreviewdisbaledText"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> */}

                {/* </Row> */}
                <Row className="headerPreviewContent">
                  <p className="PreviewAdescCon"> {ArticleData?.excerpt}</p>
                  <p className="PreviewAdescCon"> {ArticleData?.tags}</p>
                </Row>
              </Card.Body>
            </Card>
          </div>
          <CustomModal
            show={articlePublishPopup}
            titleText="Article Published Successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => this.hideModel()}
            onDelete={() => this.closePreview(false)}
          />
        </div>
      </>
    );
  }
}

export default withRouter(PreviewArticle);
