import { get, post, put, del } from "./helpers";
import { API_BASE, SERVER_IP } from "../constants";

export const createarticleDataCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/ContentManagement/createarticle`,
    body
  );
};
export const editarticleCall = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/ContentManagement/editarticle`,
    body
  );
};
export const getdraftedarticleCall = async (search) => {
  return await get(
    `${SERVER_IP}${API_BASE}/ContentManagement/getallarticle?Status=2&Page=1&PageSize=50&SearchText=${search}`
  );
};
export const getpublishedarticleCall = async (search = "") => {
  return await get(
    `${SERVER_IP}${API_BASE}/ContentManagement/getallarticle?Status=1&Page=1&PageSize=50&SearchText=${search}`
  );
};

export const getarticlebyidCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/ContentManagement/getarticlebyid?Id=${id}`
  );
};
export const updatearticlestatusCall = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/ContentManagement/updatearticlestatus`,
    body
  );
};
