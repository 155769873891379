import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Card, Form, Button } from "react-bootstrap";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import "./styles.css";
import uplaodImageNew from "../../services";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { wait } from "@testing-library/react";

import { edititemdescription } from "./../../library/Requests";

// function uploadImageCallBack1(file) {
//     return new Promise(
//       (resolve, reject) => {
//         const xhr = new XMLHttpRequest();
//         xhr.open('POST', 'https://api.imgur.com/3/image');
//         xhr.setRequestHeader('Authorization', 'Client-ID 3fb0d0761b54ae5');
//         const data = new FormData();
//         data.append('image', file);

//         // upload image in Azor block using token
//         // response will returns folder name and file name(date.time)

//         xhr.send(data);

//         xhr.addEventListener('load', () => {
//           const response = JSON.parse(xhr.responseText);
//           console.log(response)
//           resolve(response);
//         });
//         xhr.addEventListener('error', () => {
//           const error = JSON.parse(xhr.responseText);
//           console.log(error)
//           reject(error);
//         });
//         // resolve("https://wholosophy-development-content.s3-us-west-1.amazonaws.com/1611052173913_102050.jpg")
//       }
//     );
//   }

const AddLotDescription = (props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [saveContinue, setSaveContinue] = useState(false);
  const [fieldisEmpty, setFieldisEmpty] = useState(false);
  useEffect(() => {
    if (props.isEditLot && props.lotItemDetails?.description) {
      document.getElementsByClassName(
        "DraftEditor-editorContainer"
      )[0].innerHTML = props.lotItemDetails?.description;
    }
  }, [props]);

  const onEditorStateChange = (editorState) => {
    console.log(editorState);
    setEditorState(editorState);
    setFieldisEmpty(false);
  };

  const uploadImageCallBack = async (file) => {
    return new Promise(async (resolve, reject) => {
      const respnew = await uplaodImageNew(file, "lot");
      console.log(respnew);
      console.log(file);
      const obj1 = {
        data: {
          link: respnew?.imageUrl,
          type: file.type,
        },
      };
      resolve(obj1);
    });
  };
  const saveAndContinue = async () => {
    var s = convertToRaw(editorState.getCurrentContent());
    const flagVale = s.blocks[0].text.trim().length > 0;
    // console.log(flagVale);
    // const flagVale = true;
    // return false;
    if (flagVale || props.isEditLot) {
      const body = {
        id: document.getElementById("collectionLotID").value,
        itemDescription: document.getElementsByClassName(
          "DraftEditor-editorContainer"
        )[0].innerHTML,
      };

      const respNew = await edititemdescription(body);
      if (respNew?.success) {
        document.getElementById("Accordion_Section_3").click();
        setSaveContinue(true);
        props.getItemDetailsCall();
      }
    } else {
      setFieldisEmpty(true);
    }
  };
  return (
    <div className="wrapper">
      <Card>
        <Accordion.Toggle
          id="Accordion_Section_2"
          as={Card.Header}
          eventKey="2"
        >
          <span className="formHeading">3. Lot Description</span>
          {/* <AngleUpIcon className="AngleUpIcon" /> */}
          {saveContinue ? (
            <Button className="AccordionEditBtn"> Edit </Button>
          ) : (
            <AngleUpIcon className="angleUpIcon" />
          )}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body className="lotDescriptionBody">
            <p className="editorHeading requiredField">Description of Lot</p>
            <div className="editor">
              <Editor
                placeholder="Enter Description of Lot"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  // textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                  image: {
                    uploadCallback: uploadImageCallBack,
                    alt: { present: true, mandatory: false },
                  },
                }}
              />
            </div>
            {fieldisEmpty && (
              <Row className="errorEditorlot">
                Please enter Description of Lot
              </Row>
            )}
            <Row className="btnRow btnAlign">
              <Button className="saveContinue" onClick={saveAndContinue}>
                Save & Continue
              </Button>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
};

export default AddLotDescription;
