import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Table,
  Modal,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ReactComponent as ThreeDotsIcon } from "./../../assets/threeDotsIcon.svg";
import { ReactComponent as ChevronLeftDouble } from "./../../assets/chevronLeftDouble.svg";
import { ReactComponent as ChevronRightDouble } from "./../../assets/chevronRightDouble.svg";
import { ReactComponent as ChevronPageRight } from "./../../assets/chevronPageRight.svg";
import { ReactComponent as ChevronPageLeft } from "./../../assets/chevronPageLeft.svg";
import { ReactComponent as HorizontalDots } from "./../../assets/horizontalDots.svg";
import CustomModal from "./../../components/CustomModal";
import "./styles.css";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import moment from "moment";

import { LOGINID } from "./../../Scenes/common";
import { withRouter } from "react-router";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import OrderDetails from "./../../components/OrderDetails";
import TransactionDetails from "./../../components/TransactionDetails";
import CustomerInformation from "./../../components/CustomerInformation";
import SearchComponent from "./../../components/SearchComponent";

// import LogisticInformation from "./../../components/LogisticInformation"
// import Timeline from "./../../components/Timeline"

import {
  getOrdersCall,
  getOrderbyIdCall,
  getOrderStatus,
  getcustomeraddressCall,
  updateOrderStatusCall,
} from "./../../library/Requests";
import orderDetails from "./../../components/OrderDetails";

class OrderManagement extends Component {
  static propTypes = {};
  state = {
    allOrders: [],
    showOrderDetails: false,
    selectedOrder: {},
    orderDetails: {},
    changeOrder: false,
    successModal: false,
    paymentRemainder: false,
    orderStatus: [],
    customerAddresses: [],
    changedValue: "",
    currentPage: 1,
    setPageData: 10,
    pageNumberLimit: 5,
    maxPageNumberLimit: 5,
    minPageNumberLimit: 0,
    totalCount: 0,
    selectedorderStatus: [],
    selectedpaymentStatus: [],
    loading: false,
    Pageonloading: false,
    sameStatusErrorText: false,
  };
  pageNextBtn = this.pageNextBtn.bind(this);
  componentDidMount = () => {
    const id = this.props?.match?.params?.id;
    this.getAllOrders("onLoad");
    this.getOrdersStatus();
  };
  paymentRemainderPopUp = () => this.setState({ paymentRemainder: true });

  getAllOrders = async (pageType) => {
    const {
      setPageData,
      currentPage,
      selectedorderStatus,
      selectedpaymentStatus,
      loading,
    } = this.state;
    let body = {
      page: currentPage,
      pageSize: setPageData,
    };
    if (selectedorderStatus.length > 0) {
      body.orderStatus = selectedorderStatus;
    }
    if (selectedpaymentStatus.length > 0) {
      body.paymentStatus = selectedpaymentStatus;
    }
    if (pageType == "onLoad") {
      this.setState({ Pageonloading: true });
    }
    this.setState({ loading: true });
    const resp = await getOrdersCall(body);
    this.setState({ allOrders: resp?.data });
    this.setState({ loading: false, Pageonloading: false });
    if (pageType == "onLoad") {
      this.setState({ totalCount: resp?.totalCount });
    }
  };
  getOrdersStatus = async () => {
    const resp = await getOrderStatus();
    this.setState({ orderStatus: resp?.orderStatus });
  };
  filterOrders = (e) => {
    const { allOrders } = this.state;
    this.setState({ currentPage: 1 });
    const orderType = e.target.value;
    const name = e.target.name;
    if (name == "All") {
      if (e.target.checked) {
        this.setState({
          selectedorderStatus: [
            "Waiting for Payment",
            "Processing",
            "Shipped",
            "In Transist",
            "Delivered",
            "Payment Completed",
          ],
          selectedpaymentStatus: ["Paid", "Unpaid"],
        });
      } else {
        this.setState({ selectedorderStatus: [], selectedpaymentStatus: [] });
      }
    } else if (name == "orderStatus") {
      if (e.target.checked) {
        this.setState({
          selectedorderStatus: this.state.selectedorderStatus.concat(orderType),
        });
      } else {
        const existData = this.state.selectedorderStatus;
        const index = existData.findIndex((el, ind) => el == orderType);
        existData.splice(index, 1);
        this.setState({ selectedorderStatus: existData });
      }
    } else if (name == "paymentStatus") {
      if (e.target.checked) {
        this.setState({
          selectedpaymentStatus:
            this.state.selectedpaymentStatus.concat(orderType),
        });
      } else {
        const existData = this.state.selectedpaymentStatus;
        const index = existData.findIndex((el, ind) => el == orderType);
        existData.splice(index, 1);
        this.setState({ selectedpaymentStatus: existData });
      }
    }
    const self = this;
    setTimeout(() => {
      self.getAllOrders("onLoad");
    }, 100);

    // return false;
    // if(orderType=="All"){
    //     this.setState({allOrders: allOrders})
    // }else if(orderType=="Paid"){
    //     const paidOrders = allOrders.filter((item) => item.paymentStatus == 'Paid');
    //     this.setState({allOrders:paidOrders})
    // }else if(orderType=="Unpaid"){
    //     const unPaidOrders = allOrders.filter((item) => item.paymentStatus == 'Unpaid');
    //     this.setState({allOrders:unPaidOrders})
    // }else if(orderType=="Order Placed"){
    //     const orderPlaced = allOrders.filter((item) => item.orderStatus == 'Order Placed');
    //     this.setState({allOrders:orderPlaced})
    // }else if(orderType =="Waiting for Payment"){
    //     const waitingForPayment = allOrders.filter((item) => item.orderStatus == 'Waiting for Payment');
    //     this.setState({allOrders:waitingForPayment})
    // }else if(orderType =="Payment Completed"){
    // }else if(orderType =="In Transist"){
    //     const inTransist = allOrders.filter((item) => item.orderStatus == 'In Transist');
    //     this.setState({allOrders:inTransist})
    // }else if(orderType=="Shipped"){
    //     const Shipped = allOrders.filter((item) => item.orderStatus == 'Shipped');
    //     this.setState({allOrders:Shipped})
    // }else if(orderType =="Delivered"){
    //     const Delivered = allOrders.filter((item) => item.orderStatus == 'Delivered');
    //     this.setState({allOrders:Delivered})
    // }
  };
  hideModal = () => {
    this.setState({ changeOrder: false });
  };
  handleChangeValue = (e) => {
    let value = e.target.value;
    this.setState({ sameStatusErrorText: false });
    this.setState({ changedValue: value });
  };
  changeOrderStatus = async (item) => {
    this.setState({ sameStatusErrorText: false });
    this.setState({ changeOrder: true });
    this.setState({ selectedOrder: item });
  };
  checkSelected = () => {
    let existingValue = this.state.selectedOrder?.orderStatus;
    let selectedValue = this.state.changedValue;
    if (selectedValue != "" && existingValue != selectedValue) {
      this.setState({ sameStatusErrorText: false });
      this.changeOrderStatusSuccess();
    } else {
      this.setState({ sameStatusErrorText: true });
    }
  };
  changeOrderStatusSuccess = async () => {
    const id = this.state?.selectedOrder?.orderId;
    const body = {
      orderId: id,
      orderStatus: this.state.changedValue,
    };
    const resp = await updateOrderStatusCall(body);
    if (resp?.success) {
      this.setState({ changeOrder: false });
      this.setState({ successModal: true });
      if (!this.state?.showOrderDetails) {
        this.getAllOrders();
      } else {
        const resp = await getOrderbyIdCall(id);
        this.setState({ orderDetails: resp?.data });
      }
    }
  };
  selectOrder = async (item) => {
    this.setState({ showOrderDetails: true, selectedOrder: item });
    const resp = await getOrderbyIdCall(item?.orderId);
    this.setState({ orderDetails: resp?.data });
    // const addressResp = await getcustomeraddressCall(59);
    // this.setState({customerAddresses:addressResp?.address});
  };
  eachPageNumberClick(pageno) {
    this.setState({ currentPage: pageno }, () => {
      this.getAllOrders();
    });
  }
  pageNextBtn() {
    const a = this.state;
    if (a.currentPage + 1 > a.maxPageNumberLimit) {
      this.setState({
        maxPageNumberLimit: a.maxPageNumberLimit + a.pageNumberLimit,
      });
      this.setState({
        minPageNumberLimit: a.minPageNumberLimit + a.pageNumberLimit,
      });
    }
    this.setState({ currentPage: a.currentPage + 1 }, () => {
      this.getAllOrders();
    });
  }
  pagePrevBtn() {
    const a = this.state;
    if ((a.currentPage - 1) % a.pageNumberLimit == 0) {
      this.setState({
        maxPageNumberLimit: a.maxPageNumberLimit - a.pageNumberLimit,
      });
      this.setState({
        minPageNumberLimit: a.minPageNumberLimit - a.pageNumberLimit,
      });
    }
    this.setState({ currentPage: a.currentPage - 1 }, () => {
      this.getAllOrders();
    });
  }

  render() {
    const {
      allOrders,
      showOrderDetails,
      selectedOrder,
      orderDetails,
      changeOrder,
      successModal,
      paymentRemainder,
      orderStatus,
      customerAddresses,
      currentPage,
      setPageData,
      pageNumberLimit,
      maxPageNumberLimit,
      minPageNumberLimit,
      totalCount,
      selectedorderStatus,
      selectedpaymentStatus,
      loading,
      Pageonloading,
      sameStatusErrorText,
    } = this.state;
    const pages = [];
    // const optionsArray = [
    //     { key: "au", label: "Australia" },
    //     { key: "ca", label: "Canada" },
    //     { key: "us", label: "USA" },
    //     { key: "pl", label: "Poland" },
    //     { key: "es", label: "Spain" },
    //     { key: "fr", label: "France" },
    //   ];

    for (let i = 1; i <= Math.ceil(totalCount / setPageData); i++) {
      pages.push(i);
    }
    const renderPageNumbers = pages.map((number) => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        return (
          <li
            key={number}
            id={number}
            onClick={() => {
              this.eachPageNumberClick(number);
            }}
            className={currentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });

    return (
      <div className="orderMgmtContainer">
        {!showOrderDetails ? (
          <>
            <Row>
              <Col sm={4}>
                <p className="orderMgmtHeading"> Order Management </p>
              </Col>

              <Col sm={4} className="filterByBlockCol">
                <Dropdown
                  className="filterByDropdown"
                  onChange={this.filterOrders}
                  data-placement="top"
                >
                  <span
                    className={
                      selectedorderStatus.length > 0 ||
                      selectedpaymentStatus.length > 0
                        ? "tooltiptext"
                        : null
                    }
                  >
                    {(selectedorderStatus.length > 0 ||
                      selectedpaymentStatus.length > 0) && [
                      selectedorderStatus.join(", "),
                      selectedpaymentStatus.join(", "),
                    ]}
                  </span>
                  <Dropdown.Toggle>
                    <span className="FilterByText" data-toggle="tooltip">
                      {" "}
                      Filter By:{" "}
                      {selectedorderStatus.length > 0 ||
                      selectedpaymentStatus.length > 0
                        ? [selectedorderStatus, selectedpaymentStatus]
                        : "All"}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdwonOrdermanagement">
                    <li className="dropdownTexts">
                      <input
                        value="All"
                        name="All"
                        type="checkbox"
                        checked={
                          selectedpaymentStatus.length > 1 &&
                          selectedorderStatus.length > 5
                            ? true
                            : false
                        }
                        className="checkboxSelect"
                      />
                      <label className="dropdownText"> All </label>{" "}
                    </li>
                    <DropdownItem className="dropdownTextHeader">
                      Payment Status
                    </DropdownItem>
                    <Dropdown.Divider className="dropdownDivider" />
                    <li className="dropdownTexts">
                      <input
                        type="checkbox"
                        checked={selectedpaymentStatus.includes("Paid")}
                        name="paymentStatus"
                        value="Paid"
                        className="checkboxSelect"
                      />
                      <label className="dropdownText"> Paid</label>{" "}
                    </li>
                    <li className="dropdownTexts">
                      <input
                        type="checkbox"
                        checked={selectedpaymentStatus.includes("Unpaid")}
                        name="paymentStatus"
                        value="Unpaid"
                        className="checkboxSelect"
                      />
                      <label className="dropdownText"> Unpaid</label>{" "}
                    </li>
                    <DropdownItem className="dropdownTextHeader">
                      Order Status
                    </DropdownItem>
                    <Dropdown.Divider className="dropdownDivider" />
                    <li className="dropdownTexts">
                      <input
                        type="checkbox"
                        checked={selectedorderStatus.includes(
                          "Waiting for Payment"
                        )}
                        name="orderStatus"
                        value="Waiting for Payment"
                        className="checkboxSelect"
                      />
                      <label className="dropdownText"> Payment Pending</label>{" "}
                    </li>
                    <li className="dropdownTexts">
                      <input
                        type="checkbox"
                        checked={selectedorderStatus.includes("Processing")}
                        name="orderStatus"
                        value="Processing"
                        className="checkboxSelect"
                      />
                      <label className="dropdownText"> Processing</label>{" "}
                    </li>
                    <li className="dropdownTexts">
                      <input
                        type="checkbox"
                        checked={selectedorderStatus.includes("Shipped")}
                        name="orderStatus"
                        value="Shipped"
                        className="checkboxSelect"
                      />
                      <label className="dropdownText"> Shipped</label>{" "}
                    </li>
                    <li className="dropdownTexts">
                      <input
                        type="checkbox"
                        checked={selectedorderStatus.includes("In Transist")}
                        name="orderStatus"
                        value="In Transist"
                        className="checkboxSelect"
                      />
                      <label className="dropdownText"> In - Transit</label>{" "}
                    </li>
                    <li className="dropdownTexts">
                      <input
                        type="checkbox"
                        checked={selectedorderStatus.includes("Delivered")}
                        name="orderStatus"
                        value="Delivered"
                        className="checkboxSelect"
                      />
                      <label className="dropdownText"> Delivered</label>{" "}
                    </li>
                    <li className="dropdownTexts">
                      <input
                        type="checkbox"
                        checked={selectedorderStatus.includes(
                          "Payment Completed"
                        )}
                        name="orderStatus"
                        value="Payment Completed"
                        className="checkboxSelect"
                      />
                      <label className="dropdownText"> Payment Completed</label>{" "}
                    </li>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col sm={4} className="SearchComponentCol">
                <SearchComponent placeHolderText="Quick Search" />
              </Col>
            </Row>
            {loading ? (
              <>
                <div className="spinner-borderWord">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <Table className="orderMgmtTable">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Ordered Date</th>
                    <th>Full name</th>
                    <th>Email Address</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {(allOrders || []).map((item, index) => {
                    return (
                      <tr>
                        <td
                          onClick={() => {
                            this.selectOrder(item);
                          }}
                        >
                          #{item?.orderNo}
                        </td>
                        <td>
                          {moment(item?.ordredDate).format("DD MMMM, YYYY")}{" "}
                        </td>
                        <td>
                          {" "}
                          {item.firstName} {item.lastName}{" "}
                        </td>
                        <td> {item.email} </td>
                        <td
                          className={
                            item?.paymentStatus == "Unpaid" ? "red" : "green"
                          }
                        >
                          {item?.paymentStatus}
                        </td>
                        <td>{item?.orderStatus}</td>
                        <td>
                          <Dropdown className="actionsDropdown ">
                            <Dropdown.Toggle>
                              <ThreeDotsIcon className="dottedLineGrid" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  this.selectOrder(item);
                                }}
                              >
                                View{" "}
                              </Dropdown.Item>
                              {item?.paymentStatus != "Paid" && (
                                <Dropdown.Item
                                  onClick={this.paymentRemainderPopUp}
                                >
                                  Send Payment Reminder
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item
                                onClick={() => this.changeOrderStatus(item)}
                              >
                                Change Order Status
                              </Dropdown.Item>

                              <CustomModal
                                // show={modalShow}
                                titleText="Do you want to delete this ?"
                                deleteText="Delete"
                                cancelText="Cancel"
                                // onCancel={() => }
                                // onDelete={() => deleteCollectionItem(collectionItemData)}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            {!Pageonloading && (
              <Row className="paginationBoxes">
                {/* <span className="arrowBox" onClick={()=>this.firstPageBtnCall()}> <ChevronLeftDouble /> </span>
                <button className="arrowBox" > <ChevronPageLeft /> </button> */}
                <button
                  className="arrowBox"
                  onClick={() => this.pagePrevBtn()}
                  style={{
                    display: currentPage == pages[0] ? "none" : "block",
                  }}
                >
                  {" "}
                  <HorizontalDots />{" "}
                </button>
                <ul className="pageNumber">{renderPageNumbers}</ul>
                <button
                  className="arrowBox paginationNextBtn"
                  onClick={() => this.pageNextBtn()}
                  style={{
                    display:
                      currentPage == pages[pages.length - 1] ? "none" : "block",
                  }}
                >
                  {" "}
                  <HorizontalDots />{" "}
                </button>
                {/* <span className="arrowBox"> <ChevronPageRight /> </span>
                <span className="arrowBox" onClick={()=>this.lastPageBtnCall()}> <ChevronRightDouble /> </span> */}
              </Row>
            )}
          </>
        ) : (
          <div className="odvContainer">
            <p className="orderMgmtSideHeading">
              {" "}
              <span
                onClick={() => {
                  this.setState({ showOrderDetails: false });
                }}
              >
                <ChevronLeft className="chevronLeft" /> Order Management{" "}
              </span>{" "}
            </p>
            <Row>
              <Col sm="8">
                <OrderDetails
                  orderDetails={orderDetails}
                  selectedOrder={selectedOrder}
                />
                <Row>
                  <CustomerInformation
                    orderDetails={orderDetails}
                    selectedOrder={selectedOrder}
                    addressList={customerAddresses}
                  />
                </Row>
              </Col>
              <Col sm="4">
                <TransactionDetails
                  orderDetails={orderDetails}
                  orderStatusList={orderStatus}
                  handleStatusChange={this.handleChangeValue}
                  changeOrderStatus={this.changeOrderStatus}
                  changeOrderStatusSuccess={this.changeOrderStatusSuccess}
                  hideModal={this.hideModal}
                />
              </Col>
            </Row>
            <Row></Row>
          </div>
        )}
        <Modal
          show={changeOrder}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {sameStatusErrorText && (
            <p className="sameStatusText">Please Select new status</p>
          )}
          <Modal.Body>
            <span className="ChangeOrderText">Change Order Status to:</span>
            <div className="OnHoldBlock">
              <Form.Control
                defaultValue={selectedOrder?.orderStatus}
                as="select"
                className="HoldBySelect"
                onChange={this.handleChangeValue}
              >
                {(orderStatus || []).map((item, index) => {
                  const selecVAl =
                    selectedOrder?.orderStatus === item.orderStatus
                      ? true
                      : false;
                  return (
                    <>
                      {
                        // orderDetails?.orderStatus=="Completed" && item.orderStatus !="Waiting for Payment" &&  item.orderStatus !="Order Placed" )&& (
                        <option key={item?.id} value={item?.orderStatus}>
                          {item.orderStatus}
                        </option>
                      }
                    </>
                  );
                })}
              </Form.Control>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="modalCandelBtn" onClick={this.hideModal}>
              Cancel
            </Button>
            <Button className="modalDeleteBtn" onClick={this.checkSelected}>
              Change Status
            </Button>
          </Modal.Footer>
        </Modal>
        <CustomModal
          show={successModal}
          titleText="Order status has been Changed Successfully."
          deleteText="ok"
          cancelText=""
          onDelete={() => this.setState({ successModal: false })}
        />
        <CustomModal
          show={paymentRemainder}
          titleText="Payment Reminder has been sent to Email Address Successfully."
          deleteText="OK"
          cancelText=""
          onDelete={() => this.setState({ paymentRemainder: false })}
        />
      </div>
    );
  }
}

export default OrderManagement;
