import React from "react";
import { Row, Col, Accordion, Card, Form, Button } from "react-bootstrap";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import { ReactComponent as EditSubHeading } from "./../../assets/editSubHeading.svg";
import { withRouter } from "react-router-dom";
import "./styles.css";

const ReviewForm = (props) => {
  // console.log(props.lotItemDetails);
  const LotD = props?.lotItemDetails;

  const LotContactVal = props?.lotItemDetails?.itemLogisticInfos;
  let LotContact = {};
  if (
    LotContactVal != undefined &&
    LotContactVal != null &&
    LotContactVal.length > 0
  ) {
    LotContact = LotContactVal[0];
  }
  // console.log(LotContact);
  const onClinkOpenTab = (number) => {
    // console.log(number);
    document.getElementById("Accordion_Section_" + number).click();
  };
  const createMarkup = (data) => {
    return { __html: data };
  };
  const mainImageNew = LotD?.itemImages;
  let mainImageurl = null;
  if (
    mainImageNew != undefined &&
    mainImageNew != null &&
    mainImageNew.length > 0
  ) {
    mainImageurl = mainImageNew[0].vurtualPath;
  }

  return (
    <div class="wrapper">
      <Card>
        <Accordion.Toggle
          id="Accordion_Section_6"
          as={Card.Header}
          eventKey="6"
        >
          <span className="formHeading">7. Review</span>
          <AngleUpIcon className="angleUpIcon" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="6">
          <Card.Body className="reviewBody">
            <Row>
              {/* LOT DETAILS */}
              <Row className="reviewSubHeading">
                <Col sm="8" className="noPadding">
                  <p className="subHeadingText">Lot Details </p>
                </Col>
                <Col
                  sm="4"
                  className="editColumn noPadding"
                  onClick={() => {
                    onClinkOpenTab("0");
                  }}
                >
                  <EditSubHeading />
                </Col>
                <p className="headingLine"></p>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Title:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.title}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Category:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.categoryId}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Item’s Country of Origon:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.countryCode}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Artist / Maker:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.makerName}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>What is the Object:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.objectDesc}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Medium/ Material:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.material}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Lot contains restricted materials:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.isRestrictedMaterial ? "Yes" : "No"}</span>
                </Col>
              </Row>
              {LotD?.isRestrictedMaterial && (
                <Row className="detailsRow">
                  <Col sm="5" className="namesColumn">
                    <span>Restricted Material Desc:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotD?.restrictedMaterialDesc}</span>
                  </Col>
                </Row>
              )}
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Date/ Period of work:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.periodOfWork}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Measurements unit:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.converstion}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Height:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.height}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Width:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.width}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Depth:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.length}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Weight:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>
                    {LotD?.weightLb} &nbsp;&nbsp;&nbsp; {LotD?.weightOz}{" "}
                  </span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Signatures, Labels or Markings:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.markings}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Areas of Damage:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.damages}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Has it been restored? If so, to what extent:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.retoredInfo}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Appraisals/ Publication/ Exhibition:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.appraisalsAndExhibition}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Initial Bidding Price:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.initialBiddingPrice}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Reserve Price:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.reservedPrice}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Type of Estimated Price:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  {/* <span>{LotD?.isFixedEstimatedPrice}</span> */}
                  <span>{LotD?.isFixedEstimatedPrice ? "Fixed" : "Range"}</span>
                </Col>
              </Row>
              <Row className="detailsRow">
                <Col sm="5" className="namesColumn">
                  <span>Estimated Price:</span>
                </Col>
                <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                  <span>{LotD?.estimatedPrice}</span>
                </Col>
              </Row>
              {!LotD?.isFixedEstimatedPrice && (
                <Row className="detailsRow">
                  <Col sm="5" className="namesColumn">
                    <span>Estimated Price to:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotD?.estimatedPriceTo}</span>
                  </Col>
                </Row>
              )}

              {/* MEDIA */}
              <Row className="reviewSubHeading">
                <Col sm="8" className="noPadding">
                  <p className="subHeadingText">Media </p>
                </Col>
                <Col
                  sm="4"
                  className="editColumn noPadding"
                  onClick={() => {
                    onClinkOpenTab("1");
                  }}
                >
                  <EditSubHeading />
                </Col>
                <p className="headingLine"></p>
              </Row>
              <Row className="mediaContainer">
                <p className="mediaContainerHeading">Photos</p>

                {(LotD?.itemImages || []).map((itemL, index) => {
                  // console.log(itemL);
                  return (
                    <>
                      {itemL?.displayOrder == 8 ? (
                        <>
                          <div class="videosIMageReview">
                            <img src={mainImageurl} alt="Video thumbnail" />
                            <svg
                              id="Layer_1"
                              data-name="Layer 1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <title>play-glyph</title>
                              <path
                                d="M60.54,512c-17.06,0-30.43-13.86-30.43-31.56V31.55C30.12,13.86,43.48,0,60.55,0A32.94,32.94,0,0,1,77,4.52L465.7,229c10.13,5.85,16.18,16,16.18,27s-6,21.2-16.18,27L77,507.48A32.92,32.92,0,0,1,60.55,512Z"
                                fill="#434040"
                              />
                            </svg>
                          </div>
                        </>
                      ) : (
                        <img
                          src={itemL?.vurtualPath}
                          alt="images"
                          className="reviewImage"
                        />
                      )}
                    </>
                  );
                })}
              </Row>

              {/* LOT DESCRIPTION */}
              <Row className="reviewSubHeading">
                <Col sm="8" className="noPadding">
                  <p className="subHeadingText">Lot Description </p>
                </Col>
                <Col
                  sm="4"
                  className="editColumn noPadding"
                  onClick={() => {
                    onClinkOpenTab("2");
                  }}
                >
                  <EditSubHeading />
                </Col>
                <p className="headingLine"></p>
              </Row>
              <Row className="editorReviewSpacing noEditText">
                <div
                  dangerouslySetInnerHTML={createMarkup(LotD?.description)}
                />
              </Row>

              <Row className="reviewSubHeading">
                <Col sm="8" className="noPadding">
                  <p className="subHeadingText">Provenance</p>
                </Col>
                <Col
                  sm="4"
                  className="editColumn noPadding"
                  onClick={() => {
                    onClinkOpenTab("3");
                  }}
                >
                  <EditSubHeading />
                </Col>
                <p className="headingLine"></p>
              </Row>
              <Row className="provinanceDetailsSection">
                {(LotD?.itemProvenances || []).map((itemL, index) => {
                  return (
                    <Row>
                      <span className="provinanceDetails">
                        {itemL?.provenanceOwner}
                      </span>
                    </Row>
                  );
                })}
              </Row>

              {/* CONDITION REPORT */}
              <Row className="reviewSubHeading">
                <Col sm="8" className="noPadding">
                  <p className="subHeadingText">Condition Report</p>
                </Col>
                <Col
                  sm="4"
                  className="editColumn noPadding"
                  onClick={() => {
                    onClinkOpenTab("4");
                  }}
                >
                  <EditSubHeading />
                </Col>
                <p className="headingLine"></p>
              </Row>
              <Row className="editorReviewSpacing noEditText">
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    LotD?.conditionalReport
                  )}
                />
              </Row>

              <Row className="reviewSubHeading">
                <Col sm="8" className="noPadding">
                  <p className="subHeadingText">
                    Owner's Contact and Logistic Information
                  </p>
                </Col>
                <Col
                  sm="4"
                  className="editColumn noPadding"
                  onClick={() => {
                    onClinkOpenTab("5");
                  }}
                >
                  <EditSubHeading />
                </Col>
                <p className="headingLine"></p>
              </Row>

              {/* OWNERS CONTACT & LOGISTICS INFO */}
              <Row className="detailsRow">
                <Row>
                  <Row style={{ width: "100%" }}>
                    <Col sm={{ span: 5 }} className="headingColumn">
                      <p className="detailsHeading">
                        Owners Contact Information
                      </p>
                    </Col>
                  </Row>
                  <Col sm="5" className="namesColumn">
                    <span>First name:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.ownerFirstName}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>Last name:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.ownerLastName}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>Country:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.ownerCountry}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>State:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.ownerState}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>City:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.ownerCity}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>Email Address:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.ownerEmail}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>Phone number:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.ownerPhone}</span>
                  </Col>
                </Row>

                <Row style={{ width: "100%" }}>
                  <Row style={{ width: "100%" }}>
                    <Col sm={{ span: 5 }} className="headingColumn">
                      <p className="detailsHeading">Item Location</p>
                    </Col>
                  </Row>
                  <Col sm="5" className="namesColumn">
                    <span>Country:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.itemCountry}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>Region:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.itemState}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>City:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.itemCity}</span>
                  </Col>
                </Row>

                <Row>
                  <Row style={{ width: "100%" }}>
                    <Col sm={{ span: 5 }} className="headingColumn">
                      <p className="detailsHeading">
                        Logistics Contact Information
                      </p>
                    </Col>
                  </Row>
                  <Col sm="5" className="namesColumn">
                    <span>First name:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.contactFirstName}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>Last name:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.contactLastName}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>Email Address:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.contactEmail}</span>
                  </Col>
                  <Col sm="5" className="namesColumn">
                    <span>Phone number:</span>
                  </Col>
                  <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                    <span>{LotContact?.contactPhoneNumber}</span>
                  </Col>
                </Row>
              </Row>
              <Row className="previewBtnRow">
                <Button
                  className="previewBtn"
                  onClick={() => {
                    props.openPreviewPageCall();
                  }}
                >
                  Preview
                </Button>
              </Row>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
};

export default withRouter(ReviewForm);
