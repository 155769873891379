import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { withRouter } from "react-router";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import OrderDetails from "./../../components/OrderDetails";
import TransactionDetails from "./../../components/TransactionDetails";
import CustomerInformation from "./../../components/CustomerInformation";
import LogisticInformation from "./../../components/LogisticInformation";
import Timeline from "./../../components/Timeline";
import "./styles.css";

const OrderDetailsView = () => {
  return <></>;
};

export default OrderDetailsView;
