import React from "react";
import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { withRouter } from "react-router";
import kane from "./../../assets/kane.jpg";
import "./styles.css";

const UserBasicInformation = (props) => {
  return (
    <div>
      <Row className="roleInfoHeadingRow">
        <ChevronLeft />
        <span className="userMgmtHeading">User Management</span>
      </Row>
      <Row className="userInformationContainer">
        <Row className="userInformation">
          <span className="userInfoHeading">Basic Information</span>
        </Row>
        <Row xs="12" className="userInformationCard">
          <Col xs="12" className="noPadding">
            <img src={kane} roundedCircle className="imageAvatar" />
            <p className="roleInfoName">Kadin Schleifer</p>
            <Row>
              <Col sm="3" className="noPadding">
                <Form.Label>Email Address</Form.Label>
                <p className="fieldValues">kadinschleifer@gmail.com</p>
              </Col>
              <Col sm="6" className="noPadding mobileNumber">
                <Form.Label>Mobile </Form.Label>
                <p className="fieldValues">+1 8106176467</p>
              </Col>
              <Col sm="3" className="noPadding ">
                <Form.Label>Primary Address</Form.Label>
                <p className="fieldValues">
                  San Mateo, California, United States
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="3" className="noPadding">
                <Form.Label>Gender</Form.Label>
                <p className="fieldValues">Male</p>
              </Col>
              <Col sm="3" className="noPadding mobileNumber">
                <Form.Label>Role</Form.Label>
                <p className="fieldValues">Admin</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default UserBasicInformation;
