import React, { useState, Component } from "react";
import { Row, Col, Accordion, Card, Button, Form } from "react-bootstrap";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as ImagePlaceholderIcon } from "./../../assets/ImagePlaceholder.svg";
import TagsInput from "./../../components/TagsInput";
import "./styles.css";

import { withRouter } from "react-router-dom";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import uplaodImageNew from "../../services";

import CustomModal from "./../../components/CustomModal";
import { getAllCategories } from "./../../library/Requests";
import {
  createarticleDataCall,
  editarticleCall,
  getarticlebyidCall,
} from "./../../library/Requests/ContentManagement";
import { ReactComponent as DeleteBin } from "./../../assets/deleteBin.svg";

class CreateArticle extends Component {
  static propTypes = {};
  stateEm = {
    articleData: {
      categoryId: "",
      title: "",
      author: "",
      excerpt: "",
      tags: "",
      id: null,
      featuredImage: null,
      // endTime:"",
    },
    errorsFields: {
      categoryId: false,
      title: false,
      author: false,
      excerpt: false,
      featuredImage: false,
    },
    errorsData: {
      categoryId: "Please enter categoryId",
      title: "Please enter the title",
      author: "Please enter the author name",
      excerpt: "Please enter the excerpt",
    },
  };

  state = {
    ...this.stateEm,
    editorState: EditorState.createEmpty(),
    fieldisEmptySale: false,
    isArticleEdit: false,
    isImageEmpty: false,
    articleSavedPopup: false,
    editSavedPopup: false,
    saveChangesPopup: false,
  };
  componentDidMount = () => {
    this.getAllCategoriesCall();
    const currentPath = this.props.location.pathname;
    if (currentPath.indexOf("dashboard/editArticle") > 0) {
      let collID = currentPath.replace("/dashboard/editArticle/", "");
      this.setState({ articleID: collID, isArticleEdit: true });
      this.getArticleById(collID);
    } else {
      // this.newGetcollectionguid();
    }
  };
  hideModel = (keyvale, value) => {
    this.setState({ [keyvale]: value });
  };

  getArticleById = async (id) => {
    let respInfo = await getarticlebyidCall(id);
    let someCollection = respInfo.data;

    if (someCollection) {
      try {
        const LotD1 = someCollection;
        console.log(LotD1);
        const details = this.state.articleData;
        for (var key in details) {
          details[key] = LotD1[key];
        }
        this.setState({ articleData: details });
        document.getElementsByClassName(
          "DraftEditor-editorContainer"
        )[0].innerHTML = someCollection?.articalContent;
      } catch (e) {
        console.log(e);
      }
    }
  };
  handleChange = async (e) => {
    if (e.target.files.length) {
      const filesName = e.target.files[0];
      const state1 = this.state.articleData;
      state1.featuredImage = URL.createObjectURL(e.target.files[0]);
      this.setState({ articleData: state1 });

      const respApp = await uplaodImageNew(filesName, "article");
      console.log(respApp);
      const state = this.state.articleData;
      state.featuredImage = respApp?.imageUrl;
      this.setState({ articleData: state, isImageEmpty: false });
    }
  };

  handleChangeValue = (e) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    const state = this.state.articleData;
    state[name] = value;
    let errorsList = this.state.errorsFields;
    errorsList[name] = false;
    this.setState({ articleData: state, errorsFields: errorsList });
  };
  getAllCategoriesCall = async () => {
    const resp = await getAllCategories();
    this.setState({ allCategories: resp.data });
  };

  uploadImageCallBack = async (file) => {
    return new Promise(async (resolve, reject) => {
      const respnew = await uplaodImageNew(file, "article");
      console.log(respnew);
      console.log(file);
      const obj1 = {
        data: {
          link: respnew?.imageUrl,
          type: file.type,
        },
      };
      resolve(obj1);
    });
  };
  previewArticlePage = (e) => {
    this.props.history.push(
      `/dashboard/previewArticle/${this.state.articleData?.id}`
    );
  };
  goBacktoConent = () => {
    this.props.history.push(`/dashboard/contentManagement`);
  };
  onEditorStateChange = (editorState) => {
    // console.log(editorState)
    this.setState({ editorState: editorState });
    this.setState({ fieldisEmptySale: false });
  };
  checkValidForm = () => {
    const {
      editorState,
      errorsFields,
      articleData,
      isArticleEdit,
      isImageEmpty,
    } = this.state;

    let returnFlag = true;
    let errorsList = errorsFields;
    const state = articleData;

    for (var key in errorsList) {
      const Pvalue = state[key];
      if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
        errorsList[key] = true;
        returnFlag = false;
      }
    }
    var sSale = convertToRaw(editorState.getCurrentContent());
    const flagValeSale = sSale.blocks[0].text.trim().length > 0;
    if (
      document.getElementsByClassName("DraftEditor-editorContainer")[0]
        .innerHTML.length <= 518
    ) {
      this.setState({ fieldisEmptySale: true });
      returnFlag = false;
    }
    if (!articleData?.featuredImage && !isImageEmpty) {
      this.setState({ isImageEmpty: true });
    }

    this.setState({ errorsFields: errorsList });
    return returnFlag;
  };
  getJsonBody = () => {
    const { articleData } = this.state;
    // onlineEndDate: "2021-03-25"
    // onlineEndTime: "15:03:00"
    // onlineStartDate: "2021-03-10"
    // onlineStartTime: "03:03:00"

    const dataJson = {
      artical: {
        // "id" : articleData?.id,
        categoryId: articleData.categoryId,
        title: articleData.title,
        author: articleData.author,
        excerpt: articleData.excerpt,
        tags: articleData.tags,
        articalContent: document.getElementsByClassName(
          "DraftEditor-editorContainer"
        )[0].innerHTML,
        status: 2,
        featuredImage: articleData?.featuredImage,
      },
    };

    console.log(dataJson);
    // return false;
    return dataJson;
  };
  editSavedSuccess = () => {
    this.hideModel("editSavedPopup", false);
    this.gotoViewArticlePage();
  };
  gotoViewArticlePage = () => {
    this.props.history.push(
      `/dashboard/viewArticle/${this.state.articleData?.id}`
    );
  };
  saveChangesConform = async () => {
    this.hideModel("articleSavedPopup", false);
    const body = this.getJsonBody();
    body.artical.id = this.state?.articleData?.id;
    const resp = await editarticleCall(body);
    console.log(resp);
    if (resp.success) {
      this.hideModel("editSavedPopup", true);

      // this.props.history.push(`/dashboard/contentManagement`)
      console.log("#Edit SUCCESS");
    }
  };
  handleDeleteImg = () => {
    const state1 = this.state.articleData;
    state1.featuredImage = null;
    this.setState({ articleData: state1 });
  };
  saveArticle = async () => {
    // this.hideModel("articleSavedPopup", true)
    if (this.checkValidForm()) {
      const body = this.getJsonBody();
      if (this.state.isArticleEdit) {
        this.hideModel("saveChangesPopup", true);
      } else {
        const resp = await createarticleDataCall(body);
        console.log(resp);
        if (resp.success) {
          const state = this.state.articleData;
          state.id = resp?.id;
          this.setState({ articleData: state });
          this.hideModel("articleSavedPopup", true);
          // this.props.history.push(`/dashboard/contentManagement`)
          console.log("#Create SUCCESS");
        }
      }
    }
  };
  hideModelOK = (value) => {
    this.hideModel("articleSavedPopup", value);
    this.previewArticlePage();
  };

  selectedTags = (tags) => {
    console.log(tags);
  };
  selectTagsList = (list) => {
    if (list.length > 0) {
      const state = this.state.articleData;
      state.tags = list.join(", ");
      this.setState({ articleData: state });
    }
  };

  render() {
    const {
      editorState,
      articleSavedPopup,
      editSavedPopup,
      saveChangesPopup,
      image,
      isImageEmpty,
      allCategories,
      errorsData,
      articleData,
      errorsFields,
      fieldisEmptySale,
      isArticleEdit,
    } = this.state;
    return (
      <>
        <div className="wrapper">
          <div className="createArticleContainer">
            {isArticleEdit ? (
              <Row className="articleHeadingRow">
                <ChevronLeft />
                <span
                  className="contentMgmtHeading"
                  onClick={this.gotoViewArticlePage}
                >
                  {articleData.title}
                </span>
              </Row>
            ) : (
              <Row className="articleHeadingRow">
                <ChevronLeft />
                <span
                  className="contentMgmtHeading"
                  onClick={this.goBacktoConent}
                >
                  Content Management
                </span>
              </Row>
            )}

            <Card className="createArticle">
              <Card.Header className="createArticleHeading">
                {" "}
                {isArticleEdit ? "Edit Article" : "Create Article"}{" "}
              </Card.Header>
              <Card.Body className="createArticleBody">
                <Form.Label className="requiredField">Title</Form.Label>
                <Form.Control
                  className="createArticleTextpage"
                  type="text"
                  onChange={this.handleChangeValue}
                  name="title"
                  value={articleData.title}
                  isInvalid={errorsFields?.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsFields?.title ? <>{errorsData?.title}</> : null}
                </Form.Control.Feedback>

                <Form.Label className="requiredField">Author Name</Form.Label>
                <Form.Control
                  className="createArticleTextpage"
                  type="text"
                  name="author"
                  onChange={this.handleChangeValue}
                  value={articleData.author}
                  isInvalid={errorsFields?.author}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsFields?.author ? <>{errorsData?.author}</> : null}
                </Form.Control.Feedback>

                <Row>
                  <Form.Label className="hslSideHeading requiredField">
                    Category
                  </Form.Label>
                  <Form.Control
                    isInvalid={errorsFields?.categoryId}
                    as="select"
                    name="categoryId"
                    onChange={this.handleChangeValue}
                    className="selectOption"
                  >
                    <option value="">Select</option>
                    {(allCategories || []).map((item, key) => {
                      const checkVal =
                        articleData?.categoryId == item.id ? true : false;
                      console.log(checkVal);
                      return (
                        <>
                          <option
                            selected={checkVal}
                            name={item.id}
                            value={item.id}
                          >
                            {item.category}
                          </option>
                        </>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errorsFields?.categoryId ? (
                      <>{errorsData?.categoryId}</>
                    ) : null}
                  </Form.Control.Feedback>
                </Row>

                <Row className="imageContainer imageContainerArticle">
                  <Row className="imageRow">
                    {articleData.featuredImage ? (
                      <>
                        <DeleteBin
                          className="deleteImgIcon"
                          onClick={(articleData) => {
                            this.handleDeleteImg(articleData);
                          }}
                        />
                        <img
                          src={articleData.featuredImage}
                          alt="Preview"
                          className="previewImage"
                        />
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="uploadButton"
                          className="articleImageLabel"
                        >
                          <ImagePlaceholderIcon />
                          <p className="uploadImageBtn">
                            Upload Featured Image
                          </p>
                        </label>
                      </>
                    )}
                    <input
                      id="uploadButton"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      // className="uploadImageBtn"
                      onChange={this.handleChange}
                    />
                  </Row>
                </Row>
                {isImageEmpty && (
                  <Row className="errorEditorlot">
                    Please upload the featured Image
                  </Row>
                )}
                <Form.Label className="excerpt requiredField">
                  Content
                </Form.Label>
                <p className="excerptSubText"></p>
                <div className="editor">
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      // textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      image: {
                        uploadCallback: this.uploadImageCallBack,
                        alt: { present: true, mandatory: false },
                      },
                    }}
                  />
                </div>
                {fieldisEmptySale && (
                  <Row className="errorEditorlot">Please enter the value</Row>
                )}

                <Form.Label className="excerpt requiredField">
                  Excerpt
                </Form.Label>
                <p className="excerptSubText">
                  Add Summary of the Post to appear on homepage or blog
                </p>
                <Form.Control
                  as="textarea"
                  className="textArea excerptField"
                  rows="5"
                  onChange={this.handleChangeValue}
                  value={articleData.excerpt}
                  name="excerpt"
                  isInvalid={errorsFields?.excerpt}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsFields?.excerpt ? <>{errorsData?.excerpt}</> : null}
                </Form.Control.Feedback>

                <Form.Label>
                  Tags{" "}
                  <span className="tagsSubtext">
                    {" "}
                    (To separate tags, Press Comma after entering the Tag)
                  </span>
                </Form.Label>
                {/* <Form.Control value={articleData.tags}  name="tags" onChange={this.handleChangeValue} className="createArticleTextpage" type="text" /> */}
                <TagsInput
                  selectedTags={this.selectedTags}
                  selectTagsList={this.selectTagsList}
                  className="form-control"
                  name="artistName"
                  value={articleData.tags}
                />
              </Card.Body>
              <Row className="btnsRow">
                <Button className="cancelBtn" onClick={this.goBacktoConent}>
                  Cancel
                </Button>
                {/* <Button className="cancelBtn ArticlePreviewBtn" onClick={()=>{this.previewArticlePage()}}>Preview</Button> */}
                <Button
                  className="savePublish"
                  onClick={() => {
                    this.saveArticle();
                  }}
                >
                  Save & Preview
                </Button>
              </Row>
            </Card>
          </div>
          <CustomModal
            show={articleSavedPopup}
            titleText="Your Article is saved Successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => this.hideModel("articleSavedPopup", false)}
            onDelete={() => this.hideModelOK(false)}
          />
          <CustomModal
            show={editSavedPopup}
            titleText="Changes are saved Successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => this.hideModel("editSavedPopup", false)}
            onDelete={() => this.editSavedSuccess()}
          />
          <CustomModal
            show={saveChangesPopup}
            titleText="Do you want to save changes?"
            deleteText="Save Changes"
            cancelText="Cancel"
            onCancel={() => this.hideModel("saveChangesPopup", false)}
            onDelete={() => this.saveChangesConform()}
          />
        </div>
      </>
    );
  }
}

export default withRouter(CreateArticle);
