import styled from "styled-components";

export const StyledCollectionLotStatus = styled.div`
  .coll-lot-details-view-sec {
    width: 80%;
    margin: auto;
    margin-top: 30px;
  }
  .coll-lot-details-view-sec-top ul {
  }
  .card-box-bottom img {
    width: 100px;
    height: 100px;
  }
  .grid-view-all {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 50px;
    column-gap: 30px;
  }
  .card-box-top-left {
    text-align: center;
  }
  .card-box-con-title {
    margin-bottom: 10px;
    font-weight: bold;
  }
  .lots_cost_lat_coll_details {
    margin-top: 10px;
  }
  .card-box-bottom {
    display: flex;
    gap: 15px;
  }
  .card-box {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 10px;
  }
  .card-box-con-middle-row a {
    color: #000;
  }
  .show-timer-sec-timer {
    color: red;
  }
`;
