import React, { Component } from "react";
import { withRouter } from "react-router";
import { Row, Col, Button } from "react-bootstrap";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import MyAccount from "../userManagement/index.js";
import "./styles.css";
import { getOrderbyIdCall } from "../../library/Requests";
import moment from "moment";

class PurchaseDetails extends Component {
  static propTypes = {};

  state = {
    orderDetails: {},
    isLoading: false,
  };
  componentDidMount = async () => {
    const id = this.props.match?.params?.id ?? null;
    this.setState({ isLoading: true });
    const History1 = await getOrderbyIdCall(id);
    this.setState({ orderDetails: History1?.data });
    this.setState({ isLoading: false });
  };
  getfullName = (user) => {
    let fullname = user?.title ? user?.title : "";
    fullname += user?.firstName ? ` ${user?.firstName}` : "";
    fullname += user?.lastName ? ` ${user?.lastName}` : "";
    return fullname;
  };
  getfullAddress = (user) => {
    let fullname = user?.address1 ? user?.address1 : "";
    fullname += user?.address2 ? `, ${user?.address2}` : "";
    fullname += user?.city ? `, ${user?.city}` : "";
    fullname += user?.state ? `, ${user?.state}` : "";
    fullname += user?.county ? `, ${user?.county}` : "";
    fullname += user?.zipPostalCode ? `, ${user?.zipPostalCode}` : "";
    return fullname;
  };
  goBackPage = () => {
    this.props.history.push(
      `/dashboard/user/${this.state.orderDetails?.customerId}`
    );
  };
  render() {
    const { orderDetails, isLoading } = this.state;
    console.log(orderDetails);
    let bidDetails = null;
    if (orderDetails?.orderedBids?.length > 0) {
      bidDetails = orderDetails?.orderedBids[0];
    }
    return (
      <div className="PurchaseDetailContainer">
        {isLoading ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <Row className="PurchaseDetailsHeadingRow">
              <Col sm="9">
                <p>
                  <span onClick={this.goBackPage} style={{ cursor: "pointer" }}>
                    <ChevronLeft className="chevronLeft" />{" "}
                    <span className="bannerHeadingText">Purchase Details</span>{" "}
                  </span>
                </p>
              </Col>
            </Row>
            <Row className="PurchaseDetailsRow">
              <Col sm="7" className="PurchaseDetailsCol">
                <div className="purchaseImageDiv">
                  <img
                    src={bidDetails?.imageUrl}
                    className="PurchaseImage"
                    width="316"
                    height="210"
                    alt="Purchase Image"
                  />
                </div>
                <div className="purchaseImageDetails">
                  <p className="PurchaseDetailsId">#{bidDetails?.orderNo}</p>
                  <p className="PurchaseAuctionName">
                    {" "}
                    <span>Auction name: </span> {bidDetails?.collectionTitle}
                  </p>
                  <p className="PurchaseLotName">
                    {" "}
                    <span>Lot name: </span> {bidDetails?.collectionItemTitle}.
                  </p>
                  <p className="purchaseAmount">
                    {bidDetails?.amount} {bidDetails?.currency}
                  </p>
                </div>
              </Col>
              <Col sm="1" className="DispalyLineCol">
                <Line className="lineStylesPurchase" />
              </Col>
              <Col sm="4" className="PurchasePaymentCol">
                <Row className="PurchasePaymentDiv">
                  <Col sm="6" className="OrderDateDiv">
                    <p className="OrderDataText">Order Date: </p>
                    <p className="OrderDate">
                      {" "}
                      {moment(bidDetails?.orderDate).format("DD-MM-YYYY")}
                    </p>
                  </Col>
                  <Col sm="6" className="PaymentStatusDiv">
                    <p className="PaymentStatusText">Payment Status</p>
                    <p className="CompletedText">{orderDetails?.orderStatus}</p>
                    <Button className="DownloadInvoiceBtn">
                      Download invoice
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row className="DeliveryAddressRow">
              <Col className="DeliveryAddressCol">
                <p className="DeliveryAddressText">Delivery Address</p>
                <p className="PersonName">{this.getfullName(orderDetails)}</p>
                <p className="FullAddressText">
                  {this.getfullAddress(orderDetails?.address)}
                </p>
                <p className="PhoneNumber">
                  {" "}
                  <strong>Phone number: </strong>{" "}
                  {orderDetails?.address?.phoneNumber}
                </p>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(PurchaseDetails);
