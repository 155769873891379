import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { ReactComponent as PlusIcon } from "./../../assets/plusIcon.svg";
import { ReactComponent as EditPencil } from "./../../assets/editPencil.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/deleteIcon.svg";
import "./styles.css";
import {
  createitemlogisticinfoCall,
  edititemlogisticinfoCall,
  getcountriesCall,
  getstatesCall,
  getcitysCall,
} from "./../../library/Requests";

import React, { useState, useEffect, Component } from "react";
import { Row, Col, Accordion, Card, Form, Button } from "react-bootstrap";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import "./styles.css";
import { UPDATE_LOT_DATA } from "../../library/Requests/eventConstants";

import { withRouter } from "react-router-dom";
import Script from "react-load-script";

class AddOCLIForm extends Component {
  static propTypes = {};

  stateEm = {
    userData: {
      ownerFirstName: "",
      ownerLastName: "",
      ownerCountry: "",
      ownerState: "",
      ownerCity: "",
      ownerEmail: "",
      ownerPhone: "",
      itemCountry: "",
      itemState: "",
      itemCity: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhoneNumber: "",
      ownerAddress: "",
      ItemAddress: "",
    },
    errorFields: {
      ownerFirstName: false,
      ownerLastName: false,
      // ownerCountry: false,
      // ownerState: false,
      ownerEmail: false,
      ownerCity: false,
      // itemCountry: false,
      itemCity: false,
      contactFirstName: false,
      contactLastName: false,
      contactEmail: false,
      ownerPhone: false,
      contactPhoneNumber: false,
      // ownerAddress: false,
      // ItemAddress: false,
    },
    errorsData: {
      ownerFirstName: "Please enter the first name",
      ownerLastName: "Please enter the last name",
      ownerCountry: "Please select the country",
      ownerState: "Please select the state",
      ownerEmail: "Please enter the email",
      itemCountry: "Please select the country",
      itemCity: "Please select the city",
      contactFirstName: "Please enter the first name",
      contactLastName: "Please enter the last name",
      contactEmail: "Please enter the email",
      ownerAddress: "Please enter the location",
      ItemAddress: "Please enter the location",
      ownerPhone: "Please enter the Phone number",
      contactPhoneNumber: "Please enter the Phone number",
    },
  };
  state = {
    ...this.stateEm,
    saveContinue: false,
    allCountrysData: [],
    allStatesData: [],
    OwnerallStatesData: [],
    ItemallStatesData: [],
    validEmilOwner: true,
    contactEmailError: true,
    LogisticsCheckBox: false,
    itemLocationCheckBox: false,
  };

  componentDidMount = () => {
    this.updateState();
    const self = this;
    window.document.addEventListener(
      UPDATE_LOT_DATA,
      function (event) {
        self.updateState();
      },
      false
    );
    //   google.maps.event.addDomListener(window, 'load', self.initMap());
  };
  //   handleScriptLoad  =  ()=>{
  //     // const options = { types: [‘(cities)’] };

  //     var input = document.getElementById('locationTextField');
  //     var autocomplete = new google.maps.places.Autocomplete(input);

  //   }
  handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = {
      types: ["(cities)"],
    }; // To disable any eslint 'google not defined' errors
    // const options = {
    //   componentRestrictions: { country: "us" },
    // };
    // Initialize Google Autocomplete
    /*global google*/ this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("locationTextField"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields(["address_components", "formatted_address"]);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener("place_changed", this.handlePlaceSelectOwner);

    this.autocompleteItem = new google.maps.places.Autocomplete(
      document.getElementById("locationTextFieldItem"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocompleteItem.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    this.autocompleteItem.addListener(
      "place_changed",
      this.handlePlaceSelectItem
    );
  };

  handlePlaceSelectOwner = () => {
    const addressObject = this.autocomplete.getPlace();
    this.handlePlaceSelect(addressObject);
  };

  handlePlaceSelectItem = () => {
    const addressObject = this.autocompleteItem.getPlace();
    this.handlePlaceSelect(addressObject, "ItemInfo");
  };

  handlePlaceSelect = (addressObject, typeNo) => {
    console.log(addressObject);
    console.log(addressObject?.geometry?.location);
    const address1 = addressObject?.formatted_address;
    // Extract City From Address Object
    const address = addressObject?.address_components;
    let postcode = "";
    let city = "";
    let state = "";
    let country = "";
    for (const component of addressObject?.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          // address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          // address1 += component.short_name;
          break;
        }
        case "postal_code_suffix": {
          // postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;

        case "administrative_area_level_1": {
          state = component.long_name;
          break;
        }
        case "country":
          country = component.long_name;
          break;
      }
    }

    let userDatavalue = this.state.userData;
    if (address1) {
      if (typeNo == "ItemInfo") {
        userDatavalue.ownerAddress = address1;
      } else {
        userDatavalue.ItemAddress = address1;
      }
    }

    if (city != "") {
      if (typeNo == "ItemInfo") {
        userDatavalue.itemCity = city;
      } else {
        userDatavalue.ownerCity = city;
      }
    }
    if (state != "") {
      if (typeNo == "ItemInfo") {
        userDatavalue.itemState = state;
      } else {
        userDatavalue.ownerState = state;
      }
    }
    if (country != "") {
      if (typeNo == "ItemInfo") {
        userDatavalue.itemCountry = country;
      } else {
        userDatavalue.ownerCountry = country;
      }
    }

    this.setState({ userData: userDatavalue });
    // this.validateFormData()
  };

  updateState = () => {
    this.getstatesData();
    // this.getCityData();
    const LotContactVal =
      this.props?.lotItemDetails?.itemLogisticInfos ||
      this.props?.data?.itemLogisticInfos;
    let LotContact = {};
    if (
      LotContactVal != undefined &&
      LotContactVal != null &&
      LotContactVal.length > 0
    ) {
      LotContact = LotContactVal[0];
      LotContact.ownerAddress = LotContact.ownerCity;
      LotContact.ItemAddress = LotContact.itemCity;
    }
    this.setState({ userData: LotContact });
    // this.getCountryData();
    // if(name=="ownerCountry" || name=="itemCountry"){
    // this.getCitysByid(Cdata[index].id, name);
  };
  //   getCountryData  =  async()=>{
  //     const resp = await getcountriesCall()
  //     this.setState({allCountrysData: resp?.data});
  //     // const this.state?.userData?.ownerCountry
  //     // ownerCountry

  //   }
  getstatesData = async () => {};
  //  getCityData  =  async()=>{
  //    const resp = await getcitysCall()
  //    this.setState({allCitysData: resp?.data});
  //  }
  handleChangeValues = (e) => {
    const { name, value } = e.target;
    let state = this.state.userData;

    let errorsList = this.state.errorFields;
    errorsList[name] = false;
    // this.setState({userData: state, errorFields: errorsList});

    if (name == "ownerPhone" || name == "contactPhoneNumber") {
      const re = /^[0-9\b]+$/;
      if (re.test(value) || value === "") {
        state[name] = value;
        console.log("4444");
        this.setState({ userData: state, errorFields: errorsList });
      } else {
        console.log("5555");
        this.setState({ errorFields: errorsList });
      }
    } else {
      state[name] = value;
      this.setState({ userData: state, errorFields: errorsList });
    }
    if (name == "ownerEmail") {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(value).toLowerCase())) {
        this.setState({ validEmilOwner: true });
      } else {
        this.setState({ validEmilOwner: false });
      }
    }
    if (name == "contactEmail") {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(value).toLowerCase())) {
        this.setState({ contactEmailError: true });
      } else {
        this.setState({ contactEmailError: false });
      }
    }

    if (name == "ownerCountry" || name == "itemCountry") {
      // const Cdata = this.state.allCountrysData;
      // const index = Cdata.findIndex((item) => item.code == value);
      // this.getCitysByid(Cdata[index].id, name);
    }
  };
  // getCitysByid = async (id, typev)=>{
  //     const resp = await getstatesCall(id);
  //     if(typev=="ownerCountry"){
  //         this.setState({ OwnerallStatesData: resp?.data});
  //     }else if (typev == "itemCountry"){
  //         this.setState({ ItemallStatesData: resp?.data});
  //     }

  // }
  validateFormData = () => {
    const details = this.state.errorFields;
    let errorsList = this.state.errorFields;
    const datanew = this.state.userData;
    let returnFlag = true;
    for (var key in details) {
      console.log(datanew[key]);
      console.log(key);
      const Pvalue = datanew[key];
      if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
        errorsList[key] = true;
        returnFlag = false;
      } else {
        errorsList[key] = false;
      }
    }

    if (document.getElementById("locationTextField").value == "") {
    }
    this.setState({ errorFields: errorsList });
    return returnFlag;
  };

  saveandContinueName = async () => {
    const {
      userData,
      saveContinue,
      validEmilOwner,
      contactEmailError,
    } = this.state;

    if (this.validateFormData() && validEmilOwner && contactEmailError) {
      userData.itemId = document.getElementById("collectionLotID").value;
      userData.id = userData?.id;

      const body = { logisticInfo: userData };

      if ((this.props?.isEditLot || saveContinue) && userData?.id) {
        // body.id = state?.id;
        const respNew = await edititemlogisticinfoCall(body);
        if (respNew?.success) {
          this.setState({ saveContinue: true });
          this.props.getItemDetailsCall();
          document.getElementById("Accordion_Section_6").click();
        }
      } else {
        // body.itemId = document.getElementById("collectionLotID").value
        const respNew = await createitemlogisticinfoCall(body);
        if (respNew?.success) {
          let state = this.state.userData;
          state.id = respNew?.id;
          this.setState({ userData: state, saveContinue: true });
          this.props.getItemDetailsCall();
          document.getElementById("Accordion_Section_6").click();
        }
      }
    }
  };

  sameAsOwner = (e) => {
    const { name, value } = e.target;
    console.log(this.state.userData);
    const {
      ownerFirstName,
      ownerLastName,
      ownerCountry,
      ownerState,
      ownerCity,
      ownerEmail,
      ownerAddress,
      ownerPhone,
    } = this.state.userData;
    // itemCountry: "",
    // itemState: "",
    // itemCity: "",
    // contactFirstName: "",
    // contactLastName: "",
    // contactEmail: "",
    // contactPhoneNumber: "",

    console.log(name);
    if (name == "ItemLocation" && e.target.checked) {
      const state = this.state.userData;
      state.itemCountry = ownerCountry;
      state.itemState = ownerState;
      state.itemCity = ownerCity;
      state.ItemAddress = ownerAddress;
      // this.setState({ItemallStatesData: this.state.OwnerallStatesData})
      this.setState({ userData: state });
    } else if (name == "Logistics" && e.target.checked) {
      const state = this.state.userData;
      state.contactFirstName = ownerFirstName;
      state.contactLastName = ownerLastName;
      state.contactEmail = ownerEmail;
      state.contactPhoneNumber = ownerPhone;
      this.setState({ userData: state });
    }
    if (name == "Logistics") {
      if (e.target.checked) {
        this.setState({ LogisticsCheckBox: true });
      } else {
        this.setState({ LogisticsCheckBox: false });
      }
    } else {
      if (e.target.checked) {
        this.setState({ itemLocationCheckBox: true });
      } else {
        this.setState({ itemLocationCheckBox: false });
      }
    }

    console.log(e.target.checked);
    this.validateFormData();
  };

  render() {
    const {
      saveContinue,
      errorFields,
      errorsData,
      userData,
      allCountrysData,
      OwnerallStatesData,
      ItemallStatesData,
      validEmilOwner,
      contactEmailError,
      LogisticsCheckBox,
      itemLocationCheckBox,
    } = this.state;

    return (
      <>
        <div className="wrapper">
          <Card>
            <Accordion.Toggle
              id="Accordion_Section_5"
              as={Card.Header}
              eventKey="5"
            >
              <span className="formHeading">
                6. Owner’s Contact & Logistic information
              </span>
              {saveContinue ? (
                <Button className="AccordionEditBtn"> Edit </Button>
              ) : (
                <AngleUpIcon className="angleUpIcon" />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body className="ocliBody">
                <Row>
                  <p className="formSideHeading">Owner’s Contact information</p>
                  <div className="googleMapLocationBase">
                    <Script
                      url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDILWRZ3d-zi2xIjmATRtOUNkFJ8kEr4p0&libraries=places"
                      onLoad={this.handleScriptLoad}
                    />
                    <div id="pac-container">
                      {/* <Form.Control isInvalid={errorFields?.ownerAddress} onChange={this.handleChangeValues} name="ownerFirstName" value={userData.ownerFirstName} className="mediumFields"  type="text" placeholder="John" /> */}

                      <Form.Control
                        isInvalid={errorFields?.ownerCity}
                        id="locationTextField"
                        className="form-control locationTextField"
                        type="text"
                        placeholder="Enter location"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorFields?.ownerCity ? (
                          <>{errorsData?.ownerAddress}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <Col sm="4" style={{ padding: 0 }}>
                    <Form.Label className="requiredField">
                      First Name
                    </Form.Label>
                    <Form.Control
                      isInvalid={errorFields?.ownerFirstName}
                      onChange={this.handleChangeValues}
                      name="ownerFirstName"
                      value={userData.ownerFirstName}
                      className="mediumFields"
                      type="text"
                      placeholder="Enter First name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorFields?.ownerFirstName ? (
                        <>{errorsData?.ownerFirstName}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm="4" style={{ marginLeft: "29px" }}>
                    <Form.Label className="requiredField">Last Name</Form.Label>
                    <Form.Control
                      isInvalid={errorFields?.ownerLastName}
                      onChange={this.handleChangeValues}
                      name="ownerLastName"
                      value={userData.ownerLastName}
                      className="mediumFields"
                      type="text"
                      placeholder="Enter Last name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorFields?.ownerLastName ? (
                        <>{errorsData?.ownerLastName}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Col>
                  {/* 
                        <Form.Label className="requiredField">Country </Form.Label>
                        <Form.Control  isInvalid={errorFields?.ownerCountry}  onChange={this.handleChangeValues} name="ownerCountry" value={userData.ownerCountry}  as="select" >
                        <option>Select</option>
                        {(allCountrysData || []).map((item, index)=>(
                                <option selected={(userData?.ownerCountry== item?.code) ? true : false} name="ownerCountry"  valueid={item?.id} value={item?.code} > {item?.name}</option>
                            ))}
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                            {errorFields?.ownerCountry ? <>
                            {errorsData?.ownerCountry}
                            </> : null}
                        </Form.Control.Feedback> */}
                  <Col sm="4" style={{ padding: 0 }}>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      disabled={false}
                      onChange={this.handleChangeValues}
                      name="ownerCountry"
                      value={userData.ownerCountry}
                      className="mediumFields"
                      type="text"
                      placeholder="Enter Country"
                    />
                  </Col>
                  <Col sm="4" style={{ marginLeft: "29px" }}>
                    {/* <Form.Label className="requiredField">State</Form.Label>
                            <Form.Control  isInvalid={errorFields?.ownerState}  onChange={this.handleChangeValues} name="ownerState" value={userData.ownerState}  as="select" className="mediumFields" >
                            <option>Select</option>    
                            {(OwnerallStatesData || []).map((item, index)=>(
                                <option selected={(userData?.ownerState== item?.name) ? true : false} name="ownerState"  valueid={item?.name} value={item?.name} > {item?.name}</option>
                            ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                            {errorFields?.ownerState ? <>
                            {errorsData?.ownerState}
                            </> : null}
                        </Form.Control.Feedback> */}
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      disabled={false}
                      onChange={this.handleChangeValues}
                      name="ownerState"
                      value={userData.ownerState}
                      className="mediumFields"
                      type="text"
                      placeholder="Enter State"
                    />
                  </Col>
                  <Col sm="4" style={{ padding: 0 }}>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      disabled={false}
                      onChange={this.handleChangeValues}
                      name="ownerCity"
                      value={userData.ownerCity}
                      className="mediumFields"
                      type="text"
                      placeholder="Enter City"
                    />
                  </Col>

                  <Col sm="4" style={{ marginLeft: "29px" }}>
                    <Form.Label className="requiredField">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      isInvalid={errorFields?.ownerEmail}
                      onChange={this.handleChangeValues}
                      name="ownerEmail"
                      value={userData.ownerEmail}
                      className="mediumFields"
                      type="email"
                      placeholder="Enter Email Address"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorFields?.ownerEmail ? (
                        <>{errorsData?.ownerEmail}</>
                      ) : null}
                    </Form.Control.Feedback>
                    {!validEmilOwner && userData.ownerEmail != "" && (
                      <span className="invalidEmailClass">
                        {" "}
                        Enter Valid Email id.
                      </span>
                    )}
                  </Col>
                  <Col sm="4" style={{ padding: 0 }}>
                    <Form.Label className="requiredField">Phone</Form.Label>
                    <Form.Control
                      isInvalid={errorFields?.ownerPhone}
                      onChange={this.handleChangeValues}
                      name="ownerPhone"
                      value={userData.ownerPhone}
                      className="mediumFields"
                      type="text"
                      maxLength={10}
                      placeholder="Enter Phone number"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorFields?.ownerPhone ? (
                        <>{errorsData?.ownerPhone}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <p className="formSideHeading extraTopPadding">
                    Item Location
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Same as owners contact information"
                      className="checkboxText"
                      name="ItemLocation"
                      onChange={this.sameAsOwner}
                    />
                  </p>
                  <div className="googleMapLocationBase">
                    <Script
                      url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDILWRZ3d-zi2xIjmATRtOUNkFJ8kEr4p0&libraries=places"
                      onLoad={this.handleScriptLoad}
                    />
                    <div id="pac-container">
                      {/* <input id="locationTextFieldItem" className="form-control locationTextField" type="text" placeholder="Enter a location" /> */}
                      <Form.Control
                        disabled={itemLocationCheckBox}
                        isInvalid={errorFields?.itemCity}
                        id="locationTextFieldItem"
                        className="form-control locationTextField"
                        type="text"
                        placeholder="Enter location"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorFields?.itemCity ? (
                          <>{errorsData?.ItemAddress}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  {/* <Form.Label className="requiredField">Country </Form.Label>
                        <Form.Control  isInvalid={errorFields?.itemCountry} onChange={this.handleChangeValues} name="itemCountry" value={userData.itemCountry}  as="select" >
                            <option>Select</option>
                            {(allCountrysData || []).map((item, index)=>(
                                <option selected={(userData?.itemCountry== item?.code) ? true : false} name="itemCountry" value={item?.code} >{item?.name}</option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errorFields?.itemCountry ? <>
                            {errorsData?.itemCountry}
                            </> : null}
                        </Form.Control.Feedback>
                        <Col sm="4" style={{ padding: 0 }}>
                            <Form.Label>State</Form.Label>
                            <Form.Control onChange={this.handleChangeValues} name="itemState" value={userData.itemState}  as="select" className="mediumFields" >
                                <option>Select</option>
                                {(ItemallStatesData || []).map((item, index)=>(
                                    <option selected={(userData?.itemState== item?.name) ? true : false} name="itemState" value={item?.name} >{item?.name}</option>
                                ))}

                            </Form.Control>
                        </Col>
                        <Col sm="4" style={{ marginLeft: "29px" }}>
                            <Form.Label className="requiredField">City</Form.Label>
                            <Form.Control isInvalid={errorFields?.itemCity} onChange={this.handleChangeValues} name="itemCity" value={userData.itemCity}  className="mediumFields" type="text" placeholder="City" />
                            <Form.Control.Feedback type="invalid">
                                {errorFields?.itemCity ? <>
                                {errorsData?.itemCity}
                                </> : null}
                            </Form.Control.Feedback>
                        </Col> */}
                  <Col sm="4" style={{ padding: 0 }}>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      disabled={false}
                      onChange={this.handleChangeValues}
                      name="itemCountry"
                      value={userData.itemCountry}
                      className="mediumFields"
                      type="text"
                      placeholder="Enter Country"
                    />
                  </Col>
                  <Col sm="4" style={{ marginLeft: "29px" }}>
                    {/* <Form.Label className="requiredField">State</Form.Label>
                            <Form.Control  isInvalid={errorFields?.ownerState}  onChange={this.handleChangeValues} name="ownerState" value={userData.ownerState}  as="select" className="mediumFields" >
                            <option>Select</option>    
                            {(OwnerallStatesData || []).map((item, index)=>(
                                <option selected={(userData?.ownerState== item?.name) ? true : false} name="ownerState"  valueid={item?.name} value={item?.name} > {item?.name}</option>
                            ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                            {errorFields?.ownerState ? <>
                            {errorsData?.ownerState}
                            </> : null}
                        </Form.Control.Feedback> */}
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      disabled={false}
                      onChange={this.handleChangeValues}
                      name="itemState"
                      value={userData.itemState}
                      className="mediumFields"
                      type="text"
                      placeholder="Enter State"
                    />
                  </Col>
                  <Col sm="4" style={{ padding: 0 }}>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      readOnly
                      onChange={this.handleChangeValues}
                      name="itemCity"
                      value={userData.itemCity}
                      className="mediumFields"
                      type="text"
                      placeholder="Enter City"
                    />
                  </Col>
                </Row>
                <Row>
                  <p className="formSideHeading extraTopPadding">
                    Logistics Contact Information
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Same as owners contact information"
                      className="checkboxText"
                      name="Logistics"
                      onChange={this.sameAsOwner}
                    />
                  </p>

                  <Col sm="4" style={{ padding: 0 }}>
                    <Form.Label className="requiredField">
                      First Name
                    </Form.Label>
                    <Form.Control
                      disabled={LogisticsCheckBox}
                      isInvalid={errorFields?.contactFirstName}
                      onChange={this.handleChangeValues}
                      name="contactFirstName"
                      value={userData.contactFirstName}
                      className="mediumFields"
                      type="text"
                      placeholder="Enter First name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorFields?.contactFirstName ? (
                        <>{errorsData?.contactFirstName}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm="4" style={{ marginLeft: "29px" }}>
                    <Form.Label className="requiredField">Last Name</Form.Label>
                    <Form.Control
                      disabled={LogisticsCheckBox}
                      isInvalid={errorFields?.contactLastName}
                      onChange={this.handleChangeValues}
                      name="contactLastName"
                      value={userData.contactLastName}
                      className="mediumFields"
                      type="text"
                      placeholder="Enter Last name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorFields?.contactLastName ? (
                        <>{errorsData?.contactLastName}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Col>

                  <Col sm="4" style={{ padding: 0 }}>
                    <Form.Label className="requiredField">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      disabled={LogisticsCheckBox}
                      isInvalid={errorFields?.contactEmail}
                      onChange={this.handleChangeValues}
                      name="contactEmail"
                      value={userData.contactEmail}
                      className="mediumFields"
                      type="email"
                      placeholder="Enter Email Address"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorFields?.contactEmail ? (
                        <>{errorsData?.contactEmail}</>
                      ) : null}
                    </Form.Control.Feedback>
                    {!contactEmailError && userData.contactEmail != "" && (
                      <span className="invalidEmailClass">
                        {" "}
                        Enter Valid Email id.
                      </span>
                    )}
                  </Col>
                  <Col sm="4" style={{ marginLeft: "29px" }}>
                    <Form.Label className="requiredField">Phone</Form.Label>
                    <Form.Control
                      isInvalid={errorFields?.contactPhoneNumber}
                      disabled={LogisticsCheckBox}
                      onChange={this.handleChangeValues}
                      name="contactPhoneNumber"
                      value={userData.contactPhoneNumber}
                      className="mediumFields"
                      type="text"
                      maxLength={10}
                      placeholder="Enter Phone number"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorFields?.contactPhoneNumber ? (
                        <>{errorsData?.contactPhoneNumber}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row className="btnRow btnAlign">
                  <Button
                    className="saveContinue"
                    onClick={() => this.saveandContinueName()}
                  >
                    Save & Continue
                  </Button>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </>
    );
  }
}

export default withRouter(AddOCLIForm);
