import React, { useState, useReducer, useEffect, Component } from "react";
import { Row, Col, Accordion, Card, Button } from "react-bootstrap";
import { ReactComponent as BackArrowIcon } from "./../../assets/BackArrow.svg";
import CollectionDetailsForm from "./../../components/CollectionDetailsForm";
import CollectionOverviewForm from "./../../components/CollectionOverviewForm";
import ConditionSaleForm from "./../../components/ConditionSaleForm";
import { matchPath } from "react-router";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeftBold.svg";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import uplaodImageNew from "../../services";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import { Switch, Route, withRouter } from "react-router-dom";

import CustomModal from "./../../components/CustomModal";

import {
  createNewCollection,
  newGetcollectionguidCall,
  editCollection,
  getCollectionByIdCall,
} from "./../../library/Requests";

import "./styles.css";

import { showUserMessage } from "./../../Scenes/common";

class CreateCollection extends Component {
  static propTypes = {};
  stateEm = {
    CollData: {
      title: "",
      artistName: "",
      currency: "",
      auctionType: "1",
      onlineStartDate: "",
      onlineStartTime: "",
      onlineEndDate: "",
      onlineEndTime: "",
      liveStartDate: "",
      liveStartTime: "",
      biddingStartDate: "",
      biddingStartTime: "",
      biddingStartDateOnUTC: "",
      isAdvanceBidding: false,
      isAbsenteeBidding: false,
      timeZoneId: "",
      coverImage: "",
      isValid: false,
    },
    errorsFields: {
      title: false,
      currency: false,
      auctionType: false,
      timeZoneId: false,
    },
    errorsData: {
      title: "Please enter the title",
      currency: "Please select Currency",
      auctionType: "Please select the Auction type",
      timeZoneId: "Please select the time zone",
      ActionDateErrors: "",
      LiveTimeErrors: "",
    },
  };

  state = {
    ...this.stateEm,
    isCollectionEdit: false,
    currentCollection: {},
    editorStateFirst: EditorState.createEmpty(),
    editorStateSale: EditorState.createEmpty(),
    fieldisEmptyFirst: false,
    fieldisEmptySale: false,
    collectionID: 0,
    saveChangesPopup: false,
    coverImageError: null,
  };

  //   /api/Customer/getallusers
  componentDidMount = () => {
    // const location = useLocation();
    console.log(this.props);
    const currentPath = this.props.location.pathname;
    // console.log()
    if (currentPath.indexOf("dashboard/editCollection") > 0) {
      let collID = currentPath.replace("/dashboard/editCollection/", "");
      this.setState({ collectionID: collID, isCollectionEdit: true });
      this.getCollectionById(collID);
    } else {
      this.newGetcollectionguid();
    }
  };

  getCollectionById = async (id) => {
    // let { id } = useParams();
    let respInfo = await getCollectionByIdCall(id);
    let someCollection = respInfo.data;
    if (someCollection) {
      const state = this.state.CollData;
      state.title = someCollection?.title;
      state.artistName = someCollection?.artistName;
      state.auctionType = someCollection?.auctionType;
      state.onlineStartDate = someCollection?.onlineStartDate;
      state.onlineStartTime = someCollection?.onlineStartTime;
      state.onlineEndDate = someCollection?.onlineEndDate;
      state.timeZoneId = someCollection?.timeZoneId;
      state.currency = someCollection?.currency;
      state.coverImage = someCollection?.coverImage;

      this.setState({
        CollData: someCollection,
        currentCollection: someCollection,
      });

      document.getElementsByClassName(
        "DraftEditor-editorContainer"
      )[0].innerHTML = someCollection?.collectionOverview;
      document.getElementsByClassName(
        "DraftEditor-editorContainer"
      )[1].innerHTML = someCollection?.conditionOfSale;
    }
  };
  newGetcollectionguid = async () => {
    const resp = await newGetcollectionguidCall();
    localStorage.removeItem("collectionguidforImage");
    localStorage.setItem("collectionguidforImage", resp?.collectionGuid);
  };
  // onEditorStateChange = (collectionOverview) => {
  //     console.log(collectionOverview)
  //     setState({ ...state, collectionOverview });
  // };

  // onConditionEditorChange = (conditionOfSale) => {
  //     console.log(conditionOfSale)
  //     setState({ ...state, conditionOfSale });
  // };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const state = this.state.CollData;
    state[name] = value;
    let errorsList = this.state.errorsFields;
    errorsList[name] = false;
    const newP = this.state.errorsData;
    newP.ActionDateErrors = "";
    newP.LiveTimeErrors = "";
    this.setState({
      CollData: state,
      errorsFields: errorsList,
      errorsData: newP,
    });
  };
  handleAuctionInputChange = (e) => {
    const { name, value } = e.target;
    const state = this.state.CollData;
    state[name] = value;
    if (value == 1) {
      state["liveStartDate"] = null;
      state["liveStartTime"] = null;
      state["isAdvanceBidding"] = false;
      state["isAbsenteeBidding"] = false;
      state["biddingStartDate"] = null;
      state["biddingStartTime"] = null;
      state["biddingStartDateOnUTC"] = null;
    } else {
      state["onlineStartDate"] = null;
      state["onlineStartTime"] = null;
      state["onlineEndDate"] = null;
      state["onlineEndTime"] = null;
    }
    let errorsList = this.state.errorsFields;
    errorsList[name] = false;
    const newP = this.state.errorsData;
    newP.ActionDateErrors = "";
    newP.LiveTimeErrors = "";
    this.setState({
      CollData: state,
      errorsFields: errorsList,
      errorsData: newP,
    });
  };
  handleInputChangeforImage = (name, value) => {
    const state = this.state.CollData;
    state[name] = value;
    this.setState({ CollData: state });
  };
  selectTagsList = (list) => {
    if (list.length > 0) {
      const state = this.state.CollData;
      state.artistName = list.join(", ");
      this.setState({ CollData: state });
    }
  };

  timeOnChange = (value, name) => {
    var convertedTime = moment(
      moment(value).format("hh:mm a"),
      "hh:mm A"
    ).format("HH:mm:ss");
    const state = this.state.CollData;
    console.log(name);
    console.log(convertedTime);
    state[name] = convertedTime;
    console.log(state);
    this.setState({ CollData: state });
  };

  handleBiddingType = (value, type) => {
    const state = this.state.CollData;
    if (type == "advance") {
      state.isAdvanceBidding = value;
    } else if ("absentee") {
      state.isAbsenteeBidding = value;
    }
    this.setState({ CollData: state });

    // setState({ ...state, ['isAdvanceBidding']: value })
  };

  checkValidForm = () => {
    this.ValidateActionDates();
    let returnFlag = true;
    let errorsList = this.state.errorsFields;
    const state = this.state.CollData;
    for (var key in errorsList) {
      const Pvalue = state[key];
      if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
        errorsList[key] = true;
        returnFlag = false;
      }
    }
    const { editorStateFirst, editorStateSale, CollData } = this.state;
    var s = convertToRaw(editorStateFirst.getCurrentContent());
    const flagVale = s.blocks[0].text.trim().length > 0;
    var sSale = convertToRaw(editorStateSale.getCurrentContent());
    const flagValeSale = sSale.blocks[0].text.trim().length > 0;
    if (!state.coverImage) {
      this.setState({ coverImageError: "Please Upload Image" });
      returnFlag = false;
    } else {
      this.setState({ coverImageError: null });
    }
    if (
      document.getElementsByClassName("DraftEditor-editorContainer")[0]
        .innerHTML.length <= 555
    ) {
      this.setState({ fieldisEmptyFirst: true });
      returnFlag = false;
      //   document.getElementById("Create_Collection_Section_2").click();
    }
    if (
      document.getElementsByClassName("DraftEditor-editorContainer")[1]
        .innerHTML.length <= 555
    ) {
      this.setState({ fieldisEmptySale: true });
      returnFlag = false;
      // document.getElementById("Create_Collection_Section_1").click();
    }

    this.setState({ errorsFields: errorsList });
    return returnFlag;
  };

  onEditorStateChangeFirst = (editorStateFirst) => {
    try {
      // setEditorStateFirst(editorStateFirst);
      // setFieldisEmptyFirst(false)
      this.setState({
        editorStateFirst: editorStateFirst,
        fieldisEmptyFirst: false,
      });
    } catch (e) {
      console.log(e);
    }
  };
  onEditorStateChangeSale = (editorStateSale) => {
    try {
      // setEditorStateSale(editorStateSale);
      // setFieldisEmptySale(false)
      this.setState({
        editorStateSale: editorStateSale,
        fieldisEmptySale: false,
      });
    } catch (e) {
      console.log(e);
    }
  };
  uploadImageCallBack = async (file) => {
    return new Promise(async (resolve, reject) => {
      const respnew = await uplaodImageNew(file, "collection");
      const obj1 = {
        data: {
          link: respnew?.imageUrl,
          type: file.type,
        },
      };
      resolve(obj1);
    });
  };
  updateImageError = () => {
    this.setState({ coverImageError: null });
  };
  getJsonBody = () => {
    const { CollData } = this.state;
    // onlineEndDate: "2021-03-25"
    // onlineEndTime: "15:03:00"
    // onlineStartDate: "2021-03-10"
    // onlineStartTime: "03:03:00"
    // console.log("CollData?.status", CollData?.status);
    // return false;
    const statusNew = CollData?.status ?? 2;

    const dataJson = {
      collection: {
        id: this.state.collectionID,
        title: CollData.title,
        artistName: CollData.artistName,
        auctionType: CollData.auctionType,
        timeZoneId: CollData.timeZoneId,
        currency: CollData.currency,

        onlineStartDate: CollData.onlineStartDate,
        onlineStartTime: CollData.onlineStartTime,
        onlineEndDate: CollData?.onlineEndDate,
        onlineEndTime: CollData.onlineEndTime,

        liveStartDate: CollData.liveStartDate,
        liveStartTime: CollData.liveStartTime,

        isAdvanceBidding: CollData?.isAdvanceBidding,
        isAbsenteeBidding: CollData?.isAbsenteeBidding,

        biddingStartDate: CollData.biddingStartDate,
        biddingStartTime: CollData.biddingStartTime,
        // "biddingEndDate": "2021-03-15T08:14:44.471Z",
        // "biddingEndTime": CollData.biddingEndTime,

        collectionOverview: document.getElementsByClassName(
          "DraftEditor-editorContainer"
        )[0].innerHTML,
        conditionOfSale: document.getElementsByClassName(
          "DraftEditor-editorContainer"
        )[1].innerHTML,

        status: statusNew,
        coverImage: CollData.coverImage,
      },
    };
    console.log(dataJson);
    // return false;
    return dataJson;
  };

  hideModel = () => {
    this.setState({ saveChangesPopup: false });
  };
  ValidateActionDates = () => {
    let flagN = true;
    const {
      onlineStartDate,
      onlineStartTime,
      onlineEndDate,
      onlineEndTime,
      liveStartDate,
      liveStartTime,
      biddingStartDate,
      biddingStartTime,
      auctionType,
      isAbsenteeBidding,
      isAdvanceBidding,
    } = this.state.CollData;
    const isOnlineEqual = moment(onlineStartDate).isSame(onlineEndDate);
    const isOnlineTimeEqual = moment(onlineStartTime).isSame(onlineEndTime);
    const isLiveEqual = moment(liveStartDate).isSame(biddingStartDate);
    // const isOnlineAfter = onlineEndTime > onlineStartTime
    // const isLiveAfter = moment(biddingStartTime).isAfter(liveStartTime);

    if (
      onlineStartDate != "" &&
      onlineEndDate != "" &&
      onlineStartDate != null &&
      onlineEndDate != null
    ) {
      let StartDateP = new Date(onlineStartDate);
      let EndDateP = new Date(onlineEndDate);
      if (StartDateP > EndDateP) {
        const newP = this.state.errorsData;
        newP.ActionDateErrors =
          "Auction End Date should be less than Auction State Date.";
        this.setState({ errorsData: newP });
        flagN = false;
      } else if (isOnlineEqual && onlineEndTime <= onlineStartTime) {
        const newP = this.state.errorsData;
        newP.ActionDateErrors = "End Time must be greater than Start Time";
        this.setState({ errorsData: newP });
        flagN = false;
      } else if (
        auctionType == "1" &&
        (onlineStartTime === "" ||
          onlineStartTime === null ||
          onlineEndTime === "" ||
          onlineEndTime === null)
      ) {
        const newP = this.state.errorsData;
        newP.ActionDateErrors = "Start Time and End Time are mandatory";
        this.setState({ errorsData: newP });
        flagN = false;
      }
    } else if (liveStartDate != "" && liveStartDate != null) {
      if (isAbsenteeBidding === false && isAdvanceBidding === false) {
        const newP = this.state.errorsData;
        newP.LiveTimeErrors = "Please Select bidding type";
        this.setState({ errorsData: newP });
        flagN = false;
      } else if (
        (isAbsenteeBidding === true || isAdvanceBidding === true) &&
        (biddingStartDate === "" ||
          biddingStartDate === null ||
          biddingStartTime === "" ||
          biddingStartTime === null)
      ) {
        const newP = this.state.errorsData;
        newP.LiveTimeErrors = "Bidding start Date and Time are mandatory";
        this.setState({ errorsData: newP });
        flagN = false;
      } else if (isLiveEqual && biddingStartTime >= liveStartTime) {
        const newP = this.state.errorsData;
        newP.LiveTimeErrors = "Start Time must be greater than Bid Start Time";
        this.setState({ errorsData: newP });
        flagN = false;
      }
    } else if (
      auctionType == "1" &&
      (onlineStartDate === "" ||
        onlineStartDate === null ||
        onlineEndDate === "" ||
        onlineEndDate === null)
    ) {
      const newP = this.state.errorsData;
      newP.ActionDateErrors = "Start Date and End Date are mandatory";
      this.setState({ errorsData: newP });
      flagN = false;
    } else if (
      auctionType == "2" &&
      (liveStartDate === "" || liveStartDate === null)
    ) {
      const newP = this.state.errorsData;
      newP.LiveTimeErrors = "Start Date Is Mandatory";
      this.setState({ errorsData: newP });
      flagN = false;
    }
    if (!flagN) {
      document.getElementById("Create_Collection_Section_1").click();
    }
    return flagN;
  };
  onCreateCollection = async () => {
    const { CollData } = this.state;
    // const body = this.getJsonBody();
    if (this.checkValidForm() && this.ValidateActionDates()) {
      const body = this.getJsonBody();
      const resp = await createNewCollection(body);
      console.log(resp);
      if (resp.success) {
        this.props.history.push(
          `/dashboard/createlot?collectionId=${resp.collectionId}`
        );
        console.log("#Create SUCCESS");
      }
    }
  };

  onEditCollection = async () => {
    const { CollData } = this.state;
    if (this.checkValidForm() && this.ValidateActionDates()) {
      this.setState({ saveChangesPopup: true });
    }
  };
  onEditCollectionConform = async () => {
    this.setState({ saveChangesPopup: false });
    const body = this.getJsonBody();
    // console.log(body);
    // return false;
    const resp = await editCollection(body);
    console.log(resp);
    if (resp.success) {
      console.log("#Edit SUCCESS");
      this.props.history.push(
        `/dashboard/collection/${this.state.collectionID}`
      );
    }
  };
  goBackpage = () => {
    const { isCollectionEdit } = this.state;
    if (isCollectionEdit) {
      this.props.history.push(
        `/dashboard/collection/${this.state.collectionID}`
      );
    } else {
      this.props.history.push(`/dashboard/collections`);
    }
  };

  handleDeleteImage = (image) => {
    const state = this.state.CollData;
    state.coverImage = "";
    this.setState({ coverImage: state });
  };

  render() {
    const {
      fieldisEmptySale,
      saveChangesPopup,
      fieldisEmptyFirst,
      isCollectionEdit,
      CollData,
      errorsFields,
      errorsData,
    } = this.state;
    return (
      <>
        <div className="createCollectionContainer">
          <Row onClick={this.goBackpage} className="headingRow">
            <ChevronLeft className="chevronLeft" />
            <span className="lotManagementHeading">Lot Management</span>
          </Row>
          <Col sm={{ span: 4, offset: 2 }}>
            <p className="sectionHeading">
              {!isCollectionEdit ? "Create Collection" : " Edit Collection"}
            </p>
          </Col>
          <Row className="formsRow">
            <Accordion defaultActiveKey="0">
              <CollectionDetailsForm
                handleChangeValue={this.handleInputChange}
                handleAuctionChange={this.handleAuctionInputChange}
                handleDeleteImg={this.handleDeleteImage}
                selectTagsList={this.selectTagsList}
                state={CollData}
                errorsFields={errorsFields}
                errorsData={errorsData}
                timeOnChange={this.timeOnChange}
                handleBiddingType={this.handleBiddingType}
                handleInputChangeforImageCall={this.handleInputChangeforImage}
                currentCollection={this.state.currentCollection}
                updateImageError={this.updateImageError}
                coverImageError={this.state.coverImageError}
              />
              <div className="wrapper">
                <Card>
                  <Accordion.Toggle
                    id="Create_Collection_Section_2"
                    as={Card.Header}
                    eventKey="1"
                  >
                    <span className="formHeading">2. Collection Overview</span>
                    <AngleUpIcon className="angleUpIcon" />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body className="collectionOverviewBody">
                      <p className="editorHeading requiredField">
                        Description of Collection
                      </p>
                      <div className="editor">
                        <Editor
                          placeholder="Enter Description of Collection"
                          editorState={this.state.editorStateFirst}
                          onEditorStateChange={this.onEditorStateChangeFirst}
                          toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            // textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            image: {
                              uploadCallback: this.uploadImageCallBack,
                              alt: { present: true, mandatory: false },
                            },
                          }}
                        />
                      </div>
                      {fieldisEmptyFirst && (
                        <Row className="errorEditorlot">
                          Please enter Description of Collection
                        </Row>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
              <div className="wrapper">
                <Card>
                  <Accordion.Toggle
                    id="Create_Collection_Section_3"
                    as={Card.Header}
                    eventKey="2"
                  >
                    <span className="formHeading">3. Condition Of Sale</span>
                    <AngleUpIcon className="angleUpIcon" />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="conditionSaleBody">
                      <p className="editorHeading requiredField">
                        Condition Of Sale
                      </p>
                      <div className="editor">
                        <Editor
                          placeholder="Enter Condition Of Sale"
                          editorState={this.state.editorStateSale}
                          onEditorStateChange={this.onEditorStateChangeSale}
                          toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            // textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            image: {
                              uploadCallback: this.uploadImageCallBack,
                              alt: { present: true, mandatory: false },
                            },
                          }}
                        />
                      </div>
                      {fieldisEmptySale && (
                        <Row className="errorEditorlot">
                          Please enter Condition Of Sale
                        </Row>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>
          </Row>
          <Row className="btnsUpperRow">
            <Row className="collectionsBtnRow">
              {!isCollectionEdit ? (
                <>
                  <Button onClick={this.goBackpage} className="exitBtn">
                    Exit
                  </Button>
                </>
              ) : (
                <>
                  <Button onClick={this.goBackpage} className="cancelBtn">
                    cancel
                  </Button>
                </>
              )}
              {!isCollectionEdit ? (
                <>
                  <Button
                    className="saveAddBtn"
                    // disabled={!state.isValid}
                    onClick={this.onCreateCollection}
                  >
                    Save & Add Lot
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="saveAddBtn"
                    onClick={this.onEditCollection}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </Row>
          </Row>

          <CustomModal
            show={saveChangesPopup}
            titleText="Do you want to save changes?"
            deleteText="Save Changes"
            cancelText="Cancel"
            onCancel={() => this.hideModel()}
            onDelete={() => this.onEditCollectionConform()}
          />
        </div>
      </>
    );
  }
}
export default withRouter(CreateCollection);
