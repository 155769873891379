import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Card, Button, Form } from "react-bootstrap";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import "./styles.css";

import { createNewUser, getAllUserRoles } from "../../library/Requests";
import CustomModal from "./../../components/CustomModal";
import { getRoles } from "@testing-library/react";

const CreateNewRole = (props) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [gender, setGender] = useState("Male");
  const [password, setPassword] = useState("");
  const [autopassword, setAutoPassword] = useState("test@123");
  const [role, setRole] = useState("");
  const [validEmil, setValidEmil] = useState(false);
  const [showSuccessBox, setShowSuccessBox] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [resetPasswordFlag, setResetPasswordFlag] = useState(false);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    getRoles();
  }, [props]);

  const goBacktoConent = () => {
    props.history.push(`/dashboard/roles`);
  };
  const getRoles = async () => {
    const resp = await getAllUserRoles();
    console.log("get roles");
    setUserRoles(resp.data);
  };
  const validateEmail = (email) => {
    setEmail(email);
    if (emailRegex.test(String(email).toLowerCase())) {
      setValidEmil(true);
    } else {
      setValidEmil(false);
    }
  };
  const autoPassword = () => {
    setAutoPassword("test@123");
  };
  const resetPasswordBtn = () => {
    setResetPasswordFlag(true);
  };

  const onCreateUser = async () => {
    if (firstname != "" && lastname != "" && email != "") {
      const fullPAssword = password != "" ? password : autopassword;
      const body = {
        customer: {
          firstName: firstname,
          lastName: lastname,
          gender: gender,
          email: email,
          phoneNumber: mobilenumber,
          active: true,
        },
        password: fullPAssword,
        customerRole: "Admin",
      };
      console.log(body);
      const resp = await createNewUser(body);
      console.log(resp);
      if (resp.success) {
        setShowSuccessBox(true);
      }
    }
  };
  const hideModel = () => {
    setShowSuccessBox(false);
    setResetPasswordFlag(false);
    props.history.push(`/dashboard/roles`);
  };

  return (
    <div className="CreateRoleContainer">
      <Row className="articleHeadingRow">
        <ChevronLeft />
        <span className="roleMgmtHeading" onClick={goBacktoConent}>
          Role Management
        </span>
      </Row>
      <Card className="createNewRole">
        <Card.Header className="createNewRoleHeading">
          Create New Role
        </Card.Header>
        <Card.Body className="createNewRoleBody">
          <Row>
            <Col sm="4" style={{ padding: 0 }}>
              <Form.Label className="requiredField">First Name</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
                value={firstname}
                className="mediumFields"
                type="text"
                placeholder="John"
              />
            </Col>
            <Col sm="4" style={{ paddingLeft: "29px" }}>
              <Form.Label className="requiredField">Last Name</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
                value={lastname}
                className="mediumFields"
                type="text"
                placeholder="Doe"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="requiredField">Email Address</Form.Label>
              <Form.Control
                onChange={(e) => {
                  validateEmail(e.target.value);
                }}
                value={email}
                type="text"
                placeholder="Jhon@gmail.com"
              />
              {!validEmil && email != "" && (
                <span className="invalidEmailClass">
                  {" "}
                  Enter Valid Email id.
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm="4" style={{ padding: 0 }}>
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setMobilenumber(e.target.value);
                }}
                value={mobilenumber}
                className="mediumFields"
                type="text"
                placeholder=""
              />
            </Col>
            <Col sm="4" style={{ paddingLeft: "29px" }}>
              <Form.Label>Gender</Form.Label>
              <Form.Control
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                className="mediumFields"
                name=""
                as="select"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Control>
            </Col>
          </Row>

          <Row className="roleSubHeading">
            <p>Password</p>
          </Row>
          <Row>
            <Button
              onClick={() => {
                resetPasswordBtn();
              }}
              className="resetPasswordBtn"
            >
              Reset Password
            </Button>
          </Row>

          <Row className="roleSubHeading">
            <p>Choose Role</p>
          </Row>
          <Form.Label>Select Role</Form.Label>
          <Form.Control
            value={role}
            onChange={(e) => {
              setRole(e.target.value);
            }}
            name=""
            as="select"
          >
            <option>Select Role</option>
            {(userRoles || []).map((item, key) => {
              return (
                <>
                  <option>{item.name}</option>
                </>
              );
            })}
          </Form.Control>
        </Card.Body>
      </Card>
      <Row className="cancelCreate">
        <Button className="cancelBtn" onClick={goBacktoConent}>
          Cancel
        </Button>
        <Button className="createBtn" onClick={onCreateUser}>
          Create
        </Button>
      </Row>

      <CustomModal
        show={showSuccessBox}
        titleText="New Business Role is Created Successfully."
        deleteText="ok"
        cancelText=""
        onCancel={() => hideModel(false)}
        onDelete={() => hideModel(false)}
      />
      <CustomModal
        show={resetPasswordFlag}
        titleText="Password Reset link sent to Email Adress Successfully."
        deleteText="ok"
        cancelText=""
        onCancel={() => hideModel(false)}
        onDelete={() => hideModel(false)}
      />
    </div>
  );
};

export default CreateNewRole;
