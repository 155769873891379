import React from "react";

import moment from "moment-timezone";

const LotDetailsView = (props) => {
  const { lotData } = props;
  const createMarkup = (data) => {
    return { __html: data };
  };
  const locD = lotData?.itemLogisticInfos?.[0];
  const locaAdd = locD?.itemCity
    ? `${locD?.itemCity}, ${locD?.itemState}, ${locD?.itemCountry}${
        locD?.itemPostalCode ? `, ${locD?.itemPostalCode}` : ""
      }`
    : "";
  return (
    <>
      <div className="description_tabs_content">
        <div className="tabs-content-section-view-main">Lot Details</div>
        <div className="tabs-content-section-view">Description</div>
        <div>
          <div dangerouslySetInnerHTML={createMarkup(lotData?.description)} />
        </div>
        {lotData?.makerName?.length > 0 && (
          <>
            <div className="tabs-content-section-view">Artist/Designer</div>
            <div>{lotData?.makerName}</div>
          </>
        )}
        {lotData?.itemProvenances?.length > 0 && (
          <>
            <div className="tabs-content-section-view">Provenance</div>
            {lotData?.itemProvenances?.map((Itm) => {
              return <div>{Itm?.provenanceOwner}</div>;
            })}
          </>
        )}

        <div className="tabs-content-section-view">Condition Report</div>
        {lotData?.conditionalReport && (
          <div className="des_tab_cont">
            <div
              dangerouslySetInnerHTML={createMarkup(lotData?.conditionalReport)}
            />
          </div>
        )}

        <div className="tabs-content-section-view">Other Details</div>
        <ul className="other-items-list-by-sec other-items-list-by-sec1">
          {lotData?.periodOfWork && (
            <li>
              <div>
                <div className="other-item-key-val">
                  {" "}
                  Date<span className="d-none">:</span>{" "}
                </div>{" "}
                <div className="other-item-collen-val">:</div>{" "}
                <div> {moment(lotData?.periodOfWork).format("YYYY")} </div>
              </div>
            </li>
          )}
          {lotData?.restrictedMaterialDesc && (
            <li>
              <div>
                <div className="other-item-key-val">
                  {" "}
                  Place of Origin<span className="d-none">:</span>
                </div>{" "}
                <div className="other-item-collen-val">:</div>{" "}
                <div className="other-item-collen-val-val">
                  {lotData?.restrictedMaterialDesc}
                </div>{" "}
              </div>
            </li>
          )}
          {/* <li>
            <div>
              <div className="other-item-key-val">Artist/Designer</div>{" "}
              <div className="other-item-collen-val">:</div>{" "}
              <div>{lotData?.makerName}</div>{" "}
            </div>
          </li> */}
          {lotData?.material && (
            <li>
              <div>
                <div className="other-item-key-val">
                  Medium/Material<span className="d-none">:</span>
                </div>{" "}
                <div className="other-item-collen-val">:</div>{" "}
                <div className="other-item-collen-val-val">
                  {lotData?.material}
                </div>{" "}
              </div>
            </li>
          )}

          <li>
            <div>
              <div className="other-item-key-val">
                Location<span className="d-none">:</span>
              </div>{" "}
              <div className="other-item-collen-val">:</div>{" "}
              <div className="other-item-collen-val-val">{locaAdd}</div>{" "}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};
export default LotDetailsView;
