import React, { useEffect, useState } from "react";
import { Row, Col, Button, Nav, Tab } from "react-bootstrap";
import {
  getCollectionByIdCall,
  getTimeZones,
  updateCollectionStatusId,
  getItemidsbyCollectionIdCall,
  getcurrenciesCall,
  getpublishedcountCall,
  exitcollectionreviewCall,
  gethighestbid,
  getCollectionItemsCall,
  getcollectionitembyidCall,
  updateCollectionItemStatus,
  discardfrompublishCall,
} from "./../../library/Requests";
import { ReactComponent as CurrencyIcon } from "./../../assets/Currency.svg";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as InfoIcon } from "./../../assets/InfoIcon.svg";
import { useHistory } from "react-router-dom";
import CustomModal from "./../../components/CustomModal";
import { useParams } from "react-router-dom";
import moment from "moment";
import "./styles.css";
import { LOGINID } from "./../../Scenes/common";

const PreviewLot = (props) => {
  // const lotD = props?.lotItemDetails;
  const [mainImage, setMainImage] = useState(null);
  const [currCollection, setCurrCollection] = useState({});
  const [previewTimez, setPreviewTimez] = useState([]);
  const [bidDetails, setBidDetails] = useState({});
  const [currencie, setCurrencie] = useState([]);
  const [lotD, setLotD] = useState({});
  const [allCollectionItemsList, setAllCollectionItemsList] = useState([]);
  const [prevLot, setPrevLot] = useState({});
  const [nextLot, setNextLot] = useState({});
  const [currentLot, setCurrentLot] = useState({});
  const [flag, setFlag] = useState(false);
  const { id, lotNumber } = useParams();
  const [publishWarning, setPublishWarning] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [showAlertText, setShowAlertText] = useState("");
  const [showWarningExitPreview, setShowWarningExitPreview] = useState(false);

  const [showSuccessError, setShowSuccessError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const id = lotD?.itemID

  useEffect(async () => {
    // if(props?.isEditLot){
    getCollectiondetailsbyID(props?.collectionItemId);
    // getCollectionById();
    // setLotD(props?.lotItemDetails)
    getCurrencies();
    // }
  }, [props?.showPreview]);

  useEffect(async () => {
    if (!props?.isEditLot) {
      getCollectiondetailsbyID(props?.collectionItemId);
      getCurrencies();
    }
  }, []);

  const history = useHistory();
  let currencyType = currencie?.find(
    (i) => currCollection?.currency == i?.id
  )?.currencyCode;

  const endDateFormat = moment(currCollection?.onlineEndDate).format("MMMM Do");
  const endTimeFormat = moment(currCollection?.onlineEndTime, "HHmmss").format(
    "h:mm a"
  );

  let previewTimeZone = previewTimez.find(
    (i) => currCollection?.timeZoneId === i?.id
  )?.abbrivation;

  if (!previewTimeZone) {
    previewTimeZone = previewTimez.find(
      (i) => currCollection?.timeZoneId === i?.id
    )?.name;
  }
  // const getCollectionById = async () => {
  //     const collId = props?.collectionIdNewId;
  //     if(collId !=undefined && collId !=null){
  //         let resp = await getCollectionByIdCall(collId)
  //         setCurrCollection(resp?.data);
  //         console.log("resp?.data,", resp?.data)
  //     }
  // }
  const getCollectiondetailsbyID = async (id) => {
    if (id) {
      setIsLoading(true);
      const collectionItem = await getcollectionitembyidCall(id, LOGINID);
      setLotD(collectionItem?.data);
      getHighestBidDetails(id);
      getallitemsData(collectionItem?.data, collectionItem?.data?.collectionId);
      getPrevNextItems(allCollectionItemsList, collectionItem?.data);
      let resp = await getCollectionByIdCall(
        collectionItem?.data?.collectionId
      );
      setCurrCollection(resp?.data);
      setIsLoading(false);
    }
  };
  const getHighestBidDetails = async (id) => {
    const bidResp = await gethighestbid(id);
    setBidDetails(bidResp?.bidDetails);
  };

  const getCurrencies = async () => {
    if (currencie.length == 0) {
      const currenciesResp = await getcurrenciesCall();
      setCurrencie(currenciesResp?.data);
    }
    if (previewTimez.length == 0) {
      const previewTimezResp = await getTimeZones();
      setPreviewTimez(previewTimezResp.data);
    }
  };

  const handleImageChange = (item) => {
    setMainImage(item);
  };
  const getallitemsData = async (lotData, collectionId) => {
    const collId = collectionId || props?.collectionIdNewId;
    if (collId != undefined && collId != null && collId) {
      const body = {
        page: 1,
        pageSize: 50,
        collectionId: collId,
      };
      //   let resp = await getCollectionItemsCall(body);
      let resp1 = await getItemidsbyCollectionIdCall(collId);
      setAllCollectionItemsList(resp1?.data);
      getPrevNextItems(resp1?.data, lotData);
    }
  };
  const getPrevNextItems = async (list, lotData) => {
    // const {lotData} = this.state;
    (list || []).map((item, index) => {
      if (lotData.id == item.itemId) {
        setCurrentLot(item);
        let currentNoo = item.rno;
        const prevIn = currentNoo - 1;
        const nectIn = currentNoo + 1;
        let prevLotN = list.filter((i2) => i2.rno == prevIn);
        let nectLotN = list.filter((i2) => i2.rno == nectIn);
        if (prevLotN.length > 0) {
          setPrevLot(prevLotN[0]);
        } else {
          setPrevLot({});
        }
        if (nectLotN.length > 0) {
          setNextLot(nectLotN[0]);
        } else {
          setNextLot({});
        }
      }
    });
  };
  const nextLotClick = async (id) => {
    const collectionItem = await getcollectionitembyidCall(id, LOGINID);
    setLotD(collectionItem?.data);
    setMainImage(null);
    getPrevNextItems(allCollectionItemsList, collectionItem?.data);
    getHighestBidDetails(id);
    // this.setState({l: collectionItem?.data, collectionId: collectionItem?.data?.collectionId })
  };
  const publishCollectionData = async () => {
    const resp = await getpublishedcountCall(currCollection?.id);
    if (resp?.alertText && resp?.alertText != "") {
      setShowAlertText(resp?.alertText);
      setPublishWarning(true);
    }
  };
  const publishConform = async (key) => {
    // setPublishModal(false)
    setPublishWarning(false);
    let body = {
      id: currCollection?.id,
      status: 1,
    };
    let statusResp = await updateCollectionStatusId(body);
    if (statusResp?.success) {
      publishLotConfirm();
    } else if (statusResp?.errors[0] == "Incomplete data") {
      setShowAlertText("Please fill the data for all lots");
      setShowSuccessError(true);
    } else if (statusResp?.errors[0] != "") {
      setShowAlertText(statusResp?.errors[0]);
      setShowSuccessError(true);
    }
  };

  const publishLotConfirm = async () => {
    setPublishWarning(false);
    const resp = await getpublishedcountCall(currCollection?.id);
    if (resp?.sucessText && resp?.sucessText != "") {
      setShowAlertText(resp?.sucessText);
      setPublishSuccess(true);
    }
  };
  const goBack = () => {
    history.push(`/dashboard/collection/${lotD?.collectionId}`);
  };
  const flagLotConfirm = async () => {
    setFlag(false);
    let body = {
      itemId: lotD?.id,
      discardFromPublish: true,
    };
    let resp = await discardfrompublishCall(body);
    // if(resp?.success){
    const lotD1 = lotD;
    lotD1.discardFromPublish = true;
    setLotD(lotD1);
    let collectionItem = await getcollectionitembyidCall(lotD?.id, LOGINID);
    let loTData = collectionItem?.data;
    loTData.discardFromPublish = true;
    setLotD(loTData);
    // nextLotClick(lotD?.id);
    // }
    // getDataonLoad();
  };
  const removeFlag = async () => {
    let body = {
      itemId: lotD?.id,
      discardFromPublish: false,
    };
    let resp = await discardfrompublishCall(body);
    // if(resp?.success){
    const lotD1 = lotD;
    lotD1.discardFromPublish = false;
    setLotD(lotD1);
    let collectionItem = await getcollectionitembyidCall(lotD?.id, LOGINID);
    let loTData = collectionItem?.data;
    loTData.discardFromPublish = false;
    setLotD(loTData);
    // nextLotClick(lotD?.id);
    // }
  };
  const exitPreviePage = async () => {
    setShowWarningExitPreview(true);
  };
  const conformPreview = async () => {
    setShowWarningExitPreview(false);
    const body = {
      CollectionId: currCollection?.id,
    };
    const resp = await exitcollectionreviewCall(body);
    if (resp?.success) {
      goBack();
    }
  };
  const publishSingleLot = async () => {
    let body = {
      id: lotD?.id,
      status: 1,
    };
    let resp = await updateCollectionItemStatus(body);
    if (resp?.success) {
      setPublishSuccess(true);
      setShowAlertText("Lot has been successfully published.");
    } else if (
      resp?.errors?.length > 0 &&
      resp?.errors[0] != undefined &&
      resp?.errors[0] != ""
    ) {
      setPublishSuccess(true);
      setShowAlertText(resp?.errors[0]);
    }
  };
  const createMarkup = (data) => {
    return { __html: data };
  };

  const mainImageNew = lotD?.itemImages;
  let mainImageurl = null;
  if (
    mainImageNew != undefined &&
    mainImageNew != null &&
    mainImageNew.length > 0
  ) {
    mainImageurl = mainImageNew[0].vurtualPath;
  }

  return (
    <div className="previewLotContainer">
      {!props?.isPublish && !props?.isPublishSingleLot && (
        <Row className="previewBtnRow">
          <Button
            onClick={() => {
              props.closePreviewPageCall();
            }}
            className="closePreviewBtn"
          >
            Close Preview{" "}
          </Button>
        </Row>
      )}
      <Row style={{ flexDirection: "column" }}>
        {(props?.isPublish || props?.isPublishSingleLot) && (
          <>
            <Row className="viewLotTitleRow previewBackArrow">
              <span
                onClick={() => {
                  goBack(lotD);
                }}
              >
                <ChevronLeft /> <span>{currCollection?.title}</span>
              </span>
            </Row>
          </>
        )}
        <Button className="lotButton">Lot {lotD?.sno}</Button>
      </Row>
      <Row className="previewImagesWrapper">
        <Col sm="8" className="mainImageColumn noPadding">
          {/* <img src={mainImage || mainImageurl} alt="images" className="mainPreviewImage" /> */}
          {isLoading ? (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              {mainImage?.displayOrder == 8 ? (
                <>
                  <iframe
                    width="760"
                    height="630"
                    src={mainImage?.vurtualPath}
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </>
              ) : (
                <img
                  src={mainImage?.vurtualPath || mainImageurl}
                  alt="images"
                  className="mainPreviewImage"
                />
              )}
            </>
          )}
        </Col>
        <Col sm="4" className="previewRightBlock noPadding">
          <Row className="innerBlock">
            <p className="viewLotTitle textBottomStyles">{lotD?.title}</p>
            {/* <p className="viewLotLabel">Lot Closes:</p>
                    <p className="viewLotValue textBottomStyles">
                    {moment(currCollection?.onlineEndDate).format("Do MMM")} {moment(currCollection?.onlineEndTime,'HHmmss').format("h:mm a")} {previewTimeZone}

                    </p> */}
            {currCollection?.onlineEndDate ? (
              <>
                <p className="viewLotLabel">Lot Closes:</p>
                <p className="viewLotValue textBottomStyles">
                  {endDateFormat}, {endTimeFormat} ({previewTimeZone})
                </p>
              </>
            ) : null}
            <p className="viewLotLabel">
              {lotD?.isFixedEstimatedPrice ? "Fixed" : "Estimate"}:
            </p>
            <p className="viewLotValue">
              {currencyType} {lotD?.estimatedPrice}
              {lotD?.estimatedPriceTo && (
                <>
                  {" "}
                  - {currencyType} {lotD?.estimatedPriceTo}
                </>
              )}
            </p>
            <p className="viewLotLabel">Current Bid: </p>
            <p className="viewLotValue textBottomStyles">
              {currencyType} {bidDetails?.amount}{" "}
            </p>
            <Row>
              <Button className="viewLotCancelBtn">PLACE BID</Button>
              <Button className="viewLotEditBtn">Follow</Button>
            </Row>
          </Row>
          <Row className="currencyRow">
            <p className="currencyConverter">
              {" "}
              <CurrencyIcon /> Currency Converter
            </p>
            <p className="currencyConverter">
              {" "}
              <InfoIcon /> How Bidding Works
            </p>
            <p className="currencyConverter">
              {" "}
              <InfoIcon /> Bid Increments Table
            </p>
          </Row>
        </Col>
      </Row>

      <Row>
        {(lotD?.itemImages || []).map((itemL, index) => {
          return (
            <>
              {itemL?.displayOrder == 8 ? (
                <>
                  <div
                    class="videosIMageReview"
                    onClick={(e) => {
                      handleImageChange(itemL);
                    }}
                  >
                    <img src={mainImageurl} alt="Video thumbnail" />
                    <svg
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <title>play-glyph</title>
                      <path
                        d="M60.54,512c-17.06,0-30.43-13.86-30.43-31.56V31.55C30.12,13.86,43.48,0,60.55,0A32.94,32.94,0,0,1,77,4.52L465.7,229c10.13,5.85,16.18,16,16.18,27s-6,21.2-16.18,27L77,507.48A32.92,32.92,0,0,1,60.55,512Z"
                        fill="#434040"
                      />
                    </svg>
                  </div>
                </>
              ) : (
                <img
                  src={itemL?.vurtualPath}
                  onClick={(e) => {
                    handleImageChange(itemL);
                  }}
                  alt="images"
                  className="previewSideImages"
                />
              )}
              {/* <img src={itemL?.vurtualPath}  onClick={(e) => { handleImageChange(itemL) }} alt="images" className="previewSideImages" /> */}
            </>
          );
        })}
      </Row>
      <Row>
        <Tab.Container id="collections-tabs" defaultActiveKey="description">
          <Row sm="12" style={{ width: "100%" }}>
            <Col sm="12" className="noPadding">
              <Nav variant="tabs" className="collectionsTabs">
                <Nav.Item>
                  <Nav.Link eventKey="description">Description</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="conditionReport">
                    Condition Report
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm="12" className="noPadding previewContent">
              <Tab.Content>
                <Tab.Pane eventKey="description">
                  <Row sm="12">
                    <div
                      dangerouslySetInnerHTML={createMarkup(lotD?.description)}
                    />
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="conditionReport">
                  <Row sm="12">
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        lotD?.conditionalReport
                      )}
                    />
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Row>

      {props?.isPublish && (
        <>
          <Row className="previewPageBtns">
            <div className="leftBtnContainer">
              <Button
                variant="custom"
                className="exitPreviewBtn btnText"
                onClick={() => exitPreviePage()}
              >
                Exit Preview
              </Button>
              {lotD?.discardFromPublish ? (
                <Button
                  variant="custom"
                  className="removeFlagBtn btnText"
                  onClick={() => removeFlag()}
                >
                  Remove Flag
                </Button>
              ) : (
                <Button
                  variant="custom"
                  className="flagBtn btnText"
                  onClick={() => setFlag(true)}
                >
                  Flag
                </Button>
              )}
            </div>
            <div className="rightBtnContainer">
              {prevLot?.itemId && (
                <Button
                  variant="custom"
                  onClick={() => {
                    nextLotClick(prevLot?.itemId);
                  }}
                  className="prevPreviewBtn btnText"
                >
                  {" "}
                  Previous{" "}
                </Button>
              )}
              {nextLot?.itemId ? (
                <Button
                  onClick={() => {
                    nextLotClick(nextLot?.itemId);
                  }}
                  className="nextPreviewBtn btnText"
                >
                  {" "}
                  Next {nextLot?.rno}/{allCollectionItemsList.length}{" "}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    publishCollectionData();
                  }}
                  className="nextPreviewBtn"
                >
                  {" "}
                  Publish{" "}
                </Button>
              )}
            </div>
          </Row>
        </>
      )}
      {props?.isPublishSingleLot && (
        <>
          <Row className="previewPageBtns">
            <div className="leftBtnContainer">
              <Button
                variant="custom"
                className="exitPreviewBtn btnText"
                onClick={() => goBack()}
              >
                Exit Preview
              </Button>
            </div>
            <div className="rightBtnContainer">
              <Button
                onClick={() => {
                  publishSingleLot();
                }}
                className="nextPreviewBtn"
              >
                {" "}
                Publish{" "}
              </Button>
            </div>
          </Row>
        </>
      )}

      <CustomModal
        show={flag}
        titleText="Lots marked with Flag will not be published. Are you sure you want to flag the lot? "
        deleteText="Flag"
        cancelText="Cancel"
        onCancel={() => setFlag(false)}
        onDelete={() => flagLotConfirm()}
      />
      <CustomModal
        show={publishWarning}
        titleText={showAlertText}
        deleteText="Publish"
        cancelText="Cancel"
        onCancel={() => setPublishWarning(false)}
        onDelete={() => publishConform()}
      />
      <CustomModal
        show={publishSuccess}
        titleText={showAlertText}
        deleteText="ok"
        cancelText=""
        onCancel={() => setPublishSuccess(false)}
        onDelete={() => {
          setPublishSuccess(false);
          goBack();
        }}
      />
      <CustomModal
        show={showSuccessError}
        titleText={showAlertText}
        deleteText="ok"
        cancelText=""
        onCancel={() => setShowSuccessError(false)}
        onDelete={() => {
          setShowSuccessError(false);
        }}
      />
      <CustomModal
        show={showWarningExitPreview}
        titleText="Are you sure you want to exit preview?"
        deleteText="Exit Preview"
        cancelText="Cancel"
        onCancel={() => setShowWarningExitPreview(false)}
        onDelete={() => {
          conformPreview();
        }}
      />
    </div>
  );
};

export default PreviewLot;
