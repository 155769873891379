import { get, post, put, del } from "./helpers";
import { API_BASE, SERVER_IP } from "../constants";

export const getOrdersCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Order/getOrders`, body);
};
export const getOrderStatus = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Order/getOrderStatus`);
};
export const updateOrderStatusCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Order/updateOrderStatus`, body);
};
//   export const deletesitemanagerCall = async (body)=> {
//     return await put(`${SERVER_IP}${API_BASE}/SiteManagement/deletesitemanager`, body);
//   };
//   export const updatesitemanagerCall = async (body)=> {
//     return await put(`${SERVER_IP}${API_BASE}/SiteManagement/updatesitemanager`, body);
//   };
