import PropTypes from "prop-types";
import React, { Component, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
// import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
// import { ReactComponent as BlockIcon } from "./../../assets/blockIcon.svg";

import { Switch, Route, withRouter } from "react-router-dom";
import { ReactComponent as CloseIcon } from "./../../assets/closeIcon.svg";
// import routes from "../../routes";
// import Header from "../../components/Header";
// import {LOGIN_SUCCESS } from '../../library/Requests/eventConstants';

import { Row, Col, Accordion, Card, Form, Button } from "react-bootstrap";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import { ReactComponent as AddImageIcon } from "./../../assets/AddImageIcon.svg";
import { ReactComponent as AddVideoIcon } from "./../../assets/AddVideoIcon.svg";
import { ReactComponent as AddDocumentsIcon } from "./../../assets/AddDocumentsIcon.svg";
import { ReactComponent as DeleteBin } from "./../../assets/deleteBin.svg";

import "./styles.css";
import { UPDATE_LOT_DATA } from "../../library/Requests/eventConstants";

import {
  getsasToken,
  imageasyncupload,
  saveImagesforItem,
  deleteImageByIdCall,
} from "./../../library/Requests";

import uplaodImageNew from "../../services";

import { validateImageFormat } from "./../../Scenes/common.js";

class AddMediaForm extends Component {
  static propTypes = {};

  state = {
    itemId: null,
    firstImage: { preview: "" },
    secondImage: { preview: "" },
    thirdImage: { preview: "" },
    makersMark: { preview: "" },
    damage: { preview: "" },
    anotherImage: { preview: "" },
    supportedDoc: { preview: "" },
    videoContent: { preview: "" },
    jsonPrep: [],
    firstImageError: false,
    secondImageError: false,
    thirdImageError: false,
    saveAndContinue: false,
    loading: false,
    startUploadCount: 0,
    endUploadCount: 0,
  };

  //   /api/Customer/getallusers
  componentDidMount = () => {
    // this.checkLogin();

    const self = this;
    window.document.addEventListener(
      UPDATE_LOT_DATA,
      function (event) {
        self.updateDataState();
      },
      false
    );
  };

  handleDeleteImage = (image, type, dOrder) => {
    if (image?.id != undefined && image?.id) {
      deleteImageByIdCall(image.id);
    }
    // const { jsonPrep } = this.state;
    let prevData = this.state.jsonPrep;
    console.log("image", image);
    console.log("type", type);
    console.log("jsonPrep", prevData);
    console.log("jsonPrep", prevData);
    const idx = prevData?.findIndex((u) => u?.displayOrder === dOrder);
    if (idx !== -1) {
      prevData.splice(idx, 1);
    }

    this.setState({ [type]: { preview: "" }, jsonPrep: prevData });

    // console.log("firstImage",image);
    // const deleteImages = this.props.lotItemDetails?.itemImages
    // if(deleteImages !=undefined && deleteImages !=null && deleteImages.length > 0){
    //     for (var key in deleteImages) {
    //         const deleteimageContent = deleteImages[key];
    //         if (deleteimageContent.vurtualPath = image.id) {
    //             // alert("true")
    //             console.log("matched:",deleteimageContent.id)
    //         }
    //         else{
    //             // alert("false")
    //             console.log("notmatched:")
    //         }
    //     }
    // }
  };

  updateDataState = () => {
    if (this.props.isEditLot) {
      const Pimages = this.props.lotItemDetails?.itemImages;
      console.log("images list", Pimages);
      if (Pimages != undefined && Pimages != null && Pimages.length > 0) {
        for (var key in Pimages) {
          if (Pimages[key].displayOrder == 1) {
            const datanew = Pimages[key];
            datanew.preview = Pimages[key].vurtualPath;
            this.setState({ firstImage: datanew });
          }
          if (Pimages[key].displayOrder == 2) {
            const datanew = Pimages[key];
            datanew.preview = Pimages[key].vurtualPath;
            this.setState({ secondImage: datanew });
          }
          if (Pimages[key].displayOrder == 3) {
            const datanew = Pimages[key];
            datanew.preview = Pimages[key].vurtualPath;
            this.setState({ thirdImage: datanew });
          }
          if (Pimages[key].displayOrder == 4) {
            const datanew = Pimages[key];
            datanew.preview = Pimages[key].vurtualPath;
            this.setState({ makersMark: datanew });
          }
          if (Pimages[key].displayOrder == 5) {
            const datanew = Pimages[key];
            datanew.preview = Pimages[key].vurtualPath;
            this.setState({ damage: datanew });
          }
          if (Pimages[key].displayOrder == 6) {
            const datanew = Pimages[key];
            datanew.preview = Pimages[key].vurtualPath;
            this.setState({ anotherImage: datanew });
          }
          if (Pimages[key].displayOrder == 7) {
            const datanew = Pimages[key];
            datanew.preview = Pimages[key].vurtualPath;
            this.setState({ supportedDoc: datanew });
          }
          if (Pimages[key].displayOrder == 8) {
            const datanew = Pimages[key];
            datanew.preview = Pimages[key].vurtualPath;
            this.setState({ videoContent: datanew });
          }
        }
      }
    }
  };

  handleChangeFirst = async (e) => {
    console.log(e);
    if (e.target.files.length) {
      if (validateImageFormat(e.target.files[0].type)) {
        this.uploadimagesCount();
        const filesName = e.target.files[0];
        console.log(filesName);
        console.log(URL.createObjectURL(filesName));
        this.setState({
          firstImage: {
            preview: URL.createObjectURL(filesName),
            type: filesName?.type,
          },
          firstImageError: false,
        });
        const respApp = await uplaodImageNew(filesName, "lot");
        this.updateStateJson(respApp, filesName, 1);
      }
    }
  };
  handleChangeSecond = async (e) => {
    if (e.target.files.length) {
      if (validateImageFormat(e.target.files[0].type)) {
        this.uploadimagesCount();
        const filesName = e.target.files[0];
        this.setState({
          secondImage: {
            preview: URL.createObjectURL(e.target.files[0]),
            type: filesName?.type,
          },
          secondImageError: false,
        });
        const respApp = await uplaodImageNew(filesName, "lot");
        this.updateStateJson(respApp, filesName, 2);
      }
    }
  };

  handleChangeThird = async (e) => {
    if (e.target.files.length) {
      if (validateImageFormat(e.target.files[0].type)) {
        this.uploadimagesCount();
        const filesName = e.target.files[0];
        this.setState({
          thirdImage: {
            preview: URL.createObjectURL(e.target.files[0]),
            type: filesName.type,
          },
          thirdImageError: false,
        });
        const respApp = await uplaodImageNew(filesName, "lot");
        this.updateStateJson(respApp, filesName, 3);
      }
    }
  };

  handleChangeMM = async (e) => {
    if (e.target.files.length) {
      if (validateImageFormat(e.target.files[0].type)) {
        this.uploadimagesCount();
        const filesName = e.target.files[0];
        this.setState({
          makersMark: {
            preview: URL.createObjectURL(e.target.files[0]),
            type: filesName.type,
          },
        });
        const respApp = await uplaodImageNew(filesName, "lot");
        this.updateStateJson(respApp, filesName, 4);
      }
    }
  };

  handleChangeDamage = async (e) => {
    if (e.target.files.length) {
      if (validateImageFormat(e.target.files[0].type)) {
        this.uploadimagesCount();
        const filesName = e.target.files[0];
        this.setState({
          damage: {
            preview: URL.createObjectURL(e.target.files[0]),
            type: filesName.type,
          },
        });
        const respApp = await uplaodImageNew(filesName, "lot");
        this.updateStateJson(respApp, filesName, 5);
      }
    }
  };

  handleChangeAnother = async (e) => {
    if (e.target.files.length) {
      if (validateImageFormat(e.target.files[0].type)) {
        this.uploadimagesCount();
        const filesName = e.target.files[0];
        this.setState({
          anotherImage: {
            preview: URL.createObjectURL(e.target.files[0]),
            type: filesName.type,
          },
        });
        const respApp = await uplaodImageNew(filesName, "lot");
        this.updateStateJson(respApp, filesName, 6);
      }
    }
  };

  handleSupportedDocuments = async (e) => {
    if (e.target.files.length) {
      this.uploadimagesCount();
      const filesName = e.target.files[0];
      this.setState({
        supportedDoc: {
          preview: URL.createObjectURL(e.target.files[0]),
          type: filesName.type,
        },
      });
      const respApp = await uplaodImageNew(filesName, "lot");
      this.updateStateJson(respApp, filesName, 7);
    }
  };
  handleVideoHere = async (e) => {
    if (e.target.files.length) {
      this.uploadimagesCount();
      const filesName = e.target.files[0];
      this.setState({
        videoContent: {
          preview: URL.createObjectURL(e.target.files[0]),
          type: filesName.type,
        },
      });
      const respApp = await uplaodImageNew(filesName, "lot");
      this.updateStateJson(respApp, filesName, 8);
    }
  };
  uploadimagesCount = () => {
    let num = parseInt(this.state.startUploadCount) + 1;
    this.setState({ startUploadCount: num, loading: true });
  };
  updateStateJson = async (resp, filesName, order) => {
    const datanew = {
      itemID: document.getElementById("collectionLotID").value,
      vurtualPath: resp?.imageUrl,
      mimeType: filesName.type,
      displayOrder: order,
    };

    let prevData = this.state.jsonPrep;
    prevData.push(datanew);
    console.log(prevData);

    const num1 = parseInt(this.state.endUploadCount) + 1;

    this.setState({ jsonPrep: prevData, endUploadCount: num1 });
    if (this.state.startUploadCount == num1) {
      this.setState({ loading: false });
    }
  };

  saveAndCountinue = async (e) => {
    const { firstImage, secondImage, thirdImage } = this.state;
    let flagNew = true;
    if (!firstImage.preview) {
      this.setState({ firstImageError: true });
      flagNew = false;
    }
    // if(!secondImage.preview){
    //     this.setState({secondImageError: true});
    //     flagNew = false;
    // }
    // if(!thirdImage.preview){
    //     this.setState({thirdImageError: true});
    //     flagNew = false;
    // }
    if (flagNew && !this.state.loading) {
      const imagesAll = this.state.jsonPrep;
      console.log(imagesAll);
      console.log(imagesAll.length);
      if (imagesAll.length > 0) {
        this.setState({ loading: true });
        const body = { itemImage: imagesAll };
        const respNew = await saveImagesforItem(body);
        if (respNew?.success) {
          this.setState({
            saveAndContinue: true,
            loading: false,
            jsonPrep: [],
          });
          console.log("state sac:", this.state);
          document.getElementById("Accordion_Section_2").click();
          this.props.getItemDetailsCall();
        }
      } else {
        document.getElementById("Accordion_Section_2").click();
      }
    }
  };

  render() {
    const {
      firstImage,
      secondImage,
      thirdImage,
      makersMark,
      damage,
      anotherImage,
      supportedDoc,
      videoContent,
      firstImageError,
      secondImageError,
      thirdImageError,
      saveAndContinue,
      loading,
    } = this.state;

    return (
      <>
        <div className="wrapper">
          <Card>
            <Accordion.Toggle
              id="Accordion_Section_1"
              as={Card.Header}
              eventKey="1"
            >
              <span className="formHeading">2. Add Images & Documents</span>
              {saveAndContinue ? (
                <Button className="AccordionEditBtn"> Edit </Button>
              ) : (
                <AngleUpIcon className="angleUpIcon" />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="mediaContainer">
                <Row className="imageBlock first">
                  {firstImage.preview ? (
                    <>
                      <DeleteBin
                        className="deleteImageIcon"
                        onClick={() => {
                          this.handleDeleteImage(firstImage, "firstImage", 1);
                        }}
                      />
                      <img
                        src={firstImage.preview}
                        alt="Preview"
                        className="previewImage"
                      />
                    </>
                  ) : (
                    <>
                      <label
                        htmlFor="uploadButtonFirst"
                        className="labelContainer"
                      >
                        <AddImageIcon />
                        <p className="addImageText">Add Image here</p>
                        <p className="requiredField redTextFront">Front</p>
                      </label>
                    </>
                  )}

                  {!firstImage.preview && (
                    <input
                      id="uploadButtonFirst"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      // className="uploadImageBtn"
                      onChange={this.handleChangeFirst}
                    />
                  )}
                </Row>

                {firstImageError ? (
                  <>
                    <div className="errorImageFIle">
                      Please upload the Front Image
                    </div>
                  </>
                ) : null}

                <Row className="dualImages">
                  <Col
                    sm="5.5"
                    className="imageBlock imageBlockSmall second"
                    inline
                  >
                    {secondImage.preview ? (
                      <>
                        <DeleteBin
                          className="deleteImageIcon"
                          onClick={() =>
                            this.handleDeleteImage(
                              secondImage,
                              "secondImage",
                              2
                            )
                          }
                        />
                        <img
                          src={secondImage.preview}
                          alt="Preview"
                          className="previewImage"
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <label
                          htmlFor="uploadButtonSecond"
                          className="labelContainer"
                        >
                          <AddImageIcon />
                          <p className="addImageText">Add Image here</p>
                          <p className="blackText">Back</p>
                        </label>
                      </>
                    )}

                    {!secondImage.preview && (
                      <>
                        <input
                          id="uploadButtonSecond"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={this.handleChangeSecond}
                        />
                      </>
                    )}
                  </Col>
                  <Col
                    sm="5.5"
                    className="imageBlock imageBlockSmall third"
                    inline
                  >
                    {thirdImage.preview ? (
                      <>
                        <DeleteBin
                          className="deleteImageIcon"
                          onClick={() =>
                            this.handleDeleteImage(thirdImage, "thirdImage", 3)
                          }
                        />
                        <img
                          src={thirdImage.preview}
                          alt="Preview"
                          className="previewImage"
                        />
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="uploadButtonThird"
                          className="labelContainer"
                        >
                          <AddImageIcon />
                          <p className="addImageText">Add Image here</p>
                          <p className="blackText">Details</p>
                        </label>
                      </>
                    )}

                    {!thirdImage.preview && (
                      <input
                        id="uploadButtonThird"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={this.handleChangeThird}
                      />
                    )}
                  </Col>
                </Row>
                <Row
                  className={
                    secondImageError || thirdImageError
                      ? "dualImagesError"
                      : null
                  }
                >
                  <Col
                    sm="5.5"
                    className="imageBlockageBlockSmallsecond"
                    inline
                  >
                    {secondImageError ? (
                      <>
                        <div className="errorImageFIle2ndRow">
                          Please upload the Back Image
                        </div>
                      </>
                    ) : null}
                  </Col>
                  <Col
                    sm="5.5"
                    className="imageBlockmageBlockSmallthird"
                    inline
                  >
                    {thirdImageError ? (
                      <>
                        <div className="errorImageFIle2ndRow">
                          Please upload the Details Image
                        </div>
                      </>
                    ) : null}
                  </Col>
                </Row>
                <Row className="dualImages">
                  <Col
                    sm="5.5"
                    className="imageBlock  imageBlockSmall makersMark"
                    inline
                  >
                    {makersMark.preview ? (
                      <>
                        <DeleteBin
                          className="deleteImageIcon"
                          onClick={() =>
                            this.handleDeleteImage(makersMark, "makersMark", 4)
                          }
                        />
                        <img
                          src={makersMark.preview}
                          alt="Preview"
                          className="previewImage"
                        />
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="uploadButtonMM"
                          className="labelContainer"
                        >
                          <AddImageIcon />
                          <p className="addImageText">Add Image here</p>
                          <p className="blackText">Maker's Mark</p>
                        </label>
                      </>
                    )}

                    {!makersMark.preview && (
                      <input
                        id="uploadButtonMM"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={this.handleChangeMM}
                      />
                    )}
                  </Col>
                  <Col
                    sm="5.5"
                    className="imageBlock imageBlockSmall damage"
                    inline
                  >
                    {damage.preview ? (
                      <>
                        <DeleteBin
                          className="deleteImageIcon"
                          onClick={() =>
                            this.handleDeleteImage(damage, "damage", 5)
                          }
                        />
                        <img
                          src={damage.preview}
                          alt="Preview"
                          className="previewImage"
                        />
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="uploadButtonDamage"
                          className="labelContainer"
                        >
                          <AddImageIcon />
                          <p className="addImageText">Add Image here</p>
                          <p className="blackText">Details</p>
                        </label>
                      </>
                    )}

                    {!damage.preview && (
                      <input
                        id="uploadButtonDamage"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={this.handleChangeDamage}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="dualImages">
                  <Col
                    sm="5.5"
                    className="imageBlock imageBlockSmall anotherImage"
                    inline
                  >
                    {anotherImage.preview ? (
                      <>
                        <DeleteBin
                          className="deleteImageIcon"
                          onClick={() =>
                            this.handleDeleteImage(
                              anotherImage,
                              "anotherImage",
                              6
                            )
                          }
                        />
                        <img
                          src={anotherImage.preview}
                          alt="Preview"
                          className="previewImage"
                        />
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="uploadButtonAnother"
                          className="labelContainer"
                        >
                          <AddImageIcon />
                          <p className="addImageText">
                            Add Another Images here
                          </p>
                        </label>
                      </>
                    )}
                    {!anotherImage.preview && (
                      <input
                        id="uploadButtonAnother"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={this.handleChangeAnother}
                      />
                    )}
                  </Col>
                  <Col
                    sm="5.5"
                    className="imageBlock imageBlockSmall supportedDoc"
                    inline
                  >
                    {supportedDoc.preview ? (
                      <>
                        <DeleteBin
                          className="deleteImageIcon"
                          onClick={() =>
                            this.handleDeleteImage(
                              supportedDoc,
                              "supportedDoc",
                              7
                            )
                          }
                        />
                        <img
                          src={supportedDoc.preview}
                          alt="Preview"
                          className="previewImage"
                        />
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="uploadButtonDoc"
                          className="labelContainer"
                        >
                          <AddDocumentsIcon />
                          <p className="addImageText">
                            Add Supported Documents
                          </p>
                        </label>
                      </>
                    )}

                    {!supportedDoc.preview && (
                      <input
                        id="uploadButtonDoc"
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.handleSupportedDocuments}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="imageBlock videoBlock videoContentStyles">
                  {videoContent.preview ? (
                    <>
                      <DeleteBin
                        className="deleteImageIcon"
                        onClick={() =>
                          this.handleDeleteImage(
                            videoContent,
                            "videoContent",
                            8
                          )
                        }
                      />
                      {/* <img src={videoContent.preview} alt="video" className="previewVideo" /> */}

                      <iframe
                        width="560"
                        height="315"
                        src={videoContent.preview}
                        frameborder="0"
                        allowfullscreen
                      ></iframe>
                    </>
                  ) : (
                    <>
                      <label
                        htmlFor="uploadVideoButton"
                        className="labelContainer"
                      >
                        <AddVideoIcon />
                        <p className="addImageText">Add Video here</p>
                      </label>
                    </>
                  )}

                  {!videoContent.preview && (
                    <input
                      id="uploadVideoButton"
                      type="file"
                      accept="video/*"
                      style={{ display: "none" }}
                      // className="uploadImageBtn"
                      onChange={this.handleVideoHere}
                    />
                  )}
                </Row>
                <Row className="requirementTipsRow">
                  <Col sm="7" className="requirementColumn">
                    <p className="requirementHeading">Requirements*</p>
                    <ul className="requirementList">
                      <li>Supported Photos Formats: JPEG, PNG, GIF</li>
                      <li>Supported Documents: PDF, DOC</li>
                      <li>Supported Video formats: .MP4, .MOV</li>
                      <li>Minimum Dimensions: 100Px</li>
                      <li>File Size Limit: 20 MB</li>
                    </ul>
                  </Col>
                  <Col sm="4.5" className="tipsColumn">
                    <p className="tipsHeading">Tips</p>
                    <ol className="tipsList">
                      <li> Take a Photo of the entire item </li>
                      <li> Use good lighting, avoid flash.</li>
                      <li> Avoid uploading blurry images</li>
                      <li> Show Scale/ Context </li>
                      <li> Show details/ texture </li>
                      <li> Use your own photos</li>
                    </ol>
                  </Col>
                </Row>
                <Row className="btnRow">
                  {loading && (
                    <>
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </>
                  )}
                  <Button
                    disabled={loading}
                    className="saveContinue"
                    onClick={this.saveAndCountinue}
                  >
                    Save & Continue
                  </Button>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </>
    );
  }
}

export default withRouter(AddMediaForm);
