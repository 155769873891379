import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {
  Row,
  Col,
  Accordion,
  Card,
  Button,
  Form,
  CardDeck,
  CardColumns,
} from "react-bootstrap";
import { ReactComponent as PlusIcon } from "./../../assets/plusIcon.svg";
import { ReactComponent as EditPencil } from "./../../assets/editPencil.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/deleteIcon.svg";
import "./styles.css";

import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";

import {
  getAllBusinessUsers,
  deleteUserById,
  editBusinessUser,
  rolesResetPasswordCall,
} from "../../library/Requests";

import CustomModal from "./../../components/CustomModal";
class MyAccount extends Component {
  static propTypes = {};

  state = {
    editAdminRole: false,
    usersList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    businessUsers: [],
    editUserData: {},
    validEmil: true,
    showUserInfo: false,
    resetPasswordFlag: false,
    deleteUserinfo: {},
    showDeleteUserPopup: false,
    showDeleteSuccessUserPopup: false,
    showEditUserPopup: false,
    showEditSuccessUserPopup: false,
  };
  clickEditRole = async (index) => {
    this.setState({
      editAdminRole: true,
      editUserData: this.state.businessUsers[index],
    });
  };
  goBacktoConent = () => {
    this.setState({ editAdminRole: false, showUserInfo: false });
  };
  componentDidMount = () => {
    this.getBusinessUsers();
  };
  getBusinessUsers = async () => {
    const resp = await getAllBusinessUsers();
    this.setState({ businessUsers: resp.data });
    console.log("businessUsers__", this.state.businessUsers);
  };
  updateDataEnter = (selectedType, selectedvalue) => {
    const state = this.state.editUserData;
    state[selectedType] = selectedvalue;
    this.setState({ editUserData: state });
    if (selectedType == "email") {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(selectedvalue).toLowerCase())) {
        this.setState({ validEmil: true });
      } else {
        this.setState({ validEmil: false });
      }
    }
  };
  resetPasswordBtn = async (email) => {
    const body = {
      email: email,
    };
    const resp = await rolesResetPasswordCall(body);
    if (resp?.success) {
      this.setState({ resetPasswordFlag: true });
    }
  };

  hideModel = () => {
    this.setState({
      resetPasswordFlag: false,
      showDeleteUserPopup: false,
      showDeleteSuccessUserPopup: false,
      showEditUserPopup: false,
      showEditSuccessUserPopup: false,
    });
  };
  showUserInfoUser = (index) => {
    this.setState({
      showUserInfo: true,
      editUserData: this.state.businessUsers[index],
    });
  };
  clickEditRoleRole = () => {
    this.setState({ showUserInfo: false, editAdminRole: true });
  };
  deleteUserConfirm = async (key) => {
    this.setState({ showDeleteUserPopup: false });
    const Did = this.state.deleteUserinfo.customerId;
    // const resp = await deleteUserById({id: Did, isDelete:true});
    const resp = await deleteUserById(Did);
    const updatedUsers = this.state.businessUsers;
    const index = updatedUsers.findIndex((item) => item.customerId === Did);
    if (index > -1) {
      updatedUsers.splice(index, 1);
      this.setState({ businessUsers: updatedUsers });
    }
    this.setState({ showDeleteSuccessUserPopup: true });
  };
  deleteBUser = async (key) => {
    this.setState({
      showDeleteUserPopup: true,
      deleteUserinfo: this.state.businessUsers[key],
    });
  };
  editBUser = async () => {
    this.setState({ showEditUserPopup: true });
  };

  editUserConfirm = async () => {
    this.setState({ showEditUserPopup: false });
    const {
      customerId,
      firstName,
      lastName,
      email,
      gender,
      phoneNumber,
      role,
    } = this.state.editUserData;

    if (firstName != "" && lastName != "" && email != "") {
      const body = {
        customer: {
          id: customerId,
          firstName: firstName,
          lastName: lastName,
          gender: gender,
          email: email,
          phoneNumber: phoneNumber,
          status: true,
          active: true,
        },
        customerRole: role,
      };
      console.log(body);
      const resp = await editBusinessUser(body);
      console.log(resp);
      if (resp.success) {
        console.log("edit user success");
        this.setState({ showEditSuccessUserPopup: true });
      }
    }
  };
  handleChangeButton = async (event, itemL) => {
    const updatedUsers = this.state.businessUsers;
    const index = updatedUsers.findIndex(
      (item) => item.customerId == itemL.customerId
    );
    if (event.target.checked) {
      updatedUsers[index].status = true;
    } else {
      updatedUsers[index].status = false;
    }
    this.setState({ businessUsers: updatedUsers });
    const body = {
      customer: {
        id: updatedUsers[index]?.customerId,
        status: event.target.checked,
      },
    };

    let resp = await editBusinessUser(body);
    console.log("Status_resp", resp);
    if (resp.success) {
      console.log("#Status Change Success");
    }
  };
  render() {
    const {
      image,
      isValid,
      imageLoaderOpen,
      images,
      imageUploaded,
      showAllUsers,
      editAdminRole,
      businessUsers,
      editUserData,
      resetPasswordFlag,
      validEmil,
      showUserInfo,
      showDeleteUserPopup,
      showDeleteSuccessUserPopup,
      showEditUserPopup,
      showEditSuccessUserPopup,
      usersList,
    } = this.state;
    return (
      <>
        <div className="USerMangement-wrapper">
          {!showUserInfo ? (
            <>
              {!editAdminRole ? (
                <div className="userslIstManagement">
                  <Row className="userslIstManagementRow">
                    <Col sm="6" className="headingText">
                      Roles Management
                    </Col>
                    <Col sm="6" className="createBtnColumn">
                      <Button
                        className="createNewRoleBtn"
                        onClick={(event) => {
                          this.props.history.push(`/dashboard/createNewRole`);
                        }}
                      >
                        <PlusIcon className="plusIcon" />
                        Add New Business User
                      </Button>
                    </Col>
                  </Row>
                  <div className="Usertable-info">
                    <table styles="width:100%">
                      <thead>
                        <tr className="headerNamesList">
                          <th width="20%">User name</th>
                          <th width="20%">Email ID</th>
                          <th width="20%">Role</th>
                          <th width="30%">Activate</th>
                          <th width="30%">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(businessUsers || []).map((item, key) => {
                          return (
                            <>
                              <tr className="RowLevelClass">
                                <td
                                  onClick={() => {
                                    this.showUserInfoUser(key);
                                  }}
                                >
                                  {item.firstName} {item.lastName}{" "}
                                </td>
                                <td
                                  onClick={() => {
                                    this.showUserInfoUser(key);
                                  }}
                                >
                                  {item.email}
                                </td>
                                <td
                                  onClick={() => {
                                    this.showUserInfoUser(key);
                                  }}
                                >
                                  {item.role}
                                </td>
                                <td>
                                  <div className="custom-control custom-switch custom-switch-ButtonView">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`customSwitches${key}`}
                                      readOnly
                                      checked={item.status}
                                      onChange={(e) => {
                                        this.handleChangeButton(e, item);
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`customSwitches${key}`}
                                    ></label>
                                  </div>
                                </td>
                                <td className="role_actionsIcons">
                                  <span
                                    onClick={() => {
                                      this.clickEditRole(key);
                                    }}
                                  >
                                    <EditPencil />
                                  </span>{" "}
                                  <span
                                    onClick={() => {
                                      this.deleteBUser(key);
                                    }}
                                  >
                                    <DeleteIcon />{" "}
                                  </span>{" "}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="CreateRoleContainer">
                  <Row className="articleHeadingRow">
                    <ChevronLeft />
                    <span
                      className="roleMgmtHeading"
                      onClick={() => {
                        this.goBacktoConent();
                      }}
                    >
                      Role Management
                    </span>
                  </Row>
                  <p className="edit_Admin_role">Edit Admin Role</p>
                  <Card className="createNewRole createNewRoleEdit ">
                    <Row></Row>
                    <Card.Header className="createNewRoleHeading createNewRoleHeadingROle">
                      User Information
                    </Card.Header>
                    <Card.Body className="createNewRoleBody">
                      <Row>
                        <Col sm="4" style={{ padding: 0 }}>
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            className="mediumFields"
                            onChange={(e) => {
                              this.updateDataEnter("firstName", e.target.value);
                            }}
                            value={editUserData?.firstName}
                            type="text"
                            placeholder="John"
                          />
                        </Col>
                        <Col sm="4" style={{ paddingLeft: "29px" }}>
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            className="mediumFields"
                            onChange={(e) => {
                              this.updateDataEnter("lastName", e.target.value);
                            }}
                            value={editUserData?.lastName}
                            type="text"
                            placeholder="Doe"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              this.updateDataEnter("email", e.target.value);
                            }}
                            value={editUserData?.email}
                            placeholder="Jhon@gmail.com"
                          />
                          {!validEmil && editUserData?.email != "" && (
                            <span className="invalidEmailClass">
                              {" "}
                              Enter Valid Email id.
                            </span>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="4" style={{ padding: 0 }}>
                          <Form.Label>Mobile Number</Form.Label>
                          <Form.Control
                            className="mediumFields"
                            onChange={(e) => {
                              this.updateDataEnter(
                                "phoneNumber",
                                e.target.value
                              );
                            }}
                            value={editUserData?.phoneNumber}
                            type="text"
                            placeholder=""
                          />
                        </Col>
                        <Col sm="4" style={{ paddingLeft: "29px" }}>
                          <Form.Label>Gender</Form.Label>
                          <Form.Control
                            onChange={(e) => {
                              this.updateDataEnter("gender", e.target.value);
                            }}
                            value={editUserData?.gender}
                            className="mediumFields"
                            name=""
                            as="select"
                          >
                            <option>Male</option>
                            <option>Female</option>
                          </Form.Control>
                        </Col>
                      </Row>

                      <Row className="roleSubHeading">
                        <p>Password</p>
                      </Row>

                      <Row>
                        <Button
                          onClick={() => {
                            this.resetPasswordBtn(editUserData?.email);
                          }}
                          className="resetPasswordBtn"
                        >
                          Reset Password
                        </Button>
                      </Row>
                      {console.log("11111", editUserData?.email)}
                      {/* <Form.Check
                        inline
                        type="radio"
                        label="Generate Password"
                    />
                    <Form.Control type="text" placeholder="" />
                    <Form.Check
                        inline
                        type="radio"
                        label="Enter Password Manually"
                    />
                    <Form.Control type="text" placeholder="" /> */}
                      <Row className="roleSubHeading">
                        <p>Choose Role</p>
                      </Row>
                      <Form.Label>Select Role</Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          this.updateDataEnter("role", e.target.value);
                        }}
                        value={editUserData?.role}
                        name=""
                        as="select"
                      >
                        <option>Select Role</option>
                        <option>Super Admin</option>
                        <option>Auctioneer</option>
                        <option>Admin</option>
                      </Form.Control>
                    </Card.Body>
                  </Card>
                  <Row className="cancelCreate">
                    <Button
                      className="cancelBtn"
                      onClick={() => {
                        this.goBacktoConent();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="createBtn"
                      onClick={() => {
                        this.editBUser();
                      }}
                    >
                      Save Changes
                    </Button>
                  </Row>
                </div>
              )}
            </>
          ) : (
            <div>
              <div className="CreateRoleContainer">
                <Row className="articleHeadingRow">
                  <ChevronLeft />
                  <span
                    className="roleMgmtHeading"
                    onClick={() => {
                      this.goBacktoConent();
                    }}
                  >
                    Role Management
                  </span>
                </Row>

                <div className="centerTextRoles">
                  <div className="articleHeadingRowParent">
                    <div className="articleHeadingRowText">
                      <p className="edit_Admin_role">Role information</p>
                    </div>
                    <div className="articleHeadingRowEdit">
                      <Button
                        onClick={() => {
                          this.clickEditRoleRole();
                        }}
                        className="resetEditBtn"
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                  <div className="basicUserInfoRoles">
                    <div className="avatorIcon">
                      <img
                        className="userImage"
                        src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      ></img>
                      <p>
                        {editUserData.firstName} {editUserData.lastName}{" "}
                      </p>
                    </div>
                    <div className="infoList">
                      <div className="firstColunText">
                        <div>
                          <span> Email Address</span>
                        </div>
                        <div className="bottomTextRoles">
                          <span> {editUserData.email}</span>
                        </div>
                      </div>
                      <div className="secoundColunText">
                        <div>
                          <span> Mobile Number</span>
                        </div>
                        <div className="bottomTextRoles">
                          <span> {editUserData.phoneNumber} </span>
                        </div>
                      </div>
                    </div>
                    <div className="infoList">
                      <div className="GenderfirstColunText">
                        <div>
                          <span> Gender</span>
                        </div>
                        <div className="bottomTextRoles">
                          <span> {editUserData.gender} </span>
                        </div>
                      </div>

                      <div>
                        <div>
                          <span> Role</span>
                        </div>
                        <div className="bottomTextRoles">
                          <span> {editUserData.role} </span>
                        </div>
                      </div>
                    </div>
                    <div className="infoList infoListresetpass ">
                      <Button
                        onClick={() => {
                          this.resetPasswordBtn(editUserData?.email);
                        }}
                        className="resetPasswordBtn"
                      >
                        Reset Password
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <CustomModal
            show={resetPasswordFlag}
            titleText="Password Reset link sent to Email Adress Successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => this.hideModel(false)}
            onDelete={() => this.hideModel(false)}
          />
          <CustomModal
            show={showDeleteUserPopup}
            titleText="Are you Sure, Do you want to Delete User?"
            deleteText="Yes"
            cancelText="No"
            onCancel={() => this.hideModel(false)}
            onDelete={() => this.deleteUserConfirm()}
          />
          <CustomModal
            show={showDeleteSuccessUserPopup}
            titleText="You have Successfully deleted the User."
            deleteText="ok"
            cancelText=""
            onCancel={() => this.hideModel(false)}
            onDelete={() => this.hideModel(false)}
          />
          <CustomModal
            show={showEditUserPopup}
            titleText="Are you Sure, Do you want to Save Changes?"
            deleteText="Yes"
            cancelText="No"
            onCancel={() => this.hideModel(false)}
            onDelete={() => this.editUserConfirm()}
          />
          <CustomModal
            show={showEditSuccessUserPopup}
            titleText="Changes are saved successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => this.hideModel(false)}
            onDelete={() => this.hideModel(false)}
          />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      MyAccount,
    },
    dispatch
  );

export default MyAccount;
