import React, { useState, useEffect } from "react";
import { Card, Col, Row, Button, Dropdown } from "react-bootstrap";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import LotBigDaddy from "./../../assets/LotBigDaddy.jpg";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ReactComponent as DottedLine } from "./../../assets/dottedLine.svg";

import noImage from "./../../assets/noImage.jpg";
import CustomModal from "./../../components/CustomModal";
import {
  deleteLotByIdCall,
  getCollectionByIdCall,
} from "./../../library/Requests";
import "./styles.css";

const LotsCard = (props) => {
  let { id } = useParams();

  const params = new URLSearchParams(props.location.search);
  const type = params.get("type");

  const {
    collectionItemData,
    collectionData,
    lotItems,
    deleteCollectionItem,
    withdrawCollectionItem,
    rePublishCollectionItem,
    publishCollectionItem,
    index,
  } = props;

  const [mainCollection, setMainCollection] = useState("");
  const [isAuctionManagement, setIsAuctionManagement] = useState(false);
  const [isUpcoming, setIsUpcoming] = useState(true);
  const [durationLimit, setDurationLimit] = useState(null);

  const location = useLocation();
  const currentPath = location.pathname;
  const pathsearch = location.search;
  const lotId = collectionItemData?.id;
  useEffect(async () => {
    const pagetype = props.location?.pathname;
    if (pagetype.indexOf("dashboard/auctionManagement") > 0) {
      setIsAuctionManagement(true);
    }
    checkUpcoming();
  }, [props]);

  const checkUpcoming = () => {
    const onlineSD = collectionData?.onlineStartDate
      ? moment.utc(collectionData?.startOnUtc).local().format("YYYY-MM-DD")
      : null;
    const liveSD = collectionData?.biddingStartDate
      ? moment(collectionData?.biddingStartDateOnUTC)
          .local()
          .format("YYYY-MM-DD")
      : moment(collectionData?.startOnUtc).local().format("YYYY-MM-DD");

    const todayDate = moment().format("YYYY-MM-DD");

    const todayTime = moment.utc().local().format("h:m:s A");
    const utcTime = moment
      .utc(collectionData?.startOnUtc)
      .local()
      .format("h:m:s A");
    const advanceTime = moment(collectionData?.biddingStartDateOnUTC)
      .local()
      .format("h:m:s A");
    const onlineTime = moment(collectionData?.onlineStartTime).format(
      "h:m:s A"
    );

    const isOnlineAfter = moment(onlineSD).isAfter(todayDate);
    const isLiveAfter = moment(liveSD).isAfter(todayDate);

    const isOnlineEqual = moment(onlineSD).isSame(todayDate);
    const isLiveEqual = moment(liveSD).isSame(todayDate);

    if (isOnlineAfter) {
      const onlineHourDiff = moment
        .utc(collectionData?.startOnUtc)
        .local()
        .diff(moment().local(), "hours");

      setDurationLimit(onlineHourDiff);
    } else if (isLiveAfter) {
      const liveHourDiff = moment
        .utc(collectionData?.biddingStartDateOnUTC)
        .local()
        .diff(moment().local(), "hours", "minutes");

      setDurationLimit(liveHourDiff);
    } else if (isOnlineEqual) {
      const onlineHourDiff = moment
        .utc(collectionData?.startOnUtc)
        .local()
        .diff(moment().local(), "hours", "minutes");

      setDurationLimit(onlineHourDiff);
      durationLimit < 4 ? setIsUpcoming(false) : setIsUpcoming(true);
    } else if (isLiveEqual) {
      const liveHourDiff = collectionData?.biddingStartDate
        ? moment
            .utc(collectionData?.biddingStartDateOnUTC)
            .local()
            .diff(moment().local(), "hours", "minutes")
        : moment
            .utc(collectionData?.startOnUtc)
            .local()
            .diff(moment.utc().local(), "hours", "minutes");

      setDurationLimit(liveHourDiff);
      durationLimit < 4 ? setIsUpcoming(false) : setIsUpcoming(true);
    } else {
      setIsUpcoming(false);
      const liveHourDiff = moment(collectionData?.biddingStartDateOnUTC)
        .local()
        .diff(moment().local(), "hours", "minutes");
      setDurationLimit(0);
    }
  };

  let currencyName = props?.allCurrenciesData.find(
    (i) => collectionData?.currency == i?.id
  )?.currencyCode;

  const getmainImage = (item) => {
    if (item?.itemImages && item?.itemImages.length > 0) {
      let itemURRL = item?.itemImages.find(
        (i) => i?.displayOrder === 1 && !i?.deleted
      )?.vurtualPath;
      if (
        itemURRL === "string" ||
        itemURRL === null ||
        itemURRL === undefined ||
        itemURRL === ""
      ) {
        return noImage;
      } else {
        return itemURRL;
      }
    } else if (item?.itemImages.length === 0) {
      return noImage;
    }
  };
  const gotoLotPage = (collectionItemData) => {
    const secName = document.getElementById("selectedTabName").value;
    if (secName == "collections") {
      props.history.push(
        `/dashboard/viewLot/${collectionItemData?.id}/${collectionItemData?.sno}`
      );
    } else {
      props.history.push(
        `/dashboard/auctionManagement/viewLot/${collectionItemData?.id}/${collectionItemData?.sno}?type=${type}`
      );
    }
  };

  return (
    <Col sm="4" style={{ paddingRight: "4px" }}>
      <Card className="lotsCardStyles">
        <Row className="topRow">
          <Col>
            <Button className="lotBtn">{`Lot ${collectionItemData?.sno}`}</Button>
          </Col>
          <Col className="threeDotsCol">
            <Dropdown className="customDropdown threeDots">
              {(collectionData?.status == 2 ||
                (isUpcoming &&
                  durationLimit > 4 &&
                  (collectionItemData?.status == 1 ||
                    collectionItemData?.status == 2 ||
                    collectionItemData?.status == 3) &&
                  !isAuctionManagement)) && (
                <>
                  <Dropdown.Toggle>
                    <DottedLine className="dottedLineGrid" />
                  </Dropdown.Toggle>
                </>
              )}
              <Dropdown.Menu>
                {collectionData?.status == 2 ? (
                  <>
                    <Dropdown.Item
                      onClick={(event) => {
                        props.history.push(
                          `/dashboard/editLot/${collectionItemData?.id}`
                        );
                      }}
                    >
                      Edit{" "}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        deleteCollectionItem(
                          collectionItemData,
                          props?.typeofLot
                        )
                      }
                    >
                      Delete
                    </Dropdown.Item>
                    <CustomModal
                      titleText="Do you want to delete this ?"
                      deleteText="Delete"
                      cancelText="Cancel"
                      onDelete={() =>
                        deleteCollectionItem(
                          collectionItemData,
                          props?.typeofLot
                        )
                      }
                    />
                  </>
                ) : (
                  <>
                    {collectionItemData?.status == 1 && (
                      <>
                        <Dropdown.Item
                          onClick={() => withdrawCollectionItem(lotId)}
                        >
                          Withdraw
                        </Dropdown.Item>
                      </>
                    )}
                    {collectionItemData?.status == 2 && (
                      <>
                        <Dropdown.Item
                          onClick={() => publishCollectionItem(lotId)}
                        >
                          Publish
                        </Dropdown.Item>
                      </>
                    )}
                    {collectionItemData?.status == 3 && (
                      <>
                        <Dropdown.Item
                          onClick={() => rePublishCollectionItem(lotId)}
                        >
                          Re-Publish
                        </Dropdown.Item>
                      </>
                    )}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="cardMainImageStyle">
          <Card.Img
            variant="top"
            src={getmainImage(collectionItemData)}
            onClick={(event) => {
              gotoLotPage(collectionItemData);
            }}
            style={{ cursor: "pointer" }}
          />
        </Row>

        <Card.Body>
          <Card.Title className="lotTitleHeight lineClamp">
            {collectionItemData?.title}
          </Card.Title>

          <Row className="priceBidRow">
            {(currentPath.indexOf("/dashboard/collection") > -1 ||
              pathsearch.indexOf("type=upcoming") > -1) && (
              <>
                <Col sm="8" className="priceEstimate">
                  <p className="estimate">
                    {collectionItemData?.isFixedEstimatedPrice
                      ? "Estimate"
                      : "Estimate"}
                  </p>
                  <p className="price">
                    {currencyName} {collectionItemData?.estimatedPrice}{" "}
                    {collectionItemData?.isFixedEstimatedPrice ? null : "-"}{" "}
                    {collectionItemData?.isFixedEstimatedPrice === false &&
                      currencyName + " " + collectionItemData?.estimatedPriceTo}
                  </p>
                </Col>
              </>
            )}
            {(collectionData?.status == 2 ||
              (isUpcoming &&
                durationLimit > 4 &&
                (collectionItemData?.status == 1 ||
                  collectionItemData?.status == 2 ||
                  collectionItemData?.status == 3) &&
                !isAuctionManagement)) && (
              <>
                <Col sm="4" className="bitBtnContainer">
                  <Button
                    className="lotsEditBtn"
                    onClick={(event) => {
                      props.history.push(
                        `/dashboard/editLot/${collectionItemData?.id}`
                      );
                    }}
                  >
                    Edit
                  </Button>
                </Col>
              </>
            )}
          </Row>

          {isAuctionManagement && type == "active" && (
            <>
              <Row className="priceBidRow">
                <span className="metReserveText">
                  {collectionItemData?.lotMetReservice
                    ? "Met Reserve"
                    : "Not met Reserve"}{" "}
                </span>
              </Row>
            </>
          )}
          {isAuctionManagement && type == "past" && (
            <>
              <Row className="priceBidRow">
                <Col>
                  {collectionItemData?.status == 6 ? (
                    <>
                      <p className="cardSubText">Lot Sold</p>
                      <span className="cardSubtextValue">
                        {collectionItemData?.soldFor} {currencyName}{" "}
                      </span>
                    </>
                  ) : collectionItemData?.status == 7 ? (
                    <>
                      <p className="cardSubText">Lot un Sold</p>
                    </>
                  ) : null}
                </Col>
                {collectionItemData?.status == 6 && (
                  <Col className="paymentStatusCol">
                    <p className="cardSubText alignRight">Payment Status</p>
                    <p className="cardSubtextValue paymentStatusVal">
                      {collectionItemData?.paymentStatus}{" "}
                    </p>
                  </Col>
                )}
              </Row>
            </>
          )}
          {isAuctionManagement && type == "active" && (
            <>
              <Row className="priceBidRow">
                <Col>
                  {collectionItemData?.status == 6 ? (
                    <>
                      <p className="cardSubText">Lot Sold</p>
                      <span className="cardSubtextValue">
                        {collectionItemData?.soldFor} {currencyName}{" "}
                      </span>
                    </>
                  ) : collectionItemData?.status == 7 ? (
                    <>
                      <p className="cardSubText">Lot un Sold</p>
                    </>
                  ) : null}
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default withRouter(LotsCard);
