import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import { ReactComponent as WordManagerIcon } from "./../../assets/wordManagerIcon.svg";
import { ReactComponent as HeroManagerIcon } from "./../../assets/heroManagerIcon.svg";
import "./styles.css";

const SiteManager = (props) => {
  return (
    <Row className="siteInnerContainer">
      <Col sm="4" className="siteInnerBox leftBoxSpacing">
        <HeroManagerIcon />
        <span className="headingText">Hero Section Manager</span>
        <Button
          className="smEditBtn"
          onClick={() => props.history.push(`/dashboard/heroSectionManager`)}
        >
          Edit
        </Button>
      </Col>
      <Col sm="4" className="siteInnerBox">
        <WordManagerIcon />
        <span className="headingText">the Word Section Manager</span>
        <Button
          className="smEditBtn"
          onClick={() => props.history.push(`/dashboard/wordSectionManager`)}
        >
          Edit
        </Button>
      </Col>
    </Row>
  );
};

export default withRouter(SiteManager);
