import React, { Component } from "react";
import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import {
  Row,
  Col,
  Button,
  CardColumns,
  Form,
  Tab,
  Nav,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { ReactComponent as BlockIcon } from "./../../assets/blockIcon.svg";
import { ReactComponent as RedBlockIcon } from "./../../assets/redBlockIcon.svg";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as HorizontalDots } from "./../../assets/HorizontalDots2.svg";
import "./styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import CustomModal from "./../../components/CustomModal";
import {
  getAllUsers,
  getbusinessuserbyidCall,
  blockCustomer,
  getcustomeraddressCall,
  getOrderbyIdCall,
  getPendingUsersCall,
  getRejectedUsersCall,
  getApprovedUsersCall,
  getPaymentDetailsCall,
  customerApprovelCall,
} from "../../library/Requests";
import SearchComponent from "./../../components/SearchComponent";
import ImagePopup from "./../../components/ImagePopup";
import UserRejectPopup from "./../../components/UserRejectPopup";
import moment from "moment";

class MyAccount extends Component {
  static propTypes = {};

  state = {
    usersList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    showAllUsers: true,
    allUsers: [],
    approvedUsers: [],
    pendingUsers: [],
    rejectedUsers: [],
    showApprovedUsers: [],
    showBlockUserPopup: false,
    showBlockSuccessUserPopup: false,
    showUnBlockUserPopup: false,
    showUnBlockSuccessUserPopup: false,
    selectedData: {},
    selectedAddress: [],
    userId: "",
    showBlockedUsers: false,
    show: false,
    imageUrlForPopup: "",
    showImagePopup: false,
    showPendingUsersPopup: false,
    showPendingUsersText: "",
    selctedType: "",
    selectedPendingUser: {},
    showRejectUserUnputPopup: false,
    purchaseHistory: [],

    approvedcurrentPage: 1,
    pendingcurrentPage: 1,
    rejectedcurrentPage: 1,

    usersPerPage: 15,
    pageNumberLimit: 5,

    approvedmaxPageNumberLimit: 5,
    approvedminPageNumberLimit: 0,

    pendingmaxPageNumberLimit: 5,
    pendingminPageNumberLimit: 0,

    rejectedmaxPageNumberLimit: 5,
    rejectedminPageNumberLimit: 0,

    approvedTotalCount: 0,
    pendingTotalCount: 0,
    rejectedTotalCount: 0,
    loading: false,
    eventKeyTypes: "approved",
  };
  //   /api/Customer/getallusers
  // pageNextBtn=this.pageNextBtn.bind(this);
  componentDidMount = () => {
    this.getAllUsersData("onLoad");
    if (this.props?.match?.params?.id) {
      this.getuserDataid(this.props?.match?.params?.id);
    }
  };
  getAllUsersData = async (pageType) => {
    const {
      usersPerPage,
      approvedcurrentPage,
      pendingcurrentPage,
      rejectedcurrentPage,
    } = this.state;
    this.setState({ loading: true });
    // const resp = await getAllUsers();
    const approved = await getApprovedUsersCall(
      approvedcurrentPage,
      usersPerPage
    );
    const Pending = await getPendingUsersCall(pendingcurrentPage, usersPerPage);
    const Rejected = await getRejectedUsersCall(
      rejectedcurrentPage,
      usersPerPage
    );
    this.setState({ loading: false });
    let approvedUsers = approved?.data || [],
      pendingUsers = Pending?.data || [],
      rejectedUsers = Rejected?.data || [];
    this.setState({
      showApprovedUsers: approvedUsers,
      approvedUsers,
      pendingUsers,
      rejectedUsers,
    });
    if (pageType == "onLoad") {
      this.setState({
        approvedTotalCount: approved?.totalCount,
        pendingTotalCount: Pending?.totalCount,
        rejectedTotalCount: Rejected?.totalCount,
      });
    }
  };
  hideModel = () => {
    this.setState({
      showBlockUserPopup: false,
      showBlockSuccessUserPopup: false,
      showUnBlockUserPopup: false,
      showUnBlockSuccessUserPopup: false,
      showPendingUsersPopup: false,
    });
  };
  getuserDataid = async (id) => {
    if (id) {
      const resp = await getbusinessuserbyidCall(id);
      console.log(resp);
      const respt = resp?.customer;
      this.selectUserInfo(respt);
    }
  };
  selectUserInfo = async (user) => {
    window.scrollTo(0, 0);
    this.setState({ showAllUsers: false, selectedData: user });
    const getAddress = await getcustomeraddressCall(user?.id);
    this.setState({ selectedAddress: getAddress?.address });
    const History = await getPaymentDetailsCall(user?.id);
    this.setState({ purchaseHistory: History?.data });
    // const History1 = await getOrderbyIdCall(1);
  };
  selectedUsersType = (e) => {
    let value = e.target.value;
    const { approvedUsers } = this.state;
    if (value == "all") {
      this.setState({ showApprovedUsers: approvedUsers });
    } else if (value == "blocked") {
      let finaalAction = approvedUsers.filter((item) => item.isBlocked == true);
      this.setState({ showApprovedUsers: finaalAction });
    } else if (value == "unBlocked") {
      let finaalAction = approvedUsers.filter(
        (item) => item.isBlocked == false
      );
      this.setState({ showApprovedUsers: finaalAction });
    }
  };
  blockUser = (user) => {
    this.setState({ showBlockUserPopup: true, userId: user.id });
  };
  blockUserConform = async () => {
    let id = this.state.userId;
    const updatedUsers = this.state.showApprovedUsers;
    const index = updatedUsers.findIndex((item) => item.id == id);
    if (index > 0) {
      updatedUsers[index].isBlocked = true;
      this.setState({ showApprovedUsers: updatedUsers });
    }
    const updatedUsers1 = this.state.approvedUsers;
    const index1 = updatedUsers1.findIndex((item) => item.id == id);
    if (index1 > 0) {
      updatedUsers1[index1].isBlocked = true;
      this.setState({ approvedUsers: updatedUsers1 });
    }

    const body = {
      id: id,
      isBlocked: true,
    };
    let resp = await blockCustomer(body);
    if (resp.success) {
      this.setState({
        showBlockUserPopup: false,
        showBlockSuccessUserPopup: true,
      });
      this.getAllUsersData();
    }
  };
  unBlockUser = (user) => {
    this.setState({ showUnBlockUserPopup: true, userId: user.id });
  };
  unBlockUserConform = async () => {
    let id = this.state.userId;
    const updatedUsers = this.state.showApprovedUsers;
    const index = updatedUsers.findIndex((item) => item.id == id);
    if (index > 0) {
      updatedUsers[index].isBlocked = false;
      this.setState({ showApprovedUsers: updatedUsers });
    }
    const updatedUsers1 = this.state.approvedUsers;
    const index1 = updatedUsers1.findIndex((item) => item.id == id);
    if (index1 > 0) {
      updatedUsers1[index1].isBlocked = false;
      this.setState({ approvedUsers: updatedUsers1 });
    }

    const body = {
      id: id,
      isBlocked: false,
    };
    let resp = await blockCustomer(body);
    if (resp.success) {
      this.setState({
        showUnBlockUserPopup: false,
        showUnBlockSuccessUserPopup: true,
      });
      this.getAllUsersData();
    }
  };
  showImagePopup = (image) => {
    this.setState({ imageUrlForPopup: image, showImagePopup: true });
  };
  purchaseDetailsInfo(id) {
    this.props.history.push(`/dashboard/orderDetails/${id}`);
  }
  getPrimaryAdress = (address) => {
    let finalAddress = "";
    (address || []).map((user, index) => {
      console.log(user);
      if (user?.isPrimary) {
        // finalAddress = (user?.title) ? user?.title : "";
        // finalAddress += (user?.firstName) ? user?.firstName : "";
        // finalAddress += (user?.lastName) ? user?.lastName : "";

        finalAddress = user?.address1 ? `${user?.address1}` : "";
        finalAddress += user?.address2 ? `, ${user?.address2}` : "";
        finalAddress += user?.city ? `, ${user?.city}` : "";
        finalAddress += user?.state ? `, ${user?.state}` : "";
        finalAddress += user?.county ? `, ${user?.county}` : "";
        finalAddress += user?.zipPostalCode ? `, ${user?.zipPostalCode}` : "";
      }
    });
    return finalAddress;
  };

  getFullAdress = (user) => {
    let finalAddress = "";
    // finalAddress = (user?.title) ? user?.title : "";
    // finalAddress += (user?.firstName) ? user?.firstName : "";
    // finalAddress += (user?.lastName) ? user?.lastName : "";

    finalAddress = user?.address1 ? `${user?.address1}` : "";
    finalAddress += user?.address2 ? `, ${user?.address2}` : "";
    finalAddress += user?.city ? `, ${user?.city}` : "";
    finalAddress += user?.state ? `, ${user?.state}` : "";
    finalAddress += user?.county ? `, ${user?.county}` : "";
    finalAddress += user?.zipPostalCode ? `, ${user?.zipPostalCode}` : "";

    return finalAddress;
  };
  actionPendingUser = (type, user) => {
    if (type == "Approve") {
      this.setState({
        selectedPendingUser: user,
        selctedType: type,
        showPendingUsersPopup: true,
        showPendingUsersText: "Are you sure you want to Approve this User?",
      });
    } else {
      this.setState({
        selectedPendingUser: user,
        selctedType: type,
        showPendingUsersPopup: true,
        showPendingUsersText: "Are you sure you want to Reject this User?",
      });
    }
  };
  clickConformAction = async () => {
    const { selctedType, selectedPendingUser } = this.state;
    this.setState({ showPendingUsersPopup: false });
    if (selctedType == "Approve") {
      const body = {
        customerId: selectedPendingUser?.id,
        isApproved: true,
      };
      const resp = await customerApprovelCall(body);
      if (resp.success) {
        this.setState({
          showPendingUsersPopup: true,
          selctedType: "",
          showPendingUsersText: "You have Approved the User Successfully.",
        });
        this.getAllUsersData();
      } else if (
        resp?.errors?.length > 0 &&
        resp?.errors[0] != undefined &&
        resp?.errors[0] != ""
      ) {
        this.setState({
          showPendingUsersPopup: true,
          selctedType: "",
          showPendingUsersText: resp?.errors[0],
        });
      }
    } else if (selctedType == "Reject") {
      this.setState({ showRejectUserUnputPopup: true });
    }
  };
  clickConformRejectAction = async (comment) => {
    if (comment != "") {
      this.setState({ showRejectUserUnputPopup: false });

      const { selctedType, selectedPendingUser } = this.state;
      const body = {
        customerId: selectedPendingUser?.id,
        isApproved: false,
        description: comment,
      };
      // this.setState({showPendingUsersPopup: true, selctedType : "",showPendingUsersText: "This user has been rejected." });
      const resp = await customerApprovelCall(body);
      if (resp.success) {
        this.setState({
          showPendingUsersPopup: true,
          selctedType: "",
          showPendingUsersText: "This user has been rejected.",
        });
        this.getAllUsersData();
      }
    }
  };
  cancelRejectPopup = () => {
    this.setState({ showRejectUserUnputPopup: false });
    this.setState({
      showPendingUsersPopup: true,
      selctedType: "",
      showPendingUsersText: "This user has not been rejected.",
    });
  };

  eachPageNumberClick(pageno, type) {
    if (type == "approved") {
      this.setState({ approvedcurrentPage: pageno }, () => {
        this.getusersBasedType("approved");
      });
    } else if (type == "pending") {
      this.setState({ pendingcurrentPage: pageno }, () => {
        this.getusersBasedType("pending");
      });
    } else if (type == "rejected") {
      this.setState({ rejectedcurrentPage: pageno }, () => {
        this.getusersBasedType("rejected");
      });
    }
  }
  pageNextBtn(type) {
    if (type == "approved") {
      const a = this.state;
      if (a.approvedcurrentPage + 1 > a.approvedmaxPageNumberLimit) {
        this.setState({
          approvedmaxPageNumberLimit:
            a.approvedmaxPageNumberLimit + a.pageNumberLimit,
        });
        this.setState({
          approvedminPageNumberLimit:
            a.approvedminPageNumberLimit + a.pageNumberLimit,
        });
      }
      this.setState({ approvedcurrentPage: a.approvedcurrentPage + 1 }, () => {
        this.getusersBasedType("approved");
      });
    } else if (type == "pending") {
      const a = this.state;
      if (a.pendingcurrentPage + 1 > a.pendingmaxPageNumberLimit) {
        this.setState({
          pendingmaxPageNumberLimit:
            a.pendingmaxPageNumberLimit + a.pageNumberLimit,
        });
        this.setState({
          pendingminPageNumberLimit:
            a.pendingminPageNumberLimit + a.pageNumberLimit,
        });
      }
      this.setState({ pendingcurrentPage: a.pendingcurrentPage + 1 }, () => {
        this.getusersBasedType("pending");
      });
    } else if (type == "rejected") {
      const a = this.state;
      if (a.rejectedcurrentPage + 1 > a.rejectedmaxPageNumberLimit) {
        this.setState({
          rejectedmaxPageNumberLimit:
            a.rejectedmaxPageNumberLimit + a.pageNumberLimit,
        });
        this.setState({
          rejectedminPageNumberLimit:
            a.rejectedminPageNumberLimit + a.pageNumberLimit,
        });
      }
      this.setState({ rejectedcurrentPage: a.rejectedcurrentPage + 1 }, () => {
        this.getusersBasedType("rejected");
      });
    }
  }
  // pendingmaxPageNumberLimit:5,
  // pendingminPageNumberLimit:0,

  // rejectedmaxPageNumberLimit:5,
  // rejectedminPageNumberLimit:0,
  pagePrevBtn(type) {
    if (type == "approved") {
      const a = this.state;
      if ((a.approvedcurrentPage - 1) % a.pageNumberLimit == 0) {
        this.setState({
          approvedmaxPageNumberLimit:
            a.approvedmaxPageNumberLimit - a.pageNumberLimit,
        });
        this.setState({
          approvedminPageNumberLimit:
            a.approvedminPageNumberLimit - a.pageNumberLimit,
        });
      }
      this.setState({ approvedcurrentPage: a.approvedcurrentPage - 1 }, () => {
        this.getusersBasedType("approved");
      });
    } else if (type == "pending") {
      const a = this.state;
      if ((a.pendingcurrentPage - 1) % a.pageNumberLimit == 0) {
        this.setState({
          pendingmaxPageNumberLimit:
            a.pendingmaxPageNumberLimit - a.pageNumberLimit,
        });
        this.setState({
          pendingminPageNumberLimit:
            a.pendingminPageNumberLimit - a.pageNumberLimit,
        });
      }
      this.setState({ pendingcurrentPage: a.pendingcurrentPage - 1 }, () => {
        this.getusersBasedType("pending");
      });
    } else if (type == "rejected") {
      const a = this.state;
      if ((a.rejectedcurrentPage - 1) % a.pageNumberLimit == 0) {
        this.setState({
          rejectedmaxPageNumberLimit:
            a.rejectedmaxPageNumberLimit - a.pageNumberLimit,
        });
        this.setState({
          rejectedminPageNumberLimit:
            a.rejectedminPageNumberLimit - a.pageNumberLimit,
        });
      }
      this.setState({ rejectedcurrentPage: a.rejectedcurrentPage - 1 }, () => {
        this.getusersBasedType("rejected");
      });
    }
  }
  getusersBasedType = async (pageType) => {
    const {
      usersPerPage,
      approvedcurrentPage,
      pendingcurrentPage,
      rejectedcurrentPage,
    } = this.state;
    if (pageType == "approved") {
      const approved = await getApprovedUsersCall(
        approvedcurrentPage,
        usersPerPage
      );
      let approvedUsers1 = approved?.data || [];
      this.setState({
        showApprovedUsers: approvedUsers1,
        approvedUsers: approvedUsers1,
      });
    } else if (pageType == "pending") {
      const Pending = await getPendingUsersCall(
        pendingcurrentPage,
        usersPerPage
      );
      let pendingUsers1 = Pending?.data || [];
      this.setState({ pendingUsers: pendingUsers1 });
    } else if (pageType == "rejected") {
      const Rejected = await getRejectedUsersCall(
        rejectedcurrentPage,
        usersPerPage
      );
      let rejectedUsers1 = Rejected?.data || [];
      this.setState({ rejectedUsers: rejectedUsers1 });
    }
  };

  render() {
    const {
      image,
      isValid,
      imageLoaderOpen,
      images,
      imageUploaded,
      showAllUsers,
      allUsers,
      usersList,
      purchaseHistory,
      showBlockUserPopup,
      showBlockSuccessUserPopup,
      showUnBlockUserPopup,
      showUnBlockSuccessUserPopup,
      showBlockedUsers,
      selectedData,
      showApprovedUsers,
      approvedUsers,
      pendingUsers,
      rejectedUsers,
      showImagePopup,
      imageUrlForPopup,
      selectedAddress,
      showPendingUsersPopup,
      showRejectUserUnputPopup,
      showPendingUsersText,
      show,
      selctedType,
      usersPerPage,
      totalCount,
      pageNumberLimit,
      approvedmaxPageNumberLimit,
      approvedminPageNumberLimit,

      approvedcurrentPage,
      pendingcurrentPage,
      rejectedcurrentPage,

      approvedTotalCount,
      pendingTotalCount,
      rejectedTotalCount,

      pendingmaxPageNumberLimit,
      pendingminPageNumberLimit,
      rejectedmaxPageNumberLimit,
      rejectedminPageNumberLimit,
      loading,
      eventKeyTypes,
    } = this.state;

    // console.log("approvedUsers,", selectedData?.identityProof)
    // const iconName =
    const approvedpages = [];
    for (let i = 1; i <= Math.ceil(approvedTotalCount / usersPerPage); i++) {
      approvedpages.push(i);
    }
    const approvedrenderPageNumbers = approvedpages.map((number) => {
      if (
        number < approvedmaxPageNumberLimit + 1 &&
        number > approvedminPageNumberLimit
      ) {
        return (
          <li
            key={number}
            id={number}
            onClick={() => {
              this.eachPageNumberClick(number, "approved");
            }}
            className={approvedcurrentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });
    const pendingpages = [];
    for (let i = 1; i <= Math.ceil(pendingTotalCount / usersPerPage); i++) {
      pendingpages.push(i);
    }
    const pendingrenderPageNumbers = pendingpages.map((number) => {
      if (
        number < pendingmaxPageNumberLimit + 1 &&
        number > pendingminPageNumberLimit
      ) {
        return (
          <li
            key={number}
            id={number}
            onClick={() => {
              this.eachPageNumberClick(number, "pending");
            }}
            className={pendingcurrentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });
    const rejectedpages = [];
    for (let i = 1; i <= Math.ceil(rejectedTotalCount / usersPerPage); i++) {
      rejectedpages.push(i);
    }
    const rejectedrenderPageNumbers = rejectedpages.map((number) => {
      if (
        number < rejectedmaxPageNumberLimit + 1 &&
        number > rejectedminPageNumberLimit
      ) {
        return (
          <li
            key={number}
            id={number}
            onClick={() => {
              this.eachPageNumberClick(number, "rejected");
            }}
            className={rejectedcurrentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });

    return (
      <>
        <div className="USerMangement-wrapper">
          {showAllUsers && (
            <div className="userslIstManagement">
              <Row className="umHeadRow">
                <span className="headerName">User Mangement</span>
                {/* <div className="filterByBlock">
                                    <span className="sortByText">Filter by:</span>
                                    <Form.Control as="select" className="filterBySelect" onChange={this.selectedUsersType} >
                                        <option value="all">All</option>
                                        <option value="blocked">Blocked</option>
                                        <option value="unBlocked">Un Blocked</option>
                                    </Form.Control>
                                </div> */}
              </Row>
              <Tab.Container
                id="article-tabs"
                activeKey={eventKeyTypes}
                onSelect={(selectedKey) =>
                  this.setState({ eventKeyTypes: `${selectedKey}` })
                }
              >
                <Row sm="12">
                  <Col sm="12" className="noPadding">
                    <Nav variant="tabs" className="collectionsTabs">
                      <Nav.Item>
                        <Nav.Link eventKey="approved">Approved Users</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="pending">Pending Users</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="rejected">Rejected Users</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm="12" className="noPadding">
                    <Tab.Content>
                      <Tab.Pane eventKey="approved">
                        <Row className="userSearchRow">
                          <SearchComponent placeHolderText="Quick Search" />
                          <div className="filterByBlock">
                            <span className="sortByText">Filter by:</span>
                            <Form.Control
                              as="select"
                              className="filterBySelect"
                              onChange={this.selectedUsersType}
                            >
                              <option value="all">All</option>
                              <option value="blocked">Blocked</option>
                              <option value="unBlocked">Un Blocked</option>
                            </Form.Control>
                          </div>
                        </Row>
                        <div className="Usertable-info">
                          {loading ? (
                            <>
                              <div className="spinner-borderWord">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <table styles="width:100%">
                              <thead>
                                <tr className="headerNamesList">
                                  {/* <th width="10%">Bidding ID</th> */}
                                  <th width="18%">Full name</th>
                                  <th width="20%">Email Address</th>
                                  <th width="17%">Phone Number</th>
                                  <th width="24%">Address</th>
                                  <th width="24%">Uploaded Documents</th>
                                  <th width="24%">Created Date</th>
                                  <th width="6%">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(showApprovedUsers || []).map((user, key) => {
                                  const gmtDateTime = moment.utc(
                                    user?.createdOnUtc,
                                    "YYYY-MM-DD h:mm:ss a"
                                  );
                                  const local_date = gmtDateTime
                                    .local()
                                    .format("MM/DD/YYYY hh:mm A");
                                  // const collTime333 = new Date(
                                  //   local_date
                                  // ).getTime();
                                  const showTIme = local_date;
                                  return (
                                    <>
                                      {" "}
                                      <tr className="RowLevelClass bodyRowTextStyles">
                                        {/* <td onClick={()=>this.selectUserInfo(item)}>#BID100777</td> */}
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {" "}
                                          {user.firstName} {user.lastName}{" "}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {user.email}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {user?.phoneCode ? "+" : ""}
                                          {user?.phoneCode} {user.phoneNumber}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {this.getFullAdress(user?.address)}
                                        </td>
                                        <td>
                                          {user?.identityProof && (
                                            <p className="showUnderLineText">
                                              <span
                                                onClick={() => {
                                                  this.showImagePopup(
                                                    user?.identityProof
                                                  );
                                                }}
                                              >
                                                {" "}
                                                1. Photo ID.jpg{" "}
                                              </span>
                                            </p>
                                          )}
                                          {user?.addressProof && (
                                            <p className="showUnderLineText">
                                              {" "}
                                              <span
                                                onClick={() => {
                                                  this.showImagePopup(
                                                    user?.addressProof
                                                  );
                                                }}
                                              >
                                                {" "}
                                                2. Proff of Address.jpg
                                              </span>
                                            </p>
                                          )}
                                        </td>
                                        <td>
                                          {/* {" "}
                                          {moment(user?.createdOnUtc).format(
                                            "MMMM D, YYYY"
                                          )}
                                          ,{" "}
                                          {moment(
                                            user?.createdOnUtc,
                                            "HHmmss"
                                          ).format("h:mm A")}{" "} */}
                                          {showTIme}
                                        </td>
                                        {user.isBlocked ? (
                                          <td
                                            className="blockIcon"
                                            onClick={() =>
                                              this.unBlockUser(user)
                                            }
                                          >
                                            <RedBlockIcon />
                                          </td>
                                        ) : (
                                          <td
                                            className="blockIcon"
                                            onClick={() => this.blockUser(user)}
                                          >
                                            <BlockIcon />
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                        {!loading && (
                          <Row className="paginationBoxes">
                            <button
                              className="arrowBox"
                              onClick={() => this.pagePrevBtn("approved")}
                              style={{
                                display:
                                  approvedcurrentPage == approvedpages[0]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                            <ul className="pageNumber">
                              {approvedrenderPageNumbers}{" "}
                            </ul>
                            <button
                              className="arrowBox paginationNextBtn"
                              onClick={() => this.pageNextBtn("approved")}
                              style={{
                                display:
                                  approvedcurrentPage ==
                                  approvedpages[approvedpages.length - 1]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                          </Row>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="pending">
                        <Row className="userSearchRow">
                          <SearchComponent placeHolderText="Quick Search" />
                        </Row>
                        <div className="Usertable-info">
                          {loading ? (
                            <>
                              <div className="spinner-borderWord">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <table styles="width:100%">
                              <thead>
                                <tr className="headerNamesList">
                                  {/* <th width="10%">Bidding ID</th> */}
                                  <th width="20%">Full name</th>
                                  <th width="20%">Email Address</th>
                                  <th width="20%">Phone Number</th>
                                  <th width="24%">Address</th>
                                  <th width="24%">Uploaded Documents</th>
                                  <th width="10%">Created Date</th>
                                  <th width="6%">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(pendingUsers || []).map((user, key) => {
                                  const gmtDateTime = moment.utc(
                                    user?.createdOnUtc,
                                    "YYYY-MM-DD h:mm:ss a"
                                  );
                                  const local_date = gmtDateTime
                                    .local()
                                    .format("MM/DD/YYYY hh:mm A");
                                  // const collTime333 = new Date(
                                  //   local_date
                                  // ).getTime();
                                  const showTIme = local_date;

                                  return (
                                    <>
                                      {" "}
                                      <tr className="RowLevelClass bodyRowTextStyles">
                                        {/* <td onClick={()=>this.selectUserInfo(item)}>#BID100777</td> */}
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {" "}
                                          {user.firstName} {user.lastName}{" "}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {user.email}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {" "}
                                          {user?.phoneCode ? "+" : ""}
                                          {user?.phoneCode} {user.phoneNumber}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {this.getFullAdress(user?.address)}
                                        </td>
                                        <td>
                                          {user?.identityProof && (
                                            <p className="showUnderLineText">
                                              <span
                                                onClick={() => {
                                                  this.showImagePopup(
                                                    user?.identityProof
                                                  );
                                                }}
                                              >
                                                1. Photo ID.jpg{" "}
                                              </span>
                                            </p>
                                          )}
                                          {user?.addressProof && (
                                            <p className="showUnderLineText">
                                              {" "}
                                              <span
                                                onClick={() => {
                                                  this.showImagePopup(
                                                    user?.addressProof
                                                  );
                                                }}
                                              >
                                                {" "}
                                                2. Proff of Address.jpg
                                              </span>
                                            </p>
                                          )}
                                        </td>
                                        <td>{showTIme}</td>
                                        <td>
                                          <Dropdown className="actionsDropdown ">
                                            <Dropdown.Toggle>
                                              <HorizontalDots className="dottedLineGrid" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item> </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  this.actionPendingUser(
                                                    "Approve",
                                                    user
                                                  );
                                                }}
                                              >
                                                {" "}
                                                Approve
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  this.actionPendingUser(
                                                    "Reject",
                                                    user
                                                  );
                                                }}
                                              >
                                                {" "}
                                                Reject
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                        {!loading && (
                          <Row className="paginationBoxes">
                            <button
                              className="arrowBox"
                              onClick={() => this.pagePrevBtn("pending")}
                              style={{
                                display:
                                  pendingcurrentPage == pendingpages[0]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                            <ul className="pageNumber">
                              {pendingrenderPageNumbers}{" "}
                            </ul>
                            <button
                              className="arrowBox paginationNextBtn"
                              onClick={() => this.pageNextBtn("pending")}
                              style={{
                                display:
                                  pendingcurrentPage ==
                                  pendingpages[pendingpages.length - 1]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                          </Row>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="rejected">
                        <Row className="userSearchRow">
                          <SearchComponent placeHolderText="Quick Search" />
                        </Row>
                        <div className="Usertable-info">
                          {loading ? (
                            <>
                              <div className="spinner-borderWord">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <table styles="width:100%">
                              <thead>
                                <tr className="headerNamesList">
                                  {/* <th width="10%">Bidding ID</th> */}
                                  <th width="20%">Full name</th>
                                  <th width="20%">Email Address</th>
                                  <th width="20%">Phone Number</th>
                                  <th width="24%">Address</th>
                                  <th width="24%">Uploaded Documents</th>
                                  <th width="10%">Created Date</th>
                                  <th width="6%">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(rejectedUsers || []).map((user, key) => {
                                  // console.log("UsEr", user)
                                  const gmtDateTime = moment.utc(
                                    user?.createdOnUtc,
                                    "YYYY-MM-DD h:mm:ss a"
                                  );
                                  const local_date = gmtDateTime
                                    .local()
                                    .format("MM/DD/YYYY hh:mm A");
                                  // const collTime333 = new Date(
                                  //   local_date
                                  // ).getTime();
                                  const showTIme = local_date;

                                  return (
                                    <>
                                      {" "}
                                      <tr className="RowLevelClass bodyRowTextStyles">
                                        {/* <td onClick={()=>this.selectUserInfo(item)}>#BID100777</td> */}
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {" "}
                                          {user.firstName} {user.lastName}{" "}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {user.email}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {user?.phoneCode ? "+" : ""}
                                          {user?.phoneCode} {user.phoneNumber}{" "}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.selectUserInfo(user)
                                          }
                                        >
                                          {this.getFullAdress(user?.address)}
                                        </td>
                                        <td>
                                          {user?.identityProof && (
                                            <p className="showUnderLineText">
                                              <span
                                                onClick={() => {
                                                  this.showImagePopup(
                                                    user?.identityProof
                                                  );
                                                }}
                                              >
                                                {" "}
                                                1. Photo ID.jpg{" "}
                                              </span>
                                            </p>
                                          )}
                                          {user?.addressProof && (
                                            <p className="showUnderLineText">
                                              {" "}
                                              <span
                                                onClick={() => {
                                                  this.showImagePopup(
                                                    user?.addressProof
                                                  );
                                                }}
                                              >
                                                {" "}
                                                2. Proff of Address.jpg
                                              </span>
                                            </p>
                                          )}
                                        </td>
                                        <td>{showTIme}</td>
                                        <td>
                                          <Dropdown className="actionsDropdown ">
                                            <Dropdown.Toggle>
                                              <HorizontalDots className="dottedLineGrid" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item> </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  this.actionPendingUser(
                                                    "Approve",
                                                    user
                                                  );
                                                }}
                                              >
                                                {" "}
                                                Approve
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                        {!loading && (
                          <Row className="paginationBoxes">
                            <button
                              className="arrowBox"
                              onClick={() => this.pagePrevBtn("rejected")}
                              style={{
                                display:
                                  rejectedcurrentPage == rejectedpages[0]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                            <ul className="pageNumber">
                              {rejectedrenderPageNumbers}{" "}
                            </ul>
                            <button
                              className="arrowBox paginationNextBtn"
                              onClick={() => this.pageNextBtn("rejected")}
                              style={{
                                display:
                                  rejectedcurrentPage ==
                                  rejectedpages[rejectedpages.length - 1]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                          </Row>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          )}
          {!showAllUsers && (
            <div className="basicInfoManagement">
              <Row className="backheaderName">
                {" "}
                <span
                  onClick={() => {
                    this.setState({ showAllUsers: true });
                  }}
                >
                  <ChevronLeft className="umChevronLeft" /> User Mangement{" "}
                </span>{" "}
              </Row>
              <div className="centerfullScreen">
                <div className="centerText">
                  <p className="backheaderName1">Basic Information</p>
                  <div className="basicUserInfoRoles">
                    <div className="avatorIcon">
                      {/* <img className='userImage' src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"></img> */}
                      <div className="avatarImage">
                        {selectedData?.firstName.slice(0, 1)}
                      </div>
                      <p className="nameCapitalize">
                        {selectedData?.firstName} {selectedData?.lastName}
                      </p>
                    </div>
                    <div className="infoList card-columns1">
                      <div>
                        <div>
                          <span> Email Address</span>
                        </div>
                        <div>
                          <span> {selectedData?.email}</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span> Mobile</span>
                        </div>
                        <div>
                          <span>
                            {" "}
                            {selectedData.phoneNumber ? (
                              <>
                                {" "}
                                {selectedData?.phoneCode ? "+" : ""}
                                {selectedData?.phoneCode}{" "}
                                {selectedData.phoneNumber}{" "}
                              </>
                            ) : (
                              "XXXXXXXXXXXXX"
                            )}{" "}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span> Primary Address</span>
                        </div>
                        <div>
                          <span> {this.getPrimaryAdress(selectedAddress)}</span>
                        </div>
                      </div>
                    </div>
                    <Row className="infoList card-columns1">
                      {(selectedAddress || []).map((item, index) => {
                        return (
                          <>
                            {!item?.isPrimary && (
                              <div>
                                <div>
                                  <span> Address {index + 1}</span>
                                </div>
                                <div>
                                  <span> {this.getFullAdress(item)}</span>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </Row>
                    <div className="infoList card-columns1">
                      <div>
                        <div>
                          <span> Photo ID</span>
                        </div>
                        <div className="showUnderLine">
                          <span
                            onClick={() => {
                              this.showImagePopup(selectedData?.identityProof);
                            }}
                          >
                            {" "}
                            {
                              selectedData?.identityProof?.split("_")[
                                selectedData?.identityProof?.split("_").length -
                                  1
                              ]
                            }{" "}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span> Proof of Address</span>
                        </div>
                        <div className="showUnderLine">
                          <span
                            onClick={() => {
                              this.showImagePopup(selectedData?.addressProof);
                            }}
                          >
                            {" "}
                            {
                              selectedData?.addressProof?.split("_")[
                                selectedData?.addressProof?.split("_").length -
                                  1
                              ]
                            }{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="historyList">
                <div className="History-info">
                  <h2 className="PurchaseHistory">Purchase History</h2>
                  <table styles="width:100%">
                    <thead>
                      <tr className="headerNamesList">
                        <th>Order ID</th>
                        <th width="15%">Purchase Date</th>
                        <th width="16%">Auction name</th>
                        <th width="16%">Lot name</th>
                        <th width="15%">Amount</th>
                        <th width="13%">Payment Status</th>
                        <th width="20%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(purchaseHistory || []).map((itemvalue, key) => {
                        return (
                          <>
                            <tr>
                              <td
                                onClick={() =>
                                  this.purchaseDetailsInfo(itemvalue?.orderId)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                #{itemvalue?.orderNo}
                              </td>
                              <td>
                                {moment(itemvalue?.orderDate).format(
                                  "DD-MM-YY"
                                )}
                              </td>
                              <td>{itemvalue?.collectionTitle}</td>
                              <td>{itemvalue?.collectionItemTitle}</td>
                              <td>
                                {itemvalue?.amount} {itemvalue?.currency}
                              </td>
                              <td>{itemvalue?.paymentStatus}</td>
                              <td>
                                {" "}
                                {itemvalue?.paymentStatus == "Paid" ? (
                                  <Button className="InvoiceDownload">
                                    Download Invoice
                                  </Button>
                                ) : (
                                  <Button className="paymentReminderBtn">
                                    Send Payment Reminder
                                  </Button>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <CustomModal
            show={showBlockUserPopup}
            titleText="Are you sure you want to block the user?"
            deleteText="Yes"
            cancelText="No"
            onCancel={() => this.hideModel(false)}
            onDelete={() => this.blockUserConform()}
          />
          <CustomModal
            show={showBlockSuccessUserPopup}
            titleText="You have blocked user Successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => this.hideModel(false)}
            onDelete={() => this.hideModel(false)}
          />
          <CustomModal
            show={showUnBlockUserPopup}
            titleText="Are you sure you want to Unblock the user?"
            deleteText="Yes"
            cancelText="No"
            onCancel={() => this.hideModel(false)}
            onDelete={() => this.unBlockUserConform()}
          />
          <CustomModal
            show={showUnBlockSuccessUserPopup}
            titleText="You have Unblocked user Successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => this.hideModel(false)}
            onDelete={() => this.hideModel(false)}
          />
          <CustomModal
            show={showPendingUsersPopup}
            titleText={showPendingUsersText}
            deleteText={selctedType != "" ? selctedType : "OK"}
            cancelText={selctedType != "" ? "Cancel" : ""}
            onCancel={() => this.hideModel(false)}
            onDelete={() => this.clickConformAction()}
          />

          <UserRejectPopup
            show={showRejectUserUnputPopup}
            onCancel={() => {
              this.cancelRejectPopup();
            }}
            onDelete={this.clickConformRejectAction}
          />

          <ImagePopup
            show={showImagePopup}
            imageUrlForPopup={imageUrlForPopup}
            onCancel={() => {
              this.setState({ showImagePopup: false });
            }}
          />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      MyAccount,
    },
    dispatch
  );

export default MyAccount;
