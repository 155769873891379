import React, { useState, useEffect } from "react";
import { StyledCollectionLotStatus } from "./CollectionLotStatus.styled";
import fahh1 from "./../../assets/fahh1.png";
import { useSelector } from "react-redux";
import social_icon from "./../../assets/coming-soon/social_icon.png";
import Footer_icon from "./../../assets/coming-soon/footer_icon.svg";
import social_icon1 from "./../../assets/coming-soon/social_icon1.png";
import social_icon2 from "./../../assets/coming-soon/social_icon2.png";
import social_icon3 from "./../../assets/coming-soon/social_icon3.png";
import FAHH_logo_color_2 from "./../../assets/coming-soon/FAHH_logo_color_2.svg";
import Rock_the_Bells1 from "./../../assets/coming-soon/Rock_the_Bells1.svg";
// import { triggerMixpanelEvent } from "../../Scenes/common";
import Fahh_coming_soon_12 from "./../../assets/coming-soon/fahh_coming_soon_12.svg";
import { Modal, Button } from "react-bootstrap";
import * as signalR from "@microsoft/signalr";
import { SERVER_IP } from "./../../library/constants.js";

import {
  getCollectionItemsCall,
  getCollectionByIdCall,
  getcurrenciesCall,
  getTimeZones,
} from "./../../library/Requests";
import moment from "moment";
import LotBox from "./LotBox";
import Loader from "./../../components/Common/Loader";
const CollectionLotStatus = (props: any) => {
  const [collectionData, setCollectionData] = useState({} as any);
  const [collectionItems, setCollectionItems] = useState([]);
  const [timezonesList, setTimezonesList] = useState([] as any);
  const [currenciesList, setCurrenciesList] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const id = props?.match?.params?.id;
    getCollectionData();
    getStaticData();
  }, []);

  useEffect(() => {
    // if (myLot1?.id !== myLot?.id && myLot?.id) {
    // getTimeDefault();

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .withAutomaticReconnect()
      .build();
    connection.on("BidInsertSignal", (response) => {});
    connection
      .start()
      .then((result) => {
        connection.on("BidInsertSignal", (response) => {
          getCollectionData();
          console.log("signal responseresponse", response);
        });
      })
      .catch((err) => console.error(err.toString()));
  }, []);
  const getStaticData = async () => {
    const timezoneResp = await getTimeZones();
    setTimezonesList(timezoneResp?.data);
    const resp3 = await getcurrenciesCall();
    setCurrenciesList(resp3?.data);
  };
  const getCollectionData = async () => {
    setIsLoading(true);
    const id = props?.match?.params?.id;
    let resp = await getCollectionByIdCall(id);
    setCollectionData(resp?.data);

    const body = {
      page: 1,
      pageSize: 200,
      getOnlyTotalCount: false,
      collectionId: id,
      sortColumn: "Sno",
      sortDirection: "asc",
      status: [1, 6, 7],
    };
    let resp1 = await getCollectionItemsCall(body);

    setCollectionItems(resp1?.data);
    setIsLoading(false);
  };
  let bannerTimeZone = timezonesList?.find(
    (i: any) => collectionData?.timeZoneId == i?.id
  )?.abbrivation;
  let currencyName = currenciesList?.find(
    (i: any) => collectionData?.currency == i?.id
  )?.currencyCode;
  var userDetails = {} as any;
  // const userD = localStorage.getItem("customerInfoDetails");
  // if (userD != undefined && userD != null && userD != "") {
  //   userDetails = JSON.parse(userD);
  // }
  const getTotalBids = () => {
    var totalC = 0;
    collectionItems.map((ittM: any) => {
      totalC = totalC + ittM?.bidsCount;
    });
    return totalC;
  };
  const getLotsMetRPrice = () => {
    var totalC = 0;
    collectionItems.map((ittM: any) => {
      if (ittM?.lotMetReservice) {
        totalC++;
      }
    });
    return totalC;
  };
  const getLotsSold = () => {
    var totalC = 0;
    collectionItems.map((ittM: any) => {
      if (ittM?.status === 6) {
        totalC++;
      }
    });
    return totalC;
  };
  const getLotsPlacedBids = () => {
    var totalC = 0;
    collectionItems.map((ittM: any) => {
      if (ittM?.bidsCount > 0) {
        totalC++;
      }
    });
    return totalC;
  };
  console.log("collectionData", collectionData);
  console.log("collectionItems", collectionItems);
  return (
    <StyledCollectionLotStatus>
      <div className="coll-lot-details-view-sec">
        {isLoading && <Loader />}
        <div className="coll-lot-details-view-sec-top">
          {collectionData?.title && (
            <>
              <div>
                <p>
                  <span>Title:</span> <span>{collectionData?.title}</span>
                </p>

                {/* <p>
                  <span>Total Bids:</span> <span>{getTotalBids()}</span>
                </p> */}
                <p>
                  <span>Total Lots:</span>{" "}
                  <span>{collectionItems?.length}</span>
                </p>
                <p>
                  <span>Total Lots Placed Bids:</span>{" "}
                  <span>{getLotsPlacedBids()}</span>
                </p>
                <p>
                  <span>Total Lots Met Reserved Price:</span>{" "}
                  <span>{getLotsMetRPrice()}</span>
                </p>

                <p>
                  <span>Total Lots Sold:</span> <span>{getLotsSold()}</span>
                </p>
              </div>
            </>
          )}
          <h3>{collectionData?.title}</h3>
        </div>
        <div className="all-lots-sec-content-sec grid-view-all">
          {collectionItems.map((myLot: any) => {
            return (
              <>
                <LotBox
                  myLot={myLot}
                  bannerTimeZone={bannerTimeZone}
                  currencyName={currencyName}
                  userDetails={userDetails}
                  collectionData={collectionData}
                />
              </>
            );
          })}
        </div>
      </div>
    </StyledCollectionLotStatus>
  );
};
export default CollectionLotStatus;
