import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ReactComponent as DashboardIcon } from "./../../assets/Dashboard.svg";
import { ReactComponent as DashboardWhiteIcon } from "./../../assets/dashboardWhite.svg";
import { ReactComponent as LotIcon } from "./../../assets/Lot.svg";
import { ReactComponent as LotWhiteIcon } from "./../../assets/LotWhite.svg";
import { ReactComponent as HammerIcon } from "./../../assets/Hammer.svg";
import { ReactComponent as HammerWhiteIcon } from "./../../assets/hammerWhite.svg";
import { ReactComponent as UserManagementIcon } from "./../../assets/userManagement.svg";
import { ReactComponent as UserWhiteIcon } from "./../../assets/userWhite.svg";
import { ReactComponent as RoleManagementIcon } from "./../../assets/RoleManagement.svg";
import { ReactComponent as RoleWhiteIcon } from "./../../assets/roleWhite.svg";
import { ReactComponent as ReportsIcon } from "./../../assets/Reports.svg";
import { ReactComponent as ReportsWhiteIcon } from "./../../assets/reportsWhite.svg";
import { ReactComponent as ContentManagement } from "./../../assets/contentManagement.svg";
import { ReactComponent as ContentWhiteIcon } from "./../../assets/contentWhite.svg";
import { ReactComponent as SiteManagerIcon } from "./../../assets/siteManager.svg";
import { ReactComponent as SiteManagerWhiteIcon } from "./../../assets/siteManagerWhite.svg";
import { ReactComponent as OrderManagementIcon } from "./../../assets/orderManagement.svg";
import { ReactComponent as OrderManagementWhiteIcon } from "./../../assets/orderManagementWhite.svg";
import Collections from "./../../pages/Collections";
import "./styles.css";

const AdminTabs = (props) => {
  const { match, history } = props;
  // console.log("Admin Tabs Props: ", props);
  const [selectedTab, setSelectedTab] = useState({ key: "collections" });
  const [userSelectedTab, setUserSelectedTab] = useState("");

  const [mouseClick, setMouseClick] = useState(false);

  useEffect(async () => {
    checkCurrentTab();
  }, []);

  const checkCurrentTab = () => {
    const PName = props?.location?.pathname;
    console.log(PName);
    if (PName == "/dashboard/dashboard") {
      setUserSelectedTab("dashboard");
    } else if (
      PName == "/dashboard/collections" ||
      PName == "/dashboard/createlot" ||
      PName == "/dashboard/createCollection" ||
      PName.indexOf("dashboard/collection") > 0 ||
      PName.indexOf("dashboard/previewLot") > 0 ||
      PName.indexOf("dashboard/editCollection") > 0 ||
      PName.indexOf("dashboard/editLot") > 0 ||
      PName.indexOf("dashboard/viewLot") > 0 ||
      PName.indexOf("dashboard/liveAuctionLanding") > 0
    ) {
      setUserSelectedTab("collections");
    } else if (
      PName == "/dashboard/auctionManagement" ||
      PName.indexOf("auctionManagement") > 0 ||
      PName == "/dashboard/auctionManagement"
    ) {
      setUserSelectedTab("auctionManagement");
    } else if (
      PName == "/dashboard/user" ||
      PName == "/dashboard/user" ||
      PName.indexOf("dashboard/user") > 0 ||
      PName.indexOf("dashboard/orderDetails") > 0
    ) {
      setUserSelectedTab("user");
    } else if (
      PName == "/dashboard/roles" ||
      PName == "/dashboard/createNewRole"
    ) {
      setUserSelectedTab("roles");
    } else if (PName == "/dashboard/reports") {
      setUserSelectedTab("reports");
    } else if (
      PName == "/dashboard/contentManagement" ||
      PName == "/dashboard/createArticle" ||
      PName.indexOf("dashboard/createArticle") > 0 ||
      PName.indexOf("dashboard/editArticle") > 0 ||
      PName.indexOf("dashboard/viewArticle") > 0 ||
      PName.indexOf("dashboard/previewArticle") > 0
    ) {
      setUserSelectedTab("contentManagement");
    } else if (
      PName == "/dashboard/siteManager" ||
      PName == "/dashboard/heroSectionManager" ||
      PName == "/dashboard/wordSectionManager"
    ) {
      setUserSelectedTab("siteManager");
    } else if (PName == "/dashboard/orderManagement") {
      setUserSelectedTab("orderManagement");
    }
  };

  const handleChange = (event, newValue) => {
    let pageName = event;
    if (pageName.indexOf("Selected") > 0) {
      pageName = pageName.replace("Selected", "");
    }
    setUserSelectedTab(pageName);
    props.history.push(`/dashboard/${pageName}`);
    // setSelectedTab(`${event}`);
  };

  return (
    <div className="tabsContainer">
      <input type="hidden" value={userSelectedTab} id="selectedTabName" />
      <Tabs
        defaultActiveKey="dashboard"
        class="tabsList"
        id="main-tab"
        activeKey="collections"
        onSelect={handleChange}
      >
        {userSelectedTab == "dashboard" ? (
          <Tab
            eventKey="dashboardSelected"
            title={
              <>
                <span className="tabIcon">
                  <DashboardWhiteIcon />
                </span>
                <span className="tabIconText">Dashboard</span>
              </>
            }
          ></Tab>
        ) : (
          <Tab eventKey="dashboard" title={<DashboardIcon />}></Tab>
        )}

        {userSelectedTab == "collections" ? (
          <Tab
            eventKey="collectionsSelected"
            title={
              <>
                <span className="tabIcon">
                  <LotWhiteIcon />
                </span>
                <span className="tabIconText">Lot Management</span>
              </>
            }
          ></Tab>
        ) : (
          <Tab eventKey="collections" title={<LotIcon />}></Tab>
        )}

        {userSelectedTab == "auctionManagement" ? (
          <Tab
            eventKey="auctionManagementSelected"
            title={
              <>
                <span className="tabIcon">
                  <HammerWhiteIcon />
                </span>
                <span className="tabIconText">Auction Management</span>
              </>
            }
          ></Tab>
        ) : (
          <Tab eventKey="auctionManagement" title={<HammerIcon />}></Tab>
        )}
        {userSelectedTab == "user" ? (
          <Tab
            eventKey="userSelected"
            title={
              <>
                <span className="tabIcon">
                  <UserWhiteIcon />
                </span>
                <span className="tabIconText">User Management</span>
              </>
            }
          ></Tab>
        ) : (
          <Tab eventKey="user" title={<UserManagementIcon />}></Tab>
        )}

        {userSelectedTab == "roles" ? (
          <Tab
            eventKey="rolesSelected"
            title={
              <>
                <span className="tabIcon">
                  <RoleWhiteIcon />
                </span>
                <span className="tabIconText">Role Management</span>
              </>
            }
          ></Tab>
        ) : (
          <Tab eventKey="roles" title={<RoleManagementIcon />}></Tab>
        )}
        {userSelectedTab == "reports" ? (
          <Tab
            eventKey="reportsSelected"
            title={
              <>
                <span className="tabIcon">
                  <ReportsWhiteIcon />
                </span>
                <span className="tabIconText">Reports</span>
              </>
            }
          ></Tab>
        ) : (
          <Tab eventKey="reports" title={<ReportsIcon />}></Tab>
        )}
        {userSelectedTab == "contentManagement" ? (
          <Tab
            eventKey="contentManagementSelected"
            title={
              <>
                <span className="tabIcon">
                  <ContentWhiteIcon />
                </span>
                <span className="tabIconText">Content Management</span>
              </>
            }
          ></Tab>
        ) : (
          <Tab eventKey="contentManagement" title={<ContentManagement />}></Tab>
        )}
        {userSelectedTab == "siteManager" ? (
          <Tab
            eventKey="siteManagerSelected"
            title={
              <>
                <span className="tabIcon">
                  <SiteManagerWhiteIcon />
                </span>
                <span className="tabIconText">Site Manager</span>
              </>
            }
          ></Tab>
        ) : (
          <Tab eventKey="siteManager" title={<SiteManagerIcon />}></Tab>
        )}
        {userSelectedTab == "orderManagement" ? (
          <Tab
            eventKey="orderManagementSelected"
            title={
              <>
                <span className="tabIcon">
                  <OrderManagementWhiteIcon />
                </span>
                <span className="tabIconText">Order Management</span>
              </>
            }
          ></Tab>
        ) : (
          <Tab eventKey="orderManagement" title={<OrderManagementIcon />}></Tab>
        )}
      </Tabs>
      {/* {selectedTab === "collections" && <Collections />} */}
    </div>
  );
};

export default withRouter(AdminTabs);
