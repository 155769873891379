import Dashboard from "./pages/Dashboard";
import Collections from "./pages/Collections";
import CreateCollection from "./pages/CreateCollection";
import AuctionManagement from "./pages/AuctionManagement";
import AuctionLots from "./pages/AuctionLots";
import ActiveAuctions from "./pages/ActiveAuctions";
// import PastAuctions from "./pages/PastAuctions";
import Lots from "./pages/Lots";
import CreateLot from "./pages/CreateLot";
import CreateArticle from "./pages/CreateArticle";
import PreviewArticle from "./pages/previewArticle";
import CreateNewRole from "./pages/CreateNewRole";
import ViewLot from "./pages/ViewLot";
import PreviewLotPage from "./pages/PreviewLotPage";
import previewSingleLotPage from "./pages/previewSingleLotPage";
import userManagement from "./pages/userManagement";
import PurchaseDetails from "./pages/purchaseDetails";
import UserProfile from "./pages/UserProfile";
import rolesManagement from "./pages/rolesManagement";
import RoleInformation from "./pages/RoleInformation";
import ContentManagement from "./pages/ContentManagement";
import SiteManager from "./pages/SiteManager";
import OrderManagement from "./pages/OrderManagement";
import OrderDetailsView from "./pages/OrderDetailsView";
import Reports from "./pages/Reports";
import NewFeatures from "./pages/NewFeatures";
import HeroSectionManager from "./pages/HeroSectionManager";
import WordSectionManager from "./pages/WordSectionManager";
import LoginPage from "./pages/LoginPage";
import uploadImage from "./pages/uploadImage";
import liveAuctionLanding from "./pages/liveAuctionLanding";
import Auction from "./pages/Auction";
import ForgotPassword from "./pages/ForgotPassword";
import CollectionLotStatus from "./pages/CollectionLotStatus/CollectionLotStatus";
export default [
  {
    path: "/admin/auction",
    component: Auction,
    exact: true,
  },
  {
    path: "/dashboard/dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/dashboard/collections",
    component: Collections,
    exact: true,
  },
  {
    path: "/dashboard/auctionManagement",
    component: AuctionManagement,
    exact: true,
  },
  {
    path: "/dashboard/auctionManagement/AuctionLots/:id",
    component: AuctionLots,
    exact: true,
  },
  {
    path: "/dashboard/auctionManagement/collectionLotStatus/:id",
    component: CollectionLotStatus,
    exact: true,
  },
  {
    path: "/dashboard/auctionManagement/activeAuctions",
    component: ActiveAuctions,
    exact: true,
  },
  {
    path: "/dashboard/collection/:id",
    component: Lots,
    exact: true,
  },
  {
    path: "/dashboard/lots",
    component: Lots,
    exact: true,
  },
  {
    path: "/dashboard/user",
    component: userManagement,
    exact: true,
  },
  {
    path: "/dashboard/user/:id",
    component: userManagement,
    exact: true,
  },
  {
    path: "/dashboard/orderDetails/:id",
    component: PurchaseDetails,
    exact: true,
  },
  {
    path: "/dashboard/userProfile",
    component: UserProfile,
    exact: true,
  },
  {
    path: "/dashboard/roles",
    component: rolesManagement,
    exact: true,
  },
  {
    path: "/dashboard/roleInfo",
    component: RoleInformation,
    exact: true,
  },
  {
    path: "/dashboard/contentManagement",
    component: ContentManagement,
    exact: true,
  },
  {
    path: "/dashboard/reports",
    component: Reports,
    exact: true,
  },
  {
    path: "/dashboard/siteManager",
    component: SiteManager,
    exact: true,
  },
  {
    path: "/dashboard/orderManagement",
    component: OrderManagement,
    exact: true,
  },
  {
    path: "/dashboard/orderDetailsView",
    component: OrderDetailsView,
    exact: true,
  },
  {
    path: "/dashboard/createCollection",
    component: CreateCollection,
    exact: true,
  },
  {
    path: "/dashboard/editCollection/:id",
    component: CreateCollection,
    exact: true,
  },
  {
    path: "/dashboard/createLot",
    component: CreateLot,
    exact: true,
  },
  {
    path: "/dashboard/editLot/:id",
    component: CreateLot,
    exact: true,
  },
  {
    path: "/dashboard/createArticle",
    component: CreateArticle,
    exact: true,
  },
  {
    path: "/dashboard/createNewRole",
    component: CreateNewRole,
    exact: true,
  },
  {
    path: "/dashboard/previewArticle/:id",
    component: PreviewArticle,
    exact: true,
  },
  {
    path: "/dashboard/viewArticle/:id",
    component: PreviewArticle,
    exact: true,
  },
  {
    path: "/dashboard/auctionManagement/viewLot/:id/:lotNumber",
    component: ViewLot,
    exact: true,
  },
  {
    path: "/dashboard/viewLot/:id/:lotNumber",
    component: ViewLot,
    exact: true,
  },
  {
    path: "/dashboard/previewLot/:id",
    component: PreviewLotPage,
    exact: true,
  },
  {
    path: "/dashboard/previewSingleLot/:id",
    component: previewSingleLotPage,
    exact: true,
  },

  {
    path: "/dashboard/heroSectionManager",
    component: HeroSectionManager,
    exact: true,
  },
  {
    path: "/dashboard/wordSectionManager",
    component: WordSectionManager,
    exact: true,
  },
  {
    path: "/dashboard/new",
    component: NewFeatures,
    exact: true,
  },
  {
    path: "/login",
    component: LoginPage,
    exact: true,
  },
  {
    path: "/",
    component: LoginPage,
    exact: true,
  },
  {
    path: "/uploadImage",
    component: uploadImage,
    exact: true,
  },
  {
    path: "/dashboard/liveAuctionLanding/:id",
    component: liveAuctionLanding,
    exact: true,
  },
  {
    path: "/dashboard/googleMapPage",
    component: liveAuctionLanding,
    exact: true,
  },
  {
    path: "/dashboard/editArticle/:id",
    component: CreateArticle,
    exact: true,
  },

  {
    path: "/dashboard/forgotPassword",
    component: ForgotPassword,
    exact: true,
  },
];
