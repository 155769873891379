import React from "react";

// import LiveActionSlider from "../LiveActionSlider";
// import LiveActionTabMenu from "../LiveActionTabMenu";
// import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";

import WordBanner from "./../../assets/image/word_banner.jpg";
import slide1 from "./../../assets/image/slide1.jpg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { gethighestbid } from "./../../library/Requests/index";

import "./css/LiveActionBoxTwo.css";
import { Button } from "react-bootstrap";

class LiveActionBoxTwo extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      bidDetails: {},
      openLeftSideBar: false,
    };
  }
  createMarkup = (data: any) => {
    return { __html: data };
  };

  componentDidMount = async () => {
    const id = this.props?.lotData?.id;
  };
  render() {
    const ListItems = this.props?.collectionItems;
    const collectionData = this.props?.collectionData;
    const BDetails = this.props?.highestBidDetails;
    const highestBidDetailsFull = this.props?.highestBidDetailsFull;
    const lotData = this.props?.lotData;
    console.log("lotData_:", lotData);
    let currencyName = this.props?.currenciesList.find(
      (i: any) => collectionData?.currency == i?.id
    )?.currencyCode;
    let imageL = lotData?.itemImages;
    // var codes: ImageList[] = []
    //   class ImageList {
    //     async save() { }
    //   }
    let imageList: any = [];
    imageL?.map((image: any, index: any) => {
      const imageP = image?.vurtualPath;
      let imageT = image?.vurtualPath;
      if (image.displayOrder == 8) {
      } else {
        var temp = { original: imageP, thumbnail: imageT };
        imageList.push(temp);
      }
    });

    console.log("BDetails", BDetails);
    console.log("lotData?.itemStatus", lotData);
    console.log("lotData?.itemStatus", lotData?.itemStatus);
    const disableNextbtn =
      lotData?.itemStatus === "Sold" || lotData?.itemStatus === "Unsold"
        ? false
        : true;

    return (
      <>
        <div className="auction_box_2">
          {!this.props?.showEndAuction ? (
            <div className="auction_banner_for_main">
              <div className="image_gallery_slider live_action_slider">
                <ImageGallery
                  items={imageList}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  //   showNav={false}
                />
              </div>
              <div className="auction_banner_cont">
                <div className="auction_banner_cont_top">
                  <h3>{lotData?.title}</h3>
                  <div className="priceWithBtn">
                    <div className="priceColumnLeft">
                      <div className="lots_cost inlineDiv">
                        <span className="paddingRightStyle">
                          Estimate Price
                        </span>
                        <span className="strong">
                          {currencyName} {lotData?.estimatedPrice}
                          {lotData?.estimatedPriceTo && (
                            <>
                              - {currencyName} {lotData?.estimatedPriceTo}
                            </>
                          )}
                        </span>
                      </div>
                      <div className="lots_cost inlineDiv">
                        <span className="paddingRightStyle">
                          Initial Bidding Price
                        </span>
                        <span className="strong">
                          {currencyName} {lotData?.initialBiddingPrice}
                        </span>
                      </div>
                    </div>
                    {!this.props?.isLastLot && (
                      <Button
                        disabled={disableNextbtn}
                        onClick={() => {
                          this.props?.moveNextLot();
                        }}
                        className="nextLotBtn"
                        variant="custom"
                      >
                        Next Lot
                      </Button>
                    )}
                  </div>
                </div>
                <div className="currentBidBox">
                  <span className="currentBidHeading">Current Bid</span>
                  <div className="biddingDetailsLine">
                    <span>Bidding ID: #{BDetails?.registrationNo}</span>
                    <span>
                      Bidder name: {highestBidDetailsFull?.firstName}{" "}
                      {highestBidDetailsFull?.lastName}
                    </span>
                    <span>Bidding Mode: {BDetails?.type}</span>
                  </div>
                  <p className="currentBidAmt">
                    {BDetails?.amount} {currencyName}
                  </p>
                  <span className="liveButtonsSection">
                    {/* <Button className="liveCloseLotBtn" variant="custom">Close Lot</Button> */}
                    {lotData?.itemStatus != "Sold" &&
                      !this.props?.BiddingLodding &&
                      lotData?.itemStatus != "Unsold" && (
                        <>
                          {BDetails?.metResevedPrice ? (
                            <Button
                              onClick={() => {
                                this.props?.chooseWinner();
                              }}
                              className="liveChooseWinnerBtn"
                              variant="custom"
                            >
                              Choose As Winner
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                this.props?.closeLot();
                              }}
                              className="liveChooseWinnerBtn"
                              variant="custom"
                            >
                              close Lot
                            </Button>
                          )}
                        </>
                      )}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="showEndAuctionSection">
              <div className="endAuctionBox">
                <p>Auction is Completed</p>
                <Button
                  onClick={() => {
                    this.props?.endAuctionClick();
                  }}
                  className="endAuctionBtn"
                  variant="custom"
                >
                  End Auction
                </Button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
export default LiveActionBoxTwo;
