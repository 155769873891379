import React, { Component } from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import "./styles.css";
import moment from "moment";

class orderDetails extends Component {
  static propTypes = {};
  state = {};
  componentDidMount = () => {};
  render() {
    const { orderDetails, selectedOrder } = this.props;
    const orderedBids = orderDetails?.orderedBids ?? [];
    return (
      <div className="orderDetailsContainer">
        <Table bordered className="orderDetailsTable">
          <thead>
            <tr>
              <th colspan="5" className="orderDetailsHeading">
                Order Details: #{selectedOrder?.orderNo}{" "}
              </th>
            </tr>
            <tr className="orderDetailsHeadingRow">
              <th style={{ width: "20%" }}>Date</th>
              <th style={{ width: "20%" }}>Auction Name</th>
              <th style={{ width: "20%" }}>Lot Name</th>
              <th style={{ width: "20%" }}>Payment Status</th>
              <th style={{ width: "20%" }}>Price</th>
            </tr>
          </thead>
          <tbody>
            {(orderedBids || []).map((item, index) => {
              return (
                <tr>
                  <td style={{ width: "20%" }}>
                    {moment(item?.orderDate).format("DD MMMM, YYYY")}{" "}
                  </td>
                  <td style={{ width: "20%" }}>{item?.collectionTitle}</td>
                  <td style={{ width: "20%" }}>{item?.collectionItemTitle}</td>
                  <td style={{ width: "20%" }}>
                    {orderDetails?.paymentStatus}
                  </td>
                  <td style={{ width: "20%" }}>
                    {item?.amount} {item?.currency}
                  </td>
                </tr>
              );
            })}
            {/* <tr>
                    </tr> */}
          </tbody>
        </Table>
        <Table bordered className="orderDetailsTable top">
          <td colspan="">
            <Button className="orderDownloadInvoice">Download Invoice</Button>
          </td>
          <td colspan="">
            <Button
              className="paymentReminderBtn"
              disabled={orderDetails?.paymentStatus == "Paid" ? true : false}
            >
              Send Payment Reminder
            </Button>
          </td>
        </Table>
      </div>
    );
  }
}

export default orderDetails;
