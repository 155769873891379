import { Component } from "react";
import { bindActionCreators } from "redux";
import { Switch, Route, withRouter } from "react-router-dom";
import routes from "../../routes";
import Header from "../../components/Header";
import { LOGIN_SUCCESS } from "../../library/Requests/eventConstants";
import { ReactComponent as CloseIcon } from "./../../assets/closeWhite.svg";
import "./styles.css";

class HomePage extends Component {
  static propTypes = {};

  state = {
    isUserLogin: false,
  };
  //   /api/Customer/getallusers
  componentDidMount = () => {
    this.checkLogin();
    const self = this;
    window.document.addEventListener(
      LOGIN_SUCCESS,
      function (event) {
        self.checkLogin();
      },
      false
    );
  };
  handleLogout = () => {
    localStorage.removeItem("customerInfoDetails");
    localStorage.clear();
    this.props.history.push(`/login`);
    this.checkLogin();
  };
  checkLogin = () => {
    const userD = localStorage.getItem("customerInfoDetails");
    if (userD != undefined && userD != null && userD != "") {
      this.setState({ isUserLogin: true });
    } else {
      this.setState({ isUserLogin: false });
    }
  };
  handleLoginSuccess = () => {
    this.setState({ isUserLogin: true });
  };
  closeNotification = () => {
    document.getElementById("showUserMessageDivID1").style.display = "none";
  };

  render() {
    const { isUserLogin } = this.state;
    console.log("this.props", this.props);
    const urlNAme = this.props?.location?.pathname;
    return (
      <>
        <div className="apper">
          {urlNAme.indexOf("liveAuctionLanding") === -1 &&
            urlNAme.indexOf("forgotPassword") === -1 && (
              <Header
                handleLogout={this.handleLogout}
                isUserLogin={isUserLogin}
              />
            )}
          <Switch>
            {routes.map((route) => (
              <Route
                key={route.path}
                render={(props) => <route.component {...props} />}
                {...route}
              />
            ))}
          </Switch>
          <div id="showUserMessageDivID1">
            <p id="showUserMessageDivID"></p>
            <CloseIcon
              className="closeIconStyles"
              onClick={this.closeNotification}
            />{" "}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HomePage,
    },
    dispatch
  );

export default withRouter(HomePage);
