import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "./../../assets/closeIcon.svg";
import "./styles.css";

const TagsInput = (props) => {
  const [tags, setTags] = useState([]);

  useEffect(async () => {
    console.log(props.value);
    if (props.value != undefined && props.value != "") {
      setTags(props.value.split(", "));
    }
  }, [props]);
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value]);
      props.selectedTags([...tags, event.target.value]);
      const prevtags = tags;
      prevtags.push(event.target.value);
      props.selectTagsList(prevtags);
      event.target.value = "";
    }
  };
  return (
    <div className="tags-input">
      <ul id="tags">
        {tags.map((tag, index) => (
          <li key={index} className="tag">
            <span className="tag-title">{tag}</span>
            <CloseIcon
              className="closeIcon"
              onClick={() => removeTags(index)}
            />
          </li>
        ))}
      </ul>
      <input
        type="text"
        placeholder={props.placeholder}
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
        onChange={props.onChange}

        // placeholder="Press enter to add tags"
      />
    </div>
  );
};

export default TagsInput;
