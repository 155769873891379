import React from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  CardDeck,
  CardGroup,
  CardColumns,
  Nav,
} from "react-bootstrap";
import BannerActiveAuction from "./../../components/BannerActiveAuction";
import LotsCard from "./../../components/LotsCard";
import LotsViewCard from "./../../components/LotsViewCard";
import FilterComponent from "./../../components/FilterComponent";
import { ReactComponent as PrintIcon } from "./../../assets/printIcon.svg";
import { ReactComponent as DownloadIcon } from "./../../assets/downloadIcon.svg";
// import "./styles.css";

const ActiveAuctions = () => {
  return (
    <div className="lotsMatrixContainer">
      <BannerActiveAuction />
      <Row className="filterLotsContainer">
        <Tab.Container id="collections-tabs" defaultActiveKey="browseLots">
          <Col sm="12" className="tabsColumn">
            <Nav variant="tabs" className="collectionsTabs">
              <Nav.Item>
                <Nav.Link eventKey="browseLots">Browse Lots (108)</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="overview">Overview</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="auctionDetails">Auction Details</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="conditionsOfSale">
                  Conditions Of Sale
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="registeredUsers">Registered Users</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Row className="viewRow">
            <PrintIcon className="printIcon" />
            <DownloadIcon className="downloadIcon" />
          </Row>
          <Row className="filterLotsRow">
            <Col sm="3" className="filterByColumn">
              <FilterComponent />
            </Col>
            <Col sm="9" className="cardMatrix">
              <Tab.Content>
                <Tab.Pane eventKey="browseLots">
                  <Row>
                    <LotsCard />
                    <LotsCard />
                    <LotsCard />
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="overview">
                  This is Overview Section
                </Tab.Pane>
                <Tab.Pane eventKey="auctionDetails">
                  This is Auction Details Section
                </Tab.Pane>
                <Tab.Pane eventKey="conditionsOfSale">
                  This is Conditions Of Sale Section
                </Tab.Pane>
                <Tab.Pane eventKey="registeredUsers">
                  This is Registered Users Section
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Row>
    </div>
  );
};

export default ActiveAuctions;
