import React, { Component } from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import "./styles.css";

class Timeline extends Component {
  static propTypes = {};
  state = {};
  componentDidMount = () => {};
  render() {
    return <></>;
  }
}

export default Timeline;
