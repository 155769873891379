import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { imageasyncupload, getsasToken } from "./../library/Requests";

import { azure_accountID, azure_contentGuId } from "../library/constants";

const account = azure_accountID;

const contentGuId = azure_contentGuId;

// const sasToken = "sv=2020-04-08&ss=b&srt=sco&spr=https&se=2021-03-09T08%3A36%3A11Z&sp=rlac&sig=GBmkfIU1Vxc%2Br%2FiPZChXDQbhQ1YVO59cWbkjGxvxULU%3D"

// const sas = process.env.REACT_APP_AZURE_SAS_STRING;

const uploadFileToAzure = async (sasToken, file, fileName) => {
  try {
    if (!file) return "";
    // get Container - full public read access
    const blobServiceClient = new BlobServiceClient(
      `https://${account}.blob.core.windows.net?${sasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient(
      `${contentGuId}`
    );
    if (!containerClient.exists()) {
      await containerClient.createIfNotExists({
        access: "container",
      });
    }

    // upload file
    await createBlobInContainer(containerClient, file, fileName);
    return `https://${account}.blob.core.windows.net/${contentGuId}/${fileName}?${sasToken}`;
    // await getBlobsInContainer(containerClient)
  } catch (error) {
    console.log(error);
  }
  return null;
};

const createBlobInContainer = async (containerClient, file, fileName) => {
  // create blobClient for container
  const new_name = `${fileName}`;
  console.log(new_name);
  const blobClient = containerClient.getBlockBlobClient(new_name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadBrowserData(file, options);
};

const uplaodImageNew = async (filesName, imageFolder = "lot") => {
  let ingFIle = filesName;
  const mille_Sec = Math.round(new Date());

  const respData = await getsasToken();
  console.log(respData);
  const sasToken = respData?.sasToken.split("?")[1];
  console.log(ingFIle);

  const fileNAme = `${mille_Sec}__${ingFIle?.name?.replace(/ /g, "_")}`;

  const uploadedFileURL = await uploadFileToAzure(sasToken, ingFIle, fileNAme);
  console.log(uploadedFileURL);

  const body = {
    fileName: fileNAme,
    mimeType: ingFIle?.type,
    isNew: true,
    imageFolder: imageFolder,
    // "virtualPath": uploadedFileURL,
    virtualPath: localStorage.getItem("collectionguidforImage"),
  };
  const respNew = await imageasyncupload(body);
  return respNew;
};

// export default uploadFileToAzure;
export default uplaodImageNew;

// sv=2020-04-08&ss=b&srt=sco&spr=https&se=2021-03-08T10%3A15%3A29Z&sp=rlac&sig=uz%2BeI8d1jzNQmsnenjuLOE5iJULyFTK%2FsOpM9pDIkKs%3D
