import { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
import { ReactComponent as PlusIcon } from "./../../assets/plusIcon.svg";
import { ReactComponent as EditPencil } from "./../../assets/editPencil.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/deleteIcon.svg";
import "./styles.css";
import { loginActionCall } from "./../../library/Requests";
import { LOGIN_SUCCESS } from "../../library/Requests/eventConstants";
import {
  triggerEventForProfileUpdate,
  appendToMesaages,
} from "../../Scenes/common";
import LiveActionLIstContent from "../../components/LiveActionLIstContent";
import LiveActionBoxTwo from "../../components/LiveActionBoxTwo";
import LiveActionVideoContent from "../../components/LiveActionVideoContent";
import { LOGINID } from "./../../Scenes/common";
import {
  getCollectionItemsCall,
  getcollectionitembyidCall,
  CloseAuctionCall,
  getlivecollectionitemsCall,
  getCollectionByIdCall,
  placeLiveBidCall,
  getcurrenciesCall,
  gethighestbid,
  startNextLiveSessionCall,
  choosewinnerCall,
  updateCollectionItemStatusBroadCast,
  followbidCall,
  unfollowbidCall,
} from "./../../library/Requests";

import CustomModal from "./../../components/CustomModal";

import { withRouter, Link } from "react-router-dom";
import * as signalR from "@aspnet/signalr";
import { SERVER_IP } from "./../../library/constants.js";

class liveAuctionLanding extends Component {
  static propTypes = {};

  state = {
    currenciesList: [],
    collectionData: {},
    collectionItems: [],
    sortColumn: null,
    sortDirection: "asc",
    searchText: "",
    jsonData: {},
    collectionItemsFull: [],
    collectionId: 0,
    highestBidDetails: {},
    highestBidDetailsFull: {},
    showAlertModel: false,
    titleText: "",
    DeleteBtnText: "Delete",
    CancelBtnText: "Cancel",
    actionType: "",
    lotItemStatus: "",
    playStarted: false,
    isLastLot: false,
    isAuctionCompleted: false,
    showEndAuction: false,
    streamCode: "",
    BiddingLodding: false,
    // isItemContentVisible: {},
  };

  componentDidMount() {
    this.getcurrenciesDataCall();
    this.getDataonLoad();
    const self = this;
    const connection1 = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .build();

    connection1.on("StartLiveSignal", (response) => {
      console.log(
        "We got signal! and the message is 111111 StartLiveSignal: " +
          response +
          "TIME  " +
          new Date().getTime()
      );
      // Bind GetHightestBids here
      console.log(response);
    });
    connection1
      .start()
      .then((result) => {
        connection1.on("StartLiveSignal", (response) => {
          console.log(
            "We got signal! and the message is 22222 StartLiveSignal: " +
              response +
              "TIME  " +
              new Date().getTime()
          );
          console.log(response);
          // Bind GetHightestBids here
          self.deleteHistoryData();
          // self.getDataonLoad();
          self.UpdateNewCollection(response);
          self.setState({
            streamCode: response.data.streamCode,
          });
        });
      })
      .catch((err) => console.error(err.toString()));

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .build();
    connection.on("BidInsertSignal", (response) => {
      console.log(
        "We got signal! and the message 11111 is BidInsertSignal: " +
          response +
          "TIME  " +
          new Date().getTime()
      );
      // Bind GetHightestBids here
      // alert(response);
    });
    connection
      .start()
      .then((result) => {
        connection.on("BidInsertSignal", (response) => {
          console.log(
            "We got signal! and the message 22222 is BidInsertSignal: " +
              response +
              "TIME  " +
              new Date().getTime()
          );
          // Bind GetHightestBids here
          console.log(response);
          // self.getHighestBid(null);
          appendToMesaages(
            "#" +
              response?.registrationNo +
              "-sent_Online_" +
              response?.currentBidAmount
          );
          self.updateLatestData(response);
        });
      })
      .catch((err) => console.error(err.toString()));

    const connection2 = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .build();
    connection2.on("StatusSignal", (response) => {
      console.log(
        "We got signal! and the message is 111111 BidInsertSignal: " +
          response +
          "TIME  " +
          new Date().getTime()
      );
      // Bind GetHightestBids here
      // alert(response);
    });
    connection2
      .start()
      .then((result) => {
        connection2.on("StatusSignal", (response) => {
          console.log(
            "We got signal! and the message is  2222222 BidInsertSignal: " +
              response +
              "TIME  " +
              new Date().getTime()
          );
          // Bind GetHightestBids here
          console.log(response);
          self.deleteHistoryData();
          self.getDataonLoad(response, "StatusSignal");
        });
      })
      .catch((err) => console.error(err.toString()));

    const connection3 = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .build();
    connection3.on("AuctionClosedSignal", (response) => {
      console.log(
        "We got signal! and the message is 111111 BidInsertSignal: " +
          response +
          "TIME  " +
          new Date().getTime()
      );
      // Bind GetHightestBids here
      // alert(response);
    });
    connection3
      .start()
      .then((result) => {
        connection3.on("AuctionClosedSignal", (response) => {
          console.log(
            "We got signal! and the message is  2222222 BidInsertSignal: " +
              response +
              "TIME  " +
              new Date().getTime()
          );
          // Bind GetHightestBids here
          console.log(response);
          self.deleteHistoryData();
          self.auctionClosed(response);
        });
      })
      .catch((err) => console.error(err.toString()));
  }
  deleteHistoryData = () => {
    document.getElementById("messagesListParent").innerHTML = "";
  };
  updateLatestData = (response) => {
    var LotId = response?.itemId;
    var pageLotId = this.state?.lotData?.id;
    if (LotId == pageLotId) {
      if (
        response?.errors?.length > 0 &&
        response?.errors[0] != undefined &&
        response?.errors[0] != ""
      ) {
        this.setState({
          showAlertModel: true,
          titleText: response?.errors[0],
          DeleteBtnText: "",
          CancelBtnText: "ok",
        });
      } else if (response?.nexBidAmount) {
        let highestBidDetails1 = this.state.highestBidDetails;
        let highestBidDetailsFull1 = this.state.highestBidDetailsFull;

        highestBidDetailsFull1.firstName = response?.firstName;
        highestBidDetailsFull1.lastName = response?.lastName;
        highestBidDetailsFull1.nextBidAmount = response?.nexBidAmount;

        highestBidDetails1.amount = response?.currentBidAmount;
        highestBidDetails1.id = response?.id;

        highestBidDetails1.metResevedPrice = response?.metResevedPrice;
        highestBidDetails1.registrationNo = response?.registrationNo;
        if (response?.customerId) {
          highestBidDetails1.customerId = response?.customerId;
        }

        // this.setState({highestBidDetails:bidResp?.bidDetails});
        this.setState({
          highestBidDetailsFull: highestBidDetailsFull1,
          highestBidDetails: highestBidDetails1,
        });
      } else {
        this.getHighestBid(null);
      }
    }
  };
  UpdateNewCollection = (response) => {
    var nextLotId = response?.data?.itemId;
    var collectionID = response?.data?.collectionId;
    const id = this.props?.match?.params?.id;
    const { collectionItems } = this.state;
    if (collectionID == id && nextLotId != "") {
      if (response?.data?.isLastLot) {
        this.setState({ isLastLot: true });
      }
      let finaalAction = collectionItems.filter((item) => item.id == nextLotId);
      if (finaalAction?.length && finaalAction?.length > 0) {
        let lotDeta = finaalAction[0];
        this.getlotFullData(lotDeta?.id);
        this.setState({ lotData: lotDeta, lotItemStatus: lotDeta?.itemStatus });
        this.getHighestBid(lotDeta?.id);
      }
    } else {
      this.getDataonLoad();
    }
  };
  auctionClosed = (response) => {
    this.setState({ isAuctionCompleted: true });
  };
  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
  };
  getDataonLoad = async (response, signalType) => {
    if (response && response?.status) {
      this.setState({ lotItemStatus: response?.status });
    }
    const { sortColumn, sortDirection } = this.state;
    const id = this.props?.match?.params?.id;
    if (id) {
      const body = {
        page: 1,
        pageSize: 50,
        getOnlyTotalCount: false,
        collectionId: id,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        customerId: LOGINID,
        status: [1, 6, 7],
      };
      this.setState({ jsonData: body, collectionId: id });
      this.getCollectionItems(body);
      if (signalType != "StatusSignal") {
        this.getCollectionData(id);
      }
    }
  };
  getCollectionItems = async (body) => {
    // let resp = await getCollectionItemsCall(body);
    let resp = await getlivecollectionitemsCall(body);
    this.setState({ collectionItems: resp?.data, collectionItemsFull: resp });
    if (resp?.data?.length > 0) {
      let finaalAction = resp?.data.filter((item) => item.isLive == true);
      let isLastLot1 = resp?.data.filter((item) => item.isLastLot == true);

      if (finaalAction?.length && finaalAction?.length > 0) {
        let lotDeta = finaalAction[0];
        this.setState({ isLastLot: lotDeta?.isLastLot });
        this.getlotFullData(lotDeta?.id);
        this.setState({ lotData: lotDeta, lotItemStatus: lotDeta?.itemStatus });
        this.getHighestBid(lotDeta?.id);
      } else if (isLastLot1?.length && isLastLot1?.length > 0) {
        let lotDeta1 = isLastLot1[0];
        if (this.state?.lotData?.id == lotDeta1?.id) {
          this.setState({ showEndAuction: true });
          this.setState({
            lotItemStatus: lotDeta1?.itemStatus,
            isLastLot: lotDeta1?.isLastLot,
          });
        }
      }
    }
    // triggerEventForProfileUpdate(UPDATE_FILTERS);
  };
  getlotFullData = async (id) => {
    let resp = await getcollectionitembyidCall(id);
    this.setState({ lotData: resp?.data });
  };
  getHighestBid = async (id) => {
    let lotIdsnew = 0;
    if (id) {
      lotIdsnew = id;
    } else {
      lotIdsnew = this.state.lotData?.id;
    }
    if (lotIdsnew) {
      const bidResp = await gethighestbid(lotIdsnew);
      this.setState({ highestBidDetails: bidResp?.bidDetails });
      this.setState({ highestBidDetailsFull: bidResp });
    }
  };
  getCollectionData = async (id) => {
    let resp = await getCollectionByIdCall(id);
    this.setState({ collectionData: resp?.data });
    if (resp?.data?.endOnUtc && resp?.data?.endOnUtc != "") {
      this.setState({ isAuctionCompleted: true });
    }
  };
  triggerBidCall = async (amount, BID) => {
    const { lotData, currenciesList, collectionData } = this.state;
    let currencyName = currenciesList.find(
      (i) => collectionData?.currency == i?.id
    )?.currencyCode;
    const body = {
      itemId: lotData?.id,
      amount: amount,
      currency: currencyName,
      registrationNo: BID,
      type: "Online",
    };
    this.setState({ BiddingLodding: true });
    let resp = await placeLiveBidCall(body);
    this.setState({ BiddingLodding: false });
    if (
      resp?.errors?.length > 0 &&
      resp?.errors[0] != undefined &&
      resp?.errors[0] != ""
    ) {
      this.setState({
        showAlertModel: true,
        titleText: resp?.errors[0],
        DeleteBtnText: "",
        CancelBtnText: "ok",
      });
    }

    // /api/Bid/placeLiveBid
  };

  playStartedCall = (flag) => {
    const flagcal = flag == "started" ? true : false;
    this.setState({ playStarted: flagcal });
  };
  moveNextLot = async () => {
    this.setState({
      actionType: "nextLot",
      showAlertModel: true,
      titleText: "Are you sure you want to move to next Lot?",
      DeleteBtnText: "Yes",
      CancelBtnText: "Cancel",
    });
  };
  chooseWinner = async () => {
    this.setState({
      actionType: "chooseWinner",
      showAlertModel: true,
      titleText: "Are you sure you choose as Winner?",
      DeleteBtnText: "Yes",
      CancelBtnText: "Cancel",
    });
  };
  closeLot = async () => {
    this.setState({
      actionType: "closeLot",
      showAlertModel: true,
      titleText: "Are you sure you want to close Lot?",
      DeleteBtnText: "Yes",
      CancelBtnText: "Cancel",
    });
  };
  DeleteConfirm = async () => {
    this.setState({
      showAlertModel: false,
    });
    const { actionType } = this.state;
    if (actionType === "nextLot") {
      // this.getNextLotId();
      const body = {
        previousItemId: this.state.lotData?.id,
        collectionId: this.state?.collectionId,
      };
      const resp = await startNextLiveSessionCall(body);
    } else if (actionType === "chooseWinner") {
      this.chooseWinnerCall();
    } else if (actionType === "closeLot") {
      this.closeLotCall();
    }
  };
  chooseWinnerCall = async () => {
    const { highestBidDetails } = this.state;
    const body = {
      itemId: this.state.lotData?.id,
      customerId: highestBidDetails?.customerId,
      bidId: highestBidDetails?.id,
    };
    const resp = await choosewinnerCall(body);
    if (resp.success) {
      this.setState({
        showAlertModel: true,
        titleText: "You have chosen your winner successfully.",
        DeleteBtnText: "",
        CancelBtnText: "ok",
      });
    }
    if (this.state?.isLastLot) {
      this.setState({ showEndAuction: true });
    }
  };
  closeLotCall = async () => {
    let body = {
      id: this.state.lotData?.id,
      status: 7,
    };
    let resp = await updateCollectionItemStatusBroadCast(body);
    if (resp.success) {
      this.setState({
        showAlertModel: true,
        titleText: "You have closed Lot successfully.",
        DeleteBtnText: "",
        CancelBtnText: "ok",
      });
    }
    if (this.state?.isLastLot) {
      this.setState({ showEndAuction: true });
    }
  };
  endAuctionClick = async () => {
    let body = {
      collectionId: this.state.collectionData?.id,
    };
    let resp = await CloseAuctionCall(body);
  };
  viewMoreClick = () => {
    if (this.state?.collectionData?.id)
      this.props.history.push(
        `/dashboard/auctionManagement/AuctionLots/${this.state?.collectionData?.id}?type=active`
      );
  };
  render() {
    const idCollection = this.props?.match?.params?.id;
    const {
      currenciesList,
      collectionData,
      collectionItems,
      collectionItemsFull,
      lotData,
      highestBidDetails,
      showAlertModel,
      titleText,
      DeleteBtnText,
      CancelBtnText,
      highestBidDetailsFull,
      lotItemStatus,
      playStarted,
      isLastLot,
      showEndAuction,
      isAuctionCompleted,
      streamCode,
    } = this.state;
    return (
      <>
        <div className="live_auction_page live_auction_flex d-flex flex-wrap">
          <LiveActionLIstContent
            currenciesList={currenciesList}
            collectionData={collectionData}
            collectionItems={collectionItems}
            lotData={lotData}
          />
          <LiveActionBoxTwo
            lotData={lotData}
            currenciesList={currenciesList}
            collectionData={collectionData}
            highestBidDetails={highestBidDetails}
            moveNextLot={this.moveNextLot}
            chooseWinner={this.chooseWinner}
            closeLot={this.closeLot}
            highestBidDetailsFull={highestBidDetailsFull}
            playStarted={playStarted}
            isLastLot={isLastLot}
            showEndAuction={showEndAuction}
            endAuctionClick={this.endAuctionClick}
            BiddingLodding={this.state.BiddingLodding}
          />
          <LiveActionVideoContent
            lotData={lotData}
            currenciesList={currenciesList}
            collectionData={collectionData}
            highestBidDetails={highestBidDetails}
            triggerBidCall={this.triggerBidCall}
            BiddingLodding={this.state.BiddingLodding}
            idCollection={idCollection}
            highestBidDetailsFull={highestBidDetailsFull}
            lotItemStatus={lotItemStatus}
            playStartedCall={this.playStartedCall}
            streamCode={streamCode}
          />
          <CustomModal
            show={showAlertModel}
            titleText={titleText}
            deleteText={DeleteBtnText}
            cancelText={CancelBtnText}
            onCancel={() => this.setState({ showAlertModel: false })}
            onDelete={() => this.DeleteConfirm()}
          />
          <CustomModal
            viewMoreClick={this.viewMoreClick}
            show={isAuctionCompleted}
            isAuctionCompleted={true}
          />
        </div>
      </>
    );
  }
}

export default withRouter(liveAuctionLanding);
