import React, { Component } from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import { ReactComponent as TableEditIcon } from "./../../assets/tableEditIcon.svg";
import "./styles.css";
class LogisticInformation extends Component {
  static propTypes = {};
  state = {};
  componentDidMount = () => {};
  render() {
    return <></>;
  }
}

export default LogisticInformation;
