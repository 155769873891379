import React from "react";
import UserBasicInformation from "./../../components/UserBasicInformation";
import "./styles.css";

const index = () => {
  return (
    <div>
      <UserBasicInformation />
    </div>
  );
};

export default index;
