import React, { useState, Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { withRouter } from "react-router";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as DeleteBin } from "./../../assets/deleteBin.svg";
import CustomModal from "./../../components/CustomModal";
import { createNewHSM, getAllCategories } from "./../../library/Requests";
import uplaodImageNew from "../../services";
import "./styles.css";
import { updatesitemanagerCall } from "./../../library/Requests/SiteManager";

import { EDIT_SITE_DATA } from "../../library/Requests/eventConstants";

import { getpublishedarticleCall } from "./../../library/Requests/ContentManagement";

// const HSL = (props) => {
class HSL extends Component {
  initialState = {
    heroSectionData: {
      categoryId: "",
      image: "",
      title: "",
      subTitle: "",
      textLink: "",
      type: "1",
      articleId: "",
    },
    errors: {
      categoryId: false,
      image: false,
      title: false,
      subTitle: false,
      textLink: false,
    },
    errorsData: {
      categoryId: "Please enter categoryId",
      image: "Please enter image",
      title: "Please enter title",
      subTitle: "Please enter subTitle",
      textLink: "Please enter textLink",
    },
  };

  state = {
    ...this.initialState,
    showWarningMessage: false,
    publishSuccess: false,
    allCategories: [],
    pageType: "Hero",
    loading: false,
    showHiddenImage: true,
    imageMinHeight: 540,
    imageMinWidth: 960,
    errorImage: "",
    allArticles: [],
  };
  onImgLoad = this.onImgLoad1.bind(this);
  resetState = () => {
    const state2 = {
      ategoryId: "",
      image: "",
      title: "",
      subTitle: "",
      textLink: "",
      type: "1",
    };
    this.setState({ heroSectionData: state2 });
  };
  componentDidMount = () => {
    this.getAllCategoriesCall();
    this.getPublishedArticles();
    if (this.props?.location?.pathname == "/dashboard/wordSectionManager") {
      this.setState({ pageType: "the Word" });
    }
    const self = this;
    window.document.addEventListener(
      EDIT_SITE_DATA,
      function (event) {
        self.updateSiteData();
      },
      false
    );
  };
  getPublishedArticles = async () => {
    const publishedResp = await getpublishedarticleCall();
    this.setState({ allArticles: publishedResp?.data });
  };
  updateSiteData = () => {
    const dataItem = JSON.parse(localStorage.getItem("editSiteDataItem"));
    this.setState({ heroSectionData: dataItem });
  };
  getAllCategoriesCall = async () => {
    const resp = await getAllCategories();
    this.setState({ allCategories: resp.data });
  };

  goBackpage = (props) => {
    this.props.history.push(`/dashboard/siteManager`);
  };

  showWarningMessageConfirm = async (key) => {
    this.setState({ showWarningMessage: true });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const state = this.state.heroSectionData;
    state[name] = value;
    let errorsList = this.state.errors;
    errorsList[name] = false;
    if (name == "articleId") {
      errorsList.textLink = false;
    }
    this.setState({ heroSectionData: state, errors: errorsList });
  };
  checkValidForm = () => {
    const details = this.state.errors;
    let errorsList = this.state.errors;
    const { heroSectionData } = this.state;
    let returnFlag = true;
    for (var key in details) {
      const Pvalue = this.state.heroSectionData[key];
      if (Pvalue == "" || Pvalue == null || Pvalue == undefined) {
        if (key == "textLink") {
          if (
            heroSectionData.textLink == "" &&
            heroSectionData?.articleId == ""
          ) {
            errorsList[key] = true;
            returnFlag = false;
          }
        } else {
          errorsList[key] = true;
          returnFlag = false;
        }
      }
    }
    this.setState({ errors: errorsList });
    return returnFlag;
  };
  getJsonBody = () => {
    const { heroSectionData, pageType } = this.state;
    const typeN = pageType == "Hero" ? 1 : 2;
    const dataJson = {
      siteManager: {
        categoryId: heroSectionData?.categoryId,
        articleId: heroSectionData?.articleId,
        image: heroSectionData?.image,
        title: heroSectionData?.title,
        subTitle: heroSectionData?.subTitle,
        textLink: heroSectionData?.textLink,
        type: typeN,
      },
    };
    return dataJson;
  };

  onCreateHSM = async () => {
    const { heroSectionData } = this.state;
    const { allData } = this.props;
    if (allData.length <= 4) {
      if (this.checkValidForm()) {
        const body = this.getJsonBody();
        const resp = await createNewHSM(body);
        if (resp.success) {
          const state = this.state.heroSectionData;
          state.id = resp?.id;
          this.setState({ heroSectionData: state, publishSuccess: true });
          this.props.getStateUpdate();
          this.resetState();
        }
      }
    } else {
      this.showWarningMessageConfirm();
    }
  };
  saveChangesButton = async () => {
    const { heroSectionData } = this.state;
    if (this.checkValidForm()) {
      const body = this.getJsonBody();
      body.siteManager.id = heroSectionData?.id;
      const resp = await updatesitemanagerCall(body);
      if (resp.success) {
        const state = this.state.heroSectionData;
        state.id = resp?.id;
        this.setState({ heroSectionData: state, publishSuccess: true });
        this.props.getStateUpdate();
        this.resetState();
      }
    }
  };
  handleChange = async (e) => {
    if (e.target.files.length) {
      this.setState({ showHiddenImage: true });
      const filesName = e.target.files[0];
      const state = this.state.heroSectionData;
      state.image = URL.createObjectURL(e.target.files[0]);
      this.setState({ heroSectionData: state, loading: true });
      const respApp = await uplaodImageNew(filesName, "siteManager");
      this.handleInputChangeImage("image", respApp?.imageUrl);
    }
  };
  onImgLoad1({ target: img }) {
    const { imageMinHeight, imageMinWidth } = this.state;
    this.setState({ showHiddenImage: true });
    console.log(img.offsetHeight);
    console.log(img.offsetWidth);
    let errorMsg = "";
    if (imageMinHeight > img.offsetHeight) {
      errorMsg = "Image Height should be min " + imageMinHeight + "px";
    }
    if (imageMinWidth > img.offsetWidth) {
      if (errorMsg != "") {
        errorMsg += " and ";
      }
      errorMsg += "Image Width should be min " + imageMinWidth + "px";
    }

    this.setState({ showHiddenImage: false, errorImage: errorMsg });
  }
  handleInputChangeImage = (name, value) => {
    const state = this.state.heroSectionData;
    state[name] = value;
    let errorsList = this.state.errors;
    errorsList[name] = false;
    this.setState({
      heroSectionData: state,
      errors: errorsList,
      loading: false,
    });
  };
  handleDeleteImage = (image) => {
    const state = this.state.heroSectionData;
    state.image = "";
    this.setState({ image: state, errorImage: "" });
  };

  render() {
    const { allData } = this.props;
    const {
      showWarningMessage,
      loading,
      publishSuccess,
      heroSectionData,
      allCategories,
      errors,
      errorsData,
      pageType,
      showHiddenImage,
      errorImage,
      allArticles,
    } = this.state;
    return (
      <div className="hslContainer">
        <p className="hslHeading" onClick={this.goBackpage}>
          {" "}
          <ChevronLeft className="chevronLeft" /> {pageType} Section{" "}
        </p>
        <Form.Label className="requiredField hslSideHeading">Image</Form.Label>
        <Row className="hslImageContainer">
          {heroSectionData?.image ? (
            <>
              <DeleteBin
                className="deleteImgIcon"
                onClick={(image) => {
                  this.handleDeleteImage(image);
                }}
              />
              <img
                src={heroSectionData?.image}
                alt="Preview"
                className="previewImageCollectionHero"
              />
            </>
          ) : (
            <label htmlFor="uploadButton">
              <span className="labelStyles">
                <p className="hslUploadBtn">Upload Image</p>
              </span>
            </label>
          )}

          {/* {!heroSectionData.image && ( */}
          <input
            id="uploadButton"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            className="uploadImageBtn"
            onChange={this.handleChange}
          />
          {/* // )} */}
        </Row>
        {errors?.image ? (
          <>
            <div className="errorImageFIleHero">Please upload the Image</div>
          </>
        ) : null}
        {errorImage != "" && (
          <div className="errorImageFIleHero">{errorImage}</div>
        )}

        <Row>
          <Form.Label className="hslSideHeading requiredField">
            Category
          </Form.Label>
          <Form.Control
            isInvalid={errors?.categoryId}
            as="select"
            name="categoryId"
            onChange={this.handleInputChange}
            className="selectOption"
          >
            <option value="">Select</option>
            {(allCategories || []).map((item, key) => {
              const checkVal =
                heroSectionData?.categoryId == item.id ? true : false;
              return (
                <>
                  <option selected={checkVal} name={item.id} value={item.id}>
                    {item.category}
                  </option>
                </>
              );
            })}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors?.categoryId ? <>{errorsData?.categoryId}</> : null}
          </Form.Control.Feedback>
        </Row>
        <Row>
          <Form.Label className="requiredField hslSideHeading">
            Title
          </Form.Label>
          <Form.Control
            maxlength="200"
            isInvalid={errors?.title}
            name="title"
            value={heroSectionData?.title}
            type="text"
            placeholder="Enter Title Here..."
            onChange={this.handleInputChange}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.title ? <>{errorsData?.title}</> : null}
          </Form.Control.Feedback>
        </Row>
        <Row>
          <Form.Label className="requiredField hslSideHeading">
            Sub Title
          </Form.Label>
          <Form.Control
            maxlength="200"
            isInvalid={errors?.subTitle}
            name="subTitle"
            value={heroSectionData?.subTitle}
            as="textarea"
            className="textArea"
            rows="5"
            onChange={this.handleInputChange}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.subTitle ? <>{errorsData?.subTitle}</> : null}
          </Form.Control.Feedback>
        </Row>
        <Row>
          <Form.Label className="requiredField hslSideHeading">
            Text Link
          </Form.Label>

          <Form.Control
            isInvalid={errors?.articleId}
            as="select"
            name="articleId"
            onChange={this.handleInputChange}
            className="selectOption"
          >
            <option value="" name="articleId">
              Select
            </option>
            {(allArticles || []).map((item, key) => {
              const checkVal =
                heroSectionData?.articleId == item.id ? true : false;
              return (
                <>
                  <option selected={checkVal} name="articleId" value={item.id}>
                    {item.title}
                  </option>
                </>
              );
            })}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors?.articleId ? <>{errorsData?.articleId}</> : null}
          </Form.Control.Feedback>
        </Row>
        <Row className="textCenterBox">or</Row>
        <Row>
          <Form.Control
            isInvalid={errors?.textLink}
            name="textLink"
            value={heroSectionData?.textLink}
            type="text"
            placeholder="Enter a link Here..."
            onChange={this.handleInputChange}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.textLink ? <>{errorsData?.textLink}</> : null}
          </Form.Control.Feedback>
        </Row>

        {loading && (
          <>
            <div className="spinner-borderWord">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </>
        )}

        <Row>
          {heroSectionData?.id ? (
            <Button
              disabled={loading || (errorImage != "" ? true : false)}
              className="savePublishBtn"
              onClick={this.saveChangesButton}
            >
              Save Changes
            </Button>
          ) : (
            <>
              <Button
                disabled={loading || (errorImage != "" ? true : false)}
                className="savePublishBtn"
                onClick={this.onCreateHSM}
              >
                Create & Publish
              </Button>
            </>
          )}
        </Row>
        <Row>
          <Button onClick={this.resetState} className="hslCancelBtn">
            Cancel
          </Button>
        </Row>
        {showHiddenImage && heroSectionData?.image && (
          <div className="hideImageforHeight1">
            <img
              onLoad={this.onImgLoad}
              src={heroSectionData?.image}
              alt="Preview"
            />
          </div>
        )}
        <CustomModal
          show={showWarningMessage}
          titleText="Already 4 Items are created."
          deleteText="ok"
          cancelText=""
          onCancel={() => this.setState({ showWarningMessage: false })}
          onDelete={() => this.setState({ showWarningMessage: false })}
        />
        <CustomModal
          show={publishSuccess}
          titleText={`${pageType} Section Published Successfully`}
          deleteText="ok"
          cancelText=""
          onCancel={() => this.setState({ publishSuccess: false })}
          onDelete={() => this.setState({ publishSuccess: false })}
        />
      </div>
    );
  }
}

export default withRouter(HSL);
