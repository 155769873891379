import React, { useState, useEffect } from "react";
import { Row, Col, Button, Tab, Nav } from "react-bootstrap";
import { withRouter } from "react-router";
import AuctionsCard from "./../../components/AuctionsCard";
import { ReactComponent as GridActiveIcon } from "./../../assets/gridViewActive.svg";
import { ReactComponent as GridGreyIcon } from "./../../assets/gridGrayIcon.svg";
import { ReactComponent as ListActiveIcon } from "./../../assets/listViewActive.svg";
import { ReactComponent as ListGreyIcon } from "./../../assets/listGreyIcon.svg";
import "./styles.css";
import {
  getTimeZones,
  getUpcomingAuctions,
  getActiveAuctions,
  getPastAuctions,
} from "../../library/Requests";

const AuctionManagement = (props) => {
  const [upCommingAuctions, setUpCommingAuctions] = useState([]);
  const [activeAuctions, setActiveAuctions] = useState([]);
  const [pastAuctions, setPastAuctions] = useState([]);
  const [timeZ, setTimeZ] = useState([]);
  const [isUpcomingLoading, setIsUpcomingLoading] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [isPastLoading, setIsPastLoading] = useState(false);
  const [upcomingCnt, setUpcomingCnt] = useState(0);
  const [ongoingCnt, setOngoingCnt] = useState(0);
  const [pastCnt, setPastCnt] = useState(0);
  const [jsonBody, setJsonBody] = useState({});
  const [upcomingCurrentPage, setUpcomingCurrentPage] = useState(1);
  const [upcomingCurrentPageData, setUpcomingCurrentPageData] = useState(5);
  const [activeCurrentPage, setActiveCurrentPage] = useState(1);
  const [activeCurrentPageData, setActiveCurrentPageData] = useState(5);
  const [pastCurrentPage, setPastCurrentPage] = useState(1);
  const [pastCurrentPageData, setPastCurrentPageData] = useState(5);

  useEffect(async () => {
    const timezResp = await getTimeZones();
    setTimeZ(timezResp.data);
    getDataonLoad();
  }, []);

  const params = new URLSearchParams(props.location.search);
  let type = params.get("type");
  if (type == undefined || type == "") {
    type = "upcoming";
  }

  const getDataonLoad = async () => {
    const body = {
      page: upcomingCurrentPage,
      pageSize: upcomingCurrentPageData,
      getOnlyTotalCount: false,
      sortColumn: "StartOnUtc",
      sortDirection: "desc",
    };
    setJsonBody(body);
    getUpcomingDataOnload(body, upcomingCurrentPage);
    getActiveDataOnload(body, activeCurrentPage);
    getPastDataOnload(body, pastCurrentPage);
  };

  const getUpcomingDataOnload = async (body, upcomingCurrentPage, pagetype) => {
    setIsUpcomingLoading(true);
    body.page = upcomingCurrentPage;
    const getUpcomingresp = await getUpcomingAuctions(body);
    if (pagetype == "LoadMore") {
      setUpCommingAuctions([
        ...upCommingAuctions,
        ...getUpcomingresp?.upCommingAuctions,
      ]);
    } else {
      setUpCommingAuctions(getUpcomingresp?.upCommingAuctions);
    }
    setUpcomingCnt(getUpcomingresp?.totalCount);
    setIsUpcomingLoading(false);
  };

  const getActiveDataOnload = async (body, activeCurrentPage, pagetype) => {
    setIsActiveLoading(true);
    body.page = activeCurrentPage;
    body.pageSize = activeCurrentPageData;
    const getActiveresp = await getActiveAuctions(body);
    if (pagetype == "LoadMore") {
      setActiveAuctions([...activeAuctions, ...getActiveresp?.onGoingAuctions]);
    } else {
      setActiveAuctions(getActiveresp?.onGoingAuctions);
    }
    setOngoingCnt(getActiveresp?.totalCount);
    setIsActiveLoading(false);
  };

  const getPastDataOnload = async (body, pastCurrentPage, pagetype) => {
    setIsPastLoading(true);
    body.page = pastCurrentPage;
    body.pageSize = pastCurrentPageData;
    const getPastresp = await getPastAuctions(body);
    if (pagetype == "LoadMore") {
      setPastAuctions([...pastAuctions, ...getPastresp?.pastAuctions]);
    } else {
      setPastAuctions(getPastresp?.pastAuctions);
    }
    setPastCnt(getPastresp?.totalCount);
    setIsPastLoading(false);
  };

  const loadMoreUpcoming = () => {
    var a = upcomingCurrentPage + 1;
    setUpcomingCurrentPage(a);
    getUpcomingDataOnload(jsonBody, a, "LoadMore");
  };

  const loadMoreActive = () => {
    var a = activeCurrentPage + 1;
    setActiveCurrentPage(a);
    getActiveDataOnload(jsonBody, a, "LoadMore");
  };

  const loadMorePast = async () => {
    var a = pastCurrentPage + 1;
    setPastCurrentPage(a);
    getPastDataOnload(jsonBody, a, "LoadMore");
  };

  return (
    <div className="auctionContainer">
      <Row className="auctionManagementRow">
        <Col sm="6">
          <span className="headingText">Auction Management</span>
        </Col>
      </Row>
      <Tab.Container id="collections-tabs" defaultActiveKey={type}>
        {/* <Row sm="12"> */}
        <Col sm="12" className="noPadding">
          <Nav
            variant="tabs"
            className="collectionsTabs"
            style={{ paddingLeft: "18px" }}
          >
            <Nav.Item>
              <Nav.Link eventKey="upcoming">Upcoming Auctions</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="active">Active Auctions</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="past">Past Auctions</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm="12" className="noPadding">
          <Tab.Content>
            <Tab.Pane eventKey="upcoming">
              <Row sm="12">
                {(upCommingAuctions || [])?.map((uca, index) => {
                  const timeZone = timeZ.find(
                    (i) => uca?.timeZoneId === i?.id
                  )?.name;
                  return (
                    <>
                      <AuctionsCard
                        key={index.toString()}
                        auctionsData={uca}
                        type="upcoming"
                        timeZone={timeZone}
                      />
                    </>
                  );
                })}
              </Row>
              {isUpcomingLoading && (
                <>
                  {" "}
                  <div className="spinner-borderWord">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </>
              )}
              {!isUpcomingLoading && upcomingCnt > upCommingAuctions.length && (
                <Row className="loadMoreRow">
                  <Button
                    onClick={() => loadMoreUpcoming()}
                    variant="custom"
                    className="loadMoreBtn bottomPadding"
                  >
                    Load More
                  </Button>
                </Row>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="active">
              <Row sm="12">
                {(activeAuctions || [])?.map((oga, index) => {
                  const timeZone = timeZ.find(
                    (i) => oga?.timeZoneId === i?.id
                  )?.name;
                  return (
                    <>
                      <AuctionsCard
                        key={index.toString()}
                        auctionsData={oga}
                        type="active"
                        timeZone={timeZone}
                      />
                    </>
                  );
                })}
              </Row>
              {isActiveLoading && (
                <>
                  {" "}
                  <div className="spinner-borderWord">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </>
              )}
              {!isActiveLoading && ongoingCnt > activeAuctions.length && (
                <Row className="loadMoreRow">
                  <Button
                    onClick={() => loadMoreActive()}
                    variant="custom"
                    className="loadMoreBtn bottomPadding"
                  >
                    Load More
                  </Button>
                </Row>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="past">
              <Row sm="12">
                {(pastAuctions || [])?.map((pa, index) => {
                  let timeZone = timeZ.find(
                    (i) => pa?.timeZoneId === i?.id
                  )?.abbrivation;
                  if (!timeZone) {
                    timeZone = timeZ.find(
                      (i) => pa?.timeZoneId === i?.id
                    )?.name;
                  }
                  return (
                    <>
                      <AuctionsCard
                        key={index.toString()}
                        auctionsData={pa}
                        type="past"
                        timeZone={timeZone}
                      />
                    </>
                  );
                })}
              </Row>
              {isPastLoading && (
                <>
                  {" "}
                  <div className="spinner-borderWord">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </>
              )}
              {!isPastLoading && pastCnt > pastAuctions.length && (
                <Row className="loadMoreRow">
                  <Button
                    onClick={() => loadMorePast()}
                    variant="custom"
                    className="loadMoreBtn bottomPadding"
                  >
                    Load More
                  </Button>
                </Row>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Tab.Container>
    </div>
  );
};

export default AuctionManagement;
