import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./styles.css";

const ImagePopup = (props) => {
  const [selectedTExt, setSelectedTExt] = useState("");

  const handleValuesSelect = (value) => {
    setSelectedTExt(value);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="imagePopupContainer">
        <span
          className="imageCrossIcon"
          onClick={() => {
            props.onCancel();
          }}
        >
          X
        </span>
        <div className="imageUrlForPopupName">
          <img src={props.imageUrlForPopup}></img>
        </div>

        <div className="dividerLineBid"></div>
      </Modal.Body>
    </Modal>
  );
};

export default ImagePopup;
