import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  getcollectionitembyidCall,
  getCollectionItemsCall,
  getTimeZones,
  gethighestbid,
  getCollectionByIdCall,
  getcurrenciesCall,
  followbidCall,
  unfollowbidCall,
} from "./../../library/Requests";

import PreviewLot from "./../PreviewLot";

import { LOGINID } from "./../../Scenes/common";

class PreviewLotPage extends Component {
  static propTypes = {};

  state = {
    isEditLot: 0,
    collectionId: 0,
    collectionItemId: 0,
    lotItemDetailsData: {},
  };
  componentDidMount = () => {
    const id = this.props?.match?.params?.id;
    // this.getcurrenciesDataCall();
    this.getLotData(id);
  };
  getLotData = async (id) => {
    window.scrollTo(0, 0);
    // this.setState({isLoading: true})
    // const collectionItem = await getcollectionitembyidCall(id, LOGINID);
    // this.setState({isLoading: false, lotItemDetailsData: collectionItem?.data, collectionId: collectionItem?.data?.collectionId })
  };
  // getHighestBidDetails = async(id)=>{
  //     const bidResp = await gethighestbid(id);
  //     this.setState({highestBidDetails:bidResp?.bidDetails});
  //   }
  //   getcurrenciesDataCall = async ()=>{
  //     const resp = await getcurrenciesCall();
  //     this.setState({currenciesList: resp?.data})
  //     const timeZoneResp = await getTimeZones();
  //     this.setState({timeZones: timeZoneResp.data})

  //   }

  render() {
    const { collectionId, lotItemDetailsData } = this.state;
    const id = this.props?.match?.params?.id;
    return (
      <>
        <div className="previewLotPage-container">
          <PreviewLot
            isEditLot={false}
            isPublish={true}
            lotIDNew={id}
            collectionIdNewId={collectionId}
            collectionItemId={id}
            lotItemDetails={lotItemDetailsData}
            closePreviewPageCall={() => {}}
          />
        </div>
      </>
    );
  }
}

export default PreviewLotPage;
