import React, { useEffect, useState } from "react";
import { Row, Col, Button, Nav, Tab, Table, Form, Card } from "react-bootstrap";
import { withRouter } from "react-router";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as AvatarImage } from "./../../assets/avatar.svg";
import { ReactComponent as videoPlayicon } from "./../../assets/videoPlayicon.svg";
import noImage from "./../../assets/noImage.jpg";
import CustomModal from "./../../components/CustomModal";
import LotDetailsView from "./LotDetailsView";
import { useParams } from "react-router-dom";
// import moment from 'moment';
import moment from "moment-timezone";
import {
  getcollectionitembyidCall,
  choosewinnerBidCall,
  getCollectionByIdCall,
  getTimeZones,
  getcurrenciesCall,
  getBidsPlacedbyItem,
  gethighestbid,
  autobidCall,
  quickbidCall,
  quickbidCallBroadCast,
  choosewinnerBroadCastCall,
} from "./../../library/Requests";
import "./styles.css";

import * as signalR from "@aspnet/signalr";
import { SERVER_IP } from "./../../library/constants.js";

const ViewLot = (props) => {
  const { id, lotNumber } = useParams();

  const par = useParams();

  // const lotNumber = new URLSearchParams(props.location.search).get("lotNumber").split('?')[0]

  const params = new URLSearchParams(props.location.search);

  const type = params.get("type");

  const [lotItem, setLotItem] = useState({});
  const [mainImage, setMainImage] = useState(null);

  const [collectionData, setCollectionData] = useState({});
  const [bidDetails, setBidDetails] = useState({});
  const [timeZones, setTimeZones] = useState([]);
  const [currenciesData, setCurrenciesData] = useState([]);
  const [lotBids, setLotBids] = useState([]);
  const [isAuctionManagement, setIsAuctionManagement] = useState(false);
  const [userbiddingIdField, setUserbiddingIdField] = useState("");
  const [userBiddingError, setUserBiddingError] = useState("");
  const [showAutoBidBtn, setShowAutoBidBtn] = useState(false);
  const [durationLimit, setDurationLimit] = useState(null);
  const [isUpcoming, setIsUpcoming] = useState(true);
  const [showWinnerRow, setShowWinnerRow] = useState("");
  const [showAlertModel, setShowAlertModel] = useState(false);

  const [currentPage, setcurrentPage] = useState(1);
  const [setPageData, setSetPageData] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageloading] = useState(false);

  const [lotStatusText, setLotStatusText] = useState("");

  useEffect(async () => {
    window.scrollTo(0, 0);
    const pagetype = props.location?.pathname;
    if (pagetype.indexOf("dashboard/auctionManagement") > 0) {
      setIsAuctionManagement(true);
    }
    getLotItem(id);
    getHighestBidDetails(id);
    const timeZoneResp = await getTimeZones();
    setTimeZones(timeZoneResp.data);
    const curren = await getcurrenciesCall();
    setCurrenciesData(curren.data);
    getBidIds(id, currentPage);

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .build();
    connection.on("BidInsertSignal", (response) => {
      console.log(
        "We got signal! and the message 11111 is BidInsertSignal: " + response
      );
      // Bind GetHightestBids here
      // alert(response);
    });
    connection
      .start()
      .then((result) => {
        connection.on("BidInsertSignal", (response) => {
          console.log(
            "We got signal! and the message 22222 is BidInsertSignal: " +
              response
          );
          // Bind GetHightestBids here
          console.log(response);
          // self.getHighestBid(null);
          updateLatestData(response);
        });
      })
      .catch((err) => console.error(err.toString()));

    const connection2 = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .build();
    connection2.on("StatusSignal", (response) => {
      console.log(
        "We got signal! and the message is 111111 BidInsertSignal: " + response
      );
      // Bind GetHightestBids here
      // alert(response);
    });
    connection2
      .start()
      .then((result) => {
        connection2.on("StatusSignal", (response) => {
          console.log(
            "We got signal! and the message is  2222222 BidInsertSignal: " +
              response
          );
          updateLatestData1(response, "StatusSignal");
        });
      })
      .catch((err) => console.error(err.toString()));
  }, []);

  const updateLatestData = (response) => {
    var LotId = response?.itemId;
    var pageLotId = id;
    if (LotId == pageLotId) {
      setcurrentPage(1);
      getBidIds(id, 1, "loadBackground");
      getLotItem(id, "loadBackground");
      getHighestBidDetails(id);
    }
  };
  const updateLatestData1 = (response) => {
    console.log(response);
    setcurrentPage(1);
    getBidIds(id, 1, "loadBackground");
    getLotItem(id, "loadBackground");
    getHighestBidDetails(id);
  };

  const getBidIds = async (id, currentPage1, typeload) => {
    if (typeload != "loadBackground") {
      setIsLoading(true);
    }
    let bidsResp = await getBidsPlacedbyItem(id, currentPage1, setPageData);
    setIsLoading(false);
    let prevLotN = bidsResp?.data?.filter((i2) => i2.isWinner === true);
    if (prevLotN.length === 0) {
      prevLotN = bidsResp?.data?.filter((i2) => i2.isWinner === false);
    }
    setShowWinnerRow(prevLotN[0]?.id);
    setLotBids(bidsResp);
    setTotalCount(bidsResp.totalCount);
  };
  // const getLotBids = async(lotItemId)=>{

  // console.log("lotBids",lotBids)
  // console.log("bidsResp",bidsResp.data)
  // }
  const eachPageNumberClick = (number) => {
    setcurrentPage(number);
    getBidIds(id, number);
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(totalCount / setPageData); i++) {
    pages.push(i);
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => {
            eachPageNumberClick(number);
          }}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  const pageNextBtn = () => {
    setcurrentPage(currentPage + 1);
    getBidIds(id, currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const pagePrevBtn = () => {
    setcurrentPage(currentPage - 1);
    getBidIds(id, currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const PlaceQuickBid = async (amt) => {
    //BID7721
    if (userbiddingIdField != "") {
      const body = {
        quickbid: {
          itemId: lotItem?.id,
          registrationNo: userbiddingIdField,
          maxAmount: amt,
          type: "Telephone",
        },
      };

      const resp = await quickbidCallBroadCast(body);
      if (resp?.success) {
        setcurrentPage(1);
        getBidIds(lotItem?.id, 1);
      } else if (resp.errors.length > 0) {
        setUserBiddingError(resp.errors[0]);
      }
    } else {
      setUserBiddingError("Please Enter Bidding ID");
    }
  };

  const getLotItem = async (id, typeload) => {
    setImageloading(true);
    const collectionItem = await getcollectionitembyidCall(id);
    setLotItem(collectionItem?.data);
    setImageloading(false);
    if (typeload != "loadBackground") {
      getCollectionDataCall(collectionItem?.data?.collectionId);
    }
  };
  const getCollectionDataCall = async (id) => {
    let lotCollectionResp = await getCollectionByIdCall(id);
    setCollectionData(lotCollectionResp?.data);
    checkUpcoming(lotCollectionResp?.data);
    updateShowLiveActionBtn(lotCollectionResp?.data);
    showPlaceBidBtnCheck(lotCollectionResp?.data);
  };
  const handleImageChange = (item) => {
    setMainImage(item);
  };
  const showPlaceBidBtnCheck = (collectionData) => {
    console.log("collectionData", collectionData);
    if (
      collectionData?.biddingStartDateOnUTC != undefined &&
      collectionData.auctionType == 2
    ) {
      const gmtDateTime = moment.utc(
        collectionData?.biddingStartDateOnUTC,
        "YYYY-MM-DD h:mm:ss a"
      );
      const local_date = gmtDateTime.local().format("MM/DD/YYYY hh:mm:ss A");
      const collTime333 = new Date(local_date).getTime();
      const currTime = new Date().getTime();
      if (currTime < collTime333) {
        setLotStatusText("Lot Starts At");
      }
    } else if (collectionData.auctionType == 1) {
      if (collectionData?.startOnUtc != undefined) {
        const gmtDateTime = moment.utc(
          collectionData?.startOnUtc,
          "YYYY-MM-DD h:mm:ss a"
        );
        const local_date = gmtDateTime.local().format("MM/DD/YYYY hh:mm:ss A");
        const collTime1 = new Date(local_date).getTime();
        const currTime1 = new Date().getTime();
        if (currTime1 < collTime1) {
          setLotStatusText("Lot Starts At");
        }
      }
    }
  };

  const getHighestBidDetails = async (id) => {
    const bidResp = await gethighestbid(id);
    setBidDetails(bidResp?.bidDetails);
  };
  const chooseAsWinner = async (bid) => {
    const body = {
      itemId: lotItem?.id,
      customerId: bid?.customerId,
      bidId: bid?.id,
    };
    const resp = await choosewinnerBroadCastCall(body);
    setShowAlertModel(true);
  };
  const gotoSelectedTabPage = (lotItem) => {
    if (isAuctionManagement) {
      props.history.push(
        `/dashboard/auctionManagement/AuctionLots/${lotItem?.collectionId}?type=${type}`
      );
    } else {
      props.history.push(`/dashboard/collection/${lotItem?.collectionId}`);
    }
  };
  const updateShowLiveActionBtn = (collectionData) => {
    if (
      collectionData?.startOnUtc != undefined &&
      collectionData.auctionType == 2
    ) {
      var currUTC = moment(collectionData?.startOnUtc).format(
        "MM/DD/YYYY hh:mm:ss A UTC"
      );
      const collTime = new Date(currUTC).getTime();
      const currTime = new Date().getTime();
      if (currTime > collTime) {
        setShowAutoBidBtn(true);
      }
    }
  };

  const checkUpcoming = (cData) => {
    const onlineSD = cData?.onlineStartDate
      ? moment.utc(cData?.startOnUtc).local().format("YYYY-MM-DD")
      : null;
    const liveSD = cData?.biddingStartDate
      ? moment(cData?.biddingStartDateOnUTC).local().format("YYYY-MM-DD")
      : moment(cData?.startOnUtc).local().format("YYYY-MM-DD");
    const todayDate = moment().format("YYYY-MM-DD");
    const todayTime = moment.utc().local().format("h:m:s A");
    const utcTime = moment.utc(cData?.startOnUtc).local().format("h:m:s A");
    const advanceTime = moment(cData?.biddingStartDateOnUTC)
      .local()
      .format("h:m:s A");
    const onlineTime = moment(cData?.onlineStartTime).format("h:m:s A");

    const isOnlineAfter = moment(onlineSD).isAfter(todayDate);
    const isLiveAfter = moment(liveSD).isAfter(todayDate);

    const isOnlineEqual = moment(onlineSD).isSame(todayDate);
    const isLiveEqual = moment(liveSD).isSame(todayDate);
    if (isOnlineAfter) {
      const onlineHourDiff = moment
        .utc(cData?.startOnUtc)
        .local()
        .diff(moment().local(), "hours");

      setDurationLimit(onlineHourDiff);
    } else if (isLiveAfter) {
      const liveHourDiff = moment
        .utc(cData?.biddingStartDateOnUTC)
        .local()
        .diff(moment().local(), "hours", "minutes");

      setDurationLimit(liveHourDiff);
    } else if (isOnlineEqual) {
      const onlineHourDiff = moment
        .utc(cData?.startOnUtc)
        .local()
        .diff(moment().local(), "hours", "minutes");

      setDurationLimit(onlineHourDiff);
    } else if (isLiveEqual) {
      const liveHourDiff = cData?.biddingStartDate
        ? moment
            .utc(cData?.biddingStartDateOnUTC)
            .local()
            .diff(moment().local(), "hours", "minutes")
        : moment
            .utc(cData?.startOnUtc)
            .local()
            .diff(moment.utc().local(), "hours", "minutes");
      // const liveHourDiff =  moment.utc(cData?.startOnUtc).local().diff(moment().local(), 'hours', 'minutes');
      setDurationLimit(liveHourDiff);
    } else {
      setIsUpcoming(false);
      const liveHourDiff = moment(cData?.biddingStartDateOnUTC)
        .local()
        .diff(moment().local(), "hours", "minutes");

      setDurationLimit(0);
    }
  };

  const soldStatusCheck = () => {
    let sold = lotItem?.status == 6;
    let unsold = lotItem?.status == 7;
    if (sold) {
      return "Lot Sold:";
    } else if (unsold) {
      return "Un Sold:";
    } else {
      return `Current Bid (${lotItem?.bidsCount} Bids, reserve met)`;
    }
  };
  const soldValueCheck = () => {
    let sold = lotItem?.status == 6;
    let unsold = lotItem?.status == 7;
    let soldAmt = lotItem?.soldFor;
    let bidAmt = bidDetails?.amount;
    if (sold) {
      return soldAmt;
    } else if (unsold) {
      return "";
    } else {
      return bidAmt;
    }
  };

  const mainImageNew = lotItem?.itemImages;
  let mainImageurl = null;
  if (
    mainImageNew != undefined &&
    mainImageNew != null &&
    mainImageNew.length > 0
  ) {
    mainImageurl = mainImageNew[0].vurtualPath;
  } else {
    mainImageurl = noImage;
  }

  const endDateFormat = moment(collectionData?.onlineEndDate).format("MMMM Do");
  const endTimeFormat = moment(collectionData?.onlineEndTime, "HHmmss").format(
    "h:mm a"
  );

  const StartDateFormat = moment(collectionData?.onlineStartDate).format(
    "MMMM Do"
  );
  const StartTimeFormat = moment(
    collectionData?.onlineStartTime,
    "HHmmss"
  ).format("h:mm a");

  const StartDateFormatLive = moment(collectionData?.liveStartDate).format(
    "MMMM Do"
  );
  const StartTimeFormatLive = moment(
    collectionData?.liveStartTime,
    "HHmmss"
  ).format("h:mm a");

  let timeZone = timeZones.find((i) => collectionData?.timeZoneId === i?.id)
    ?.abbrivation;
  if (!timeZone) {
    timeZone = timeZones.find((i) => collectionData?.timeZoneId === i?.id)
      ?.name;
  }
  let currendyCode = currenciesData.find(
    (i) => collectionData?.currency == i?.id
  )?.currencyCode;

  const createMarkup = (data) => {
    return { __html: data };
  };
  return (
    <div className="viewLotContainer">
      {isAuctionManagement ? (
        <>
          <Row className="viewLotTitleRow">
            <span
              onClick={() => {
                gotoSelectedTabPage(lotItem);
              }}
            >
              {" "}
              <ChevronLeft /> <span>{collectionData?.title}</span>
            </span>
          </Row>
        </>
      ) : null}
      <Row className="lotNumberRow">
        <Button className="lotButton">Lot {lotNumber}</Button>
      </Row>
      {imageLoading ? (
        <>
          <div className="spinner-borderWord spinner-borderWord1">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <Row sm="12" className="imagesWrapper lot-details-container-new">
            <Col sm="2" className="sideImagesColumn noPadding">
              {(lotItem?.itemImages || []).map((itemL, index) => {
                return (
                  <>
                    {itemL?.displayOrder == 8 ? (
                      <>
                        <div
                          class="videosIMage"
                          onClick={(e) => {
                            handleImageChange(itemL);
                          }}
                        >
                          <img src={mainImageurl} alt="Video thumbnail" />
                          <svg
                            id="Layer_1"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <title>play-glyph</title>
                            <path
                              d="M60.54,512c-17.06,0-30.43-13.86-30.43-31.56V31.55C30.12,13.86,43.48,0,60.55,0A32.94,32.94,0,0,1,77,4.52L465.7,229c10.13,5.85,16.18,16,16.18,27s-6,21.2-16.18,27L77,507.48A32.92,32.92,0,0,1,60.55,512Z"
                              fill="#434040"
                            />
                          </svg>
                        </div>
                      </>
                    ) : (
                      <img
                        src={itemL?.vurtualPath}
                        onClick={(e) => {
                          handleImageChange(itemL);
                        }}
                        alt="images"
                        className="sideImages"
                      />
                    )}
                  </>
                );
              })}
              {/* <img src={lotItem.itemImages[0].vurtualPath} onClick={(e) => { handleImageChange(e) }} alt="images" className="sideImages" />
                    <img src={lotItem.itemImages[1].vurtualPath} onClick={(e) => { handleImageChange(e) }} alt="images" className="sideImages" />
                    <img src={lotItem.itemImages[2].vurtualPath} onClick={(e) => { handleImageChange(e) }} alt="images" className="sideImages" /> */}
            </Col>
            <Col sm="6" className="mainImageColumn noPadding">
              {mainImage?.displayOrder == 8 ? (
                <>
                  <iframe
                    width="647"
                    height="488"
                    src={mainImage?.vurtualPath}
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </>
              ) : (
                <img
                  src={mainImage?.vurtualPath || mainImageurl}
                  alt="images"
                  className="mainImage"
                />
              )}
            </Col>
            <Col sm="3" className="noPadding lotRightContainer">
              <p className="viewLotTitle 1111 textBottomStyles wrapText">
                {lotItem?.title}
              </p>
              {lotStatusText == "Lot Starts At" ? (
                <>
                  <p className="viewLotLabel">{"Lot Starts At:"} </p>
                  <p className="viewLotValue textBottomStyles">
                    {collectionData?.auctionType == 2 ? (
                      <>
                        {StartDateFormatLive}, {StartTimeFormatLive}
                      </>
                    ) : (
                      <>
                        {StartDateFormat}, {StartTimeFormat}{" "}
                      </>
                    )}
                    ({timeZone})
                  </p>
                </>
              ) : (
                <>
                  {" "}
                  {collectionData?.auctionType == 2 &&
                  collectionData?.endOnUtc ? (
                    <>
                      <p className="viewLotLabel">{"Lot Closed on:"} </p>
                      <p className="viewLotValue textBottomStyles">
                        {moment(collectionData?.endOnUtc).format("MMMM Do")}{" "}
                        {moment(collectionData?.endOnUtc, "HHmmss").format(
                          "h:mm a"
                        )}{" "}
                        ({timeZone})
                      </p>
                    </>
                  ) : (
                    <>
                      {type == "upcoming" && collectionData?.onlineEndDate ? (
                        <>
                          <p className="viewLotLabel">
                            {type == "past" ||
                            lotItem?.status == 6 ||
                            lotItem?.status == 7
                              ? "Lot Closed on:"
                              : "Lot Starts At:"}{" "}
                          </p>
                          <p className="viewLotValue textBottomStyles">
                            {StartDateFormat}, {StartTimeFormat} ({timeZone})
                          </p>
                        </>
                      ) : (
                        <>
                          {collectionData?.onlineEndDate ? (
                            <>
                              <p className="viewLotLabel">
                                {type == "past" ||
                                lotItem?.status == 6 ||
                                lotItem?.status == 7
                                  ? "Lot Closed on:"
                                  : "Lot Closes:"}{" "}
                              </p>
                              {type == "past" ||
                              lotItem?.status == 6 ||
                              lotItem?.status == 7 ? (
                                <p className="viewLotValue textBottomStyles">
                                  {endDateFormat}, {endTimeFormat} ({timeZone})
                                </p>
                              ) : (
                                <p className="viewLotValue textBottomStyles">
                                  {moment(lotItem?.expiresOn).format(
                                    "MMMM Do h:mm a"
                                  )}
                                  , ({timeZone})
                                </p>
                              )}
                            </>
                          ) : null}
                        </>
                      )}
                    </>
                  )}{" "}
                </>
              )}

              <p className="viewLotLabel">
                {lotItem?.estimatedPriceTo ? "Estimate" : "Fixed"}
              </p>
              <p className="viewLotValue">
                {currendyCode} {lotItem?.estimatedPrice}
                {lotItem?.estimatedPriceTo && (
                  <>
                    {" "}
                    - {currendyCode} {lotItem?.estimatedPriceTo}
                  </>
                )}
              </p>
              {/* <p className="viewLotLabel">Reserve Price:</p>
                    <p className="viewLotValue">{lotItem?.reservedPrice} {lotItem?.currency}</p>
                    <p className="viewLotLabel">Starting Bid:</p>
                    <p className="viewLotValue">{lotItem?.initialBiddingPrice} {lotItem?.currency}</p> */}
              <Row className="lotViewBtnRow">
                {collectionData?.title && (
                  <>
                    {isAuctionManagement ? (
                      <>
                        {type == "past" ? (
                          <>
                            <span style={{ flexDirection: "column" }}>
                              <p className="viewLotLabel">
                                {lotItem?.status == 6
                                  ? "Lot Sold"
                                  : lotItem?.status == 7 && "Lot Unsold"}{" "}
                              </p>
                              {lotItem?.status == 6 && (
                                <p className="viewLotValue textBottomStyles">
                                  {currendyCode} {bidDetails?.amount}{" "}
                                </p>
                              )}
                            </span>
                          </>
                        ) : (
                          <>
                            <span style={{ flexDirection: "column" }}>
                              <p className="viewLotLabel">
                                Current Bid: ({lotItem?.bidsCount} Bids, reserve
                                met)
                              </p>
                              <p className="viewLotValue textBottomStyles">
                                {currendyCode} {bidDetails?.amount}{" "}
                              </p>
                            </span>
                          </>
                        )}
                      </>
                    ) : collectionData?.status == 1 ? (
                      <>
                        <span style={{ flexDirection: "column" }}>
                          <p className="viewLotLabel">{soldStatusCheck()}</p>
                          <p className="viewLotValue textBottomStyles">
                            {" "}
                            {lotItem?.status != 7 && <>{currendyCode}</>}{" "}
                            {soldValueCheck()}
                          </p>
                        </span>
                        {durationLimit > 4 && (
                          <>
                            <Row>
                              <Col sm="6" className="noPadding">
                                <Button
                                  className="viewLotCancelBtn"
                                  onClick={() => {
                                    gotoSelectedTabPage(lotItem);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Col>
                              <Col sm="6" className="noPadding">
                                <Button
                                  className="viewLotEditBtn"
                                  onClick={() => {
                                    props.history.push(
                                      `/dashboard/editLot/${lotItem?.id}`
                                    );
                                  }}
                                >
                                  Edit
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Col sm="6" className="noPadding">
                          <Button
                            className="viewLotCancelBtn"
                            onClick={() => {
                              gotoSelectedTabPage(lotItem);
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col sm="6" className="noPadding">
                          <Button
                            className="viewLotEditBtn"
                            onClick={() => {
                              props.history.push(
                                `/dashboard/editLot/${lotItem?.id}`
                              );
                            }}
                          >
                            Edit
                          </Button>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>
            </Col>
          </Row>{" "}
        </>
      )}
      {!isAuctionManagement ? (
        <div>
          <LotDetailsView lotData={lotItem} />
        </div>
      ) : (
        <>
          <Row>
            <Tab.Container
              id="collections-tabs"
              defaultActiveKey={
                isAuctionManagement ? "placedBids" : "description"
              }
            >
              <Row sm="12" style={{ width: "100%" }}>
                <Col sm="12" className="noPadding">
                  <Nav variant="tabs" className="collectionsTabs">
                    <Nav.Item>
                      <Nav.Link eventKey="description">Lot Details</Nav.Link>
                    </Nav.Item>

                    {isAuctionManagement ? (
                      <>
                        <Nav.Item>
                          {/* <Nav.Link eventKey= {type == 'past'? 'placedBids2': 'placedBids'}>Placed Bids</Nav.Link> */}
                          <Nav.Link eventKey="placedBids">Placed Bids</Nav.Link>
                        </Nav.Item>
                      </>
                    ) : null}
                  </Nav>
                </Col>
                <Col sm="12" className="noPadding">
                  <Tab.Content>
                    <Tab.Pane eventKey="description">
                      <Row sm="12">
                        <div>
                          <LotDetailsView lotData={lotItem} />
                        </div>
                      </Row>
                    </Tab.Pane>

                    {isAuctionManagement ? (
                      <>
                        <Tab.Pane eventKey="placedBids">
                          {type == "past" ? (
                            <Row sm="12">
                              <Row sm="12" className="winnerWrapper">
                                <Card className="winnerCard">
                                  <Card.Header>Winner Details</Card.Header>
                                  <Card.Body>
                                    {lotBids?.winner?.paymentStatus ? (
                                      <>
                                        <AvatarImage />
                                        <p className="winnerName">
                                          {lotBids?.winner?.firstName}{" "}
                                          {lotBids?.winner?.lastName}
                                        </p>
                                        <ul sm="12">
                                          <Row
                                            sm="12"
                                            className="autionwinnerDetails"
                                          >
                                            <li sm="6">
                                              Location:{" "}
                                              {lotBids?.winner?.location}
                                            </li>
                                            <li sm="6">
                                              Bidding Date:{" "}
                                              {moment(
                                                lotBids?.winner?.ordredDate
                                              ).format("DD/MM/YY")}
                                            </li>
                                          </Row>
                                          <Row
                                            sm="12"
                                            className="autionwinnerDetails"
                                            style={{ paddingLeft: "30px" }}
                                          >
                                            <li sm="6">
                                              Bidding Price:{" "}
                                              {lotBids?.winner?.bidPrice}{" "}
                                              {currendyCode}
                                            </li>
                                            <li sm="6">
                                              Payment Status:{" "}
                                              {lotBids?.winner?.paymentStatus}
                                            </li>
                                          </Row>
                                        </ul>
                                        <Row className="cardButtonRow">
                                          <Button
                                            disabled={
                                              lotBids?.winner?.paymentStatus ==
                                              "Paid"
                                                ? true
                                                : false
                                            }
                                            className="paymentLinkBtn"
                                          >
                                            Send Payment Link to Email
                                          </Button>
                                          <Button className="downloadInvoiceBtn">
                                            Download Invoice
                                          </Button>
                                        </Row>
                                      </>
                                    ) : (
                                      <Row
                                        sm="12"
                                        className="autionwinnerDetailsunsold"
                                      >
                                        <p>Lot unsold</p>
                                      </Row>
                                    )}
                                  </Card.Body>
                                </Card>
                              </Row>
                            </Row>
                          ) : (
                            <Row sm="12">
                              {lotBids?.nextBidPrice &&
                                !lotBids?.declaredWinner &&
                                showAutoBidBtn && (
                                  <>
                                    <Col className="addNewBidColumn">
                                      <p className="addNewBidText">
                                        Add New Bid
                                      </p>
                                      <p className="enterBiddingId">
                                        Enter Bidding ID
                                      </p>
                                      <Row>
                                        <Form.Control
                                          type="text"
                                          value={userbiddingIdField}
                                          onChange={(e) => {
                                            setUserbiddingIdField(
                                              e.target.value
                                            );
                                            setUserBiddingError("");
                                          }}
                                          className="biddingIdField"
                                        />
                                        <Button
                                          onClick={() =>
                                            PlaceQuickBid(lotBids?.nextBidPrice)
                                          }
                                          className="dynamicBidBtn"
                                        >
                                          Bid {lotBids?.nextBidPrice}{" "}
                                          {currendyCode}
                                        </Button>
                                      </Row>
                                      {userBiddingError && (
                                        <div className="errorBiddingUser">
                                          {userBiddingError}
                                        </div>
                                      )}
                                    </Col>
                                  </>
                                )}
                            </Row>
                          )}
                          <Row sm="12">
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Bidding ID</th>
                                  <th>Name</th>
                                  <th>Location</th>
                                  <th>Mode of Bid</th>
                                  <th>Bidding Price</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {(lotBids?.data || []).map((bid, index) => {
                                  return (
                                    <>
                                      <tr>
                                        <td> #{bid.biddingId} </td>
                                        <td>
                                          {bid.firstName} {bid.lastName}{" "}
                                        </td>
                                        <td>{bid.location} </td>
                                        <td>{bid.modeOfBid} </td>
                                        <td>
                                          {bid.biddingPrice} {currendyCode}{" "}
                                        </td>
                                        <td>
                                          {lotBids?.declaredWinner ? (
                                            <>
                                              {bid?.isWinner &&
                                                showWinnerRow == bid?.id && (
                                                  <Button className="asWinnerBtn">
                                                    {" "}
                                                    Winner
                                                  </Button>
                                                )}
                                            </>
                                          ) : (
                                            <>
                                              {!lotBids?.declaredWinner &&
                                                showWinnerRow == bid?.id &&
                                                collectionData?.auctionType ==
                                                  2 &&
                                                bidDetails?.metResevedPrice && (
                                                  <Button
                                                    onClick={() => {
                                                      chooseAsWinner(bid);
                                                    }}
                                                    className="asWinnerBtn"
                                                  >
                                                    Choose as Winner
                                                  </Button>
                                                )}
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Row>
                          <Row className="Paginationbtns">
                            {isLoading ? (
                              <>
                                <div className="spinner-borderWord">
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </Row>
                          {lotBids?.data?.length > 0 && (
                            <Row className="Paginationbtns">
                              <button
                                className="arrowBox"
                                onClick={pagePrevBtn}
                                style={{
                                  display:
                                    currentPage == pages[0] ? "none" : "block",
                                }}
                              >
                                {" "}
                                ...{" "}
                              </button>
                              <ul className="pageNumber">
                                {renderPageNumbers}
                              </ul>
                              <button
                                className="arrowBox paginationNextBtn"
                                onClick={pageNextBtn}
                                style={{
                                  display:
                                    currentPage == pages[pages.length - 1]
                                      ? "none"
                                      : "block",
                                }}
                              >
                                {" "}
                                ...{" "}
                              </button>
                            </Row>
                          )}
                        </Tab.Pane>
                      </>
                    ) : null}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Row>
        </>
      )}
      <CustomModal
        show={showAlertModel}
        titleText="You have chosen your winner successfully."
        deleteText=""
        cancelText="ok"
        onCancel={() => {
          setShowAlertModel(false);
        }}
        onDelete={() => {
          setShowAlertModel(false);
        }}
      />
    </div>
  );
};

export default withRouter(ViewLot);
