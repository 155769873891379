import React, { useEffect, useState } from "react";
import { Card, Button, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import noImage from "./../../assets/noImage.jpg";
import { getTimeZones } from "./../../library/Requests";
import moment from "moment";
import "./styles.css";

const CollectionsCard = (props) => {
  // const {title, date, id} = props;
  const { collectionData, timeZone } = props;

  const checkImage = (colldata) => {
    if (
      colldata?.coverImage === "string" ||
      colldata?.coverImage === null ||
      colldata?.coverImage === undefined ||
      colldata?.coverImage === ""
    ) {
      return noImage;
    } else {
      return colldata?.coverImage;
    }
  };

  const actionType = collectionData?.auctionType == "1" ? "online" : "live";
  const gotoViewLot = () => {
    window.scrollTo(0, 0);
    props.history.push(`/dashboard/collection/${collectionData?.id}`);
  };

  return (
    <Col sm="4" className="cardsColumn">
      <Card className="collectionsCard">
        <Card.Img
          className="mainImageNew"
          variant="top"
          onClick={gotoViewLot}
          src={checkImage(collectionData)}
          style={{ cursor: "pointer" }}
        />
        <Card.Body>
          <Card.Text>
            {collectionData?.auctionType == "1" ? (
              <>
                <span className="date">
                  {moment(collectionData?.onlineStartDate).format(
                    "MMMM Do YYYY"
                  )}
                </span>
                <Line className="lineStyles" />
                <span className="time">
                  {" "}
                  {moment(collectionData?.onlineStartTime, "HHmmss").format(
                    "h:mm a"
                  )}{" "}
                  {timeZone}{" "}
                </span>
              </>
            ) : (
              <>
                <span className="date">
                  {moment(collectionData?.liveStartDate).format("MMMM Do YYYY")}
                </span>
                <Line className="lineStyles" />
                <span className="time">
                  {" "}
                  {moment(collectionData?.liveStartTime, "HHmmss").format(
                    "h:mm a"
                  )}{" "}
                  {timeZone}{" "}
                </span>
              </>
            )}
            <Line className="lineStyles" />
            <span> {actionType} Auction</span>
          </Card.Text>
          <Card.Title className="cardTitleHeight lineClamp">
            {collectionData?.title}
          </Card.Title>
          <Button
            className="browseAuctionBtn"
            onClick={() => {
              props.history.push(`/dashboard/collection/${collectionData?.id}`);
            }}
          >
            Browse Lot
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default withRouter(CollectionsCard);
