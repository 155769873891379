import { Button, Col, Container, Row } from "react-bootstrap";
import OpenTok from "opentok";
import { useState } from "react";
import {
  SERVER_IP,
  VONTAGE_API_KEY,
  VONTAGE_API_SECRET,
} from "../../library/constants";
import axios from "axios";

import "./styles.css";

let Auction = (props) => {
  console.log(SERVER_IP);
  let [publisher, setPublisher] = useState(null),
    [isAudio, setIsAudio] = useState(true),
    [isVideo, setIsVideo] = useState(true),
    [isLoadVideo, setIsLoadVideo] = useState(false),
    opentok = new OpenTok(VONTAGE_API_KEY, VONTAGE_API_SECRET),
    urlSearchParams = new URLSearchParams(window.location.search),
    params = Object.fromEntries(urlSearchParams.entries()),
    joinRoom = () => {
      setIsLoadVideo(true);
      opentok.createSession(
        { mediaMode: "routed", archiveMode: "always" },
        function (err, argSession) {
          let session = window.OT.initSession(
            VONTAGE_API_KEY,
            argSession.sessionId
          );
          session.connect(
            opentok.generateToken(session.sessionId, {}),
            function (error) {
              if (error) {
                console.log("Error connecting: ", error.name, error.message);
              } else {
                let publisher = window.OT.initPublisher("publisher", {
                  insertMode: "append",
                  height: "400px",
                  width: "600px",
                });
                setPublisher(publisher);
                session.publish(publisher);
                setIsLoadVideo(false);
                params["streamCode"] = session.sessionId;

                axios
                  .post(SERVER_IP + "/api/BroadCast/startLive", params)
                  .then((response) => console.log(response));

                axios
                  .post(
                    "https://api-fahh.azurewebsites.net/api/BroadCast/startLive",
                    params
                  )
                  .then((response) => console.log(response));
              }
            }
          );
        }
      );
    };
  return (
    <Container className="mt-2">
      <Row className="mt-1">
        <Col md={{ span: 6, offset: 3 }}>
          {isLoadVideo && (
            <div className="spinner-borderWord">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {publisher ? (
            <div
              id="publisher"
              className="text-center aligned-items-center"
            ></div>
          ) : (
            <div
              style={{ height: "100px", width: "500px" }}
              className="text-center aligned-items-center"
            ></div>
          )}
        </Col>
      </Row>

      {!publisher && (
        <Row className="controlButtons">
          <Col md={12}>
            <Button
              variant="primary"
              onClick={joinRoom}
              disabled={isLoadVideo || !params.collectionId}
            >
              Start video
            </Button>
          </Col>
        </Row>
      )}
      {publisher && (
        <>
          <Row className="controlButtons">
            <Col md={12}>
              <Button
                variant="primary"
                onClick={() => {
                  publisher.publishAudio(!isAudio);
                  setIsAudio(!isAudio);
                }}
              >
                {isAudio ? "Mute" : "Unmute"}
              </Button>
              <Button
                className="ml-1"
                variant="primary"
                onClick={() => {
                  publisher.publishVideo(!isVideo);
                  setIsVideo(!isVideo);
                }}
              >
                {isVideo ? "Stop" : "Start"} Video
              </Button>
              <Button
                className="ml-1"
                variant="primary"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Disconnect
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default Auction;
