import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import { ReactComponent as GreenDot } from "./../../assets/greenDot.svg";
import "./styles.css";

const BannerActiveAuction = (props) => {
  return (
    <div className="bannerContainer">
      <Row className="bannerRow">
        <Col sm="9" className="contentColumn columnStyles">
          <p>
            {" "}
            <ChevronLeft /> Active Auction{" "}
          </p>
          <p>
            <span className="date">22nd Jan </span>
            <Line className="lineStyles" />
            <span className="time"> 4:00 pm IST </span>
            <Line className="lineStyles" />
            <span className="onlineAuction">Online Auction</span>
          </p>
          <p className="bannerTitle">
            {/* The Collection of Big Daddy Kane */}
            The collection of Mr. and Mrs. John H. Gutfreund
          </p>
        </Col>
        <Col sm="3" className="btnColumn columnStyles">
          <Row className="btnTopRow" style={{ float: "right" }}>
            <Button className="viewLiveBtn">
              <GreenDot style={{ marginRight: "5px" }} /> View Live
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(BannerActiveAuction);
