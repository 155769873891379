import React, { Component } from "react";
import { Row, Col, Button, Table, Form, Modal } from "react-bootstrap";
import CustomModal from "./../../components/CustomModal";
import { ReactComponent as TableEditIcon } from "./../../assets/tableEditIcon.svg";
import "./styles.css";
class CustomerInformation extends Component {
  static propTypes = {};
  state = {
    showPopup: false,
    showLogisticPopup: false,
    successPopup: false,
  };
  componentDidMount = () => {};
  hideModal = () => {
    this.setState({
      showLogisticPopup: false,
      showPopup: false,
      successPopup: false,
    });
  };
  modalPopUp = () => {
    this.setState({ showPopup: true });
  };
  logisticModalPopUp = () => {
    this.setState({ showLogisticPopup: true });
  };

  saveChange = () => {
    this.setState({
      showLogisticPopup: false,
      showPopup: false,
      successPopup: true,
    });
  };

  getfullAddress = (user) => {
    let fullname = user?.address1 ? user?.address1 : "";
    fullname += user?.address2 ? `, ${user?.address2}` : "";
    fullname += user?.city ? `, ${user?.city}` : "";
    fullname += user?.state ? `, ${user?.state}` : "";
    fullname += user?.county ? `, ${user?.county}` : "";
    fullname += user?.zipPostalCode ? `, ${user?.zipPostalCode}` : "";
    return fullname;
  };
  render() {
    const { orderDetails, selectedOrder, addressList } = this.props;
    const address = orderDetails?.address ?? {};
    const { showPopup, showLogisticPopup, successPopup } = this.state;
    return (
      <>
        <Col sm="6" style={{ paddingLeft: "0px" }}>
          <div className="customerInformationContainer">
            <Table btransactioned className="customerInformationTable">
              <thead>
                <tr>
                  <th colspan="5" className="customerInformationHeading">
                    Customer Information{" "}
                    <TableEditIcon
                      className="tableEditIcon"
                      onClick={this.modalPopUp}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Customer name</td>
                  <td>
                    {address?.firstName}
                    {` `}
                    {address?.lastName}
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>
                    {address?.countryCode} {address?.phoneNumber}
                  </td>
                </tr>
                <tr>
                  <td>Email ID</td>
                  <td>{address?.email}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{this.getfullAddress(address)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
        <Col sm="6" style={{ paddingRight: "0px" }}>
          <div className="logisticInformationContainer">
            <Table btransactioned className="logisticInformationTable">
              <thead>
                <tr>
                  <th colspan="5" className="logisticInformationHeading">
                    Logistic Information{" "}
                    <TableEditIcon
                      className="tableEditIcon"
                      onClick={this.logisticModalPopUp}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Reference Number</td>
                  <td>#0000000</td>
                </tr>
                <tr>
                  <td>Courier Company</td>
                  <td>none</td>
                </tr>
                <tr>
                  <td>Tracking ID</td>
                  <td>#90i8766</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>

        <Modal
          show={showPopup}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="ContentDiv">
              <p className="CustomerInformationText">Customer information</p>
              <Form.Label className="FormLabelText">Title</Form.Label>
              <div className="TitleSelectDiv">
                <Form.Control as="select" className="InputsSelect">
                  <option>Mr.</option>
                  <option>Mrs.</option>
                  <option>Miss</option>
                </Form.Control>
              </div>
              <Form.Label className="FormLabelText">Customer name</Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="Customer name"
                value={`${address?.firstName} ${address?.lastName}`}
                className="InputsForm"
              />

              <Form.Label className="FormLabelText">Email ID</Form.Label>
              <Form.Control
                type="text"
                disabled
                value={address?.email}
                placeholder="Email ID"
                className="InputsForm"
              />
              <Form.Label className="FormLabelText">Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                disabled
                value={address?.phoneNumber}
                className="InputsForm"
              />
              <Form.Label className="FormLabelText">Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                className="InputsForm"
                onChange={this.handleChangeValue}
                as="select"
              >
                {(addressList || [])?.map((address, index) => {
                  return (
                    <>
                      <option value={address?.id}>
                        {this.getfullAddress(address)}
                      </option>
                    </>
                  );
                })}
              </Form.Control>
            </div>
          </Modal.Body>
          <Modal.Footer className="ModalFooter">
            <Button
              className="modalCandelBtn"
              onClick={() => this.setState({ showPopup: false })}
            >
              Cancel
            </Button>
            <Button
              className="modalDeleteBtn modalDeleteBtns"
              onClick={this.saveChange}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showLogisticPopup}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="ContentDiv">
              <p className="LogisticInformationText">Logistic information</p>
              <Form.Label className="FormLabelText">
                Reference number
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Reference number"
                className="InputsForm"
              />
              <Form.Label className="FormLabelText">Courier Company</Form.Label>
              <div className="FormSelectDiv">
                <Form.Control as="select" className="InputsFormSelect">
                  <option className="courierTestOption">None</option>
                  <option className="courierTestOption">Delhivery</option>
                  <option className="courierTestOption">Bluedart</option>
                  <option className="courierTestOption">FedEx</option>
                  <option className="courierTestOption">eCom Express</option>
                  <option className="courierTestOption">Aramex</option>
                  <option className="courierTestOption">
                    Indian Post Service
                  </option>
                  <option className="courierTestOption">Gati</option>
                </Form.Control>
              </div>
              <Form.Label className="FormLabelText">
                Enter Tracking URL
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Tracking URL"
                className="InputsForm"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="ModalFooter">
            <Button
              className="modalCandelBtn"
              onClick={() => this.setState({ showLogisticPopup: false })}
            >
              Cancel
            </Button>
            <Button
              className="modalDeleteBtn modalDeleteBtns"
              onClick={this.saveChange}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <CustomModal
          show={successPopup}
          titleText="Customer information Changes are saved Successfully."
          deleteText="ok"
          cancelText=""
          onCancel={this.hideModal}
          onDelete={this.hideModal}
        />
      </>
    );
  }
}

export default CustomerInformation;
