import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  getcollectionitembyidCall,
  getCollectionItemsCall,
  getTimeZones,
  gethighestbid,
  getCollectionByIdCall,
  getcurrenciesCall,
  followbidCall,
  unfollowbidCall,
} from "./../../library/Requests";

import PreviewLot from "./../PreviewLot";

import { LOGINID } from "./../../Scenes/common";

class previewSingleLotPage extends Component {
  static propTypes = {};
  state = {
    isEditLot: 0,
    collectionId: 0,
    collectionItemId: 0,
    lotItemDetailsData: {},
  };
  componentDidMount = () => {
    const id = this.props?.match?.params?.id;
    this.getLotData(id);
  };
  getLotData = async (id) => {
    window.scrollTo(0, 0);
    // this.setState({isLoading: true})
    // const collectionItem = await getcollectionitembyidCall(id, LOGINID);
    // this.setState({isLoading: false, lotItemDetailsData: collectionItem?.data, collectionId: collectionItem?.data?.collectionId })
  };
  render() {
    const { collectionId, lotItemDetailsData } = this.state;
    const id = this.props?.match?.params?.id;
    return (
      <>
        <div className="previewLotPage-container">
          <PreviewLot
            isEditLot={false}
            isPublishSingleLot={true}
            collectionIdNewId={collectionId}
            collectionItemId={id}
            lotItemDetails={lotItemDetailsData}
            closePreviewPageCall={() => {}}
          />
        </div>
      </>
    );
  }
}

export default previewSingleLotPage;
