import React, { Component } from "react";
import { Row, Col, Button, Table, Modal, Form } from "react-bootstrap";
import "./styles.css";
import CustomModal from "./../../components/CustomModal";
import { ReactComponent as StatusTicked } from "./../../assets/statusTicked.svg";
import { ReactComponent as StatusEmpty } from "./../../assets/statusEmpty.svg";

import moment from "moment";

class TransactionDetails extends Component {
  static propTypes = {};
  state = {
    changeOrder: false,
    successModal: false,
  };
  componentDidMount = () => {};

  render() {
    const {
      orderDetails,
      selectedOrder,
      orderStatusList,
      handleStatusChange,
      changeOrderStatusSuccess,
      hideModal,
      changeOrderStatus,
    } = this.props;
    const { changeOrder, successModal } = this.state;
    const orderBids = orderDetails?.orderedBids;
    const orderePayemntType = orderBids?.map((item, index) => (
      <span key={index}>{item.paymentType}</span>
    ));
    return (
      <>
        <div className="transactionDetailsContainer">
          <Table btransactioned className="transactionDetailsTable">
            <thead>
              <tr>
                <th colspan="5" className="transactionDetailsHeading">
                  Transaction Details{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Payment Date</td>
                <td>
                  {orderDetails?.paymentStatus == "Paid"
                    ? moment(orderDetails?.paidDate).format("DD MMMM, YYYY")
                    : null}
                </td>
              </tr>
              <tr>
                <td>Type</td>
                <td>
                  {orderDetails?.paymentStatus == "Paid"
                    ? orderePayemntType
                    : null}{" "}
                </td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>
                  {orderDetails?.bidAmount} {orderDetails?.currencyCode}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="timelineContainer">
          <Table btransactioned className="timelineTable">
            <thead>
              <tr>
                <th colspan="2" className="timelineHeading">
                  <span className="timelineText"> Timeline</span>
                  <Button
                    className="updateStatusBtn"
                    onClick={() => changeOrderStatus(orderDetails)}
                  >
                    Update Status
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              <ul className="statusList">
                {(orderStatusList || []).map((item, index) => {
                  const statusIndex = orderStatusList.find(
                    (i) => orderDetails?.orderStatus == i?.orderStatus
                  )?.id;
                  return (
                    <li>
                      {index < statusIndex ? (
                        <StatusTicked className="orderStatusIcons" />
                      ) : (
                        <StatusEmpty className="orderStatusIcons" />
                      )}
                      {item.orderStatus}
                    </li>
                  );
                })}
              </ul>
            </tbody>
          </Table>
        </div>
        <CustomModal
          show={successModal}
          titleText="Order status has been Changed Successfully."
          deleteText="ok"
          cancelText=""
          onDelete={() => this.setState({ successModal: false })}
        />
      </>
    );
  }
}

export default TransactionDetails;
