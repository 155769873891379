import React, { useState } from "react";

// import { Link } from "react-router-dom";

import SliderOne from "./../../assets/image/slide1.jpg";
import menuIcon from "./../../assets/image/menu.svg";
import closeIcon from "./../../assets/image/close.svg";

import "./css/LiveActionLIstContent.css";

class LiveActionLIstContent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      openLeftSideBar: false,
    };
  }
  getmainImage = (item: any) => {
    if (item?.itemImages && item?.itemImages.length > 0) {
      let itemURRL = item?.itemImages.find(
        (i: any) => i?.displayOrder == 1
      )?.vurtualPath;
      return itemURRL;
    }
  };

  // export const LiveActionLIstContent: React.FC = () => {
  render() {
    const { openLeftSideBar } = this.state;
    const ListItems = this.props?.collectionItems;
    const lotData = this.props?.lotData;
    const collectionData = this.props?.collectionData;
    let currencyName = this.props?.currenciesList.find(
      (i: any) => collectionData?.currency == i?.id
    )?.currencyCode;

    return (
      <>
        <div
          className="d-block d-lg-none live_action_left_sidebar_icon"
          onClick={() => {
            this.setState({ openLeftSideBar: true });
          }}
        >
          <img src={menuIcon} alt="menu-icon" />
        </div>
        <div className={`auction_box_1 ${openLeftSideBar ? "active" : ""}`}>
          <h2 className="mb-0 position-relative">
            <span>{collectionData.title}</span>
            <img
              src={closeIcon}
              alt="close-icon"
              className="position-absolute d-block d-lg-none"
              onClick={() => {
                this.setState({ openLeftSideBar: false });
              }}
            />
          </h2>
          <div className="auction_left_list">
            {(ListItems || []).map((item: any, index: any) => {
              return (
                <div
                  key={item.id}
                  className="auction_left_flex_box d-flex align-items-center"
                >
                  <div className="auction_left_img">
                    <img src={this.getmainImage(item)} />
                  </div>
                  <div className="auction_left_details">
                    <h6 className={item.onlineClass}>
                      Lot {item.sno}{" "}
                      {lotData?.id == item?.id && (
                        <span className="liveDotIcon"></span>
                      )}
                    </h6>
                    <h4>{item.title}</h4>
                    {item?.itemStatus === "Sold" ||
                    item?.itemStatus === "Unsold" ? (
                      <>
                        {item?.itemStatus === "Sold" && (
                          <>
                            <h5 className="mb-0"> Lot Sold : </h5>
                            <h4 className="">
                              {" "}
                              {currencyName} {item.soldFor}{" "}
                            </h4>
                          </>
                        )}

                        {item?.itemStatus === "Unsold" && (
                          <h5 className="mb-0"> Lot {item?.itemStatus}</h5>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        <h5>Estimate: </h5>{" "}
                      </>
                    )}

                    {item?.itemStatus != "Sold" &&
                      item?.itemStatus != "Unsold" && (
                        <h3 className="mb-0">
                          {currencyName} {item?.estimatedPrice}
                          {item?.estimatedPriceTo && (
                            <>
                              {" "}
                              - {currencyName} {item?.estimatedPriceTo}{" "}
                            </>
                          )}
                        </h3>
                      )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default LiveActionLIstContent;
