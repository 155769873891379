import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
import { ReactComponent as PlusIcon } from "./../../assets/plusIcon.svg";
import { ReactComponent as EditPencil } from "./../../assets/editPencil.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/deleteIcon.svg";
import "./styles.css";
import { loginActionCall } from "./../../library/Requests";
import { LOGIN_SUCCESS } from "../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "../../Scenes/common";

import { AES, enc } from "crypto-js";

import { ENCRYPTED_KEY } from "../../library/constants.js";

import { withRouter } from "react-router-dom";
class LoginPage extends Component {
  static propTypes = {};

  state = {
    userName: "",
    password: "",
    NOData: "",
  };
  onKeyUp = this.onKeyUp.bind(this);
  ChangeValue = async (selectedType, selectedvalue) => {
    this.setState({ [selectedType]: selectedvalue });
  };
  loginAction = async () => {
    const { userName, password } = this.state;
    if (userName != "" && password != "") {
      const body = {
        checkoutAsGuest: false,
        email: userName,
        password: password,
        returnURL: "admin",
      };
      const resp = await loginActionCall(body);

      if (resp.success) {
        localStorage.removeItem("cat");
        localStorage.removeItem("crt");
        localStorage.removeItem("customerInfoDetails");

        let obj = resp?.customer;
        const envrypted = AES.encrypt(
          JSON.stringify(obj),
          ENCRYPTED_KEY
        ).toString();
        localStorage.setItem("customerInfoDetails", envrypted);
        localStorage.setItem("cat", resp?.accessToken);
        localStorage.setItem("crt", resp?.refreshToken);
        // const data = JSON.parse(localStorage.getItem('userInfoDetails'));
        this.props.history.push(`/dashboard/collections`);
        triggerEventForProfileUpdate(LOGIN_SUCCESS);
      } else if (resp.errors.length > 0) {
        this.setState({ NOData: resp.errors[0] });
      }
    } else {
      this.setState({ NOData: "Please enter the username and password" });
    }
  };

  onKeyUp(event) {
    if (event.key === "Enter") {
      this.loginAction();
    }
  }

  componentDidMount = () => {
    const userD = localStorage.getItem("customerInfoDetails");
    if (userD != undefined && userD != null && userD != "") {
      this.props.history.push(`/dashboard/collections`);
    }
    console.log(this.props);
  };
  render() {
    const { userName, password, NOData } = this.state;
    return (
      <>
        <div className="login-wrapper">
          <div className="loginManagement">
            <h2>Login </h2>

            <div className="login-page-container">
              <label for="uname">
                <b>Username</b>
              </label>
              <input
                type="text"
                onKeyPress={this.onKeyUp}
                onChange={(e) => {
                  this.ChangeValue("userName", e.target.value);
                }}
                value={userName}
                className="login_input_page"
                placeholder="Enter Username"
                name="uname"
                required
              />

              <label for="psw">
                <b>Password</b>
              </label>
              <input
                type="password"
                onKeyPress={this.onKeyUp}
                onChange={(e) => {
                  this.ChangeValue("password", e.target.value);
                }}
                value={password}
                className="login_input_page"
                placeholder="Enter Password"
                name="psw"
                required
              />
              {NOData && <div className="noDataerrorMessage">{NOData}</div>}
              <button
                type="submit"
                onClick={() => this.loginAction()}
                className="login_btn_page"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      LoginPage,
    },
    dispatch
  );

export default withRouter(LoginPage);
