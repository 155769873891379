import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "./../../assets/Logo.svg";
import header_Logo from "./../../assets/Logo/header_logo_new.png";
import { ReactComponent as SearchIcon } from "./../../assets/Search.svg";
import { ReactComponent as UserIcon } from "./../../assets/User.svg";
import { Row, Col, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import "./styles.css";
import AdminTabs from "../AdminTabs";

import { withRouter } from "react-router-dom";

const Header = (props) => {
  const [isuserLogin, setIsUserLogin] = useState(false);
  const [selectedTab, setSelectedTab] = useState("new pate"),
    location = useLocation();

  useEffect(() => {
    checkLogin();
  }, [props]);

  const checkLogin = () => {
    const userD = localStorage.getItem("customerInfoDetails");
    if (userD != undefined && userD != null && userD != "") {
      setIsUserLogin(true);
    } else {
      setIsUserLogin(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("customerInfoDetails");
    localStorage.clear();
    props.history.push(`/login`);
    checkLogin();
  };
  const handleLogin = () => {
    props.history.push(`/login`);
  };
  return (
    <>
      <div className="header">
        <Row className="upperHeader">
          <Col sm={{ span: 4, offset: 4 }}>
            <ul className="upperHeaderLinks">
              <li>How To Sell</li>
              <li>Contact</li>
            </ul>
          </Col>
        </Row>
        <Row className="lowerHeader">
          <Col sm="4">
            <img src={header_Logo} className="appLogo" alt="Logo" sm="4" />
          </Col>
          {isuserLogin && (
            <Col sm="8" className="headerIcons">
              <SearchIcon className="searchIcon" />

              <UserIcon />
              <Button onClick={handleLogout} className="logoutButtonNAme">
                {" "}
                Logout{" "}
              </Button>
            </Col>
          )}
          {!isuserLogin && (
            <Col sm="8" className="headerIcons">
              <Button onClick={handleLogin} className="logoutButtonNAme">
                {" "}
                login{" "}
              </Button>
            </Col>
          )}
        </Row>
      </div>
      {isuserLogin && location.pathname !== "/admin/auction" && (
        <AdminTabs selectedTab={selectedTab} />
      )}
    </>
  );
};

export default withRouter(Header);
