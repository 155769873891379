import React, { Component } from "react";
import { Row, Col, Button, Tab, Nav } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ReactComponent as PlusIcon } from "./../../assets/plusIcon.svg";
import ArticleCard from "./../../components/ArticleCard";
import "./styles.css";
//

import SearchComponent from "./../../components/SearchComponent";

import {
  getdraftedarticleCall,
  getpublishedarticleCall,
} from "./../../library/Requests/ContentManagement";

class ContentManagement extends Component {
  static propTypes = {};

  state = {
    draftedArticles: [],
    publishedArticles: [],
  };

  componentDidMount = () => {
    console.log(this.props);

    this.getDraftedArticles("");
    this.getPublishedArticles("");
  };
  getDraftedArticles = async (search) => {
    const draftedResp = await getdraftedarticleCall(search);
    console.log("draftedResp", draftedResp);
    this.setState({ draftedArticles: draftedResp?.data });
  };
  getPublishedArticles = async (search) => {
    const publishedResp = await getpublishedarticleCall(search);
    this.setState({ publishedArticles: publishedResp?.data });
  };
  searchClick = (search) => {
    this.getDraftedArticles(search);
    this.getPublishedArticles(search);
  };
  render() {
    const { draftedArticles, publishedArticles } = this.state;
    const activeTabs =
      this.props?.location?.search == "?type=published" ? "active" : "upcoming";
    return (
      <>
        <div className="wrapper">
          <div className="ArticlesContainer">
            <Row className="ArticlesManagementRow">
              <Col sm="5" className="noPadding">
                <span className="headingText">Content Management</span>
              </Col>
              <Col className="createBtnColumn">
                <div className="articleClass">
                  <SearchComponent
                    searchClickCall={this.searchClick}
                    placeHolderText="Search Article"
                  />
                </div>
                <Button
                  className="createArticleBtn"
                  onClick={(event) => {
                    this.props.history.push(`/dashboard/createArticle`);
                  }}
                >
                  <PlusIcon className="plusIcon" />
                  Create Article
                </Button>
              </Col>
            </Row>

            <Tab.Container id="collections-tabs" defaultActiveKey={activeTabs}>
              <Row sm="12">
                <Col sm="12" className="noPadding">
                  <Nav variant="tabs" className="collectionsTabs">
                    <Nav.Item>
                      <Nav.Link eventKey="upcoming" className="firstTab">
                        Drafted Articles
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="active">Published Articles</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm="12" className="noPadding">
                  <Tab.Content>
                    <Tab.Pane eventKey="upcoming" className="cardGroup">
                      <Row sm="12">
                        {(draftedArticles || []).map((item, key) => {
                          return (
                            <>
                              {/* {(item?.status==2) && ( */}
                              <ArticleCard ArticleData={item} />
                              {/* )} */}
                            </>
                          );
                        })}
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="active" className="cardGroup">
                      <Row sm="12">
                        {(publishedArticles || []).map((item, key) => {
                          return (
                            <>
                              {/* {(item?.status==1) && ( */}
                              <ArticleCard ArticleData={item} />
                              {/* )} */}
                            </>
                          );
                        })}
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>

            <Row className="viewMoreBtnRow">
              {/* <Button
                    className="viewMoreBtn"
                >
                    View More
                </Button> */}
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ContentManagement);
