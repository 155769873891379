import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import { ReactComponent as GreenDot } from "./../../assets/greenDot.svg";
import {
  getCollectionByIdCall,
  updateCollectionStatusId,
  getTimeZones,
} from "./../../library/Requests";
import "./styles.css";

const BannerUpcomingAuction = (props) => {
  let { id } = useParams();

  const [currentCollection, setCurrentCollection] = useState("");
  const [bannerTimeZ, setBannerTimeZ] = useState([]);

  useEffect(async () => {
    getCollectionById();
    const bannerTimezResp = await getTimeZones();
    setBannerTimeZ(bannerTimezResp.data);
  }, []);

  const bannerTimeZone = bannerTimeZ.find(
    (i) => currentCollection?.timeZoneId === i?.id
  )?.name;
  const getCollectionById = async () => {
    let resp = await getCollectionByIdCall(id);
    setCurrentCollection(resp?.data);
  };

  const params = new URLSearchParams(props.location.search);

  const type = params.get("type");

  const goBackpage = () => {
    props.history.push(`/dashboard/auctionManagement?type=${type}`);
  };

  const gotoViewLivepage = () => {
    props.history.push(`/dashboard/liveAuctionLanding/${id}`);
  };
  return (
    <div className="bannerContainer">
      <Row className="bannerRow">
        <Col sm="9" className="contentColumn columnStyles">
          <p>
            {" "}
            <span onClick={goBackpage} style={{ cursor: "pointer" }}>
              {" "}
              <ChevronLeft className="chevronLeft" />{" "}
              <span className="auctionTypeText">{type}</span> Auctions{" "}
            </span>{" "}
          </p>
          {currentCollection?.title && (
            <p>
              <span className="date">
                {currentCollection?.auctionType == "1" ? (
                  <>
                    {moment(currentCollection?.onlineStartDate).format(
                      "Do MMMM"
                    )}{" "}
                  </>
                ) : (
                  <>
                    {moment(currentCollection?.liveStartDate).format("Do MMMM")}
                  </>
                )}
              </span>
              <Line className="lineStyles" />
              <span className="time">
                {currentCollection?.auctionType == "1" ? (
                  <>
                    {moment(
                      currentCollection?.onlineStartTime,
                      "HHmmss"
                    ).format("h:mm a")}{" "}
                    {bannerTimeZone}{" "}
                  </>
                ) : (
                  <>
                    {moment(currentCollection?.liveStartTime, "HHmmss").format(
                      "h:mm a"
                    )}{" "}
                    {bannerTimeZone}{" "}
                  </>
                )}
              </span>
              <Line className="lineStyles" />
              <span className="onlineAuction">
                {currentCollection?.auctionType === 1 ? "Online" : "Live"}{" "}
                Auction
              </span>
              <span>
                {type == "past" ? (
                  <>
                    {" "}
                    <Line className="lineStyles" />
                    <span className="auctionClosed">Auction Closed </span>{" "}
                  </>
                ) : null}
              </span>
            </p>
          )}
          <p className="bannerTitle">{currentCollection?.title}</p>
        </Col>
        {type === "active" &&
          currentCollection?.auctionType == 2 &&
          currentCollection.endOnUtc == null && (
            <>
              <Col sm="3" className="btnColumn columnStyles">
                <Row className="btnTopRow" style={{ float: "right" }}>
                  <Button
                    className="viewLiveBtn"
                    onClick={() => {
                      gotoViewLivepage();
                    }}
                  >
                    <GreenDot style={{ marginRight: "5px" }} /> View Live
                  </Button>
                </Row>
              </Col>
            </>
          )}
      </Row>
    </div>
  );
};

export default withRouter(BannerUpcomingAuction);
